import {Rect} from "./svgrects";
import * as React from "react";
import {ru} from "../xutils";

export default function Frames(props) {

  const {cSz, frame, imageElements, xw, xh, polylines} = props

  const renderMainFrame = ()=> {
    if (!frame || frame.type === "none")
      return
    if (polylines) {
      return <>
        {polylines.map(line => {
          return <polyline key={JSON.stringify(line[0])} points={line.map(point => `${point.x}, ${point.y}`).join(" ")} stroke={frame.color || "black"} fill="none" strokeWidth={frame.thickness}/>
        })}
      </>
    }
    return <rect x={0} y={0} width={xw*cSz} height={xh*cSz} fill="none" stroke={frame.color || "black"} strokeWidth={frame.thickness}></rect>
  }

  const renderImageFrames = () => {
    if (!frame)
      return
    return imageElements.map(e=> {
      return <Rect sq={e.sq}  strokeWidth={frame.thickness} key={`im_frame_rect_${ru.str(e.rect)}`} stroke={frame.color || "black"}/>
    })
  }

  return <g className="frameLayer">
    {renderImageFrames()}
    {renderMainFrame()}
  </g>;
}
