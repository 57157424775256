import {SvgIcon, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {LEFT, RIGHT} from "../../cellTypes";

export default function VrtOverlayTextBtn({overlayPosition, text, fontFamily, onClick, strike}) {
    const lineX = overlayPosition === RIGHT ? 16 : 8
    const textX = overlayPosition === RIGHT ? 19 : 4
    return <Tooltip title={overlayPosition === LEFT ? "overlay left" : "overlay right"}>
        <IconButton key={`overlay_list_btn_${overlayPosition}`} onClick={onClick}>
            <SvgIcon width={30} height={30}>
                <text x={textX} y={0} textAnchor="middle" fontSize={7} fontFamily={fontFamily}>
                    <tspan dy={7}>{text}</tspan>
                </text>
                <rect x={1} y={1} width={22} height={22} fill={"none"} stroke="black"></rect>
                <line x1={lineX} x2={lineX} y1={1} y2={22} strokeWidth={0.5} stroke="black"/>
                {strike && <line x1={1} x2={22} y1={1} y2={22} strokeWidth={2.5} stroke="red"/>}
            </SvgIcon>
        </IconButton>
    </Tooltip>
}
