import React, {useEffect} from "react";
import {fetchOpenUrl, fetchUrl} from "../features/urlcache/urlSlice";
import {useDispatch, useSelector} from "react-redux";


const Paragraphstyle = (props) => {
    const {iDs, stream} = props
    const {xId, uId} = iDs
    const font = props.paragraphStyle?.text?.font
    const dispatch = useDispatch()
    const signedUrl = useSelector((state) => state.persistedReducer.urls.cache?.[font]?.url)

    useEffect(()=>{
        if (stream) {
            dispatch(fetchOpenUrl({streamName: stream, puzzleId: xId, font: font}))
        } else {
            dispatch(fetchUrl({xId, uId, font: {name: font}}))
        }
        if (signedUrl) {
            const family = font.indexOf(".") === -1 ? font : font.split(".")[0]
            const fontFace = new FontFace(family, `url(${signedUrl})`);
            document.fonts.add(fontFace);
            fontFace.load();
        }
    }, [dispatch, font, signedUrl, uId, xId, stream])

    return <></>
}

export const MParagraphstyle = React.memo(Paragraphstyle, (pp, np) => {
    return pp.paragraphStyle?.text?.font === np.paragraphStyle?.text?.font
})
