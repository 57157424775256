export const KRYPTO = "krypto"
export const CHAOS = "chaos"

export const WORD = "WORD"
export const GHOST = "GHOST"
export const TEXT = "TEXT"
export const IMAGE = "IMAGE"
export const ARROW = "ARROW"

export const HZ = 1
export const VRT = 0

export const O_TOP = "ot"
export const O_BOTTOM = "otl"
export const O_LEFT = "otLeft"
export const O_RIGHT = "otRight"

export const UPPER = "upper"
export const LOWER = "lower"
export const RIGHT = "right"
export const LEFT = "left"

export const otExcludes = o => {
    switch (o) {
        case UPPER:
            return [RIGHT, LEFT]
        case LOWER:
            return [RIGHT, LEFT]
        case RIGHT:
            return [LOWER, UPPER]
        case LEFT:
            return [LOWER, UPPER]
        default:
            return []
    }
}
export const ot2Prop = o => {
    switch (o) {
        case UPPER:
            return O_TOP
        case LOWER:
            return O_BOTTOM
        case RIGHT:
            return O_RIGHT
        case LEFT:
            return O_LEFT
        default:
            return null
    }
}

export const EMPTY_KEY = ""

export const ONE_CELL_TYPES = [WORD, GHOST]
