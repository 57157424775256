import React, {useEffect, useState} from "react";
import Crossword from "../../crossword/svgcrossword";
import {createMatrix} from "../../xutils";
import MediaControlCard from "../../ui/MediaControlCard";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {getX} from "../../features/solving/soveSlice";
import {useLocation} from "react-router-dom";

export default function Stream() {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const streamId = queryParams.get("streamId")
    console.log(streamId)

    const [loaded, setLoaded] = useState(false)
    const [response, setResponse] = useState()
    useTheme();
    const streamName = streamId || "teststream"
    console.log(streamName)

    const cachedPuzzles = useSelector((state) => state.persistedReducer.solve.puzzles)

    const dispatch = useDispatch()

    useEffect(() => {
        fetch(`https://jh0gpkrasj.execute-api.eu-north-1.amazonaws.com/dev/streams/${streamName}`)
            .then(res => res.json()).then(data => {
                console.log(data)
                return data
        }).then(setResponse)
    }, [streamName])
    useEffect(()=> {
        console.log(response)
        if (response && !loaded) {
            setLoaded(true)
            response.result.threeTop?.forEach((tp) => {
                const {xId} = tp
                if (!cachedPuzzles[xId]) {
                    dispatch(getX({streamId: streamName, puzzleId: xId}))
                }
            })
            response.result.singleEntries?.forEach((tp) => {
                const {xId} = tp
                if (!cachedPuzzles[xId]) {
                    dispatch(getX({streamId: streamName, puzzleId: xId}))
                }
            })
        }
    }, [cachedPuzzles, dispatch, response, loaded, streamName])
    console.log(response)

    const openX = (xId) => {
        window.open(`solve/${streamName}/puzzle/${xId}`, "_self")
    }

    const renderCard = (puzzle, xPrps, hasImage) => {
        if (!puzzle)
            return
        const {title, description} = xPrps
        const matrix = createMatrix(puzzle)
        if (hasImage) {
            const img = <img
                src={`https://d31agymsqnwyv.cloudfront.net/thumbs/${puzzle.xId}_thumb.jpeg`}
                onLoad={console.log}
                onError={console.error}
                alt={puzzle.xId}
            />
            return <MediaControlCard image={img} text={description} title={title} bgColor="#FFC300" onClick={()=>{openX(puzzle.xId)}}/>
        } else {
            const crossword = <Crossword stream={streamName} x={puzzle} iDs={{xId: puzzle.xId}} matrix={matrix} clickListener={()=>{openX(puzzle.xId)}}></Crossword>
            return <MediaControlCard image={crossword} text={description} title={title} bgColor="#FFC300" onClick={()=>{openX(puzzle.xId)}}/>
        }
    }

    const renderCards = () => {
        return <Container sx={{backgroundColor: "orange", minHeight: "110vh"}}>
            <Typography variant="h4" color="text.primary" component="div" sx={{padding: 3}}>
                Dagens korsord
            </Typography>
            <Grid container spacing={2} sx={{padding: "20px"}}>
                {response?.result?.threeTop?.map(tp => cachedPuzzles[tp.xId])
                    .filter(n=>n)
                    .map((p, ind) => {
                        return <Grid item xs={12} sm={6} md={4} key={`p_${ind}`}>
                            {renderCard(JSON.parse(JSON.stringify(p)), response.result.threeTop[ind])}
                        </Grid>
                    })}
                {response?.result?.singleEntries?.map(tp => cachedPuzzles[tp.xId])
                    .filter(n=>n)
                    .map((p, ind) => {
                        return <Grid item xs={12} sm={6} md={4} key={`p_${p.xId}`}>
                            {renderCard(JSON.parse(JSON.stringify(p)), response.result.singleEntries[ind], true)}
                        </Grid>
                    })}
            </Grid>
        </Container>
    }

    return <>
        {renderCards()}
        {/*{puzzle2 && <div>{JSON.stringify(puzzle2, "<\br>", 2)}</div>}
        {puzzle3 && <div>{JSON.stringify(puzzle3, "<\br>", 2)}</div>}*/}
    </>
}
