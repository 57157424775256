const round = nbr => Math.floor(nbr*1000)/1000

const roundSqr = sqr => {
  return {x1: round(sqr.x1), x2: round(sqr.x2), y1: round(sqr.y1), y2: round(sqr.y2)}
}

export default function square (e, cSz, extra = {}) {
  try {
    const {yDelta = 0, ySz = 0, xSz = 0, xDelta = 0} = extra
    let sq = {
      x1: e.rect.x*cSz + xDelta,
      x2: (e.rect.x*cSz + xDelta) + (e.rect.w || 1)*cSz - xSz,
      y1: e.rect.y*cSz + yDelta,
      y2:  (e.rect.y*cSz + yDelta) + (e.rect.h || 1)*cSz-ySz
    }

    if (e.floatPnt) {
      sq = {
        x1: e.floatPnt.x,
        x2: e.floatPnt.x+ e.floatPnt.w,
        y1: e.floatPnt.y,
        y2: e.floatPnt.y + e.floatPnt.h
      }
    }

    if (e.parts) {
      const partLengths = e.parts.map(part => part.text.split("<br>").length)
      const partTotalLineOfTexts = partLengths.reduce((partialSum, a) => partialSum + a, 0)
      const partsHeight = sq.y2 - sq.y1
      const lengthSums = partLengths.map((elem, index) => partLengths.slice(0,index + 1).reduce((a, b) => a + b))
      const parts = partLengths.map((l, i) => {

        const subSq = {
          x1: sq.x1,
          x2: sq.x2,
          y1: sq.y1 + (i === 0 ? 0 : partsHeight*lengthSums[i-1]/partTotalLineOfTexts),
          y2: sq.y1 + partsHeight*lengthSums[i]/partTotalLineOfTexts
        }
        return roundSqr(subSq)
      })
      return {...(roundSqr(sq)), parts: parts}
    }
    return roundSqr(sq)
  } catch(err) {
    console.error(err)
  }
}

export const sqEqualish = (sq1, sq2) => equalish(sq1.x1, sq2.x1)
    && equalish(sq1.x2, sq2.x2)
    && equalish(sq1.y1, sq2.y1)
    && equalish(sq1.y2, sq2.y2)

export const equalish = (n1, n2) => Math.abs(n1-n2) < 0.1

export const yOverlap = (sq1, sq2) => {
  const y1 = Math.max(sq1.y1, sq2.y1)
  const y2 = Math.min(sq1.y2, sq2.y2)
  if (y1 < y2) {
    return {y1: y1, y2: y2}
  }
}

export const xOverlap = (sq1, sq2) => {
  const x1 = Math.max(sq1.x1, sq2.x1)
  const x2 = Math.min(sq1.x2, sq2.x2)
  if (x1 < x2) {
    return {x1: x1, x2: x2}
  }
}
