export const paragraphStyle = {
  name: "ALL",
  squareSize: 28.34645669,
  text: {
    font: "tektonpro-boldobl",
    fontSizes: [
      {
        nbr: "3",
        sz: 7,
        leading: 7.5
      },
      {
        nbr: "4",
        sz: 6.4,
        leading: 6.2
      }
    ]
  }
}
