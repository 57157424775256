import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Auth} from "aws-amplify";
import {userLoggedIn} from "../../features/users/userSlice";
import { useSelector, useDispatch } from 'react-redux';
import {Navigate, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Alert} from "@mui/material";

function Copyright(props) {
  return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.burnessonikvadrat.se">
          Burnesson i kvadrat
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const theme = createTheme();

export default function Signin() {

  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [signinError, setSigninError] = useState()

  useEffect(() => {
    console.log("signin useEffect")
    // Update the document title using the browser API
    //Auth.currentUserInfo().then(console.log)
    //Auth.currentCredentials().then(console.log)
    //Auth.currentSession().then(console.log)
    const checkLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        console.log(user)
        //dispatch(userLoggedIn(user.username))
        navigate("/dashboard")
      } catch(err) {
        console.error(err)
      }
    }
    checkLoggedIn()
  }, [navigate]);

  const handleSignInError = error => {
    console.error(error)
    setSigninError(error.message)
  }

  const handleSubmit = (event) => {
    setSigninError()
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    Auth.signIn(data.get('email'), data.get('password')).then(user => {
      console.log(user)
      Auth.currentCredentials().then(credentials => {
        console.log(credentials)
        dispatch(userLoggedIn(user))
      })
    }).catch(handleSignInError);
  };

  console.log("Signin render")

  if (user?.username) {
    return <Navigate replace to="/dashboard" />;
  }

  return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in to Kruxet
            </Typography>
            {signinError && <Alert severity="error">{signinError}</Alert>}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Username"
                  name="email"
                  autoComplete="email"
                  autoFocus
              />
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
              />
{/*              <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
              />*/}
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
              >
                Sign in
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/recover" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2" onClick={()=>{
                    console.log("Signing up")

                  }}>
                    {"No account yet? Sign up here!"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
  );
}
