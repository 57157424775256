import * as React from "react";
import {eu} from "../xutils";
import {sqEqualish} from "./square";
import {EMPTY_KEY} from "../cellTypes";

export default function Plates (props) {
  const {elements, overlayTexts} = props
  return <g className="plateLayer">
    {elements.map((e) => (<MPlate
      e={e}
      key={`plate_${eu.str(e)}_${e.clr || ""}`}
      sq={e.sq}
    />))}
    {overlayTexts?.map((e) => (<MPlate
        e={e}
        key={`ol_plate_${eu.str(e)}_${e.clr || ""}`}
        sq={e.sq}
    />))}

  </g>
}

const Plate = (props) => {
  const {e, sq} = props
  const {clr} = e
  if (sq.parts) {
    return sq.parts.map((part, i)=> {
      let partFill = e.parts[i].clr || "white"
      if (e.parts[i].text === EMPTY_KEY) {
        partFill = "gray"
      }
      return <rect onClick={()=>{console.log("Clicked plate")}} key={`part_y${part.y1}`} x={part.x1} y={part.y1} width={part.x2-part.x1} height={part.y2-part.y1} fill={partFill} stroke="none"></rect>
    })
  }
  let fill = clr || "white"
  if (e.text === EMPTY_KEY) {
    fill = "gray"
  }
  return (<rect x={sq.x1} y={sq.y1} width={sq.x2-sq.x1} height={sq.y2-sq.y1} fill={fill} stroke="none"></rect>)
}

const MPlate = React.memo(Plate, (pp, np) => {
  return sqEqualish(pp.sq, np.sq) && pp.clr === np.clr && pp.e.text === np.e.text && partsEqual(pp.e, np.e)
})

function partsEqual(pp, np) {
  return pp.parts?.[0]?.text === np.parts?.[0]?.text && pp.parts?.[0]?.clr === np.parts?.[0]?.clr &&
  pp.parts?.[1]?.text === np.parts?.[1]?.text && pp.parts?.[1]?.clr === np.parts?.[1]?.clr
}
