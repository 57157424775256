import React from "react";
import {FormControl} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function ThreeInTopEntry({entry, xListMap, xList, uId, onChange, onSelect, selected}) {
    const {xId, title, description} = entry

    //console.log(xListMap)
    const bgColor = selected && "green"
    return <Box sx={bgColor && {
        padding: 1,
        boxShadow: 24,
    }} onClick={onSelect}>
        <FormControl>
            <Typography>{xListMap?.[xId]?.name}</Typography>
            <TextField
                id="title"
                label="Title"
                variant="standard"
                value={title}
                onChange={(event)=>{
                    console.log("Onchange title")
                    onChange({...entry, title: event.target.value})
                }}
            />
            <TextField
                id="description"
                label="Description"
                variant="standard"
                value={description}
                onChange={(event)=>{
                    console.log("Onchange textfield description")
                    onChange({...entry, description: event.target.value})
                }}
            />
            {/*<PickOrderModal onPicked={(pickedId)=>{
                onChange({title: title, description: description, xId: pickedId.id, uId: uId})
            }
            } open={showPickOrderModal} handleClose={()=>setShowPickOrderModal(false)}></PickOrderModal>*/}
        </FormControl>
    </Box>
}
