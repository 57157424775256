import ThreeInTopEntry from "./ThreeInTopEntry";
import Card from "@mui/material/Card";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import React from "react";
import {RemoveCircle} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

export default function ThreeInTop({uId, data, xListMap, xList, expanded, handleChange, onChange, onDelete, onSelect, focusedThreeInTopEntry}) {

    const {start, created} = data

    return (
        <Accordion key={created || "what"} expanded={expanded === start} onChange={()=>{handleChange(expanded === start ? undefined : start)}}>
            <AccordionSummary>
                <Grid item xs={12} sm={6} md={4}>
                    {start}
                    <IconButton onClick={onDelete}><RemoveCircle/></IconButton>
                </Grid>
            </AccordionSummary>
            {expanded === start && <AccordionDetails>
                <Card variant="outlined">
                    <DatePicker
                        defaultValue={ dayjs(new Date(start))}
                        onChange={(event)=>{
                            const newDate = event.toISOString().split("T")[0]
                            onChange({...data, start: newDate})
                            handleChange(newDate)
                        }}
                    />
                    <Grid container spacing={2} sx={{padding: "20px"}}>
                        {data.threeTop.map((entry, ind) => {
                            return (<Grid item xs={12} sm={6} md={4} key={`p_${ind}`}>
                                <ThreeInTopEntry
                                    selected={focusedThreeInTopEntry === ind}
                                    entry={entry.description !== undefined ? entry : {xId: entry, title: "", description: ""}}
                                    xList={xList}
                                    xListMap={xListMap}
                                    uId={uId}
                                    onChange={(newEntry)=> {
                                        const newData = JSON.parse(JSON.stringify(data))
                                        console.log(newData)
                                        console.log(newEntry)
                                        newData.threeTop[ind] = newEntry
                                        onChange(newData)
                                    }}
                                    onSelect={() =>{
                                        console.log(ind)
                                        onSelect(ind)
                                    }}
                                />
                            </Grid>)
                        })}
                    </Grid>
                </Card>
            </AccordionDetails>}
        </Accordion>
    )
}
