import {diag_right_east, down_right, right_down, dynamic} from "./linkTypes";
import {compareRects, createMatrix, ru} from "./xutils";
import {ARROW, HZ, IMAGE, LOWER, TEXT, UPPER, VRT, WORD} from "./cellTypes";
import {isHzArrow, isVrtArrow} from "./arrowTypes";
import {sqEqualish} from "./crossword/square";

export const autoLinkWordRangeToOverlayText = (xObj, wordRange, ot, direction) => {
    const newX = JSON.parse(JSON.stringify(xObj))
    const newOt = newX.overlayTexts.find(t => sqEqualish(ot.sq, t.sq))
    // Find if the wordRange is already linked to the textRange/part
    const index = newOt.links.findIndex((link)=>{
        console.log("findIndex")
        const directionEquals = direction === link.direction
        const wordRangeEquals = ru.equals(wordRange.rect, link.wordRange)
        return wordRangeEquals && directionEquals
    })
    console.log(index)
    if (index !== -1) {
        // If it is, then unlink it
        newOt.links.splice(index, 1)
    } else {
        // Else, link it
        newOt.links.push({wordRange: wordRange.rect, direction: direction, type: dynamic})
    }
    return newX
}
export const autoLinkWordRangeToTextRange = (xObj, wordRange, textRange, part, direction) => {
    const newX = JSON.parse(JSON.stringify(xObj))
    // Find if the wordRange is already linked to the textRange/part
    const index = newX.links.findIndex((link)=>{
        console.log("findIndex")
        const directionEquals = direction === link.direction
        const wordRangeEquals = ru.equals(wordRange.rect, link.wordRange)
        const sourceEquals = ru.equals(textRange.rect, link.source)
        const partEquals = ((link.sourceType === undefined && (link.part === part))
            || ((typeof link.sourceType === part === undefined)
                || (link.sourceType === UPPER && part === 0)
                || (link.sourceType === LOWER && part === 1)))

        return wordRangeEquals
            && sourceEquals
            && partEquals
            && directionEquals
    })
    console.log(index)
    if (index !== -1) {
        // If it is, then unlink it
        newX.links.splice(index, 1)
    } else {
        // Else, link it
        newX.links.push({wordRange: wordRange.rect, source: textRange.rect, direction: direction, part: part, type: dynamic})
    }
    return newX
}

export const autoLinkTextRange = (xObj, textRange, part) => {
    console.log("autoLinkTextRange")
    const newX = JSON.parse(JSON.stringify(xObj))
    const matrix = createMatrix(newX)
    const {x, y, w=1, h=1} = textRange.rect
    console.log(`${x} ${y} ${w} ${h}`)
    const {e} = matrix[y][x]
    const rE = matrix[y]?.[x+w]?.e
    const rEE = matrix[y]?.[x+w+1]?.e
    const lE = matrix[y+1]?.[x]?.e
    const lrE = matrix[y+1]?.[x+1]?.e
    const lEE = matrix[y+2]?.[x]?.e
    if (e?.type === TEXT) {
        console.log("Auto linking text")
        if (part === undefined) {
            if (rE?.type === WORD && rEE?.type === WORD) {
                newX.links.push({wordRange: {...rE.rect}, source: e.rect, direction: HZ})
            } else if (lE?.type === WORD && lEE?.type === WORD) {
                newX.links.push({wordRange: {...lE.rect}, source: e.rect, direction: VRT})
            } else if (lE?.type === WORD && lrE?.type === WORD) {
                newX.links.push({wordRange: {...lE.rect}, source: e.rect, direction: HZ, type: down_right})
            } else if (rE?.type === WORD && lrE?.type === WORD) {
                newX.links.push({wordRange: {...rE.rect}, source: e.rect, direction: VRT, type: right_down})
            }
        } else if (part === 0) {
            if (rE?.type === WORD && (rEE?.type === WORD || (xObj.yPatternOffset && !rEE))) {
                newX.links.push({wordRange: {...rE.rect}, source: e.rect, direction: HZ, sourceType: UPPER})
            } else if (rE?.type === WORD && lrE?.type === WORD) {
                newX.links.push({wordRange: {...rE.rect}, source: e.rect, direction: VRT, sourceType: UPPER, type: right_down})
            }
        } else if (part === 1) {
            if (w === 1) {
                if (lE?.type === WORD) {
                    if (lEE?.type === WORD) {
                        newX.links.push({wordRange: {...lE.rect}, source: e.rect, direction: VRT, sourceType: LOWER})
                    } else if (lrE?.type === WORD) {
                        newX.links.push({wordRange: {...lE.rect}, source: e.rect, direction: HZ, sourceType: LOWER, type: down_right})
                    }
                }
            } else if (w === 2) {
                if (lE?.type === WORD && lEE?.type === WORD) {
                    newX.links.push({wordRange: {...lE.rect}, source: e.rect, direction: VRT, sourceType: LOWER})
                } else if (lrE?.type === WORD && matrix[y+2]?.[x+1]?.e?.type === WORD) {
                    newX.links.push({wordRange: {...lrE.rect}, source: e.rect, direction: VRT, sourceType: LOWER})
                }
            }
        }
    }
    newX.links.sort((l1, l2) => compareRects(l1.wordRange, l2.wordRange))
    return newX
}

export const autoLink = (xObj, rect = {x:0, y:0, w: xObj.w, h: xObj.h}) => {
    console.log("autoLink")
    const newX = JSON.parse(JSON.stringify(xObj))
    const matrix = createMatrix(newX)

    // Mark all wordRanges that is already linked in the matrix.
    // To be able to ignore later...
    newX.links.forEach(link => {
        const source = link.source
        if (link.sourceType) {
            if (link.sourceType === UPPER) {
                matrix[source.y][source.x].upperLinked = link
            } else if (link.sourceType === LOWER) {
                matrix[source.y][source.x].lowerLinked = link
            }
        } else {
            try {
                matrix[source.y][source.x].linked = link
            } catch(err) {
                console.error(err)
                throw err
            }
        }

        const wordRange = link.wordRange
        if (link.direction === HZ) {
            matrix[wordRange.y][wordRange.x].hzLinked = link
        } else if (link.direction === VRT) {
            matrix[wordRange.y][wordRange.x].vrtLinked = link
        }
    })

    // 1. Find all word ranges
    // 2. For each word range
    // 2.1 If not linked.
    // 2.2 Find a source.
    // 2.3. Link it
    const {x, y, w=1, h=1} = rect
    const newLinks = []
    for (let rx = x; rx < x+w; rx++) {
        for (let ry = y; ry < y+h; ry++) {
            const pos = matrix[ry][rx]
            const lPos = matrix[ry]?.[rx-1]
            const lowerPos = matrix[ry+1]?.[rx]
            const rPos = matrix[ry]?.[rx+1]
            const upperPos = matrix[ry-1]?.[rx]
            const upperLeftPos = matrix[ry-1]?.[rx-1]
            const {e} = pos
            const leftE = lPos?.e
            const lowerE = lowerPos?.e
            const rightE = rPos?.e
            const upperE = upperPos?.e
            const ulE = upperLeftPos?.e
            if (e?.type === WORD) {
                if (leftE?.type !== WORD && rightE?.type === WORD // e is word range start horizontal
                    && !(leftE?.type === ARROW && isHzArrow(leftE.arrow)) //We currently don't link ARROWs
                    && !pos.hzLinked // And we skip if it is already linked
                    && (!xObj.yPatternOffset || leftE?.type === TEXT || leftE?.type === IMAGE)
                ) {

                    const newLink = {
                        wordRange: {...e.rect},
                        //source: src,
                        //sourceType: sourceType,
                        direction: HZ,
                        //type: type
                    }
                    if (leftE?.type === TEXT) {
                        newLink.source = leftE
                        newLink.source.links = [...(newLink.source.links||[]), newLink]
                        //newLink.type = mini_east
                        newLinks.push(newLink)
                    } else if (upperE?.type === TEXT) {
                        console.log("Setting down_right")
                        newLink.source = upperE
                        newLink.source.links = [...(newLink.source.links||[]), newLink]
                        newLink.type = down_right
                        newLinks.push(newLink)
                    } else if (ulE?.type === TEXT) {
                        newLink.source = ulE
                        newLink.source.links = [...(newLink.source.links||[]), newLink]
                        newLink.type = diag_right_east
                        newLinks.push(newLink)
                    }
                }
                if (upperE?.type !== WORD  && lowerE?.type === WORD // e is word range start vertical
                    && !(upperE?.type === ARROW && isVrtArrow(upperE.arrow))  //We currently don't link ARROWs
                    && !pos.vrtLinked  // And we skip if it is already linked
                    && (!xObj.yPatternOffset || upperE?.type === TEXT || upperE?.type === IMAGE)
                ) {
                    const newLink = {
                        wordRange: {...e.rect},
                        //source: src,
                        //sourceType: sourceType,
                        direction: VRT,
                        //type: type
                    }

                    if (upperE?.type === TEXT) {
                        newLink.source = upperE
                        newLink.source.links = newLink.source.links || []
                        newLink.source.links.push(newLink)
                        //newLink.type = mini_south
                        newLinks.push(newLink)
                    } else if (leftE?.type === TEXT) {
                        newLink.source = leftE
                        newLink.source.links = newLink.source.links || []
                        newLink.source.links.push(newLink)
                        newLink.type = right_down
                        newLinks.push(newLink)
                    }
                }
            }
        }
    }

    newLinks.forEach(link => {
        if (link.source.links.length > 1) {
            if (!link.source.parts) {
                link.source.parts = [{text: ''}, {text: ''}]
            }
            console.log(`Setup sourceType ${JSON.stringify(link.direction)} ${JSON.stringify(link.wordRange)}`)
            console.log("Stop here")
            link.source.links.forEach(deepLink => {
                if (!deepLink.sourceType) {
                    if (!deepLink.type) {
                        deepLink.sourceType = deepLink.direction === HZ ? UPPER : LOWER
                    } else {
                        if (deepLink.type === down_right) {
                            deepLink.sourceType = LOWER
                        } else if (deepLink.type === right_down) {
                            deepLink.sourceType = UPPER
                        }
                    }
                }
            })
        }
    })
    newLinks.forEach(newLink => {
        delete newLink.source.links
        newLink.source = {x: newLink.source.rect.x, y: newLink.source.rect.y}
    })


    newX.links = [...newX.links, ...newLinks]
    newX.links.sort((l1, l2) => compareRects(l1.wordRange, l2.wordRange))
    console.log(newX.links.length)
    return newX
}

export const link = (xObj, src, dest, direction, type, sourceType) => {
    if (!src) {
        console.error("Cannot link. No source")
        return false
    }
    if (!dest) {
        console.error("Cannot link. No destination")
        return false
    }
    const newLinke = {
        wordRange: dest,
        source: src,
        sourceType: sourceType,
        direction: direction,
        type: type
    }
    console.log(newLinke)
    // Is there allready a link like that? Remove it.
    const index = xObj.links.findIndex(link => {
        return ru.equals(link.source, src)
            && ru.equals(link.wordRange, dest)
            && link.direction === direction
            && link.type === type
            && link.sourceType === sourceType
    })
    if (index !== -1) {
        xObj.links.splice(index, 1)
    } else {
        xObj.links.push(newLinke)
    }
    xObj.links.sort((l1, l2) => compareRects(l1.wordRange, l2.wordRange))
    return true
}
