import Draggable from "react-draggable";
import {Divider, Paper, Tooltip} from "@mui/material";
import * as React from "react";
import WindowHeader from "./WindowHeader";
import {useEffect, useRef, useState} from "react";
import {getKeys, putKeys} from "../backend";
import {AddCircle, Approval, Close} from "@mui/icons-material";
import {breakKey, unbreakKey} from "../xutils";
import {createTheme} from "@mui/material/styles";
import "./util.css"
import IconButton from "@mui/material/IconButton";


/**
 * keys contains the keys with \n in, because they come from the db
 * currentKey has <br>, since it comes from the json file
 *
 * */
export default function KeyEditWindow({xId, uId, onApply, currentWord, currentKey, onClose}) {
    const [keys, setKeys] = useState([])
    const [selectedKey, setSelectedKey] = useState()
    const [editedText, setEditedText] = useState()
    const [minimized, setMinimized] = useState(false)
    const inputElement = useRef()
    const brokenKey = breakKey(currentKey)
    const listTheme = createTheme({
        palette: {
            primary: {
                main: '#eceff1',
                light: '#fff59d'
            },
            secondary: {
                main: '#fafafa'
            },
        },
    })

    useEffect(()=>{
        if (currentWord) {
            getKeys(xId, uId, currentWord).then(setKeys)
            setSelectedKey(undefined)
            setEditedText(undefined)
        }
    }, [currentWord, uId, xId])
    useEffect(()=>{
        setTimeout(()=>{inputElement?.current?.focus()}, 20)
    },[currentWord])

    if (!currentWord) {
        console.error("Can't render KeyEditWindow. No currentWord")
        return null
    }

    const brokenSelected = selectedKey && breakKey(selectedKey)

    const getCurrentIndex = () => {
        if (!keys)
            return
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (editedText) {
                if (key === editedText) {
                    return i
                }
            } else if (selectedKey) {
                if (key === brokenSelected) {
                    return i
                }
            } else {
                if (key === brokenKey) {
                    return i
                }
            }
        }
        return -1
    }
    let currentIndex = getCurrentIndex()

    const onMinimize = () => {
        setMinimized(true)
    }

    const onMaximize = ()=> {
        setMinimized(false)
    }

    const handleStoreAndApply = () => {
        onApply?.(unbreakKey(editedText||selectedKey||currentKey||""))
        const newKeys = [...(keys||[]), breakKey(editedText||selectedKey||currentKey||"")].sort((a,b)=>a.localeCompare(b))
        const uniqueKeys = [...new Set(newKeys)]
        putKeys(uId, currentWord, uniqueKeys).then(console.log).catch(console.error)
        return uniqueKeys
    }

    return <Draggable bounds="parent" handle="strong">
        <Paper
            elevation={3}
            style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                zIndex: 9999999,
                width: "400px"
            }}>
            <WindowHeader title={currentWord} onClose={onClose} onMinimize={onMinimize} onMaximize={onMaximize}/>
            <Divider/>
            {!minimized && <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                <ul style={{
                    maxHeight: "20vh",
                    paddingLeft: "1px",
                    fontSize: "10px",
                    overflow: "scroll"
                }}>
                    {
                        keys?.map((key, keyIndex)=>{
                            let bgColor = listTheme.palette.primary.main
                            if (keyIndex%2 === 1) {
                                bgColor = listTheme.palette.secondary.main
                            }
                            if (currentIndex === keyIndex) {
                                bgColor = listTheme.palette.primary.light
                            }
                            return <li className="darkhover" onClick={()=>{
                                setSelectedKey(unbreakKey(key))
                                setEditedText(undefined)
                                inputElement.current.focus()
                            }} onDoubleClick={()=>{
                                onApply?.(unbreakKey(key))
                                onClose()
                            }} style={{backgroundColor: bgColor}}>
                                <ul style={{display: "grid", gridTemplateColumns: "12fr 1fr", listStyle: "none", paddingInlineStart: 0}}>
                                    <li style={{cursor: "pointer"}}>{(key||"WTF??").replace("-\n", "")}</li>
                                    <li onClick={()=>{
                                        const newKeys = [...keys]
                                        newKeys.splice(keyIndex, 1)
                                        setKeys(newKeys)
                                        putKeys(uId, currentWord, newKeys).then(console.log).catch(console.error)
                                    }}>
                                        <Close style={{fontSize: "6px", cursor: "pointer"}}/>
                                    </li>
                                </ul>
                            </li>
                        })
                    }
                </ul>
                <div>
                    <div
                        onKeyDown={(e)=>{
                            e.stopPropagation()
                            console.log(e.code)
                            if (e.shiftKey && e.code === "Enter") {
                                onClose()
                                onApply?.(unbreakKey(editedText||selectedKey||currentKey||""))
                            } else if (e.shiftKey && e.code === "Space") {
                                onClose()
                                handleStoreAndApply()
                            } else if (e.code === "Escape") {
                                onClose()
                            }
                            console.log(e.code)
                        }}
                        onInput={e=>{
                            setEditedText(e.currentTarget.innerText?.toUpperCase())
                        }}
                        contentEditable
                        style={{
                            textAlign: "center",
                            textTransform: "uppercase"
                        }}
                        dangerouslySetInnerHTML={{__html: selectedKey || currentKey}}
                        ref={inputElement}
                    />
                </div>
                <Divider/>
                <div style={{display: "flex", justifyContent: "right"}}>
                    <Tooltip title="Store and apply key. Shift+Space">
                        <IconButton
                            color="primary"
                            disabled={currentIndex !== -1}
                            onClick={()=>{
                                const newKeys = handleStoreAndApply()
                                setKeys(newKeys)
                            }
                            }><AddCircle/></IconButton>
                    </Tooltip>
                    <Tooltip title="Apply key. Shift+Enter">
                        <IconButton onClick={()=>{onApply?.(unbreakKey(editedText||selectedKey))}}>
                            <Approval/>
                        </IconButton>
                    </Tooltip>
                </div>


            </div>}


        </Paper>
    </Draggable>;
}
