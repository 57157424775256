import * as React from "react";
import convert from "color-convert";
import {useContext} from "react";
import {DocContext} from "./ClassListContext";

export default function Sudoku({x, y, sudoku}) {
 const {width, height, left, top,
     lineWidth, innerBorderWidth, borderWidth,
     lineColor, innerBorderColor, borderColor,
     puzzle, solutionFontSize, solutionFont
 } = sudoku

    const docContext = useContext(DocContext)
    const {cb, state={}} = docContext
    const {selection, attempts} = state
    const attempt = attempts?.[puzzle]

    const lineHex = lineColor && `#${convert.cmyk.hex(...lineColor)}`
    const borderHex = lineColor && `#${convert.cmyk.hex(...borderColor)}`
    const innerBorderHex = innerBorderColor && `#${convert.cmyk.hex(...innerBorderColor)}`
    const cSz = width/9
    const key = `sudoku_${puzzle}`;

    const puzzleFocus = selection?.puzzleType === "sudoku" && selection?.puzzle === puzzle

    return <g key={key} fontFamily={solutionFont}>
        {Array(9).fill({}).map((d, i) => {
            const col = Math.floor(i/3)
            const row = i%3
            return <rect width={cSz * 3} height={cSz * 3} x={x + left + col * cSz * 3} y={y + top + row * cSz * 3} stroke={innerBorderHex} strokeWidth={innerBorderWidth} id={`i_${i}`} fill="transparent"/>
        })}
        <rect width={width} height={height} x={x+left} y={y+top} stroke={puzzleFocus ? "pink" : borderHex} strokeWidth={borderWidth} id={`frame`} fill="transparent"></rect>
        {[...puzzle].map((val, i) => {
            if (i > 80)
                return null
            const col = Math.floor(i/9)
            const row = i%9

            const rx = x+left + col*cSz
            const ry = y+top + row*cSz

            const offset = cSz - (cSz-solutionFontSize*1.2)

            const focus = puzzleFocus && selection.col === col && selection.row === row

            return <>
                {focus && <rect width={cSz} height={cSz} x={rx} y={ry} stroke={"transparent"} id={`i_${i}_s`} fill="pink"></rect>}
                {puzzle[i] !== "." && <text textAnchor={"middle"} y={ry + offset} x={rx+cSz/2} key={`${rx}_${ry}`} fill={"black"}>{puzzle[i]}</text>}
                {attempt && attempt?.[i] !== "." && <text textAnchor={"middle"} y={ry + offset} x={rx+cSz/2} key={`attempt_${rx}_${ry}`} fill={"gray"}>{attempt[i]}</text>}
                <rect width={cSz} height={cSz} x={rx} y={ry} stroke={lineHex} strokeWidth={lineWidth} id={`i_${i}`} fill="transparent" onClick={(e)=>{
                    if (puzzle[i] === ".") {
                        cb?.({
                            puzzleType: "sudoku",
                            puzzle: puzzle,
                            col: col,
                            row: row
                        })
                        e.stopPropagation()
                        e.preventDefault()
                    }
                }}></rect>
            </>
        })}
    </g>
}
