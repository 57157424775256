import Box from "@mui/material/Box";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useRef} from "react";
import {getGetUserList, getUserLists} from "../backend";
import Container from "@mui/material/Container";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {ListItem, Tooltip} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import List from "@mui/material/List";

export default function ListEditor({uId}) {

    const [userLists, setUserLists] = React.useState([])
    const [fetchedLists, setFetchedLists] = React.useState({})
    const [rows, setRows] = React.useState([])
    const [columns, setColumns] = React.useState([])

    const [formats, setFormats] = React.useState(() => []);

    const [removed, setRemoved] = React.useState({})
    const [added, setAdded] = React.useState({})

    const stateRef = useRef();
    stateRef.removed = removed
    stateRef.added = added

    const renderDetailsButton = (params) => {
        const existsInList = params.row[params.field]
        const removedFromList = stateRef.removed[params.field]?.[params.id]
        const addedToList = stateRef.added[params.field]?.[params.id]
        let tooltipText = existsInList ? "Remove" : "Add"
        if (removedFromList) {
            tooltipText = "Undo remove"
        }
        if (addedToList) {
            tooltipText = "Undo add"
        }
        let color = "warning"
        return (
            <strong>
                <Tooltip title={tooltipText}>
                    <ToggleButton
                        value="dummy"
                        color={(existsInList && color) || "success"}
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => {
                            console.log(existsInList)
                            console.log(params.row[params.field])
                            if (existsInList) {
                                const newRemoved = {...stateRef.removed}
                                if (!newRemoved[params.field]) {
                                    newRemoved[params.field] = {}
                                }
                                if (removedFromList) {
                                    newRemoved[params.field][params.id] = undefined
                                } else {
                                    newRemoved[params.field][params.id] = true
                                }
                                setRemoved(newRemoved)
                            } else {
                                const newAdded = {...stateRef.added}
                                if (!newAdded[params.field]) {
                                    newAdded[params.field] = {}
                                }
                                if (addedToList) {
                                    newAdded[params.field][params.id] = undefined
                                } else {
                                    newAdded[params.field][params.id] = true
                                }
                                setAdded(newAdded)
                            }
                        }}
                        selected={removedFromList || addedToList}
                    >
                        {(((existsInList && !removedFromList) || (!existsInList && addedToList)) && <RemoveIcon/>) || <AddIcon/>}
                    </ToggleButton>
                </Tooltip>
            </strong>
        )
    }

    const handleFormat = (event, newFormats) => {
        console.log(`handleFormat(${newFormats})`)
        setFormats(newFormats);

        const newColumns = [{field: 'id', headerName: "ID", width: 300}, ...newFormats.map(format => {
            return {
                field: format,
                headerName: format,
                width: 90,
                renderCell: renderDetailsButton,
                disableClickEventBubbling: true,
            }
        })]
        setColumns(newColumns)
    };

    useEffect(() => {
        const fetchUserLists = async () => {
            const userLists = await getUserLists(uId)
            setUserLists(userLists.map(info => info.name.split(".")[0]))
        }

        fetchUserLists()
    }, [uId])

    useEffect(() => {
        formats.forEach(async format => {
            if (!fetchedLists[format]) {
                console.log(`Need to fetch ${format}`)
                const listName = `${format}.txt`
                const data = await getGetUserList({listName: listName})
                fetch(data.url).then(data => {
                    return data.text()
                }).then((text) => {
                    const newFetchedLists = {...fetchedLists}
                    newFetchedLists[format] = text.split("\n").sort()
                    setFetchedLists(newFetchedLists)
                })
            }
        })
        console.log(Object.keys(fetchedLists))
    }, [formats, fetchedLists])

    useEffect(()=>{
        const superMap = {}
        Object.keys(fetchedLists).forEach(list => {
            const words = fetchedLists[list];
            words.forEach(word => {
                if (!superMap[word]) {
                    superMap[word] = {id: word}
                }
                superMap[word][list] = "X"
            })
        })
        const newRows = Object.keys(superMap).sort().map(key => superMap[key])
        setRows(newRows)
    },[fetchedLists])

    //console.log(removed)

    return <Container>
        <ToggleButtonGroup
            color="primary"
            value={formats}
            onChange={handleFormat}
            aria-label="Platform"
        >
            {userLists.map(userList => (<ToggleButton key={userList} value={userList} onClick={()=>{
                //getUserList(`${userList}.txt`)
            }}>{userList}</ToggleButton>))}
        </ToggleButtonGroup>
        {<Box sx={{ height: 400, width: 1 }}>
            <DataGrid
                rows={rows}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>}
        <Box>
            <h1>Removed</h1>
            <>
                {Object.keys(removed).map(l => (<>
                    <h3>{l}</h3>
                    <List>
                        {Object.keys(removed[l]).map(w => (<ListItem>{w}</ListItem>))}
                    </List>
                </>))}
            </>
        </Box>
        <Box>
            <h1>Added</h1>
            <>
                {Object.keys(added).map(l => (<>
                    <h3>{l}</h3>
                    <List>
                        {Object.keys(added[l]).map(w => (<ListItem>{w}</ListItem>))}
                    </List>
                </>))}
            </>
        </Box>
    </Container>
}
