import * as React from "react";

export default function Byline({paragraphStyle, cSz, frame, byline, imageByline, xw, xh, lowerLeft, lowerRight}) {
    if (!byline && !imageByline)
        return

    const fontSize = 8
    const xPos1 = lowerLeft?.x || 10
    const xPos2 = lowerRight?.x || (xw*cSz - 10)
    const yPos = lowerLeft?.y || xh*cSz
    const fontFamily= "Arial" || paragraphStyle.font


    return <g className="bylineLayer">
        {byline && <text x={xPos1} y={yPos} textAnchor="start" fontSize={fontSize} key={`byline`} fontFamily={fontFamily} fill={"black"}>
            <tspan dy={fontSize} x={xPos1}>{byline}</tspan>
        </text>}
        {imageByline && <text x={xPos1} y={yPos} textAnchor="end" fontSize={fontSize} key={`imageByline`} fontFamily={fontFamily} fill={"black"}>
            <tspan dy={fontSize} x={xPos2}>{imageByline}</tspan>
        </text>}
    </g>;
}
