import * as React from "react";
import {Auth} from "aws-amplify";
import {userLoggedIn, userLoggedOut} from "../features/users/userSlice";
import {fetchXlist} from "../features/xlist/xlistSlice";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import {Button, Chip, Divider} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {DataGrid, GridToolbarContainer} from "@mui/x-data-grid";
import {scanOrders} from "../backend";

export default function Orders () {
  // For the datagrid
  const drawerWidth = 240;
  const theme = createTheme();
  const user = useSelector((state) => state.user)
  const [uId, setUid] = useState()
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [response, setResponse] = useState()

  useEffect(() => {
    console.log("orders useEffect")
    const checkLoggedIn = async () => {
      console.log("checkLoggedIn")
      try {
        const user = await Auth.currentAuthenticatedUser()
        dispatch(userLoggedIn(user))
        const credentials = await Auth.currentCredentials()
        setUid(credentials.identityId)
        return true
      } catch(err) {
        console.error(err)
        navigate("/signin")
        return false
      }
    }
    checkLoggedIn().then(console.log)
  }, [dispatch, navigate]);

  useEffect(()=>{
    if (uId) {
      console.log("Going to scan orders")
      scanOrders(uId, {}).then(response => {
        console.log(response)
        setResponse(response)
      })
    }
  }, [uId])

  const signOut = () => {
    console.log("in signout")
    Auth.signOut().then(() => {
      console.log("dispatching userLoggedOut")
      dispatch(userLoggedOut())
      navigate("/signin")
    })
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Namn',
      flex: 2
    },
    {
      field: 'constructor',
      headerName: 'Konstruktur',
      flex: 1
    },
  ];

  const rows = response?.Items?.map(item => ({id: item.xId, name: item.xName, constructor: item.xClient}))

  const onCellClick = (params, event) => {
    if (params.field === "name") {
      console.log("Open " + params.row.name)
      Auth.currentCredentials().then(credentials => {
        let win = window.open(`/editor?xId=${params.id}&uId=${credentials.identityId}`, event.shiftKey ? '_blank' : '_self')
        win.focus()
      })
    }
  }

  const CustomToolbar = () => {
    return (
        <GridToolbarContainer/>
    )
  }

  return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex'}}>
          <CssBaseline />
          <AppBar
              position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
          >
            <Toolbar>
              <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Kruxet
              </Typography>
              {user.username && <Button color="inherit" onClick={signOut}>{user.username} - Log out</Button>}

            </Toolbar>
          </AppBar>
          <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
              }}
          >
            <Toolbar/>
            <Box sx={{overflow: 'auto'}}>
              <List>
                <ListItem key={"key_item"} disablePadding>
                  <ListItemButton
                      onClick={_=>{}}
                  >
                    <ListItemIcon>
                      <InsertCommentIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Nyckelruta"}/>
                  </ListItemButton>
                </ListItem>
                <ListItem key={"key_image"} disablePadding>
                  <ListItemButton
                      onClick={_=>{}}
                  >
                    <ListItemIcon>
                      <InboxIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Bildruta"}/>
                  </ListItemButton>
                </ListItem>
              </List>
              <Divider/>
            </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Välkommen
              </Typography>
            </Box>
            <Divider sx={{ m: 2 }}>
              <Chip label="Dina befintliga kryss" />
            </Divider>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
              <Box maxWidth="md" sx={{flexGrow: 1}} >
                <DataGrid
                    autoHeight
                    rows={rows || []}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    onCellClick={onCellClick}
                    onColumnHeaderClick={()=>{
                      dispatch(fetchXlist())
                    }}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>)
}
