import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import _ from "lodash"; // cool kids know _ is low-dash

const initialState = {
  loading: 'idle',
  puzzles: {},
  attempts: {},
  docAttempts: {}
}

export const getX = createAsyncThunk(
    'x/fetchStatus',
    async (arg, {getState}) => {
      console.log("getX")
      const {streamId, puzzleId, force} = arg
      const state = getState()
      if (state.persistedReducer?.solve?.puzzles?.[puzzleId] && !force) {
        return {xId: puzzleId, xObj: state.persistedReducer.solve.puzzles[puzzleId]}
      } else {
        const response = await fetch (`https://jh0gpkrasj.execute-api.eu-north-1.amazonaws.com/dev/streams/${streamId}/puzzles/${puzzleId}`)
        console.log(response)
        const theJson = await response.json()
        const xObj = theJson.result.puzzle
        console.log(xObj)
        return {xId: puzzleId, xObj: xObj}
      }
    }
)
export const solveSlice = createSlice({
  name: 'solving',
  initialState,
  reducers: {
    reset: () => initialState,
    clearAttempt: (state, action) => {
      const attempts = {...state.attempts}
      delete attempts[action.payload.puzzleId]
      return {
        ...state,
        attempts: attempts
      }
    },
    saveAttempt: (state, action) => {
      const attempts = {...state.attempts}
      attempts[action.payload.puzzleId] = [...action.payload.attempt]
      return {
        ...state,
        attempts: attempts
      }
    },
    saveDocAttempts: (state, action) => {
      const docAttempts = {...state.docAttempts}
      docAttempts[action.payload.puzzleId] = {docAttempt: _.cloneDeep(action.payload.attempt)}
      const newState = {
        ...state,
        docAttempts: docAttempts
      }
      console.log(newState)
      return newState
    }
  },
  extraReducers: {
    [getX.pending]: (state) => {
      state.loading = true
    },
    [getX.rejected]: (state) => {
      state.loading = false
    },
    [getX.fulfilled]: (state, {payload}) => {
      state.loading = false
      const {xId, xObj} = payload
      state.puzzles[xId] = xObj
    }
  }
})

export const solveReducer = solveSlice.reducer
export const saveAttempt = solveSlice.actions.saveAttempt
export const saveDocAttempts = solveSlice.actions.saveDocAttempts
export const clearAttempt = solveSlice.actions.clearAttempt
export const actions = solveSlice.actions
