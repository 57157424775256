
import {MLink} from "./svglink";

export default function Links (props) {
  const {cSz, links, matrix} = props

  //console.log(`render ${links?.length} links`)

  return <g className="linkLayer">
    {
      links.map(e => (
          <MLink
              key={`link_x${e.wordRange.x}_y${e.wordRange.y}_${e.type||e.sourceType}_${e.direction}`}
              cSz={cSz}
              e={e}
              matrix={matrix}
          />
      ))
    }
  </g>
}
