import {useEffect, useState} from "react";
import {Plupper} from "../../utils/Plupper";
import {ListItem, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {getAllWordMarkers, getAllWords, ru} from "../../xutils";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

export default function AnalyticsModal({x, setSelections, onCancel}){
    const [open, setOpen] = useState(false)
    const [analysis, setAnalysis] = useState(null)

    const handleCancel = () => {
        setOpen(false)
        setAnalysis(false)
        onCancel?.()
    }

    useEffect(()=>{
        const analyze = () => {
            if (x) {
                const p = new Plupper(x)
                const density = p.getDensity()
                const allWordMarkers = getAllWordMarkers(p.matrix, p.xObj).filter(wm=>wm.length > 1)
                const allWords = getAllWords(p.matrix, p.xObj)
                const duplicates = allWords.filter((word, index)=>allWords.indexOf(word) !== index)
                const wordCntMap = allWords.reduce((map, word) => {
                    if (!map[word.length]) {
                        map[word.length] = []
                    }
                    map[word.length].push(word)
                    return map
                }, [])

                const {
                    textRanges: unlinkedTextRanges,
                    badLinks,
                    duplicateLinks,
                    wordRanges
                } = p.getUnlinkedTextRanges()


                setAnalysis({
                    density,
                    allWords,
                    allWordMarkers,
                    duplicates,
                    wordCntMap,
                    unlinkedTextRanges,
                    badLinks,
                    duplicateLinks,
                    wordRanges
                })
            }
        }
       if (x && !analysis && open) {
           analyze()
       }
    },[x, analysis, open])

    return <>
        <React.Fragment>
            {!open && <Tooltip title="Analytics">
                <IconButton onClick={()=>{setOpen(true)}}>
                    <AnalyticsIcon/>
                </IconButton></Tooltip>}
            {open && analysis && <Dialog
                open={open}
                onClose={handleCancel}
            >
                <DialogContent>
                    <Typography>Density: {analysis.density}</Typography>
                    {analysis.unlinkedTextRanges && <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Unlinked keys <SmallText text={`(${analysis.unlinkedTextRanges.length})`}/></AccordionSummary>
                        <AccordionDetails>
                            <List>
                                {analysis.unlinkedTextRanges.map(tr => {
                                    if (tr.parts) {
                                        return (<>
                                            {tr.parts.map((part, pI) => {
                                                if (part.linked)
                                                    return null
                                                return <ListItem key={`tr_${ru.str(tr.rect)}_${pI}`}>{part.text || JSON.stringify(tr.rect)}</ListItem>
                                            }).filter(n => n)}
                                        </>)
                                    }
                                    return <ListItem key={`tr_${ru.str(tr.rect)}`}>{tr.text  || JSON.stringify(tr.rect)}</ListItem>
                                })}
                            </List>
                        </AccordionDetails>
                    </Accordion>}
                    {analysis.wordRanges && <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Unlinked words <SmallText text={`(${analysis.wordRanges.length})`}/></AccordionSummary>
                        <AccordionDetails>
                            <List>
                                {analysis.wordRanges.map(wr=>(<ListItem>{wr.map(wr=>wr.val).join("")}</ListItem>))}
                            </List>
                        </AccordionDetails>
                    </Accordion>}
                    {analysis.duplicates && <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Duplicates <SmallText text={`(${analysis.duplicates.length})`}/></AccordionSummary>
                        <AccordionDetails><List>
                            {analysis.duplicates.map(word=>(<ListItem>{word}</ListItem>))}
                        </List></AccordionDetails>
                    </Accordion>}
                    {analysis.wordCntMap && <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Words by length</AccordionSummary>
                        <AccordionDetails>
                            <List>
                                {analysis.wordCntMap.filter(n=>n).map(words => (<Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{words[0].length} <SmallText text={`(${words.length})`}/></AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            {words.map(word=>(<ListItem>{word}</ListItem>))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>))}
                            </List>
                        </AccordionDetails>
                    </Accordion>}
                    {analysis.allWordMarkers && <>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>All words <SmallText text={`(${analysis.allWords.length})`} /></AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {analysis.allWordMarkers.map(wordMarker=>{
                                        const word = wordMarker.map(wm=>wm.val || ".").join("")
                                        return <ListItem
                                            key={word}
                                            onClick={()=>{
                                                setSelections?.([{
                                                    clickedObj: {e: {...wordMarker[0]}},
                                                    endObj: {e: {...wordMarker[wordMarker.length-1]}}
                                                }])
                                                handleCancel()
                                            }}
                                        >{word}</ListItem>
                                    })}
                                </List>
                            </AccordionDetails>
                        </Accordion>

                    </>}

                </DialogContent>
            </Dialog>}
        </React.Fragment>
    </>
}

function SmallText({text}) {
    return <Typography fontSize={8}>
        {text}
    </Typography>
}
