import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Auth} from "aws-amplify";
import { useSelector } from 'react-redux';
import {Navigate, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Alert, CircularProgress} from "@mui/material";

function Copyright(props) {
  return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.burnessonikvadrat.se">
          Burnesson i kvadrat
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const theme = createTheme();

export default function Signup() {

  const user = useSelector((state) => state.user)

  const [enterConfirm, setEnterConfirm] = useState()
  const [newUser, setNewUser] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [signedUp, setSignedUp] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    console.log("signin useEffect")
    // Update the document title using the browser API
    //Auth.currentUserInfo().then(console.log)
    //Auth.currentCredentials().then(console.log)
    //Auth.currentSession().then(console.log)
    const checkLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        console.log(user)
        /*setTimeout(()=>{
          navigate("/dashboard")
        }, 3000)*/
      } catch(err) {
        console.error(err)
      }
    }
    checkLoggedIn()
  }, [navigate]);

  const handleSubmit = (event) => {
    setErrorMessage()
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userName = data.get('username')
    const password = data.get('password')
    const email = data.get('email')
    const code = data.get('code')

    if (!enterConfirm) {
      Auth.signUp({
        username: userName,
        password: password,
        attributes: {
          email: email
        },
        autoSignIn: {
          enabled: true,
        }
      }).then((data)=>{
        console.log(data)
        setNewUser(data.user)
        setEnterConfirm(true)
      }).catch(error=>{setErrorMessage(error.message)});
    } else {
      console.log(`confirmSignup with ${newUser.username} ${code}`)
      Auth.confirmSignUp(newUser.username, code).then((data)=>{
        console.log(data)
        console.log(newUser)
        setSignedUp(true)
        setTimeout(()=>{
          navigate("/dashboard")
        }, 3000)
      }).catch(error=>{setErrorMessage(error.message)});
    }
  };

  console.log("Signin render")

  if (user?.username) {
    return <Navigate replace to="/dashboard" />;
  }

  return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
          >
            {signedUp && <CircularProgress onClick={()=>{
              Auth.currentAuthenticatedUser().then(user => {
                console.log(user)
                setTimeout(()=>{
                  navigate("/dashboard")
                }, 3000)
              }).catch(console.error)
            }}/>}

            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {signedUp ? "Hold on a few seconds..." : "Sign up to Kruxet"}
            </Typography>
            {!signedUp && <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="off"
                  autoFocus
                  type="text"
                  disabled={enterConfirm}
              />
              {enterConfirm && <Typography>
                Check your email for a verification code...
              </Typography>}
              {enterConfirm && <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  name="code"
                  autoComplete="number"
                  autoFocus
                  type="number"
              />}
              {!enterConfirm && <>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    type="email"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                />
              </>}
              {/*              <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
              />*/}
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
              >
                {enterConfirm ? "Confirm code" : "Sign up"}
              </Button>
              {errorMessage && <Alert severity="error">{JSON.stringify(errorMessage, null, 2)}</Alert>}
            </Box>}
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
  );
}
