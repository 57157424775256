import React, {useState} from "react";
import { useTheme } from '@mui/material/styles';
import Divider from "@mui/material/Divider";
import ThreeInTop from "./ThreeInTop";
import Grid from "@mui/material/Grid";
import PickOrderBox from "../../ui/PickOrderBox";

const sortSchedule = (a,b) => new Date(b.start).getTime() - new Date(a.start).getTime()

export default function ThreeInTopEditor({xlist, xListMap, uId, data, onUpdated}) {

    const [selection, setSelection] = useState()
    const [currentDate, setCurrentDate] = useState()
    const [focusedThreeInTop, setFocusedThreeInTop] = useState()
    const [focusedThreeInTopEntry, setFocusedThreeInTopEntry] = useState()

    useTheme();

    const style = {
        height: "80vh",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: "scroll"
    };

    const onSelect = (threeInTopInd, threeInTopEntryInd) => {
        setFocusedThreeInTop(threeInTopInd)
        setFocusedThreeInTopEntry(threeInTopEntryInd)
        console.log(`${threeInTopInd} ${threeInTopEntryInd}`)
    }
    console.log(data)

    return <Grid container spacing={2} >
        <Grid container item xs={8} direction="column" >
            <Divider/>
            {data?.schedule.sort(sortSchedule).map((entry, index) => {
                return <ThreeInTop
                    focusedThreeInTopEntry={focusedThreeInTop === index && focusedThreeInTopEntry}
                    key={`threeInTop_${index}`}
                    data={entry}
                    xListMap={xListMap}
                    xList={xlist}
                    onSelect={(entryInd)=>{
                        onSelect(index, entryInd)
                    }}
                    selection={selection}
                    onSelected={setSelection}
                    onDelete={()=>{
                        const newData = JSON.parse(JSON.stringify(data))
                        newData.schedule.splice(index, 1)
                        newData.puzzles = undefined
                        onUpdated(newData)
                    }}
                    uId={uId}
                    onChange={(newEntry)=>{
                        const newData = JSON.parse(JSON.stringify(data))
                        newData.schedule[index] = newEntry
                        newData.schedule.sort(sortSchedule)
                        onUpdated(newData)
                        console.log(newData)
                    }}
                    handleChange={(selectedDate)=>{
                        setFocusedThreeInTop()
                        setFocusedThreeInTopEntry()
                        setCurrentDate(selectedDate)
                    }}
                    expanded={currentDate}
                />
            })}
            {/*<Grid container spacing={2} sx={{padding: "20px"}}>
                <div>
                    {data && JSON.stringify(data, '<br/>', 2)}
                </div>
            </Grid>*/}
        </Grid>
        <Grid container item xs={4} direction="column" >
            <PickOrderBox boxStyle={style} onPicked={(picked)=>{
                console.log(picked)
                console.log(data)
                if (focusedThreeInTopEntry !== undefined && focusedThreeInTopEntry !== undefined) {
                    console.log(focusedThreeInTop)
                    console.log(focusedThreeInTopEntry)
                    const newData = JSON.parse(JSON.stringify(data))
                    const newEntry = newData.schedule[focusedThreeInTop].threeTop[focusedThreeInTopEntry]
                    newEntry.xId = picked.id
                    newEntry.uId = uId
                    newEntry.title = newEntry.title || "?"
                    newEntry.description = newEntry.description || "?"
                    newData.schedule.sort(sortSchedule)
                    console.log(newData.schedule[focusedThreeInTop].threeTop[focusedThreeInTopEntry])
                    onUpdated(newData)
                }
            }}/>
        </Grid>
    </Grid>
}
