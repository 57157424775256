import {Auth} from "aws-amplify";
import Lambda from "aws-sdk/clients/lambda";

const params = (payload, functionName = "serverlexs-dev-x-data") => ({FunctionName: functionName, LogType: 'Tail', Payload: JSON.stringify(payload)})
const pdfParams = (payload) => params(payload, "serverlexs-dev-lambda-pdf")
const pdf2imageParams = (payload) => params(payload, "serverlexs-dev-pdf2image")
const invoke = async (lambda, payload) => {
  const result = await lambda.invoke(payload).promise()
  if (result.statusCode && result.statusCode !== 200) {
    console.error(result)
    throw new Error(`Invocation error for ${payload}`)
  }
  return JSON.parse(result.Payload)
}

async function setup(userId) {
  const credentials = await Auth.currentCredentials()
  const lambda = new Lambda({
    region: "eu-north-1",
    credentials: Auth.essentialCredentials(credentials)}
  )
  const uId = userId || credentials.identityId
  return {lambda, uId, credentials}
}

export const getUserRights = async (userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "getUserRights", params:{uId: uId}}))).body?.Item
}

export const setUserRightsName = async (userId, name) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "setUserRightsName", params:{uId: uId, xUserName: name}}))).body
}

export const listStreamsForUser = async (userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "listStreamsForUser", params: {uId: uId}}))).body.streams
}

export const openStream = async (userId, streamId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "openStream", params: {uId: uId, streamId: streamId}}))).body.stream
}

export const saveStream = async (userId, streamId, streamData) => {
  const {lambda, uId} = await setup(userId)
  await invoke(lambda, params({action: "saveStream", params: {uId: uId, streamId: streamId, streamData: streamData}}))
}

export const createStream = async (userId, streamId, streamData) => {
  const {lambda, uId} = await setup(userId)
  return await invoke(lambda, params({action: "createStream", params: {uId: uId, streamId: streamId, streamData: streamData}})).body
}

export const deleteStream = async (userId, streamId, streamData) => {
  const {lambda, uId} = await setup(userId)
  return await invoke(lambda, params({action: "deleteStream", params: {uId: uId, streamId: streamId}})).body
}

export const generatePdf = async (params) => {
  const {userId} = params
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, pdfParams({...params, uId: uId}))).signedUrl
}

export const deletePdf = async (xId, userId, fileName) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "deletePdf", params: {xId: xId, uId: uId, fileName: fileName}}))).body
}

export const getLists = async (userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "getLists", params: {uId: uId}}))).body
}

export const getUserLists = async (userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "getUserLists", params: {uId: uId}}))).body
}

export const getGetUserList = async (payload) => {
  console.log(`getGetUserList(${JSON.stringify(payload)})`)
  const {userId, listName} = payload
  const {lambda, uId} = await setup(userId)
  return (await  invoke(lambda, params({action: "getGetUserList", params: {uId: uId, listName: listName}}))).body
}

export const getGetUserLists = async (payload) => {
  console.log(`getGetUserLists(${JSON.stringify(payload)})`)
  const {userId, lists} = payload
  const {lambda, uId} = await setup(userId)
  return (await  invoke(lambda, params({action: "getGetUserLists", params: {uId: uId, lists: lists}}))).body
}

export const getPutUserList = async (payload) => {
  const {userId, listName} = payload
  return await urlGet("getPutUserList", "", userId, {listName: listName})
}

export const addUserList = async(payload) => {
  const {userId, listName, words} = payload
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "addUserList", params: {uId: uId, listName: listName, words: words}})))
}

export const removeUserList = async(payload) => {
  const {userId, listName} = payload
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "removeUserList", params: {uId: uId, listName: listName}})))
}

export const removeUserFont = async(payload) => {
  const {userId, fontName} = payload
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "removeUserFont", params: {uId: uId, fontName: fontName}})))
}

export const getGetLists = async (payload) => {
  console.log(`getGetLists(${JSON.stringify(payload)})`)
  const {userId, lists} = payload
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "getGetLists", params: {uId: uId, lists: lists}}))).body
}

export const scanOrders = async (userId, payload) => {
  const {lambda} = await setup(userId)
  return (await invoke (lambda, params({action: "scanOrders", params: {...payload}}))).body
}

export const listX = async (userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "listX", params: {uId: uId}}))).body
}

export const listXVersions = async (xId, userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "listXVersions", params: {xId: xId, uId: uId}}))).body
}

export const deleteXVersion = async (xId, userId, versionId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "deleteXVersion", params: {xId: xId, uId: uId, versionId: versionId}}))).body
}

export const listXOrders = async(userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "listXOrders", params: {uId: uId}}))).body
}

export const deleteX = async (xId, userId) => {
  const {lambda, uId} = await setup(userId)
  await invoke(lambda, params({action: "deleteX", params: {xId: xId, uId: uId}}))
  return true
}

export const createX = async (xId, userId, x) => {
  const {lambda, uId} = await setup(userId)
  await invoke(lambda, params({action: "createX", params: {xId: xId, uId: uId, x: x}}))
  return true
}

export const pdf2Image = async (xId, userId, fileName, width=200) => {
  const {lambda, uId} = await setup(userId)
  return await invoke(lambda, pdf2imageParams({action: "resize", params: {xId: xId, uId: uId, width: width, fileName: fileName}}))
}

export const getKeys = async(xId, userId, word) => {
  if (!word || word.length === 0) {
    throw new Error(`getKeys word must not be ${word}`)
  }
  const {lambda, uId} = await setup(userId)
  const body = (await invoke(lambda, params({action: "getKeys", params: {xId: xId, uId: uId, word: word}}))).body
  return body
}

export const getAllKeysForUser = async(userId) => {
  const {lambda, uId} = await setup(userId)
  let allItems = []
  let body
  do {
    body = (await invoke(lambda, params({action: "getAllKeysForUser", params: {uId: uId, LastEvaluatedKey: body?.LastEvaluatedKey}}))).body
    allItems = [...allItems, ...body.Items]
  } while (body.LastEvaluatedKey)
  return allItems
}

export const getALlKeyWordsForUser = async(userId) => {
  const {lambda, uId} = await setup(userId)
  const body = (await invoke(lambda, params({action: "getAllKeyWords", params: {uId: uId}}))).body
  return body
}

export const queryKeys = async(userId, begins_with) => {
  const {lambda, uId} = await setup(userId)
  const body = (await invoke(lambda, params({action: "queryKeys", params: {uId: uId, begins_with: begins_with}}))).body
  return body
}

export const putKeys = async(userId, word, keys) => {
  const {lambda, uId} = await setup(userId)
  const body = (await invoke(lambda, params({action: "putKeys", params: {uId: uId, word: word, keys: keys}}))).body
  return body
}

export const deleteKeys = async(userId, word) => {
  const {lambda, uId} = await setup(userId)
  const body = (await invoke(lambda, params({action: "deleteKeys", params: {uId: uId, word: word}}))).body
  return body
}

export const saveX = async (xId, userId, x) => {
  const {lambda, uId} = await setup(userId)
  await invoke(lambda, params({action: "saveX", params: {xId: xId, uId: uId, x: x}}))
  return true
}

export const generateX = async(xId, userId, x, config) => {
  const {lambda, uId} = await setup(userId)
  return await invoke(lambda, params({action: "generate", params: {xId: xId, uId: uId, x: x, ...config}}, "serverlexs-dev-genx"))
}

export const stopGenerateX = async(xId, userId, jobName) => {
  const {lambda, uId} = await setup(userId)
  return await invoke(lambda, params({action: "stopJob", params: {xId: xId, uId: uId, jobName: jobName}}, "serverlexs-dev-genx"))
}

export const listJobs = async (xId, userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "listJobs", params: {xId: xId, uId: uId}}, "serverlexs-dev-genx"))).body
}

export const getJob = async (xId, userId, jobName, compact) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "getJob", params: {xId: xId, uId: uId, jobName: jobName, compact: compact}}, "serverlexs-dev-genx"))).body
}
export const deleteJob = async (xId, userId, jobName) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "deleteJob", params: {xId: xId, uId: uId, jobName: jobName}}, "serverlexs-dev-genx"))).body
}
export const getJobEvent = async (xId, userId, jobName) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "getJobEvent", params: {xId: xId, uId: uId, jobName: jobName}}, "serverlexs-dev-genx"))).body
}

export const openX = async (xId, userId, versionId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "openX", params: {xId: xId, uId: uId, versionId: versionId}}))).body.x
}

export const urlGet = async (action, xId, userId, extraParams= {}) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: action, params: {xId: xId, uId: uId, ...extraParams}}))).body.url
}

export const getPutFont = async (userId, fontName, contentType) => {
  return await urlGet("getPutFont", "", userId, {fontName: fontName, contentType: contentType})
}

export const getGetFont = async (userId, fontName) => {
  console.log("getGetFont")
  return await urlGet("getGetFont", "", userId, {fontName: fontName})
}

export const getGetImg = async (xId, userId, imageName) => {
  return await urlGet("getGetImg", xId, userId, {imageName: imageName})
}

export const getPutImg = async (xId, userId, imageName) => {
  return await urlGet ("getPutImg", xId, userId, {imageName: imageName})
}

export const getGetSvg = async (xId, userId) => {
  return await urlGet("getGetSvg", xId, userId)
}

export const getPutSvg = async (xId, userId) => {
  return await urlGet("getPutSvg", xId, userId)
}

export const getFontList = async (userId) => {
  const {lambda, uId} = await setup(userId)
  return (await invoke(lambda, params({action: "getFontList", params: {uId: uId}}))).body
}
