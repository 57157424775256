import Box from "@mui/material/Box";
import * as React from "react";
import {useDropzone} from "react-dropzone";
import {useCallback, useRef} from "react";
import {Button} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {addUserList} from "../backend";

export default function ListAdder({uId}) {

    const [words, setWords] = React.useState()
    const [listName, setListName] = React.useState()

    const updateListName = (txt) => {
        setListName(txt.replace(/[^a-z0-9]/gi, ''))
    }

    const onDrop = useCallback(async (acceptedFiles, fileRejections, event) => {
        console.log(event)

        for (const file of acceptedFiles) {

            console.log(file.name)
            console.log(stateRef.uId)

            const reader = new FileReader()
            reader.onload = function(e) {
                const contents = e.target.result
                console.log(contents)
                setWords(contents.split("\n").filter(n => n).filter(n=> n.length > 0).map(n=>n.toUpperCase()).sort())
                updateListName(file.name.split(".")[0])
            };
            reader.readAsText(file)
        }
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true})
    const stateRef = useRef();
    stateRef.uId = uId

    return <>
        {words && <>
            <TextField
                id="standard-basic"
                label="List name"
                variant="standard"
                onChange={(e)=>{
                    updateListName(e.target.value)
                }}
                value={listName}
            />
            <Button
                onClick={async ()=>{
                    setWords()
                    setListName()
                    const result = await addUserList({listName: listName, words: words})
                    console.log(result)
                }
            }>Submit</Button>

            <Button onClick={()=>{
                setWords()
            }
            }>Clear</Button>


            {words && <Typography>{words.length} words</Typography>}
        </>}
        {!words && <>
            <div {...getRootProps()} >
                <input {...getInputProps()} readOnly={true} />
                <Box sx={{ minWidth: 120, minHeight: 500, backgroundColor: "darkGray" }}>
                    <Typography sx={{textAlign: "center", padding: "70px 0"}} variant="h3">Drop your list here</Typography>
                </Box>
            </div>
        </>}
        </>
}
