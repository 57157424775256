import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";
import {Button, CardActionArea, CardMedia} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme();

export default function Index() {
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={theme}>
            <AppBar
                position="static"
            >
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Kruxet
                    </Typography>
                    <Button color="inherit" onClick={()=>{navigate("/signin")}}>Sign in to the Console</Button>

                </Toolbar>
            </AppBar>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <Container>
                    <Box textAlign='center'>
                        <Card sx={{
                            backgroundColor: "white",
                            marginTop: 1
                        }}>
                            <CardActionArea onClick={() => {
                                navigate("/signin")
                            }}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="res/images/xpart.jpeg"
                                    title="xpart">
                                    <div style={{
                                        width: "100%", height: "100%",
                                        backgroundImage: "linear-gradient(to left, rgba(205,205,205,0), rgba(205, 205, 205, 1))"}}/>
                                </CardMedia>
                                <CardContent>
                                    <Typography>
                                        Kruxet is Everything you need to create and share crosswords.
                                    </Typography>
                                    <Button>Sign in to the Console</Button>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card sx={{
                            backgroundColor: "white",
                            marginTop: 1
                        }}>
                            <CardActionArea onClick={() => {
                                window.open("https://kryssa.kruxet.com")
                            }}>
                                <CardMedia
                                    sx={{height: 140}}
                                    image="res/images/xpart3.jpeg"
                                    title="xpart3">
                                    <div style={{
                                        width: "100%", height: "100%",
                                        backgroundImage: "linear-gradient(to left, rgba(205,205,205,0), rgba(205, 205, 205, 1))"}}/>
                                </CardMedia>
                                <CardContent>
                                    <Typography>
                                        Everyone can access Crosswords published in streams by You and Others.
                                    </Typography>
                                    <Button>Try out some crosswords</Button>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
}
