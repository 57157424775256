import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getGetUserLists, getUserLists} from "../../backend";

export const fetchUserLists = createAsyncThunk("userlists/fetchStatus", async (uId) => {return getUserLists(uId)})
export const fetchUserWords = createAsyncThunk("userlists/fetchWords", async (payload, {rejectWithValue, getState}) => {
    console.log("fetchUserWords")
    const state = getState()

    payload.lists = payload.lists.filter(entry => {
        const lastFetch = state?.persistedReducer?.userLists?.wordLists?.[entry]?.fetched
        const indexEntry = state?.persistedReducer?.userLists?.lists?.find(e2=>e2.name === entry)
        const indexFetch = indexEntry?.lastModified
        return !lastFetch || !indexFetch || (new Date(lastFetch) < new Date(indexFetch)) || (new Date().getTime() - new Date(lastFetch).getTime() > 1000*(3600-60))
    })
    if (payload.lists.length === 0) {
        return rejectWithValue({message: "No need to fetch"})
    }
    const result = await getGetUserLists(payload)
    const now = new Date().toISOString()
    console.log("Returning userWordlists")
    return result.map(i => ({...i, fetched: now}))
})

const initialState = {
    loading: false,
    lists: [],
    wordLists: {}
}

export const userWordlists = createSlice({
    name: "userWordlists",
    initialState,
    reducers: {
        reset: () => initialState,
        removeList: (draftState, action) => {
            const listName = `${action.payload.listName}`
            draftState.lists = draftState.lists.filter(listInfo => listInfo.name !== listName)
            delete draftState.wordLists[listName]
            return draftState
        }
    },
    extraReducers: {
        [fetchUserLists.pending]: (state) => {
            state.loading = false
        },
        [fetchUserLists.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.lists = payload
        },
        [fetchUserLists.rejected]: (state, {error}) => {
            console.error(error)
            state.loading = false
        },
        [fetchUserWords.pending]: (state) => {
            state.loading = false
        },
        [fetchUserWords.fulfilled]: (state, { payload }) => {
            state.loading = false
            payload.forEach(entry=> {
                state.wordLists[entry.list] = {url: entry.url, fetched: entry.fetched}
            })
        },
        [fetchUserWords.rejected]: (state, value) => {
            state.loading = false
        }
    }
})

export const userWordlistReducer = userWordlists.reducer
export const removeUserWordList = userWordlists.actions.removeList
export const clearUserWordLists = userWordlists.actions.reset
