import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet";
import favicon from "./kruxet.png"
import * as React from 'react';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-central-1:1825447d-ad4c-4ae4-8d2a-e796b5576cbb',

    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: 'eu-central-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-central-1_zbLeS0DqL',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '7ejfs64m0q748ag0ts6iqvrra3',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod: 'code', // 'code' | 'link'

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    //cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //domain: '.kruxet.com',
      // OPTIONAL - Cookie path
      //path: '/',
      // OPTIONAL - Cookie expiration in days
      //expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //sameSite: "strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //secure: true
    //},

    // OPTIONAL - customized storage object
    //storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    //authenticationFlowType: 'USER_PASSWORD_AUTH',
    authenticationFlowType: 'USER_SRP_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
/*    clientMetadata: { myCustomKey: 'myCustomValue' },*/

    // OPTIONAL - Hosted UI configuration
/*    oauth: {
      domain: 'your_cognito_domain',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'http://localhost:3000/',
      redirectSignOut: 'http://localhost:3000/',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }*/
  }
});

// You can get the current config object
Auth.configure();

export default function Root() {
  return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{process.env.REACT_APP_VARIANT === "kryssa" ? "Kryssa": "Kruxet"}</title>
          <link rel="icon" type="image/png" href={favicon} />
        </Helmet>
        <div id="detail">
          <Outlet />
        </div>
      </>
  );
}