export const east_right = "east_right"
export const west_left = "west_left"
export const south_left = "south_left"
export const north_right = "north_right"
export const east_straight = "east_straight"
export const south_straight = "south_straight"
export const arrowTypes = [east_right, west_left, south_left, north_right, east_straight, south_straight]
export const hzArrows = [east_straight, south_left, north_right]
export const vrtArrows = [west_left, east_right, south_straight]

export const isVrtArrow = arrType => vrtArrows.indexOf(arrType) !== -1
export const isHzArrow = arrType => hzArrows.indexOf(arrType) !== -1

