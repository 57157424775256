import * as React from "react";
import {LEFT, RIGHT} from "../../cellTypes";
import TextEntryNumberInput from "./TextEntryNumberInput";
import TextEntrySelect from "./TextEntrySelect";

export default function TextOverlayEntry({elem, fonts, drawerWidth, updateXElem, getFontSize, getLeadingSize}) {

    const hzSizes = []
    for (let i = 0; i < (elem.rect.w || 1); i++) {
        for (let j = 5; j < 95; j++)
        hzSizes.push(100*i + j)
    }
    const hzElem = elem.overlayPosition === LEFT || elem.overlayPosition === RIGHT

    return <>
        <TextEntrySelect
            idPart={"font"}
            vals={fonts}
            updateXElem={updateXElem}
            drawerWidth={drawerWidth}
            elem={elem}
        />
        <TextEntryNumberInput
            idPart={"fontSize"}
            label={"font size"}
            getSize={getFontSize}
            updateXElem={updateXElem}
            drawerWidth={drawerWidth}
            elem={elem}/>
        <TextEntryNumberInput
            idPart={"leading"}
            label={"leading"}
            getSize={getLeadingSize}
            updateXElem={updateXElem}
            drawerWidth={drawerWidth}
            elem={elem}/>
        {hzElem && <TextEntrySelect
            idPart={"fixedHzSize"}
            vals={hzSizes}
            label="fixed size"
            updateXElem={updateXElem}
            drawerWidth={drawerWidth}
            elem={elem}
        />}
    </>
}
