import React, { memo } from "react";
import Button from '@mui/material/Button';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import {deleteX} from "../../backend";

export const DeleteButton = memo(({ selectionModel = [], uId, onDelete }) => {
    const handleClick = () => {
        const deleted = []
        console.log("Radera", selectionModel);
        selectionModel.forEach(xId => {
            deleteX(xId, uId)
            deleted.push(xId)
        })
        onDelete?.(deleted)
    };

    const isDisabled = !(selectionModel.length > 0);

    return (
        <Button
            startIcon={<DeleteOutline />}
            onClick={handleClick}
            disabled={isDisabled}
        >
            Delete
        </Button>
    );
});
