import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from "react";
import {getFontList, removeUserFont} from "../backend";
import {Button} from "@mui/material";

export default function FontRemover({uId}) {

    const [userFonts, setUserFonts] = React.useState([])

    useEffect(() => {
        if (uId) {
            getFontList(uId).then(fontInfoList => {
                const fontNames = fontInfoList.map(fontInfo => fontInfo.name).sort()
                console.log(fontNames)
                setUserFonts(fontNames)
            })
        }
    }, [uId])

    const [fontName, setFontName] = React.useState('');

    const handleChange = (event) => {
        setFontName(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <Select
                    id="demo-simple-select"
                    onChange={handleChange}
                    value={fontName}
                >
                    {userFonts.map(list => (<MenuItem key={list} value={list}>{list}</MenuItem>))}
                </Select>
            </FormControl>
            {fontName &&
                <Button onClick={async ()=>{
                    const result = await removeUserFont({fontName: fontName})
                    console.log(result)
                    setUserFonts([])
                    setFontName(null)
                    setTimeout(()=>{
                        getFontList(uId).then((ul)=>{
                            setUserFonts(ul.map(info => info.name))
                        })
                    }, 10)
                }
            }>Delete {fontName}</Button>}

        </Box>
    );
}
