import {Chip, Divider} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {DataGrid, GridToolbarContainer, GridToolbarFilterButton} from "@mui/x-data-grid";
import {fetchXlist, fetchXOrders} from "../features/xlist/xlistSlice";
import {useDispatch, useSelector} from "react-redux";

export default function PickOrderBox({onPicked, boxStyle}) {

    const dispatch = useDispatch()
    const xListSlice=useSelector((state) => state.persistedReducer.xlist)
    const xlist = xListSlice?.entities
    const xOrders = xListSlice?.xOrders

    const xColumns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            flex: 1,
        },
        {
            field: 'level',
            headerName: 'Level',
            width: 20
        },
    ];

    const xRows = xlist?.map(entity => ({
        name: entity.name,
        level: entity.level,
        id: entity.xId
    }))

    const xOrderColumns = [{
        field: 'name',
        headerName: 'Name',
        width: 150,
        flex: 1,
    }]
    const xOrderRows = xOrders?.map(entity => ({
        name: entity.xName,
        id: entity.xId
    }))

    const onCellClick = (params, event) => {
        if (params.field === "name") {
            console.log("Open " + params.row.name)
            onPicked?.({...params.row, id: params.row.id})
            /*Auth.currentCredentials().then(credentials => {
                let win = window.open(`/editor?xId=${params.id}&uId=${credentials.identityId}`, event.shiftKey ? '_blank' : '_self')
                win.focus()
            })*/
        }
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        )
    }

    const renderDataGrid = (rows, columns, fetchData) => {
        return <DataGrid
            autoHeight
            rows={rows || []}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            onCellClick={onCellClick}
            onColumnHeaderClick={()=>{
                dispatch(fetchData())
            }}
            localeText={{
                toolbarColumns: "my columns",
                toolbarFilters: "Filter",
                toolbarDensity: "my density",
                toolbarExport: "my export"
            }}
            components={{
                Toolbar: CustomToolbar,
            }}
        />
    }

    return <Box sx={boxStyle}>
        <Divider sx={{ m: 2 }}>
            <Chip label="Your puzzles" />
        </Divider>
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            <Box maxWidth="md" sx={{flexGrow: 1}} >
                {renderDataGrid(xRows, xColumns, fetchXlist)}
            </Box>
        </Box>
        {xOrderRows && <>
            <Divider sx={{ m: 2 }}>
                <Chip label="Your orders" />
            </Divider>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <Box maxWidth="md" sx={{flexGrow: 1}} >
                    {renderDataGrid(xOrderRows, xOrderColumns, fetchXOrders)}
                </Box>
            </Box>
        </>
        }
    </Box>
}
