import React, {useEffect, useState} from "react";
import Crossword from "../../crossword/svgcrossword";
import {createMatrix} from "../../xutils";
import MediaControlCard from "../../ui/MediaControlCard";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {getX} from "../../features/solving/soveSlice";
import StreamSolver from "./streamsolver";

export default function StreamSolverMenu({config={}}) {
    const {streamId, singleEntry} = config

    const [loaded, setLoaded] = useState(false)
    const [response, setResponse] = useState()
    const [currentX, setCurrentX] = useState()
    useTheme();
    const streamName = streamId || "teststream"
    console.log(streamName)

    const cachedPuzzles = useSelector((state) => state.persistedReducer.solve.puzzles)

    const dispatch = useDispatch()

    useEffect(() => {
        fetch(`https://jh0gpkrasj.execute-api.eu-north-1.amazonaws.com/dev/streams/${streamName}`)
            .then(res => res.json()).then(data => {
            console.log(data)
            return data
        }).then(setResponse)
    }, [streamName, singleEntry])
    useEffect(()=> {
        console.log(response)
        if (response && !loaded) {
            setLoaded(true)
            response.result.threeTop?.forEach((tp) => {
                const {xId} = tp
                if (!cachedPuzzles[xId]) {
                    dispatch(getX({streamId: streamName, puzzleId: xId}))
                }
            })
            response.result.singleEntries?.filter(entry => entry.xId === singleEntry).forEach((tp) => {
                const {xId} = tp
                if (!cachedPuzzles[xId]) {
                    dispatch(getX({streamId: streamName, puzzleId: xId}))
                }
            })
        }
    }, [cachedPuzzles, dispatch, response, loaded, streamName, singleEntry])
    console.log(response)

    const openX = (xId) => {
        setCurrentX(xId)
    }

    const renderCard = (puzzle, xPrps={}, image) => {
        if (!puzzle)
            return
        const {title, description} = xPrps
        return <MediaControlCard image={image} text={description} title={title} onClick={()=>{openX(puzzle.xId)}}/>
    }

    const createCrossword = (puzzle) => {
        if (!puzzle)
            return
        const matrix = createMatrix(puzzle)
        const crossword = <Crossword stream={streamName} x={puzzle} iDs={{xId: puzzle.xId}} matrix={matrix} clickListener={()=>{openX(puzzle.xId)}}></Crossword>
        return crossword
    }

    const getImage = (xId) => {
        return <img
            width={"200"}
            src={`https://d31agymsqnwyv.cloudfront.net/thumbs/${xId}_thumb.jpeg`}
            alt={xId}
        />
    }

    if (currentX) {
        return <StreamSolver streamId={streamId} puzzleId={currentX} back={setCurrentX}></StreamSolver>
    }

    return <Container className="streamsolver-menu-container">
        {response?.result?.threeTop && <Typography variant="h4" color="text.primary" component="div" sx={{padding: 3}}>
            Dagens korsord
            </Typography>}
        <Grid container spacing={2} sx={{padding: "20px"}}>
            {response?.result?.threeTop?.map(tp => cachedPuzzles[tp.xId])
                .filter(n=>n)
                .map((p, ind) => {
                    return <Grid item xs={12} sm={6} md={4} key={`p_${ind}`}>
                        {renderCard(p, response.result.threeTop[ind], createCrossword(p))}
                    </Grid>
                })}
            {singleEntry && response?.result?.singleEntries?.filter(entry => entry.xId === singleEntry && cachedPuzzles[singleEntry])
                .map((entry) => {
                    return renderCard(cachedPuzzles[entry.xId], {}, getImage(entry.xId))
            })}
        </Grid>
    </Container>
}
