import {ARROW, HZ, IMAGE, LOWER, TEXT, UPPER, VRT, WORD} from "../cellTypes";
import {createMatrix, divideArea, getAllWordMarkers, getWordMarker, ru, setArea} from "../xutils";
import {east_right, east_straight, north_right, south_left, south_straight, west_left} from "../arrowTypes";
import {autoLinkWordRangeToTextRange, link} from "../linkutils";
import {annot_down, annot_right} from "../linkTypes";

export const is = (e, type) => e?.type === type
export const isWord = (e, val) => is(e, WORD) && (!val || (val === e.val))
export const isEmptyWord = (e) => isWord(e) && !e.val
export const notWord = e => !isWord(e)
export const isText = e => is(e, TEXT)
export const isImage = e => is(e, IMAGE)
export const isArrow = (e, arrow) => is(e, ARROW) && (!arrow || e.arrow === arrow)

export const TOP_LEFT = "top left"
export const TOP_CENTER = "top center"
export const TOP_RIGHT = "top right"
export const MIDDLE_LEFT = "middle left"
export const MIDDLE_CENTER = "middle center"
export const MIDDLE_RIGHT = "middle right"
export const BOTTOM_LEFT = "bottom left"
export const BOTTOM_CENTER = "bottom center"
export const BOTTOM_RIGHT = "bottom right"

export const DIRECTION_HZ = "HZ"
export const DIRECTION_VRT = "VRT"

const isTaken = (nextCell, chr)=> {
    return !nextCell || !isWord(nextCell) || (nextCell.val && nextCell.val !== chr && nextCell.val !== "")
}

const throwIfTaken = (nextCell, chr) => {
    if (isTaken(nextCell, chr)) {
        throw new Error(`Cell is already taken: \n${JSON.stringify(nextCell)}.`)
    }
}

const getSplitIndex = (str, l) => {
    console.log("getSplitIndex")
    const arr = []
    let i
    for (i = 0; i < str.length; i++) {
        const chr = str.charAt(i)
        if (chr !== " ") {
            arr.push(chr)
        }
        if (arr.length === l) {
            break
        }
    }
    if (arr.length < l) {
        throw new Error(`Could only fill to ${arr.length}`)
    }
    return i+1
}

const imageXY = (anchors, iw, ih, theX) => {
    const s = anchors.split(" ")
    return [imageX(s[1], iw, theX.w), imageY(s[0], ih, theX.h)]
}
const imageX = (anchor, w, xW) => {
    switch (anchor) {
        case "left":
            return 0
        case "center":
            return w
        case "right":
            return xW-w
        default:
            throw new Error(`anchor ${anchor} is not supported`)
    }
}

const imageY = (anchor, h, xH) => {
    switch (anchor) {
        case "top":
            return 0
        case "middle":
            return h
        case "bottom":
            return xH-h
        default:
            throw new Error(`anchor ${anchor} is not supported`)
    }
}

export const makeElemText = elem => {
    elem.type = TEXT
    elem.text = ""
}

export const makeElemWord = (elem, val, clr) => {
    elem.type = WORD
    elem.val = val
    elem.clr = clr
}

/**
 * Terminology:
 *  - Island: A set of adjecent cells of the same type
 *  - Hole: An island of type WORD, separated from the main WORD grid
 *
 * */
export class Plupper {
    DEFAULT_SENTENCE_COLOR = "#FFCCE6";
    WHITE = "#FFFFFF";

    constructor(xObj) {
        this.xObj = JSON.parse(JSON.stringify(xObj))
        this.matrix = createMatrix(this.xObj)
        this.w = this.xObj.w
        this.h = this.xObj.h
    }

    getX() {
        return JSON.parse(JSON.stringify(this.xObj))
    }

    e(x, y) {
        return this.matrix[y]?.[x]?.e
    }

    neighbor (elem, xD=0, yD=0) {
        if (!elem)
            return null
        return this.matrix[elem.rect.y + yD]?.[elem.rect.x + xD]?.e
    }

    /**
     * Get the link having the pointed out position as wordRange, if it exists
     * */
    getWrLinks (elem, xD=0, yD=0) {
        if (!elem)
            return null
        return this.matrix[elem.rect.y + yD]?.[elem.rect.x + xD]?.l
    }

    throwIfNotEmptyWord(rect) {
        const {x,y,w=1,h=1} = rect
        for (let xi =0; xi < w; xi++) {
            for (let yi = 0; yi < h; yi++) {
                throwIfTaken(this.e(x+xi, y+yi))
            }
        }
    }

    isTextNeighbor (elem, xD=0, yD=0) {
        return elem && isText(this.neighbor(elem, xD, yD))
    }

    isImageNeighbor (elem, xD, yD) {
        return elem && isImage(this.neighbor(elem, xD, yD))
    }

    isLeftBorder (elem) {
        return elem && (!this.neighbor(elem, -1, 0) || this.isImageNeighbor(elem, -1, 0))
    }

    isTopBorder(elem) {
        return elem && (!this.neighbor(elem, 0, -1) || this.isImageNeighbor(elem, 0, -1))
    }

    isRightBorder (elem) {
        return elem && (!this.neighbor(elem, 1, 0) || this.isImageNeighbor(elem, 1, 0))
    }

    isBottomBorder(elem) {
        return elem && (!this.neighbor(elem, 0, 1) || this.isImageNeighbor(elem, 0, 1))
    }

    isBottomRightCorner (e) {
        return this.isBottomBorder(e) && this.isRightBorder(e)
    }

    isImageCornerEdge (elem) {
        return elem && (this.isImageNeighbor(elem, -1, -1)
            && !this.isImageNeighbor(elem, 0, -1)
            && !this.isImageNeighbor(elem, -1, 0))
    }
    isTopOrLeftBorderElem (elem) {
        return elem && (this.isLeftBorder(elem) || this.isTopBorder(elem))
    }
    isTopAndLeftBorderElem (elem) {
        return elem && (this.isLeftBorder(elem) && this.isTopBorder(elem))
    }
    isBottomOrRightBorderElem(elem) {
        return elem && (this.isRightBorder(elem) || this.isBottomBorder(elem))
    }
    isBorderElem(elem) {
        return elem && (this.isTopOrLeftBorderElem(elem) || this.isBottomOrRightBorderElem(elem))
    }
    countNonBorderTextNeighbors(elem) {
        let cnt = 0
        for (let x = -1; x <= 1; x++) {
            for (let y = -1; y <= 1; y++) {
                if (x === 0 && y === 0)
                    continue
                const theNeighbor = this.neighbor(elem, x, y)
                if (!this.isBorderElem(theNeighbor) && this.isTextNeighbor(theNeighbor)) {
                    cnt++;
                }
            }
        }
        console.log(`${JSON.stringify(elem.rect)}: ${cnt}`)
        return cnt
    }

    getAllNeighbors(elem, ignoreBorders = false) {
        const neighbors = []
        for (let x = -1; x <= 1; x++) {
            for (let y = -1; y <= 1; y++) {
                if (x === 0 && y === 0)
                    continue
                const theNeighbor = this.neighbor(elem, x, y)
                if (theNeighbor && neighbors.indexOf(theNeighbor) === -1 && (!ignoreBorders || !this.isBorderElem(theNeighbor)))
                    neighbors.push(theNeighbor)
            }
        }
        return neighbors
    }

    getXEdgeLength(e, pos) {
        let delta = 0
        while (isWord(this.neighbor(e, pos*(delta+1), 0)))
            delta++
        return delta
    }

    getXLength(e) {
        if (!isWord(e))
            return 0
        return this.getXEdgeLength(e, -1) + this.getXEdgeLength(e, 1) + 1
    }

    getYEdgeLength(e, pos) {
        let delta = 0
        while(isWord(this.neighbor(e, 0, pos*(delta+1))))
            delta++
        return delta
    }

    getYLength(e) {
        if (!isWord(e))
            return 0
        return this.getYEdgeLength(e, -1) + this.getYEdgeLength(e, 1) + 1
    }

    getCrossLength(e) {
        return this.getXLength(e) + this.getYLength(e)
    }

    getCrossWeight(e) {
        return (this.getXEdgeLength(e, -1)+1)*(this.getXEdgeLength(e, 1)+1)
            + (this.getYEdgeLength(e, -1)+1)*(this.getYEdgeLength(e, 1)+1)
    }

    getAllIslands(props = {}) {
        const {types = [TEXT, ARROW]} = props
        let allIslands = []
        this.xObj.elements
            .filter(elem=>types.indexOf(elem.type) !== -1)
            .forEach(elem=>{
                if (allIslands.flat().indexOf(elem) === -1) {
                    const island = this.getIsland(elem, props)
                    if (island.length > 0) {
                        allIslands.push(island)
                    }
                }
            })
        return allIslands
    }

    getIsland(elem, props={}) {
        const {
            ignoreBorders = false,
            types = [TEXT, ARROW],
        } = props
        let island = []
        const islandContains = (data)=>island.indexOf(data) === -1
        if (elem && types.indexOf(elem.type) !== -1) {
            let elemsToProcess = [elem]
            while (elemsToProcess.length > 0) {
                const popped = elemsToProcess.pop()
                const uncheckedNeighbors = this.getAllNeighbors(popped, ignoreBorders)
                    .filter(islandContains)
                    .filter(data => types.indexOf(data.type) !== -1)
                elemsToProcess = [...elemsToProcess, ...uncheckedNeighbors]
                island = [...island, ...uncheckedNeighbors]
            }
        }
        return island
    }

    /**
     * Plups with Image/No neighbor to the left/top needs to be treated different
     * compared to right/bottom.
     *  - Only WORD cells can be plupped
     *  - It must not create a hole
     *  - It must not create a too big island
     *  - It must not create a ladder from the fram
     * */
    canPlup(e, props={}) {
        const {allowHidden=true} = props
        const p = this

        if (isWord(e) && isImage(p.neighbor(e, 0, -2))) {
            console.log("Checking cell 2 below image")
        }

        if (!(is(e, WORD) && (!e.val || (e.val === "")) && (!e.clr || e.clr === p.WHITE)))
            return false

        if (p.isBottomRightCorner(e))
            return false
        for (let x = 0; x <= 1; x++)
            for (let y = 0; y <= 1; y++)
                if (p.isBottomRightCorner(p.neighbor(e, x, y)))
                    return false

        // Check for ladders in general, inside the borders
        if (!p.isLeftBorder(e) && !p.isTopBorder(e)) {
            const nonWordNeighbors = p.getAllNeighbors(e, true)
                .filter(notWord)
                if (nonWordNeighbors.length > 1)
                    return false
                if (nonWordNeighbors
                    .map(n => p.getIsland(n))
                    .find(island=>island.length > 1))
                    return false
        }

        if (p.isRightBorder(e)) {
            // Prevent towers to the right
            if (isText(p.neighbor(e, 0, -1))
                || isText(p.neighbor(e, 0, 1)))
                return false

            // Prevent ladders from the right
            if (notWord(p.neighbor(e, -1, -1))
                || notWord(p.neighbor(e, -1, 1))
                || notWord(p.neighbor(e, -1, 0)))
                return false
        } else {
            // Prevent ladders to the right
            if([-1, 0, 1]
                .map(y => p.neighbor(e, 1, y))
                .filter(n => p.isRightBorder(n) && notWord(n))
                .length > 0)
                return false
        }

        if (p.isBottomBorder(e)) {
            // Prevent boats at the bottom
            if (isText(p.neighbor(e, -1))
                || isText(p.neighbor(e, 1)))
                return false

            // Prevent ladders from the bottom
            if (notWord(p.neighbor(e, -1, -1))
                || notWord(p.neighbor(e, 0, -1))
                || notWord(p.neighbor(e, 1, -1)))
                return false
        } else {
            // Prevent ladders to the bottom
            if([-1, 0, 1]
                .map(x => p.neighbor(e, x, 1))
                .filter(n => p.isBottomBorder(n) && notWord(n))
                .length > 0)
                return false
        }

        // Prevent ladders at the top
        const anyBorderAbove = [-1,0,1]
            .filter(x => p.isTopBorder(p.neighbor(e, x, -1)))
            .length > 0
        if (anyBorderAbove) {
            const anyNotWordBelow = [-1,0,1]
                .filter(x => notWord(p.neighbor(e, x, 1)))
                .length > 0
            if (anyNotWordBelow)
                return false
        }

        // Prevent ladders to the left
        const anyBorderToTheRight = [-1,0,1]
            .filter(x => p.isLeftBorder(p.neighbor(e, x, -1)))
            .length > 0
        if (anyBorderToTheRight) {
            const anyNotWordInside = [-1,0,1]
                .filter(y => notWord(p.neighbor(e, 1, y)))
                .length > 0
            if (anyNotWordInside)
                return false
        }

        /*const anyBorderAboveAbove = [-1,0,1]
            .filter(x => isWord(p.neighbor(e, x, -2)) && p.isTopBorder(p.neighbor(e, x, -2)))
            .length > 0
        if (anyBorderAboveAbove) {
            const anyNotWordAbove = [-1,0,1]
                .filter(x => notWord(p.neighbor(e, x, -1)))
                .length > 0
            if (anyNotWordAbove)
                return false
        }*/

        // Prevent holes at the left border
        if (p.isLeftBorder(p.neighbor(e, -1, 0))
            && isWord(p.neighbor(e, -1, 0)))
            return false

        // Prevent holes at the top border
        if (p.isTopBorder(p.neighbor(e, 0, -1))
            && isWord(p.neighbor(e, 0, -1)))
            return false

        if (!allowHidden) {
            // Check to the right
            if (isWord(p.neighbor(e, 1, 0))
                && p.neighbor(e, 2, 0) && notWord(p.neighbor(e, 2, 0)))
                return false

            // Check to the left
            if (isWord(p.neighbor(e, -1, 0))
                && p.neighbor(e, -2, 0) && notWord(p.neighbor(e, -2, 0)))
                return false

            // Check to the top
            if (isWord(p.neighbor(e, 0, -1))
                && p.neighbor(e, 0, -2) && notWord(p.neighbor(e, 0, -2)))
                return false

            // Check to the bottom
            if (isWord(p.neighbor(e, 0, 1))
                && p.neighbor(e, 0, 2) && notWord(p.neighbor(e, 0, 2)))
                return false


        }

        // Make sure that min-distance just inside the (top/left)
        // and the (right/bottom) border is 2
        if (p.isTopBorder(p.neighbor(e, 0, -1))
            && [-2, -1, 1, 2]
                .map(x => notWord(p.neighbor(e, x, 0)))
                .includes(true))
            return false

        if (p.isLeftBorder(p.neighbor(e, -1, 0))
            && [-2, -1, 1, 2]
                .map(y => notWord(p.neighbor(e, 0, y)))
                .includes(true))
            return false
        return true
    }

    getDensity() {
        const p = this
        const textElems = this.xObj.elements.filter(e => isText(e) && !p.isBorderElem(e))
        const wordElems = this.xObj.elements.filter(e => isWord(e))
        return textElems.length / wordElems.length
    }

    /**
     * Fill a board with plups
     *  1. Make sure the left/top borders are filled
     *  2. Make sure the rest of the board is filled
     *  3. Make sure that the fill confirms to the config
     *    a. Number of hiddens
     *    b. Number of islands of various sizes
     *    c. Number of boats
     *    d. Number of ladders of various sizes
     *    e. Density. Ratio WORD/TEXT
     *  4. Handle more edge cases
     *    a. Relate to ARROW
     *    b. Skip filled WORD clr
     *    c. Take number of words into account? (WORD, val)
     *
     * */
    plupBoard(config={}) {
        const {densityLimit = 0.2, print=false, avoidLongCrosses=false, words = []} = config
        const p = this
        let canary = 0

        const wordMatch = (wmStr) => words.findIndex(word => {
            if (!(word.length === wmStr.length))
                return false
            return !!word.match(new RegExp(wmStr))
        })

        //Fill topleft border elements (corners)
        this.getTopLeftCornerElements()
            .filter(e=>isWord(e))
            .forEach(makeElemText)

        // Make sure that cells with letters in are well covered
        const letterCells = p.xObj.elements.filter(e=>isWord(e) && !isEmptyWord(e))
        const wordMarkers = []
        const compareMarkers = (m1, m2) => ru.equals(m1?.[0]?.rect,m2?.[0]?.rect) && ru.equals(m1?.[1]?.rect,m2?.[1]?.rect)
        letterCells
            .forEach(e=>{
                return [HZ, VRT].forEach(dir => {
                    const wm = getWordMarker(p.matrix, {e},dir, null, null, p.xObj)
                    if (wordMarkers.findIndex(wmE=>compareMarkers(wm, wmE))  === -1)
                        wordMarkers.push(wm)
                })
            })
        const markersWithZeroHits = wordMarkers.filter(wm => {
            const wmStr = wm.map(e => e.val || ".").join("")
            if (wmStr.indexOf(".") === -1)
                return false
            const index = wordMatch(wmStr)
            return index === -1
        })
        markersWithZeroHits.forEach(marker => {
            const possibles = marker.map((e, index) => isEmptyWord(e) ? {e, index} : null).filter(n=>n)
            const stillPossibles = possibles.filter(pos => {
                const {index: posIndex} = pos
                if (posIndex === 0) {
                    // No string before
                } else if (posIndex === 1) {
                    // A string before with length 1
                } else if (posIndex === marker.length-1) {
                    // No string after
                } else if (posIndex === marker.length-2) {
                    // A string after with length 1
                } else {
                    // There is a string both before and after with at least length 2
                    const part1 = marker.slice(0, posIndex)
                    const part2 = marker.slice(posIndex+1)
                    const wmStr1 = part1.map(e => e.val || ".").join("")
                    const wmStr2 = part2.map(e => e.val || ".").join("")
                    const matchIndex1 = wordMatch(wmStr1)
                    const matchIndex2 = wordMatch(wmStr2)
                    if (matchIndex1 !== -1 && matchIndex2 !== -1) {
                        console.log(`Uncomplicated close ${words[matchIndex1]} ${words[matchIndex2]}`)
                        return true
                    } else if (matchIndex1 !== -1 && wmStr2.indexOf(".") === -1) {
                        console.log("Complicated end close")
                        return true
                    } else if (matchIndex2 !== -1 && wmStr1.indexOf(".") === -1) {
                        console.log("Complicated start close")
                        return true
                    }
                }
                return false
            })
            const picked = stillPossibles[Math.floor(Math.random()*stillPossibles.length)];
            if (picked) {
                makeElemText(p.e(picked.e.rect.x, picked.e.rect.y))
            }
        })


        // Make sure to cover all arrow elements
        this.xObj.elements.filter(e=> isArrow(e)).forEach(e => {
            const {arrow, rect} = e
            const {x,y, w=1, h=1} = rect
            switch (arrow) {
                case north_right:
                    if (h === 2) {
                        makeElemText(p.e(x+1, y+1))
                    }
                    break
                case west_left:
                    if (w === 2) {
                        if (Math.random() > 0.5) {
                            p.xObj.overlayTexts = p.xObj.overlayTexts || []
                            p.xObj.overlayTexts.push({
                                overlayPosition: UPPER,
                                rect: {...e.rect},
                                text: "",
                                links: [] // Todo: Can this be removed,
                            })
                            p.matrix = createMatrix(p.xObj)
                        } else {
                            makeElemText(p.e(x+1, y+1))
                        }
                    }
                    break
                case south_straight:
                    if (w===1 && h===1) {
                        const xLength = p.getXLength(p.e(x+1, y))
                        if (xLength >= 4) {
                            makeElemText(p.e(x+1, y))
                        } else if (xLength === 3) {
                            makeElemText(p.e(x+2, y))
                        }
                    }
                    break
                case east_straight:
                    if (w===1 && h===1) {
                        // Make sure not to make holes between image and arrow
                        if (isEmptyWord(p.e(x,y-1)) && isImage(p.e(x,y-2))) {
                            makeElemText(p.e(x,y-1))
                        } else if (isEmptyWord(p.e(x,y+1)) && isImage(p.e(x,y+2))) {
                            makeElemText(p.e(x,y+1))
                        }
                    }
                    const yLength = p.getYLength(p.e(x, y+1))
                    const beforeLength = p.getXLength(p.e(x-1, y))
                    const afterLength = p.getXLength(p.e(x+1, y))
                    if (yLength > 2 && beforeLength > 2 && afterLength > 2) {
                        makeElemText(p.e(x,y+1))
                    } else if (yLength === 3) {
                        makeElemText(p.e(x,y+2))
                    }
                    break
                default:
                    console.warn(`arrow ${arrow} is not supported`)
            }
        })

        while (this.getDensity() < densityLimit) {

            let elements = this.getNotOkBorderElements()
            if (elements.length === 0) {
                const notTopOrLeft = this.xObj.elements
                    .filter(e=>!p.isTopOrLeftBorderElem(e))
                elements = notTopOrLeft.filter(e=>p.canPlup(e))
                if (avoidLongCrosses) {
                    //elements = elements.sort((e1, e2) => p.getCrossLength(e1) - p.getCrossLength(e2))
                    elements = elements.sort((e1, e2) => p.getCrossWeight(e1) - p.getCrossWeight(e2))
                    if (elements.length > 10)
                        elements.splice(0, elements.length - 10)
                }
            }
            if (elements.length > 0) {
                const picked = elements[Math.floor(Math.random()*elements.length)];
                makeElemText(picked)
            }

            if (canary++ > 1000) {
                console.error("Breaking up to avoid eternal loop")
                break
            }
            if (print) {
                p.print()
            }
        }
        return this
    }

    getTopLeftCornerElements() {
        const p = this
        return this.xObj.elements
            .filter(e => p.isTopAndLeftBorderElem(e))
    }
    getNotOkBorderElements() {
        const p = this
        return this.xObj.elements.filter(e => {
            if (isWord(e)) {
                if (p.isLeftBorder(e)) {
                    if (p.isLeftBorder(p.neighbor(e, 0, -1))
                        && isWord(p.neighbor(e, 0, -1)))
                        return true
                    if (p.isLeftBorder(p.neighbor(e, 0, 1))
                        && isWord(p.neighbor(e, 0, 1)))
                        return true
                }
                if (p.isTopBorder(e)) {
                    if (p.isTopBorder(p.neighbor(e, -1, 0))
                        && isWord(p.neighbor(e, -1, 0)))
                        return true
                    if (p.isTopBorder(p.neighbor(e, 1, 0))
                        && isWord(p.neighbor(e, 1, 0)))
                        return true
                }
            }
            return false
        });
    }

    static fromString(str) {
        const map = {
            ".": WORD,
            "X": TEXT
        }
        const rows = str.split("\n")
        const w = rows[0].length
        const h = rows.length
        const elements = rows.map((row, y) => {
            return row.split("").map((chr, x) => {
                const elem = {
                    rect: {
                        x: x,
                        y: y,
                    },
                    type: map[chr]
                }
                if (map[chr] === WORD) {
                    elem.val = ""
                }
                return elem
            })
        }).flat()
        return new Plupper({
            elements: elements,
            w: w,
            h: h,
            links: []
        })
    }

    toString() {
        let str = ""
        for (let y = 0; y < this.xObj.h; y++) {
            for (let x = 0; x < this.xObj.w; x++) {
                if (isWord(this.e(x,y))) {
                    str += this.e(x,y).val || "."
                } else if (isImage(this.e(x,y))) {
                    str += "I"
                } else if (is(this.e(x,y), ARROW)) {
                    str += "$"
                } else {
                    str += "X"
                }
            }
            str += "\n"
        }
        return str
    }

    print() {
        const str = this.toString()
        console.log(str)
        console.log(this.getDensity())
        return this
    }

    setArea(rect, type, params) {
        this.throwIfNotEmptyWord(rect)
        const success = setArea(this.xObj, this.matrix, rect, type, params)
        if (!success) {
            throw new Error(`Could not set area ${type}`)
        }
        this.matrix = createMatrix(this.xObj)
    }

    layoutVertically(startPoint, sentence, arrowInSameCell) {
        const [startX, startY] = startPoint
        const p = this
        let theMinus = 0
        const decTheMinus = ()=>{theMinus--}
        sentence.split("").forEach((val, y) => {
            const nextCell = p.e(startX, startY + y + theMinus)
            throwIfTaken(nextCell, val)
            if (val === " ") {
                if (arrowInSameCell) {
                    link(this.xObj, p.neighbor(nextCell, 0, -1).rect, nextCell.rect, VRT, annot_down)
                    decTheMinus()
                } else {
                    p.setArea({x: startX, y: startY + y}, ARROW, {arrow: south_straight})
                }
            } else {
                makeElemWord(nextCell, val, p.xObj.themeColors?.[0] || this.DEFAULT_SENTENCE_COLOR)
            }
        })
        if (sentence.length < p.xObj.h - startY) {
            const blocker = p.e(startX, startY + sentence.length)
            if(isEmptyWord(blocker)) {
                makeElemText(blocker)
            } else if (isWord(blocker)) {
                throw new Error("Couldn't add blocker")
            }
        }
    }

    tryLayoutVertically(sentence, xStart, yStart = 1) {
        const {w, h} = this
        console.log("tryLayoutVertically\n" + this.toString())
        console.log(sentence)
        console.log(xStart)
        if (sentence.replace(" ", "").length < h) {
            this.layoutVertically([xStart, yStart], sentence, h <= sentence.length)
        } else if (sentence.length < (h-yStart) + (w - xStart)) {
            const part1 = sentence.substring(0, h-yStart)
            const part2 = sentence.substring(h-yStart)
            this.layoutVertically([xStart, yStart], part1, false)
            this.layoutHorizontally([xStart +1, h-yStart], part2, false)
        } else if (sentence.replace(" ", "").length <= (h-yStart) + (w - xStart)) {
            const splitIndex = getSplitIndex(sentence, h-yStart)
            const part1 = sentence.substring(0, splitIndex)
            const part2 = sentence.substring(splitIndex)
            console.log(`Split into ${part1} ${part2}`)
            this.layoutVertically([xStart, yStart], part1, true)
            this.layoutHorizontally([xStart+1, h-yStart], part2, true)
        } else {
            throw new Error(`Sentence of length ${sentence.replace(" ", "").length} doesn't fit ${h + xStart}`)
        }
    }

    tryLayoutHorizontally(sentence, yStart, xStart= 1) {
        console.log("tryLayoutHorizontally\n" + this.toString())
        console.log(sentence)
        console.log(yStart)
        const {w, h} = this
        if (sentence.replace(" ", "").length < w) {
            this.layoutHorizontally([xStart, yStart], sentence, w <= sentence.length)
        } else if (sentence.length < (w-xStart) + (h - yStart)) {
            const part1 = sentence.substring(0, w-xStart)
            const part2 = sentence.substring(w-xStart)
            this.layoutHorizontally([xStart, yStart], part1, false)
            this.layoutVertically([w-xStart, yStart+1], part2, false)
        } else if (sentence.replace(" ", "").length <= (w-xStart) + (h - yStart)) {
            const splitIndex = getSplitIndex(sentence, w-xStart)
            const part1 = sentence.substring(0, splitIndex)
            const part2 = sentence.substring(splitIndex)
            console.log(`Split into ${part1} ${part2}`)
            this.layoutHorizontally([xStart, yStart], part1, true)
            this.layoutVertically([w-xStart, yStart+1], part2, true)
        } else {
            throw new Error(`Sentence of length ${sentence.replace(" ", "").length} doesn't fit ${w + yStart}`)
        }
    }

    layoutHorizontally(startPoint, sentence, arrowInSameCell) {
        const [startX, startY] = startPoint
        const p = this
        let theMinus = 0
        const decTheMinus = ()=>{theMinus--}
        sentence.split("").forEach((val, x) => {
            const nextCell = p.e(startX + x + theMinus, startY)
            throwIfTaken(nextCell, val)
            if (val === " ") {
                if (arrowInSameCell) {
                    link(this.xObj, p.neighbor(nextCell, -1, 0).rect, nextCell.rect, VRT, annot_right)
                    decTheMinus()
                } else {
                    p.setArea({x: startX + x, y: startY}, ARROW, {arrow: east_straight})
                }
            } else {
                makeElemWord(nextCell, val, p.xObj.themeColors?.[0] || this.DEFAULT_SENTENCE_COLOR)
            }
            p.print()
        })
        if (sentence.length < p.xObj.w - startX) {
            const blocker = p.e(startX + sentence.length, startY)
            if (blocker) {
                if(isEmptyWord(blocker)) {
                    makeElemText(blocker)
                } else if(isWord(blocker)) {
                    throw new Error("Couldn't add blocker")
                }
            }
        }
    }

    addImageWithSentences(entry) {
        console.log("addImageWithSentences\n" + this.toString())
        console.log(entry)
        const p = this
        const xw = p.xObj.w
        const xh = p.xObj.h
        const {sentenceInfos,anchor, sz = [Math.floor(xw / 3), Math.floor(xw / 3)]} = entry
        const [iw, ih] = sz
        const [ix, iy] = imageXY(anchor, iw, ih, p.xObj)
        p.setArea({x: ix, y: iy, w: iw, h: ih}, IMAGE)
        p.print()
        sentenceInfos.forEach(sentenceInfo => {
            const {sentence, direction} = sentenceInfo
            switch (anchor) {
                case TOP_LEFT:
                    switch (direction) {
                        case DIRECTION_HZ:
                            p.setArea({x: 0, y: ih, w: 1, h: 2}, ARROW, {arrow: south_left})
                            this.tryLayoutHorizontally(sentence, ih+1)
                            break
                        case DIRECTION_VRT:
                            p.setArea({x: iw, y: iy, w: 2, h: 1}, ARROW, {arrow: east_right})
                            this.tryLayoutVertically(sentence, iw+1)
                            //this.layoutVertically([iw+1, 1], sentence, xh <= sentence.length)
                            break
                        default:
                            throw new Error(`Direction ${direction} not supported for ${anchor}`)
                    }
                    break
                case TOP_RIGHT:
                    switch (direction) {
                        case DIRECTION_VRT:
                            p.setArea({x: ix-2, y: iy, w: 2, h: 1}, ARROW, {arrow: west_left})
                            this.tryLayoutVertically(sentence, ix-2)
                            break
                        case DIRECTION_HZ:
                            p.setArea({x: ix, y: iy+ih, w: 1, h: 2}, ARROW, {arrow: south_left})
                            this.tryLayoutHorizontally(sentence, iy + ih+1, ix+1)
                            break
                        default:
                            throw new Error(`Direction ${direction} not supported for ${anchor}`)
                    }
                    break
                case BOTTOM_LEFT:
                    switch (direction) {
                        case DIRECTION_HZ:
                            p.setArea({x: 0, y: xh - ih - 2, w: 1, h: 2}, ARROW, {arrow: north_right})
                            this.tryLayoutHorizontally(sentence, xh - ih - 2)
                            break
                        case DIRECTION_VRT:
                            p.setArea({x: ix+iw, y: xh - ih, w: 2, h: 1}, ARROW, {arrow: east_right})
                            this.tryLayoutVertically(sentence, ix+iw+1, xh - ih+1)
                            break
                        default:
                            throw new Error(`Direction ${direction} not supported for ${anchor}`)
                    }
                    break
                default:
                    throw new Error(`Anchor ${anchor} not supported`)
            }
        })

        p.print()
    }

    addImagesWithSentences(entries) {
        const p = this
        const xw = this.xObj.w
        const xh = this.xObj.h
        const w = Math.floor(xw/3)
        const h = Math.floor(xh/3)

        if (entries.length === 1) {
            const {sentences} = entries[0]
            if (sentences.length === 1) { // There is only one sentence.
                if (xh >= xw) { // Portrait or square
                    const sentence = sentences[0]
                    if (xw > sentence.length) {
                        this.addImageWithSentences({sentenceInfos: [{sentence, direction: DIRECTION_HZ}], sz: [w, h], anchor: TOP_LEFT})
                    } else if (xh >= sentence.replace(" ", "").length) {
                        this.addImageWithSentences({sentenceInfos: [{sentence, direction: DIRECTION_VRT}], sz: [w, h], anchor: TOP_RIGHT})
                    } else if (xh + Math.floor(xw/2) > sentence.length) {
                        this.addImageWithSentences({sentenceInfos: [{sentence, direction: DIRECTION_VRT}], sz: [w, h], anchor: TOP_RIGHT})
                    } else {
                        throw new Error(`Sentence length ${sentence.length} doesn't fit w:${xw} h: ${xh}`)
                    }
                } else { // Landscape

                }
            }
        }
        else if (entries.length === 2) {
            if (entries[0].sentences.length === 1 && entries[1].sentences.length === 1) {
                const sentence0 = entries[0].sentences[0]
                const sentence1 = entries[1].sentences[0]
                const longest =  sentence0.length >= sentence1.length ? sentence0 : sentence1
                const shortest = sentence0.length >= sentence1.length ? sentence1 : sentence0
                if (shortest.length < xw && longest.length < xh) {
                    if (shortest.length < w) {
                        const [ix, iy] = imageXY(TOP_RIGHT, w, h, p.xObj)
                        p.setArea({x: ix, y: iy, w: w, h: h}, IMAGE)
                        p.setArea({x: ix-2, y: iy, w: 2, h: 1}, ARROW, {arrow: west_left})
                        const [ix2, iy2] = imageXY(BOTTOM_LEFT, w, h, p.xObj)
                        p.setArea({x: ix2, y: iy2, w: w, h: h}, IMAGE)
                        p.setArea({x: ix2, y: iy2-2, w: 1, h: 2}, ARROW, {arrow: south_left})
                        this.layoutVertically([ix-2, iy+1], longest, false)
                        this.layoutHorizontally([ix2+1, iy2-2], shortest, false)
                    } else {
                        let intersections = []
                        longest.split("").forEach((chr, li) => {
                            shortest.split("").forEach((chr2, si) => {
                                if (chr === chr2) {
                                    intersections.push([si, li])
                                }
                            })
                        })
                        console.log(intersections)
                        console.log(`Searching for ${xw - (xw-shortest.length)-w} ${xh - (xh-longest.length)-h}`)
                        const intersection = intersections.find(([ptx, pty]) => ptx === w && pty === xh - (xh-longest.length)-h)
                            || intersections.filter(([ptx, pty]) => ptx > 2 && pty > 2)[0]
                        if (intersection) {
                            console.log(`intersecting in ${intersection}`)
                            const [si, li] = intersection
                            this.layoutVertically([si+1, 1], longest, false)
                            p.setArea({x: si+3, y: 0, w: xw - (si+3), h: h}, IMAGE)
                            p.setArea({x: si+1, y: 0, w: 2, h: 1}, ARROW, {arrow: west_left})

                            const image2h = xh - (li+3)
                            if (image2h < 3)
                                throw new Error("Intersection causes to low image")
                            const image2w = Math.min(w, si)
                            this.layoutHorizontally([1, li+1], shortest, false)
                            p.setArea({x: 0, y: li+3, w: image2w, h: image2h}, IMAGE)
                            p.setArea({x: 0, y: li+1, w: 1, h: 2}, ARROW, {arrow: north_right})
                        }
                    }
                }
            }
        }
        this.matrix = createMatrix(this.xObj)
        return this
    }

    addImages(sentences) {
        const p = this
        const anchors = [TOP_RIGHT, BOTTOM_LEFT, TOP_LEFT, TOP_CENTER, MIDDLE_LEFT, BOTTOM_RIGHT, MIDDLE_RIGHT, BOTTOM_CENTER, MIDDLE_CENTER]
        const w = Math.floor(this.xObj.w/3)
        const h = Math.floor(this.xObj.h/3)

        sentences.forEach((sentence, i) => {
            const anchor = anchors[i]
            const imageAnchors = anchor.split(" ")
            const x = imageX(imageAnchors[1], w, p.xObj.w)
            const y = imageY(imageAnchors[0], h, p.xObj.h)
            p.setArea({x: x, y: y, w: w, h: h}, IMAGE)

            switch (anchor) {
                case "top right":
                    p.setArea({x: x-2, y: 0, w: 2, h: 1}, ARROW, {arrow: west_left})
                    sentence.split("").forEach((val, y) => {
                        if (val === " ") {
                            p.setArea({x: x-2, y: 0, w: 2, h: 1}, ARROW, {arrow: south_straight})
                        }
                        makeElemWord(p.e(x-2, 1+y), val, p.xObj.themeColors?.[0] || this.DEFAULT_SENTENCE_COLOR)
                        if (y === sentence.length -1) {
                            makeElemText(p.e(x-2, 1+y+1))
                        }
                    })
                    break
                default:
                    console.error(`anchor ${anchor} is not supported`)
            }
        })
        this.matrix = createMatrix(this.xObj)
    }

    /**
     * 1. Links textRanges that are not linked and are adjacent to WORD with length 1
     * 2. Merges unlinked textRange with right textRange neighbor.
     * */
    handleUnLinkedTextRange (tr) {
        const p = this
        let newX = null
        if (tr.type === TEXT) {
            if (!tr.parts) {
                const {w=1, h=1} = tr.rect
                if (w === 1 && h === 1) {
                    if (isWord(p.neighbor(tr, 1, 0))) {
                        const wr = p.neighbor(tr, 1, 0)
                        const wrLinks = p.getWrLinks(wr)
                        if (!wrLinks || wrLinks.filter(l => l.direction === HZ).length === 0) {
                            newX = autoLinkWordRangeToTextRange(p.xObj, wr, tr, undefined, HZ)
                        }
                    }
                    if (!newX && isWord(p.neighbor(tr, 0, 1))) {
                        const wr = p.neighbor(tr, 0, 1)
                        const wrLinks = p.getWrLinks(wr)
                        if (!wrLinks || wrLinks.filter(l => l.direction === VRT).length === 0) {
                            newX = autoLinkWordRangeToTextRange(p.xObj, wr, tr, undefined, VRT)
                        }
                    }
                    if (!newX && isText(p.neighbor(tr, 1, 0))) {
                        const rightTr = p.neighbor(tr, 1, 0)
                        const {w: rightW = 1, h: rightH = 1} = rightTr.rect
                        if (rightH === 1 && rightW === 1) {
                            const newRect = {...tr.rect, w:2, h: 1}
                            setArea(p.xObj, p.matrix, newRect, TEXT)
                            p.matrix = createMatrix(p.xObj) // to keep xObj and matrix in sync
                            if (isWord(p.neighbor(tr, 1, 0))) {
                                divideArea(p.xObj, p.matrix, newRect)
                            }
                            newX = p.getX()
                        }
                    }
                }
            }
        }
        if (newX) {
            p.xObj = newX
            p.matrix = createMatrix(p.xObj)
        }
    }

    getUnlinkedTextRanges() {
        const getWrDir = wr => wr[0].rect.x === wr[1].rect.x ? VRT : HZ
        const links = JSON.parse(JSON.stringify(this.xObj.links))
        const duplicateLinks = links.filter((link, linkIndex ) => links.findIndex(l => ru.equals(link.wordRange, l.wordRange) && link.direction === l.direction) !== linkIndex)
        const badLinks = []
        const textRanges = JSON.parse(JSON.stringify(this.xObj.elements.filter(e => e.type === TEXT)))
        const wordRanges = JSON.parse(JSON.stringify(getAllWordMarkers(this.matrix, this.xObj).filter(wm => wm.length > 1)))
        links.forEach(link => {
            const index = textRanges.findIndex(e => ru.equals(link.source, e.rect))
            if (index !== -1) {
                const e = textRanges[index]
                if (link.sourceType) {
                    if (link.sourceType === UPPER) {
                        e.parts[0].linked = true
                    } else if (link.sourceType === LOWER) {
                        e.parts[1].linked = true
                    }
                    if (e.parts[0].linked && e.parts[1].linked) {
                        textRanges.splice(index, 1)
                    }
                } else {
                    textRanges.splice(index, 1)
                }
            } else {
                badLinks.push(link)
            }

            const wordRangeIndex = wordRanges.findIndex(wr => {
                if (ru.equals(link.wordRange, wr[0].rect)) {
                    if (link.direction === VRT) {
                        if (wr[0].rect.x === wr[1].rect.x)
                            return true
                    } else if (link.direction === HZ) {
                        if (wr[0].rect.y === wr[1].rect.y)
                            return true
                    } else {
                        throw new Error(`Strange direction: ${link.direction}`)
                    }
                }
                return false
            })
            if (wordRangeIndex !== -1) {
                wordRanges.splice(wordRangeIndex, 1)
            } else {
                console.log(`Couldn't find wordRangeIndex for ${JSON.stringify(link.wordRange)}`)
            }

        })

        this.xObj.overlayTexts.forEach(ot => {
            ot.links?.forEach(link => {
                const wrIndex = wordRanges.findIndex(wr => ru.equals(link.wordRange, wr.rect) && getWrDir(wr) === link.direction)
                wordRanges.splice(wrIndex, 1)
            })
        })

        return {textRanges, badLinks, duplicateLinks, wordRanges: [...wordRanges.filter(wr => wr.filter(w => w.clr).length !== wr.length)]}
    }
}
