import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import './util.css'

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function InputSlider(props) {

    const {min, max, step, def, label, icon, onChange} = props

    const getMin = () => min || 5
    const getMax = _ => max || 40
    const getStep = _ => step || 1
    const getDefault = _ => def || 10

    const [value, setValue] = React.useState(getDefault());

    const handleSliderChange = (event, newValue) => {
        setValue(newValue)
        onChange?.(newValue)
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value === '' ? '' : Number(event.target.value)
        setValue(newValue)
        onChange?.(newValue)
    };

    const handleBlur = () => {
        if (value < getMin()) {
            setValue(getMin());
        } else if (value > getMax()) {
            setValue(getMax());
        }
    };

    return (
        <Box sx={{width: 250}}>
            <Typography id="input-slider" gutterBottom>
                {label || "No label"}
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    {icon}
                </Grid>
                <Grid item xs>
                    <Slider
                        value={typeof value === 'number' ? value : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={getMin()}
                        max={getMax()}
                        step={getStep()}
                    />
                </Grid>
                <Grid item>
                    <Input
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: getStep(),
                            min: getMin(),
                            max: getMax(),
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
