import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from "react";
import {getUserLists, removeUserList} from "../backend";
import {Button} from "@mui/material";
import {removeUserWordList} from "../features/wordlists/userWordlists";
import {useDispatch} from "react-redux";

export default function ListRemover({uId}) {

    const dispatch = useDispatch()
    const [userLists, setUserLists] = React.useState([])

    useEffect(() => {
        const fetchUserLists = async () => {
            const userLists = await getUserLists(uId)
            setUserLists(userLists.map(info => info.name.split(".")[0]))
        }

        fetchUserLists()
    }, [uId])

    const [listName, setListName] = React.useState('');

    const handleChange = (event) => {
        setListName(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Lists</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleChange}
                    value={listName}
                >
                    {userLists.map(list => (<MenuItem value={list}>{list}</MenuItem>))}
                </Select>
            </FormControl>
            {listName &&
                <Button onClick={async ()=>{
                    dispatch(removeUserWordList({listName: listName}))
                    const result = await removeUserList({listName: listName})
                    console.log(result)
                    setUserLists([])
                    setListName()
                    setTimeout(()=>{
                        getUserLists(uId).then((ul)=>{
                            setUserLists(ul.map(info => info.name.split(".")[0]))
                        })
                    }, 10)
                }
            }>Delete {listName}</Button>}

        </Box>
    );
}
