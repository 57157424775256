import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import ListIcon from '@mui/icons-material/List';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {userLoggedIn, userLoggedOut} from "../features/users/userSlice";
import {fetchXlist, fetchXOrders, xListTimestampInvalidate} from "../features/xlist/xlistSlice"
import { fetchLists } from "../features/wordlists/wordlists"
import {useSearchParams} from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer, GridToolbarFilterButton
} from '@mui/x-data-grid';
import {Button, Chip, Divider, Tooltip} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import NewCrosswordModal from "../ui/NewCrosswordModal";
import {NewButton} from "../ui/gridtoolbar/NewButton";
import {DeleteButton} from "../ui/gridtoolbar/DeleteButton";
import {createStream, createX, deleteStream, getUserRights, listStreamsForUser, setUserRightsName} from "../backend";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {Cake, Edit, Stream} from "@mui/icons-material";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  createSingleEntriesStreamTemplate,
  createThreeInTopStreamTemplate,
  SINGLE_ENTRIES,
  THREE_IN_TOP
} from "./streams/streamTemplates";
import CreateStreamModal from "../ui/CreateStreamModal";
import AlertDialog from "../ui/alertdialog";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListEditor from "../ui/ListEditor"
import ListRemover from "../ui/ListRemover";
import ListAdder from "../ui/ListAdder";
import {fetchUserLists} from "../features/wordlists/userWordlists";
import FontAdder from "../ui/FontAdder";
import FontRemover from "../ui/FontRemover";
import Fab5Button from "./Fab5Button";

const theme = createTheme();

export default function Dashboard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const searchParams = useSearchParams()[0];

  //NewCrosswordModal state
  const [open, setOpen] = React.useState(false)

  const xListSlice=useSelector((state) => state.persistedReducer.xlist)
  const xlist = xListSlice?.entities
  const xOrders = xListSlice?.xOrders


  const user = useSelector((state) => state.user)
  const fab5 = useSelector((state) => state.persistedReducer.fab5);
  const [uId, setUid] = useState()
  const [streams, setStreams] = useState()
  const [expanded, setExpanded] = useState()
  const [deleted, setDeleted] = useState([])
  const [showTextInputModal, setShowTextInputModal] = useState(false)

  // For the user crosswords datagrid
  const [userXselectionModel, setUserXselectionModel] = React.useState([]);

  // For the xOrders datagrid
  const [orderXselectionModel, setOrderXselectionModel] = React.useState([])

  const [editList, setEditList] = React.useState()

  const drawerWidth = 240;

  const xColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      flex: 1,
    },
    {
      field: 'level',
      headerName: 'Level',
      width: 20
    },
  ];

  const xRows = xlist.filter(entity=>deleted.indexOf(entity.xId) === -1)?.map(entity => ({
    name: entity.name,
    level: entity.level,
    id: entity.xId
  }))

  const xOrderColumns = [{
    field: 'name',
    headerName: 'Name',
    width: 150,
    flex: 1,
  }]

  const xOrderRows = xOrders?.filter(entity=>deleted.indexOf(entity.xId) === -1)?.map(entity => ({
    name: entity.xName,
    id: entity.xId
  }))

  useEffect(() => {
    const checkLoggedIn = async () => {
      const user = await Auth.currentAuthenticatedUser()
      dispatch(userLoggedIn(user))
      const credentials = await Auth.currentCredentials()
      if (credentials.identityId) {
        const {identityId} = credentials
        const assume = searchParams.get("uId")
        const userRights = await getUserRights(identityId)
        if (userRights) {
          if (!!assume && identityId !== assume && userRights.xRole === "admin") {
            setUid(assume)
            return `Assuming user ${assume}`
          }
        } else {
          setUserRightsName(identityId, user.username)
              .then(console.log)
              .catch(console.error)
        }
        setUid(credentials.identityId)
        return `Logged in as user ${credentials.identityId}`
      }
    }
    checkLoggedIn()
        .then(console.log)
        .catch(err=>{
          console.error(err)
          navigate("/")
        })
  }, [dispatch, navigate, searchParams]);

  useEffect(()=>{
    if (uId) {
      listStreamsForUser(uId).then(setStreams)
      dispatch(fetchLists(uId))
      dispatch(fetchUserLists(uId))
      dispatch(fetchXlist(uId))
      dispatch(fetchXOrders(uId))
    }
  }, [dispatch, uId])

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const signOut = () => {
    Auth.signOut().then(() => {
      dispatch(userLoggedOut())
      navigate("/")
    })
  }

  const xOrdersToolbar = () => {
    return (
        <GridToolbarContainer>
          <NewButton name="New" onClick={_=>{setOpen(true)}} disabled={orderXselectionModel.length > 0}/>,
          <DeleteButton name="Delete" selectionModel={orderXselectionModel} uId={uId} onDelete={newDeleted=>{
            setDeleted([...newDeleted])
          }}/>
          <GridToolbarFilterButton />
          <Button onClick={()=>{dispatch(fetchXOrders(uId))}}>Refresh</Button>
          {orderXselectionModel?.length === 1 && <Button onClick={()=>{
            navigate(`/corrsolve/${orderXselectionModel[0]}`)
          }}>Preview</Button>}
        </GridToolbarContainer>
    )
  }
  const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
          {/*<GridToolbarColumnsButton />

          <GridToolbarDensitySelector />
          <GridToolbarExport />*/}
          <NewButton name="New" onClick={_=>{setOpen(true)}} disabled={userXselectionModel.length > 0}/>,
          <DeleteButton name="Delete" selectionModel={userXselectionModel} uId={uId} onDelete={newDeleted=>{
            setDeleted([...newDeleted])
          }}/>
          <GridToolbarFilterButton />
          <Button onClick={()=>{dispatch(fetchXlist(uId))}}>Reload</Button>
          {userXselectionModel?.length === 1 && <Button onClick={()=>{
            navigate(`/corrsolve/${userXselectionModel[0]}`)
          }}>Preview</Button>}
        </GridToolbarContainer>
    )
  }

  const onCellClick = (params, event) => {
    if (params.field === "name") {
      Auth.currentCredentials().then(credentials => {
        let win = window.open(`/editor?xId=${params.id}&uId=${searchParams.get("uId") || credentials.identityId}${searchParams.get("uId") ? "&noAutoSave=true" : ""}`, event.shiftKey ? '_blank' : '_self')
        win.focus()
      })
    }
  }

  const handleCreateNew = async x => {
    await createX(x.xId, uId, x)
    dispatch(xListTimestampInvalidate())
    let win = window.open(`/editor?xId=${x.xId}&uId=${uId}`, '_self')
    win.focus()
  }

  const renderDataGrid = (rows, columns, toolbar, updateSelectionModel) => {
    return <DataGrid
        autoHeight
        rows={rows || []}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        onCellClick={onCellClick}
        localeText={{
          toolbarColumns: "my columns",
          toolbarFilters: "Filter",
          toolbarDensity: "my density",
          toolbarExport: "my export"
        }}
        components={{
          Toolbar: toolbar,
        }}
        onRowSelectionModelChange={(rowSelectionModel) => updateSelectionModel(rowSelectionModel)}
    />
  }
  return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex'}}>
          <CssBaseline />
          <AppBar
              position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
          >
            <Toolbar>
              <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Kruxet
              </Typography>
              {uId && searchParams.get("uId") === uId && <Typography>Assuming {uId}</Typography>}
              {user.username && <Button color="inherit" onClick={signOut}>{user.username} - Log out</Button>}

            </Toolbar>
          </AppBar>
          <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
              }}
          >
            <Toolbar/>
            <Box sx={{overflow: 'auto'}}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={()=>{setEditList()}}>
                    <ListItemText primary={"Crosswords"}/>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={()=>{
                    window.open(`/keys?uId=${uId}`, '_self')
                  }}>
                    <ListItemText primary={"Keys"}/>
                  </ListItemButton>
                </ListItem>
                <Accordion expanded={expanded === 'streamsPanel'} onChange={handleAccordionChange('streamsPanel')}>
                  <AccordionSummary expandIcon={<Stream/>}>
                    <Typography>Streams</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CreateStreamModal
                        open={showTextInputModal}
                        handleClose={()=>{
                          setShowTextInputModal(false)
                        }}
                        onPicked={async (streamName, streamType)=>{
                          let template;
                          switch(streamType) {
                            case THREE_IN_TOP:
                              template = createThreeInTopStreamTemplate(uId)
                              break;
                            case SINGLE_ENTRIES:
                              template = createSingleEntriesStreamTemplate(uId)
                              break;
                            default:
                              throw new Error(`Stream type ${streamType} not supported`)
                          }
                          await createStream(uId, streamName, template)
                          setTimeout(()=>{
                            listStreamsForUser(uId).then(setStreams)
                          }, 1000)
                        }}
                    ></CreateStreamModal>
                    <Button onClick={async ()=>{
                      setShowTextInputModal(true)
                    }}>New stream</Button>
                    {streams?.map(streamName =>(<Box key={`box_${streamName}`}>
                      <h4>{streamName}</h4>
                      <Tooltip title="Open"><IconButton color="primary" onClick={()=>navigate(`/stream?streamId=${streamName}`)}><Cake/></IconButton></Tooltip>
                      <Tooltip title="Edit"><IconButton color="secondary" onClick={()=>navigate(`/streams/${streamName}`)}><Edit/></IconButton></Tooltip>
                      <AlertDialog streamName={streamName} handleAcceept={async ()=>{
                        await deleteStream(uId, streamName)
                        setTimeout(()=>{listStreamsForUser(uId).then(setStreams)}, 1000)
                      }
                      }></AlertDialog>
                    </Box>))}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'listsPanel'} onChange={handleAccordionChange('listsPanel')}>
                  <AccordionSummary expandIcon={<ListIcon/>}>
                    <Typography>Lists</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setEditList("edit")}}>
                          <ListItemText primary={"Edit lists"}/>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setEditList("add")}}>
                          <ListItemText primary={"Add lists"}/>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setEditList("remove")}}>
                          <ListItemText primary={"Remove lists"}/>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'fontsPanel'} onChange={handleAccordionChange('fontsPanel')}>
                  <AccordionSummary expandIcon={<FontDownloadIcon/>}>
                    <Typography>Fonts</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setEditList("addFonts")}}>
                          <ListItemText primary={"Add fonts"}/>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setEditList("removeFonts")}}>
                          <ListItemText primary={"Remove fonts"}/>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
              <Divider/>
            </Box>
          </Drawer>
          <NewCrosswordModal setOpen={setOpen} open={open} accept={handleCreateNew}></NewCrosswordModal>
          <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 8 }}>
            {fab5.fifo.map(fab => (<Fab5Button xId={fab.xId} name={fab.name} searchParams={searchParams}/>))}
            {editList === "edit" && <ListEditor uId={uId}/>}
            {editList === "add" && <ListAdder uId={uId}/>}
            {editList === "remove" && <ListRemover uId={uId}/>}
            {editList === "addFonts" && <FontAdder uId={uId}/>}
            {editList === "removeFonts" && <FontRemover uId={uId}/>}
            {!editList && <>
              <Divider sx={{ m: 2 }}>
                <Chip label="Your puzzles" />
              </Divider>
              <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <Box maxWidth="md" sx={{flexGrow: 1}} >
                  {renderDataGrid(xRows, xColumns, CustomToolbar, setUserXselectionModel)}
                </Box>
              </Box>
              {xOrderRows && xOrderRows?.length > 0 && <>
                <Divider sx={{ m: 2 }}>
                  <Chip label="Your orders" />
                </Divider>
                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                  <Box maxWidth="md" sx={{flexGrow: 1}} >
                    {renderDataGrid(xOrderRows, xOrderColumns, xOrdersToolbar, setOrderXselectionModel)}
                  </Box>
                </Box>
              </>}
            </>}
          </Box>
        </Box>
      </ThemeProvider>)
}
