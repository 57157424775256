const convert = require('color-convert');

export const findFonts = (xObj) => {
    return [...new Set([
        xObj.paragraphStyle?.text?.font,
        ...xObj.elements.filter(e=>e.font).map(e=>e.font),
        ...xObj.elements.filter(e=>e.parts).flatMap(e=>e.parts).filter(e=>e.font).map(e=>e.font),
        ...(xObj.overlayTexts?.filter(e=>e.font).map(e=>e.font) || [])])
    ]
}

export const findImages = (xObj) => {
    return [...new Set([
        ...xObj.elements.filter(e=>e.image).map(e=>e.image)]),
        ...(xObj.overlayImages?.filter(e=>e.image).map(e=>e.image) || []),
        ...(xObj.bgImages?.filter(e=>e.image).map(e=>e.image) || [])
    ]
}

export const findColors = xObj => {
    const colorMap = {}
    const {arrCmyk, frameCmyk} = xObj
    if (arrCmyk) {
        const arrClr = `#${convert.cmyk.hex(...arrCmyk)}`
        colorMap[arrClr] = arrCmyk
    }
    if (frameCmyk) {
        const frameClr = `#${convert.cmyk.hex(...frameCmyk)}`
        colorMap[frameClr] = frameCmyk
    }
    console.log(colorMap)
    return colorMap
}
