import square from "../../crossword/square";
import {HZ, LOWER, ot2Prop, UPPER, VRT} from "../../cellTypes";
import {createMatrix, getWordMarker, ru} from "../../xutils";
import {paragraphStyle} from "../../crossword/defaultParagraphStyle";

export const startState = {
    selections: [],
    cSz: 28.34645669,
    direction: HZ,
    selectedWord: "",
    errors: null
}

export function editorReducer(state, action) {
    function getFocusArea(s) {
        const {endObj, clickedObj} = s
        if (!endObj || endObj === clickedObj)
            return

        const {e} = clickedObj
        const { e: e2} = endObj

        const minX = Math.min(e.rect.x, e2.rect.x)
        const minY = Math.min(e.rect.y, e2.rect.y)

        const maxX = Math.max(e.rect.x + (e.rect.w || 1), e2.rect.x + (e2.rect.w || 1))
        const maxY = Math.max(e.rect.y + (e.rect.h || 1), e2.rect.y + (e2.rect.h || 1))

        return {x: minX, y: minY, w: maxX-minX, h: maxY-minY}
    }
    function getFocusRect(s) {
        return s && square({rect: s}, state.cSz)
    }
    function getSelectedWord(selections, direction) {
        const letters = []
        let word
        let endE = selections?.[0]?.endObj?.e
        let start = selections?.[selections.length - 1]?.clickedObj
        let searchDir = direction
        if (endE) {
            if (endE.rect.x === start.e.rect.x) {
                searchDir = VRT
                if (endE.rect.y < start.e.rect.y) {
                    endE = start.e
                    start = selections?.[0]?.endObj
                }
            } else if (endE.rect.y === start.e.rect.y) {
                searchDir = HZ
                if (endE.rect.x < start.e.rect.x) {
                    endE = start.e
                    start = selections?.[0]?.endObj
                }
            }
        }
        const theWordMarker = selections.length === 1 && getWordMarker(state.matrix, start, searchDir, endE !== undefined, endE, state.x)
        if (theWordMarker) {
            forEachPos(({row, col}) => {
                letters.push(state.matrix[row][col].e.val || ".")
            }, theWordMarker)
            word = letters.join("")
        }

        return word
    }
    function getElem(selections) {
        const info = selections?.[selections.length - 1]?.clickedObj
        if (info?.e?.parts) {
            return info.e.parts[info.part || 0]
        }
        return info?.e
    }
    function getFocusedElement (selections, matrix) {
        const el = selections[0]?.clickedObj.e
        if (!el)
            return
        if (el.overlayPosition) {
            return matrix[el.rect.y][el.rect.x][ot2Prop(el.overlayPosition)]
        }
        return matrix[el.rect.y][el.rect.x].e
    }
    function getCurrents(matrix, wordMarker, selections) {
        let currentWord = ""
        let currentKey = ""
        let currentKeyElement
        let errors = null

        const theStartObj = selections?.[selections.length - 1]?.clickedObj
        if (wordMarker) {
            // Find the key that the word might be linked to
            if (wordMarker[0]?.rect) {
                const filteredLinks = state.x?.links?.filter(link => ru.equals(wordMarker[0].rect, link.wordRange) && link.direction === state.direction)
                if (filteredLinks?.length === 1) {
                    const coordinates = filteredLinks[0].source
                    const textRange = matrix[coordinates.y][coordinates.x].e
                    currentKey=textRange.text
                    currentKeyElement=textRange
                }
            }
        }

        // If the current element is TEXT, find the word that it is linked to
        const focusedElement = getFocusedElement(selections, matrix)
        if (focusedElement?.overlayPosition) {
            if(focusedElement.links.length > 0) {
                const coordinates = focusedElement.links[0].wordRange
                const wordRange = matrix[coordinates.y][coordinates.x].e
                const wordMarker2 = getWordMarker(matrix, {e: wordRange}, focusedElement.links[0].direction, true, null, state.x)
                if (wordMarker2) {
                    forEachPos(({row, col}) => {
                        currentWord+=matrix[row][col].e.val || "."
                    }, wordMarker2)
                }
            }
            currentKey = focusedElement.text || ""
            currentKeyElement = focusedElement
        }
        else if (selections.length === 1 && theStartObj?.e.type === "TEXT") {
            currentKey = getElem(selections).text
            const clicked = theStartObj
            currentKeyElement=clicked?.e
            const theElement = currentKeyElement
            const filteredLinks = state.x?.links?.filter(link => {
                if (!ru.equals(theElement.rect, link.source))
                    return false
                if (clicked.part !== undefined) {
                    if (clicked.part === 0) {
                        return link.sourceType === UPPER || link.part === clicked.part
                    } else if (clicked.part === 1) {
                        return link.sourceType === LOWER || link.part === clicked.part
                    }
                }
                return !theElement.parts
            })

            if (filteredLinks?.length === 1) {
                const coordinates = filteredLinks[0].wordRange
                const wordRange = matrix[coordinates.y][coordinates.x].e
                const wordMarker2 = getWordMarker(matrix, {e: wordRange}, filteredLinks[0].direction, true, null, state.x)
                if (wordMarker2) {
                    forEachPos(({row, col}) => {
                        currentWord+=matrix[row][col].e.val || "."
                    }, wordMarker2)
                }
            } else if (filteredLinks?.length > 0 ) {
                errors = {
                    filteredLinks: {
                        message: "Too many links",
                        data: filteredLinks
                    }}
            }
            else if (filteredLinks?.length === 0 ) {
                errors = {filteredLinks: {
                        message: "No link!",
                        data: filteredLinks
                    }}
            }
        }
        return {currentWord, currentKey, currentKeyElement, focusedElement, errors}
    }

    function handle() {
        let currents = {}
        switch (action.type) {
            case 'setX':
                return {...startState, x: action.x, matrix: createMatrix(action.x), cSz: (action.x?.paragraphStyle || paragraphStyle)?.squareSize || 28.34645669}
            case 'xUpdated':
                return {...state, x: action.x, matrix: createMatrix(action.x), cSz: (action.x?.paragraphStyle || paragraphStyle)?.squareSize || 28.34645669}
            case 'setSelections':
                const {selections} = action
                const focusAreas = selections.map(getFocusArea)
                const focusRects = focusAreas.map(getFocusRect)
                currents = getCurrents(state.matrix, state.wordMarker, selections)
                return {...state,
                    ...currents,
                    selections,
                    focusRects,
                    focusAreas,
                    selectedWord: getSelectedWord(selections, state.direction),
                    wordMarker: selections.length === 1
                        && getWordMarker(state.matrix, selections?.[selections.length - 1]?.clickedObj, state.direction, null, null, state.x)
                }
            case 'setDirection':
                const {direction} = action
                currents = getCurrents(state.matrix, state.wordMarker, state.selections)
                return {...state,
                    ...currents,
                    direction,
                    selectedWord: getSelectedWord(state.selections, direction),
                    wordMarker: state.selections.length === 1
                        && getWordMarker(state.matrix, state.selections?.[state.selections.length - 1]?.clickedObj, direction, null, null, state.x),
                }
            default:
                return {...state}
        }
    }

    return handle()
}

export const forEachPos = (func, vdm) => {
    vdm?.forEach((elem, index) => func({row: elem.rect.y, col: elem.rect.x}, index))
}
