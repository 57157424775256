
export function createEntries(entries, directions, sizes, anchorsCombinations) {
    console.log("createEntries")
    // 1. Transform entries to hold sentenceInfos instead
    const entriesWithSentenceInfos = entries.map(entry =>
        ({id: entry.id, sentenceInfos: entry.sentences.map(sentence => ({sentence}))}))

    // 2. Add directions
    const withDirections = entriesWithSentenceInfos
        .map(entry => combine(directions, entry.sentenceInfos)
            .map(combination => ({...entry, sentenceInfos: combination})))
    const combinedDirections = combineArrays(withDirections)

    // 3. Add sizes
    const withSizes = combinedDirections.map(cd => combine(sizes, cd))
        .flat()

    // 4. Add anchors
    const withAnchors = anchorsCombinations
        .map(anchors => withSizes.map(entries => combine(anchors, entries, true))
            .flat())
        .flat()

    return withAnchors
}

/**
 * arrays contains n arrays with objects.
 * Returns an array where
 * */
export function combineArrays(arrays) {
    const combinations = []

    if (arrays.length === 1)
        return arrays[0].map(entry=>[entry])
    const dive = (arrays, iterators, index) => {
        const iteratorEnd = arrays[index].length-1
        for (let iteratorValue = iterators[index]; iteratorValue <= iteratorEnd; iteratorValue++) {
            const newIterators = [...iterators]
            newIterators[index] = iteratorValue
            const combination = arrays.map((o, i) => ([arrays[i][newIterators[i]]])).flat()
            combinations.push(combination)
            for (let index2 = index+1; index2 < newIterators.length; index2++) {
                const newIterators2 = [...newIterators]
                newIterators2[index2] = 1
                dive(arrays, newIterators2, index2)
            }
        }
    }
    dive(arrays, arrays.map(n=>0), 0)
    return combinations
}

export function combine(valueSet, objectSet, exclusive=false) {
    const objectSetList = [] // Populate this one
    const iteratorEnd = valueSet.length-1

    const dive = (vs, os, iterators, index) => {
        for (let iteratorValue = iterators[index]; iteratorValue <= iteratorEnd; iteratorValue++) {
            const newIterators = [...iterators]
            newIterators[index] = iteratorValue
            if (!exclusive || getDupls(newIterators).length === 0) {
                const newObjectSet = objectSet.map((o, i) => ({...o, ...valueSet[newIterators[i]]}))
                objectSetList.push(newObjectSet)
            }
            for (let index2 = index+1; index2 < newIterators.length; index2++) {
                const newIterators2 = [...newIterators]
                newIterators2[index2] = 1
                dive(vs, os, newIterators2, index2)
            }
        }
    }

    dive(valueSet, objectSet, objectSet.map(n=>0), 0)

    return objectSetList
}

export const getDuplicates = arr => arr
    .filter((item, index) => arr
        .filter((item2, index2) => (JSON.stringify(item) === JSON.stringify(item2))
            && (index !== index2)).length > 0)

export const getDupls = arr => arr
    .filter((item, index) => arr
        .filter((item2, index2) => (item === item2)
            && (index !== index2)).length > 0)
