import IconButton from "@mui/material/IconButton";
import {SvgIcon} from "@mui/material";
import {useState} from "react";
import convert from 'color-convert'
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";

export default function ColorProperty({theme, onNewColor, onDeleteColor}) {

  const [r, setR] = useState(0)
  const [g, setG] = useState(0)
  const [b, setB] = useState(0)

  return <>
    <Divider/>
    <label>Theme colors</label>
    <br/>
    <InputLabel>RGB</InputLabel>
    {theme?.map(themeColor => {
      return <IconButton
          key={`theme_clr_item_${themeColor}`}
          onClick={()=>{onDeleteColor?.(themeColor)}}>
        <SvgIcon sx={{
          backgroundColor: themeColor,
        }}>
          <DeleteIcon/>
        </SvgIcon>
      </IconButton>
    })}
    <br/>
    <input type="number" max="255" min="0" step="1" value={Number(r).toString()} onChange={(event)=>{
      const val = parseInt(event.target.value)
      if (val >= 0 && val <= 255) {
        setR(val)
      }
    }}></input>
    <input type="number" max="255" min="0" step="1" value={Number(g).toString()} onChange={(event)=>{
      const val = parseInt(event.target.value)
      if (val >= 0 && val <= 255) {
        setG(val)
      }
    }}></input>
    <input type="number" max="255" min="0" step="1" value={Number(b).toString()} onChange={(event)=>{
      const val = parseInt(event.target.value)
      if (val >= 0 && val <= 255) {
        setB(val)
      }
    }}></input>
    <br/>
    <div style={{height: "100%", width: "100%", backgroundColor: `#${convert.rgb.hex(r,g,b)}`}}>
      <button onClick={()=>{
        const newHex = `#${convert.rgb.hex(r,g,b)}`
        const newCmyk = convert.hex.cmyk(newHex)
        console.log(`newHex: ${newHex}, newCMyk: ${newCmyk}`)
        onNewColor?.({hex: newHex, cmyk: newCmyk})
      }}>Add</button>
    </div>


    <Divider/>
    <br/>
  </>
}