import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchOpenUrl, fetchUrl} from "../features/urlcache/urlSlice";

export default function Wordfontloader ({uId, stream, xId, x}) {
  const wordFont = x?.wordFont
  const dispatch = useDispatch()
  const signedWordFontUrl = useSelector((state) => state.persistedReducer.urls.cache?.[wordFont]?.url)

  useEffect(()=>{
    if (wordFont) {
      if (stream) {
        dispatch(fetchOpenUrl({streamName: stream, puzzleId: xId, font: wordFont}))
      } else {
        dispatch(fetchUrl({xId, uId, font: {name: wordFont}}))
      }
      if (signedWordFontUrl) {
        const family = wordFont.indexOf(".") === -1 ? wordFont : wordFont.split(".")[0]
        const fontFace = new FontFace(family, `url(${signedWordFontUrl})`);
        document.fonts.add(fontFace);
        fontFace.load();
      }
    }
  }, [dispatch, wordFont, signedWordFontUrl, uId, xId, stream])

  return null
}
