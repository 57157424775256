
import {MLink} from "./svglink";

export default function OverlayTextLinks (props) {
  const {cSz, overlayTexts, matrix} = props

  if (!overlayTexts)
    return

  return <g className="overlayTextLinkLayer">
    {
      overlayTexts.map(e => e.links.filter(l=>l.type !== "no_arrow").map(l => (
          <MLink
              key={`link_x${l.wordRange.x}_y${l.wordRange.y}_${l.type||l.sourceType}_${l.direction}`}
              cSz={cSz}
              e={l}
              overlaySq={e.sq}
              matrix={matrix}
          />
      ))).flat()
    }
  </g>
}
