import convert from "color-convert";
import * as React from "react";
import Sudoku from "./SvgSudoku";
import {ClassListContext} from "./ClassListContext";
import {useContext} from "react";

const isString = c => (c && (typeof c === 'string' || c instanceof String)) ? true : false

export default function SvgContent({content: c, left, top, selectedWord, currentLetters, found}) {
    const classList = useContext(ClassListContext);
    let pt = {...c.pt}
    const {no, id, content, brokenContent, sudoku, cls} = c

    cls?.split(" ").forEach(someClass => {
        if (classList?.[someClass]?.pt) {
            pt = {...pt, ...classList?.[someClass]?.pt}
        }
    })

    let l = (left || 0) + (pt?.left || 0)
    let t = (top || 0) + (pt?.top || 0)

    const bg = no?.["background-color"]
    const bgHex = (bg && `#${convert.cmyk.hex(bg[0], bg[1], bg[2], bg[3])}`) || "transparent"

    const bc = no?.["border-color"]
    const borderHex = bc && `#${convert.cmyk.hex(bc[0], bc[1], bc[2], bc[3])}`

    const fontSize = pt?.["font-size"]
    const lineHeight = pt?.["line-height"]
    const fontFamily = no?.["font-family"]


    let contentX = l
    let contentY = t
    if (pt?.padding) {
        contentX+=pt.padding
        contentY+=pt.padding
    }

    if (c instanceof Array) {
        return <>
            {c.map(cont => (<SvgContent key={cont.id} content={cont} left={contentX} top={contentY} selectedWord={selectedWord} currentLetters={currentLetters} found={found}/>))}
        </>
    }

    const manyWordsPerLine = brokenContent && content?.indexOf(",") !== -1

    return <>
        {pt && <rect width={pt.width} height={pt.height} x={l} y={t} fill={bgHex} stroke={borderHex} id={id}></rect>}
        {brokenContent && <g textAnchor="left" fontSize={fontSize} key={`broken_${id}`} fontFamily={fontFamily} fill={"black"}>
            {brokenContent.map((txt, i) => {
                if (manyWordsPerLine) {
                    return <text y={contentY + (i+1)*(lineHeight || fontSize)} x={contentX} key={`${i}_${txt}`} fill={"black"}>
                        {txt.replace(/ /g,'').split(",").map((spt, ind, arr) => (
                            <>
                                {spt && <tspan key={spt} text-decoration={(found && found.indexOf(spt) !== -1) ? "line-through" : undefined}>{spt}</tspan>}
                                {ind < arr.length -1 && <tspan key={spt + "_br"}>{", "}</tspan>}
                            </>
                        ))}
                    </text>
                }
                else if (selectedWord && (txt === selectedWord)) {
                    return <>
                        <text text-decoration="line-through" y={contentY + (i+1)*(lineHeight || fontSize)} x={contentX} key={`${i}_${txt}`}>{txt}</text>
                    </>
                } else if (found && found.indexOf(txt) !== -1) {
                    return <text text-decoration="line-through" fill="green" y={contentY + (i+1)*(lineHeight || fontSize)} x={contentX} key={`${i}_${txt}`}>{txt}</text>
                } else {
                    let clr = "black"
                    if (txt === currentLetters) {
                        clr = "green"
                    } else if (txt?.indexOf(currentLetters) === 0) {
                        clr = "orange"
                    }
                    return <text y={contentY + (i+1)*(lineHeight || fontSize)} x={contentX} key={`${i}_${txt}`} fill={clr}>{txt}</text>
                }


            })}
        </g>}
    {!brokenContent && content && !isString(content) && <SvgContent content={content} left={contentX} top={contentY} selectedWord={selectedWord} currentLetters={currentLetters} found={found}/>}

    {!brokenContent && isString(content) && <g textAnchor="left" fontSize={fontSize} key={`broken_${id}`} fontFamily={fontFamily} fill={"black"}>
        {content.split("\n").map((txtPart, i) => (<text x={contentX} y={contentY + (i+1)*(lineHeight || fontSize*1.2)}>{txtPart}</text>))}
    </g>}
        {sudoku && <Sudoku pt={pt} x={contentX} y={contentY} sudoku={sudoku}/>}
    </>
}
