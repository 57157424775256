import React, {useEffect, useReducer, useRef, useState} from "react";
import Crossword from "../../crossword/svgcrossword";
import {allStep, createMatrix, getWordMarker, wordStep, compareElementRects, hasLink, getAllWords} from "../../xutils";
import {CHAOS, HZ, KRYPTO, VRT, WORD} from "../../cellTypes"
import { useTheme } from '@mui/material/styles';
import {useParams} from "react-router-dom";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import {layout, sudokuLayout} from '../../keyboardlayouts/seCrossword'
import Cursor from "../../crossword/svgcursor";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {clearCache, fetchOpenUrl} from "../../features/urlcache/urlSlice";
import {getScores, getX} from "../../openBackend";
import {debounce} from "../../debounce";
import {clearAttempt, saveAttempt, saveDocAttempts} from "../../features/solving/soveSlice";
import {findFonts} from "../../xsearch";
import {t_down_right, t_right_down} from "../../linkTypes";
import chaosReducer, {
    CHAOS_ACTION_END,
    CHAOS_ACTION_INIT,
    CHAOS_ACTION_RESET,
    CHAOS_ACTION_SET_FOUND,
    CHAOS_ACTION_START,
    chaosStartState
} from "./chaosReducer";
import ChaosCursor from "../../crossword/ChaosCursor";
import docReducer, {docStartState, ON_DOC_SELECTION, ON_KEY_PRESS, RESTORE_SAVED, SUDOKU_KEY_DOWN} from "./docReducer";
import { DocContext } from "../../crossword/ClassListContext";

export default function Solver() {

    const [showKeyboard, setShowKeyboard] = useState(false)
    const {streamId, puzzleId} = useParams()
    const [stream, setStream] = useState()
    const [direction, setDirection] = useState(HZ)

    const [selections, setSelections] = useState([])
    const [progress, setProgress] = useState(0)
    const [mouseMoved, setMouseMoved] = useState(false)
    const [touchStarted, setTouchStarted] = useState(false)

    const attempt = useSelector((state) => state.persistedReducer.solve.attempts[puzzleId])
    const docAttempt = useSelector((state) => state.persistedReducer.solve.docAttempts[puzzleId]?.docAttempt)
    const [initedPuzzle, setInitedPuzzle] = useState()
    const [origPuzzle, setOrigPuzzle] = useState()

    const dispatch = useDispatch()
    const [chaosState, chaosDispatch] = useReducer(chaosReducer, chaosStartState)
    const [completedWords, setCompledwords] = useState([])
    const [keyboardLayout, setKeyboardLayout] = useState(layout)
    const [docState, docDispatch] = useReducer(docReducer, docStartState)

    const reset = ()=>{
        chaosDispatch({type: CHAOS_ACTION_RESET})
        setTimeout(()=>{
            dispatch(saveAttempt({puzzleId: puzzleId, attempt: stateRef.chaosState.found}))
        }, 1000)
    }

    const resetDebounced = useRef(debounce(reset, 1000))

    useTheme();
    useEffect(() => {
        fetch(`https://jh0gpkrasj.execute-api.eu-north-1.amazonaws.com/dev/streams/${streamId}`)
            .then(res => res.json()).then(data => {
                console.log(data)
                return data
        }).then(setStream)
    }, [streamId])
    useEffect(()=> {
        getX(streamId, puzzleId).then((openedX) =>{
            if (openedX.type !== CHAOS) {
                openedX.elements.filter(e=>e.type === WORD && !e.locked).forEach(e=>delete e.val)
            }
            setOrigPuzzle(openedX)
            chaosDispatch({type: CHAOS_ACTION_INIT, cSz: openedX?.paragraphStyle.squareSize, puzzle: openedX})
        })
    }, [streamId, puzzleId])

    useEffect(()=>{
        if(origPuzzle && stream && puzzleId) {
            const xFonts = findFonts(origPuzzle)
            console.log(`Found fonts ${xFonts}`)
            xFonts.forEach(font => dispatch(fetchOpenUrl({streamName: streamId, puzzleId: puzzleId, font: font})))
        }
    }, [origPuzzle, stream, puzzleId, dispatch, streamId])

    useEffect(()=> {
        console.log("Checking if should load attempt")
        if (origPuzzle && !initedPuzzle) {
            const newPuzzle = JSON.parse(JSON.stringify(origPuzzle))
            console.log("If there is an attempt...")
            if (attempt) {
                console.log("...loading it")
                if (newPuzzle.type === CHAOS) {
                    chaosDispatch({type: CHAOS_ACTION_SET_FOUND, found: attempt})
                } else {
                    newPuzzle.elements.filter(e => e.type === "WORD").sort(compareElementRects).forEach((e, index) => {
                        if (attempt[index] && attempt[index] !== ".") {
                            e.val = attempt[index]
                        }
                    })
                    setCompledwords(getAllWords(createMatrix(newPuzzle), newPuzzle))
                }
            }
            if (docAttempt) {
                docDispatch({type: RESTORE_SAVED, attempts: docAttempt})
            }
            console.log("Setting inited puzzle")
            console.log(newPuzzle)
            setInitedPuzzle(newPuzzle)

            console.log(`Branch: ${process.env.REACT_APP_BRANCH_NAME}`)
            console.log(`SHA: ${process.env.REACT_APP_SHA}`)
            console.log(`BUILT: ${process.env.REACT_APP_BUILD_TIME}`)
        }
    }, [attempt, docAttempt, initedPuzzle, origPuzzle])
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('touchstart', handleTouchStarted)

        // cleanup this component
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchstart', handleTouchStarted);
        };
    });

    const keyboardRef = useRef(null)

    const stateRef = useRef();
    stateRef.showKeyboard = showKeyboard
    stateRef.selections = selections
    stateRef.x = initedPuzzle
    stateRef.matrix = createMatrix(initedPuzzle)
    stateRef.direction = direction
    stateRef.mouseMoved = mouseMoved
    stateRef.chaosState = chaosState

    const zoomToSelection = (s) => {
        if (transformComponentRef.current && s[0]?.clickedObj?.e?.sq) {
            const {x1, y1} = s[0].clickedObj.e.sq
            const endSq = s[0]?.endObj?.e?.sq || s[0].clickedObj.e.sq
            const {x2, y2} = endSq
            const targetScale = stateRef.transformState?.scale || 2
            const calculatedX = window.innerWidth/2 - (x1+(x2-x1)/2)*targetScale
            const calculatedY =  window.innerHeight/2 - (y1+(y2-y1)/2)*targetScale
            const animationTime = 500
            transformComponentRef.current.setTransform(calculatedX, calculatedY, targetScale, animationTime)
        }
    }

    const selectionIsOutsideWindow = (s) => {
        if (stateRef.transformState && s[0]?.clickedObj?.e?.sq) {
            const {x1, y1} = s[0].clickedObj.e.sq
            const {positionX, positionY, scale} = stateRef.transformState
            const endSq = s[0]?.endObj?.e?.sq || s[0].clickedObj.e.sq
            const {x2, y2} = endSq
            const calculatedX = positionX + (x1+(x2-x1)/2)*scale
            const calculatedY = positionY + (y1+(y2-y1)/2)*scale
            return calculatedX > window.innerWidth-200
                || calculatedY > window.innerHeight-200
                || calculatedX < 0+200
                || calculatedY < 0+200
        }
        return false
    }

    const renderCard = (puzzle) => {
        if (!puzzle)
            return

        const wordMarker = stateRef.selections.length === 1 && getWordMarker(stateRef.matrix, startObj(), direction, null, null, stateRef.x)

        const cSz = stateRef.x?.paragraphStyle.squareSize
        const cursor = chaosState.points ? <ChaosCursor points={chaosState.points}/> : <>
            {selections.map(selection => {
                const {clickedObj} = selection
                return <Cursor key={clickedObj.key} cSz={cSz} selection={selection}
                               wordmarker={wordMarker} matrix={stateRef.matrix}></Cursor>
            })}
        </>

        const filterKeys = (event) => {
            return event.ctrlKey || event.metaKey
        }

        const getElem = () => {
            const info = startObj()
            if (info?.e?.parts) {
                return info.e.parts[info.part || 0]
            }
            return info?.e
        }

        const chaosMouseDownListener = (event, nativeEvent) => {
            chaosDispatch({type: CHAOS_ACTION_START, rect: event.e.rect})
        }

        const mouseDownListener = (event, nativeEvent) => {
            if (stateRef.x.type === CHAOS) {
                chaosMouseDownListener(event, nativeEvent)
            } else {
                if (filterKeys(nativeEvent))
                    return
                const focusedElement = getElem()
                const newSel = nativeEvent.shiftKey ? [...stateRef.selections] : []
                event.key = new Date().getTime()
                newSel.push({clickedObj: event})
                setSelections(newSel)
                const {e} = event
                if (e === focusedElement) {
                    switchDirection()
                }
                /*if (!showKeyboard && focusedElement.type === WORD) {
                    setShowKeyboard(true)
                }*/
            }
        }

        const chaosMouseEnterListener = (event, nativeEvent) => {
            if (nativeEvent.nativeEvent.buttons) {
                if (stateRef.chaosState.start) {
                    chaosDispatch({type: CHAOS_ACTION_END, rect: event.e.rect})
                }
            } else {
                chaosDispatch({type: CHAOS_ACTION_RESET})
            }
        }

        const chaosMouseUpListener = () => {
            resetDebounced.current()
        }

        const mouseUpListener = (event, nativeEvent) => {
            if (stateRef.x.type === CHAOS) {
                chaosMouseUpListener(event, nativeEvent);
            } else {
                if (filterKeys(nativeEvent))
                    return
                const newSel = nativeEvent.shiftKey ? [...stateRef.selections] : []
                if (newSel.length > 0) {
                    newSel[newSel.length - 1].endObj = event
                    setSelections(newSel)
                }
            }
        }

        const doubleClickListener = () => {
            console.log("double clicked")
        }

        return <DocContext.Provider value={{
            state: docState,
            cb: (selection)=>{
                if (selection) {
                    setKeyboardLayout(sudokuLayout)
                    setShowKeyboard(true)
                    docDispatch({type: ON_DOC_SELECTION, selection: selection})
                } else {
                    setShowKeyboard(false)
                    docDispatch({type: ON_DOC_SELECTION, selection: undefined})
                }
            }
        }}>
            <Crossword
                x={puzzle}
                iDs={{xId: puzzle.xId}}
                cursor={cursor}
                matrix={stateRef.matrix}
                chaosState={stateRef.chaosState}
                completedwords={completedWords}
                clickListener={() => {
                    console.log(new Date().toISOString() + " clickListener " + stateRef.showKeyboard + " " + stateRef.mouseMoved)
                    if (!stateRef.mouseMoved) {
                        console.log("settingShowKeyboard true")
                        setShowKeyboard(true)
                        setKeyboardLayout(layout)
                    }
                }}
                mouseDownListener={mouseDownListener}
                mouseUpListener={mouseUpListener}
                mouseEnterListener={(initedPuzzle.type === CHAOS && chaosMouseEnterListener) || undefined}
                mouseOutsideListener={(initedPuzzle.type === CHAOS && chaosMouseUpListener) || undefined}
                doubleClickListener={doubleClickListener}
                touchAction={stateRef.x?.type === CHAOS ? "none" : undefined}
                stream={streamId}/>
        </DocContext.Provider>
    }

    const onChange = (input) => {
        console.log("Input changed", input);
    }

    const onSudokuKeyRelease = (button, e) => {
        docDispatch({type: ON_KEY_PRESS, nbr: button})
        setTimeout(()=>{
            dispatch(saveDocAttempts({puzzleId: puzzleId, attempt: docState.attempts}))
        }, 1000)
    }

    const onKeyRelease = (button, e) => {
        console.log("Button pressed", button)
        if (button === '⌨️') {
            setTimeout(()=> {
                setShowKeyboard(false)
            }, 200)

        } else {
            let code = button
            if (button === "{space}") {
                code = "Space"
            } else if (button === "⟸") {
                code = "Backspace"
            } else if (button === "↵") {
                code = "Enter"
            }
            handleKeyDown({code: code, key: button})
        }
        e.stopPropagation()
    }

    const renderProgress = () => {
        if (progress < 0.005)
            return null
        console.log(progress)
        const size = 30
        const stroke = 3.14 * (size/2)*progress
        return (<>
            <div style={{position: "fixed", top: 0, left: 0}} onDoubleClick={()=>{
                dispatch(clearAttempt({puzzleId: puzzleId}))
                window.location.reload(false);
            }}>
                <svg height={size} width={size} viewBox={`-2 -2 ${size+4} ${size+4}`}>
                    <circle r={size/2} cx={size/2} cy={size/2} fill="orange" stroke="black" strokeWidth={1}/>
                    <circle r={size/4} cx={size/2} cy={size/2} fill="transparent"
                            stroke="green"
                            strokeWidth={size/2}
                            strokeDasharray={`${stroke} 1000`}
                            transform={`rotate(-90) translate(-${size})`} />
                </svg>
            </div>
        </>)
    }
    const renderKeyboard = () => {
        console.log(showKeyboard)
        return (<>
            <div
                style={{position: "fixed", top: 0, right: 0, backgroundColor: showKeyboard ? "lightgray" : "lightgreen", fontSize: "54px", borderRadius: "64px", height: "64px", width: "64px", textAlign: "center", verticalAlign: "middle"}}
                onClick={(e)=>{
                    console.log("Clicked keyboard button")
                    setShowKeyboard(!stateRef.showKeyboard)
                    e.preventDefault()
                    e.stopPropagation()
                }}
                onDoubleClick={()=> {
                    dispatch(clearCache())
                }}
            >⌨️</div>
            {showKeyboard && <div
                onClick={(e)=>{
                    e.preventDefault()
                    e.stopPropagation()
                }}
                className="keyboardContainer" style={{position: "fixed", bottom: 0, width: "100%"}} >
                <Keyboard
                    onChange={onChange}
                    onKeyReleased={keyboardLayout === sudokuLayout ? onSudokuKeyRelease : onKeyRelease}
                    keyboardRef={(r) => (keyboardRef.current = r)}
                    layout={keyboardLayout}
                />
            </div>}
        </>)
    }

    const resetCursor = () => {
        setSelections([])
    }

    const renderPage = () => {
        if (!stream)
            return <div>loading stream...</div>
        return (
            <Box className="fullheightdiv"
                 style={{height: "100vh", backgroundColor: "lightblue"}}
                 onClick={resetCursor}
            >
                <TransformWrapper
                    wheel={{step: 0.05}}
                    initialScale={1}
                    maxScale={100}
                    minScale={0.1}
                    centerOnInit={false}
                    limitToBounds={false}
                    doubleClick={{disabled: true}}
                    panning={{disabled: stateRef.x?.type === CHAOS && stateRef.chaosState.start}}
                    onTransformed={(theRef)=>{
                        stateRef.transformState = {...theRef.state}
                    }}
                    ref={transformComponentRef}
                    //wheel={{activationKeys: ["Alt", "Shift", "Control"]}}
                >
                    <TransformComponent
                        wrapperStyle={{
                            width: "100%",
                            height: "100%"}}
                    >
                        {renderCard(initedPuzzle)}
                    </TransformComponent>
                </TransformWrapper>
                {renderKeyboard()}
                {renderProgress()}

        </Box>
        )
    }

    const getFocusedElement = () => {
        const el = stateRef.selections[0].clickedObj.e
        if (el.overlayPosition && stateRef.matrix[el.rect.y][el.rect.x].ot && stateRef.matrix[el.rect.y][el.rect.x].ot.overlayPosition === el.overlayPosition) {
            return stateRef.matrix[el.rect.y][el.rect.x].ot
        }
        return stateRef.matrix[el.rect.y][el.rect.x].e
    }

    const startObj = () => {
        return stateRef.selections?.[stateRef.selections.length - 1]?.clickedObj
    }

    const switchDirection = () => {
        console.log("switchDirection")
        setDirection((stateRef.direction + 1) % 2)
    }

    const xUpdated = (x, triggerSave=true) => {
        console.log(`xUpdated ${x.xId} ${triggerSave}`)
        if (x.type !== CHAOS) {
            setInitedPuzzle(x)

            const answer = x.elements
                .filter(e=>e.type === "WORD")
                .sort(compareElementRects)
                .map(e => e.val || ".")

            dbx.current(streamId, puzzleId, answer)
            dispatch(saveAttempt({puzzleId: puzzleId, attempt: answer}))
            setCompledwords(getAllWords(stateRef.matrix, stateRef.x))
        } else {
            console.error("xUpdated should not be called for CHAOS")
        }
    }

    const checkScores = (streamId, puzzleId, answer) => {
        getScores(streamId, puzzleId, answer).then(result => {
            setProgress(result.body.ratio)
        })
    }

    const dbx = useRef(debounce(checkScores, 1000))
    const transformComponentRef = useRef(null);

    const handleMouseMove = (e) => {
        if (!touchStarted) {
            setMouseMoved(true)
        }
    }
    const handleTouchStarted = (e) => {
        setTouchStarted(true)
    }
    const handleKeyDown = (e) => {
        if (stateRef.x?.type === CHAOS) {
            return
        } else if (docState.selection?.puzzleType === "sudoku") {
            docDispatch({type: SUDOKU_KEY_DOWN, key: e.key})
            setTimeout(()=>{
                dispatch(saveDocAttempts({puzzleId: puzzleId, attempt: docState.attempts}))
            }, 1000)
        }
        if (e.shiftKey || e.ctrlKey || e.metaKey) {
            //setEndObj()
            //setDragging(false)
        }
        if (stateRef.selections.length === 0)
            return false
        const element = getFocusedElement()
        if (element) {
            console.log(e.key)
            let next = null
            const chr = e.key.toUpperCase()
            const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ"

            if (e.code === "ArrowRight") {
                next = allStep(stateRef.matrix, startObj(), HZ)
            } else if (e.code === "ArrowLeft") {
                next = allStep(stateRef.matrix, startObj(), HZ, false)
            } else if (e.code === "ArrowUp") {
                next = allStep(stateRef.matrix, startObj(), VRT, false)
            } else if (e.code === "ArrowDown") {
                next = allStep(stateRef.matrix, startObj(), VRT)
            }
            else {
                console.log(e.code)
                if (e.code === "Enter") {
                    if (element.type === WORD) {
                        switchDirection()
                    }
                } else if (e.code === "Space") {
                    if (element.type === WORD) {
                        if (!element.locked) {
                            element.val = ""
                            if (stateRef.x.type === KRYPTO && !isNaN(element.number)) {
                                stateRef.x.elements.filter(elem => elem.number === element.number && !elem.locked).forEach(elem => elem.val = "")
                            }
                            xUpdated(JSON.parse(JSON.stringify(stateRef.x)))
                        }
                        next = wordStep(stateRef.matrix, startObj(), direction)
                        if (next?.e) {
                            if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                                setDirection(VRT)
                            } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                                setDirection(HZ)
                            }
                        }
                    }
                } else if (e.code === "Backspace") {
                    if (element.type === WORD) {
                        if (!element.locked) {
                            element.val = ""
                            if (stateRef.x.type === KRYPTO && !isNaN(element.number)) {
                                stateRef.x.elements.filter(elem => elem.number === element.number && !elem.locked).forEach(elem => elem.val = "")
                            }
                            xUpdated({...stateRef.x})
                        }
                        next = wordStep(stateRef.matrix, startObj(), direction, false)
                        if (next?.e) {
                            if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                                setDirection(HZ)
                            } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                                setDirection(VRT)
                            }
                        }
                    }
                } else {
                    if (ALPHABET.indexOf(chr) !== -1) {
                        if (element?.type === WORD) {
                            if (!element.locked) {
                                element.val = chr
                                if (stateRef.x.type === KRYPTO && !isNaN(element.number)) {
                                    stateRef.x.elements.filter(elem => elem.number === element.number && !elem.locked).forEach(elem => elem.val = chr)
                                }
                                xUpdated({...stateRef.x}) // This works because the key of the element changes. Memo won't work though
                            }
                            next = wordStep(stateRef.matrix, startObj(), direction)
                            if (next?.e) {
                                if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                                    setDirection(VRT)
                                } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                                    setDirection(HZ)
                                }
                            }
                        }
                    } else {
                        console.error(`${chr} is not in alphabet`)
                    }
                }
            }
            if (next) {
                const newSel = [{clickedObj: next}]
                setSelections(newSel)
                if (selectionIsOutsideWindow(newSel)) {
                    zoomToSelection(newSel)
                }
            }
        } else {
            console.error("Couldn't find any match")
        }
    }

    return <>
        {renderPage()}
        {/*{puzzle2 && <div>{JSON.stringify(puzzle2, "<\br>", 2)}</div>}
        {puzzle3 && <div>{JSON.stringify(puzzle3, "<\br>", 2)}</div>}*/}
    </>
}
