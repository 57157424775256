import * as React from "react";
import {elementEquals, ru} from "../xutils";
import {EMPTY_KEY} from "../cellTypes";
import {sqEqualish} from "./square";
const convert = require('color-convert');

const OFFSET = 0.2 // This value should be overridable to adjust for different fonts and user liking

const OverlayText = (p) => {
  const {e, fontSizes, fontFamily, sq} = p
  const x = sq.x1 + (sq.x2-sq.x1)/2
  const overlayLines = e.text.split("<br>").length


  const fontSizeInfo = fontSizes[fontSizes.length-1]
  const fontSize = e.fontSize || fontSizeInfo.sz
  const leading = e.leading || fontSizeInfo.leading


  let yPos = sq.y1 + ((sq.y2-sq.y1) - overlayLines*leading - OFFSET*leading)/2
  return (
      <g textAnchor="middle" fontSize={fontSize} fontFamily={fontFamily}>
        {e.text?.split("<br>").map((t, i) => (<text y={yPos + (i+1)*leading} x={x} key={`${i}_${t}`}>{t}</text>))}
      </g>)
}

const Text = (p) => {
  const {cSz, e, fontSizes, linesAbove, linesUnder} = p
  const {rect: r, sq} = e
  const h = r.h || 1
  const x = e.sq.x1 + (e.sq.x2-e.sq.x1)/2
  const emptyKeyClor = "lightgray"
  let lines
  if (e.parts) {
    lines = e.parts.map(part => part.text.split("<br>").length).reduce((partialSum, a) => partialSum + a, 0) || 0
  } else {
    lines = ((e.autoBroken) || (e.text))?.split("<br>").length || 0
  }

  const overlayLines = linesAbove + linesUnder
  const totalLineOfTexts = lines + overlayLines

  const fontSizeInfo = fontSizes.find(info => totalLineOfTexts/h <= info.nbr) || fontSizes[fontSizes.length-1]
  let fontSize = e.fontSize || fontSizeInfo.sz
  let leading = e.leading || fontSizeInfo.leading

  if (e.parts) {
    try {
      const lengths = e.parts.map(part => part.text.split("<br>").length)
      return e.parts.map((part, i) => {
        const partLeading = part.leading || leading
        const box = sq.parts[i]
        const yPos = box.y1 + (box.y2 - box.y1-(lengths[i]*partLeading + OFFSET*partLeading))/2
        return <g textAnchor="middle" fontSize={part.fontSize || fontSize} key={`text_part_${i}_${ru.str(e.rect)}`} fontFamily={e.parts[i].font || e.font} fill={(part.text === EMPTY_KEY && emptyKeyClor)||undefined}>
          {(part.text === EMPTY_KEY ? "?" : part.text)?.split("<br>").map((t,i) => (<text y={yPos + (i+1)*partLeading} x={x} key={`${i}_${t}`}>{t}</text>))}
        </g>
      })
    } catch(err) {
      console.error(err)
      throw err
    }
  }

  const sqh = sq.y2 - sq.y1
  const empty = e.text === EMPTY_KEY
  const fsz = empty ?  Math.min(Math.round(sqh), cSz) : fontSize
  const fszLeading = empty ? fsz : leading
  let yPos = sq.y1 + ((sq.y2-sq.y1) - lines*fszLeading - OFFSET*fszLeading)/2
  const textCmykAsHex = e.textCmyk && `#${convert.cmyk.hex(...e.textCmyk)}`
  return (
      <g textAnchor="middle" fontSize={fsz} key={`text_${ru.str(e.rect)}`} fontFamily={e.font} fill={(empty && emptyKeyClor) || textCmykAsHex || undefined}>
        {(e.text === EMPTY_KEY ? "?" : (e.autoBroken || e.text))?.split("<br>").map((t, i) => (<text y={yPos + (i+1)*fszLeading} x={x} key={`${i}_${t}`}>{t}</text>))}
      </g>)
}

export const MText = React.memo(Text, (pp, np) => {
  return pp.id === np.id && sqEqualish(pp.sq, np.sq) && elementEquals(pp.e, np.e)
})

export const MOverlayText = React.memo(OverlayText, (pp, np) => {
  return pp.id === np.id && sqEqualish(pp.sq, np.sq) && elementEquals(pp.e, np.e)
})
