import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./routes/route";
import ErrorPage from "./error-page";
import {Provider} from 'react-redux'
import store from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {
    persistStore
} from 'redux-persist'
import Signin from "./routes/signinflow/signin";
import Signup from "./routes/signinflow/signup"
import Dashboard from "./routes/dashboard";
import Editor from "./routes/editor/Editor";
import Index from "./routes";
import Stream from "./routes/streams/stream";
import Solver from "./routes/solver/solver";
import Debug from "./routes/debug";
import Orders from "./routes/orders";
import RecoverPswd from "./routes/signinflow/recoverpswd";
import StreamEditor from "./routes/streams/streameditor";
import KeysPage from "./routes/keys/KeysPage"
import CorrSolver from "./routes/solver/corrsolver";
import StreamSolverMenu from "./routes/solver/StreamSolverMenu";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <Index/>
            },
            {
                path: "dashboard",
                element: <Dashboard/>
            },
            {
                path: "streams/:streamId",
                element: <StreamEditor/>
            },
            {
                path: "editor",
                element: <Editor/>
            },
            {
                path: "keys",
                element: <KeysPage/>
            },
            {
                path: "signin",
                element: <Signin/>,
            },
            {
                path: "signup",
                element: <Signup/>,
            },
            {
                path: "recover",
                element: <RecoverPswd/>
            },
            {
                path: "stream",
                element: <Stream/>
            },
            {
                path: "solve/:streamId/puzzle/:puzzleId",
                element: <Solver/>
            },
            {
                path: "corrsolve/:xId",
                element: <CorrSolver/>
            },
            {
                path: "dbg",
                element: <Debug/>
            },
            {
                path: "orders",
                element: <Orders/>
            }
        ]
    },
]);

const persistor = persistStore(store)
const root = ReactDOM.createRoot(document.getElementById('root'));

let appVariant = undefined
if (process.env.REACT_APP_VARIANT === "solver") {
    appVariant = <StreamSolverMenu config={window.kruxet_config}/>
} else {
    appVariant = <RouterProvider router={router}/>
}
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {appVariant}
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
