import Draggable from "react-draggable";
import {ListItem, Paper, List, Typography, ListItemButton} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";

export default function WordListWindow({currentWord, showWords, wrdCntCache, left, callback}) {

    const step = 100
    const [end, setEnd] = useState(step)

    useEffect(() => {
        setEnd(step)
    }, [currentWord])

    const apply = (word) => {
        callback?.(word)
    }

    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 10;
        if (bottom) {
            console.log("handleScroll")
            setEnd(end+step)
        }
    }

    const renderContent = () => {
        console.log("render content")
        const matches = wrdCntCache[currentWord]
        return <List>
            {matches?.slice(0, end).map((wrd)=>(<ListItem disablePadding key={wrd}>
                <ListItemButton sx={{fontSize: 12}} dense onClick={()=> {
                    apply(wrd)
                }}>
                    {wrd}
                </ListItemButton>
            </ListItem>))}
        </List>
    }

    const disableDefault = e => e.stopPropagation()

    const renderWordsMenu = () => {
        return <Draggable bounds="parent">
            <Paper
                onClick={disableDefault}
                onMouseUp={disableDefault}
                elevation={3}
                style={{
                    position: "absolute",
                    left: left||100,
                    bottom: 0,
                    zIndex: 9999999,
                    visibility: showWords ? "visible" : "hidden"}}>
                <Typography variant="body1" style={{textAlign: "center"}}>Words: <span style={{fontSize: 8}}>{wrdCntCache[currentWord]?.length || 0}</span></Typography>
                <div style={{height:"30vh", width: "200px", overflow: "auto"}} onScroll={handleScroll}>
                    {showWords && renderContent()}
                </div>
            </Paper>
        </Draggable>
    }

    return renderWordsMenu()
}

export const MWordListWindow = React.memo(WordListWindow, (pp, np)=>{
    return pp.currentWord === np.currentWord && pp.showWords === np.showWords && (pp.wrdCntCache === np.wrdCntCache)
})
