import {combineReducers, configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import userReducer from './features/users/userSlice'
import {urlReducer} from './features/urlcache/urlSlice'
import {xlistReducer} from "./features/xlist/xlistSlice";
import {wordListsReducer} from "./features/wordlists/wordlists"
import {editorReducer} from "./features/editor/editorSlice"
import {
  persistCombineReducers,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import {solveReducer} from "./features/solving/soveSlice";
import {userWordlistReducer} from "./features/wordlists/userWordlists";
import {fab5Reducer} from "./features/fab5Slice";

const persistConfig = {
  key: 'root',
  version: 1,
  storage
}

const persistedReducer = persistCombineReducers(persistConfig, {
  urls: urlReducer,
  lists: wordListsReducer,
  userLists: userWordlistReducer,
  solve: solveReducer,
  editor: editorReducer,
  xlist: xlistReducer,
  fab5: fab5Reducer,
})
const appReducer = combineReducers({
  persistedReducer,
  user: userReducer})

const rootReducer = (state, action) => {
  //console.log(action.type)
  if (action.type === "user/userLoggedOut") {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //serializableCheck: false,
    //immutableCheck: false
    serializableCheck: {
      ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
})


export default store
