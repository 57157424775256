import * as React from "react";
import {equalish, xOverlap, yOverlap} from "./square";
import * as LINK from "../linkTypes";
import {HZ, LOWER, UPPER, VRT} from "../cellTypes";

const UNKNOWN = "unknown"

const HEAD_BASE=0.15
const HEAD_LENGTH=0.2
const MINI_HEAD_BASE=0.1
const MINI_HEAD_LENGTH=0.1
const LARGE_HEAD_BASE=0.3
const LARGE_HEAD_LENGTH=0.2
const OFFSET=0.1
const I_OFFSET=0.65
const L1=0.2
const L2=0.1
const LINE_WIDTH=1

function Link(props) {
  const {e, cSz, frame, matrix, overlaySq, wordRangeSq} = props
  const {type, wordRange, source, part, direction, sourceType} = e
  const sq = wordRangeSq || (matrix && wordRange && matrix[wordRange.y][wordRange.x].e.sq)
  const headBase = HEAD_BASE*cSz
  const headLength = HEAD_LENGTH*cSz
  const miniHeadBase = MINI_HEAD_BASE*cSz
  const miniHeadLength = MINI_HEAD_LENGTH*cSz
  const largeHeadBase = LARGE_HEAD_BASE*cSz
  const largeHeadLength = LARGE_HEAD_LENGTH*cSz
  const lineWidth = LINE_WIDTH
  const clr = e.clr || "black"

  const getType = () => {
    return type || UNKNOWN
  }

  const polyLine = (arr, color) => {
    return <polyline stroke={color || clr} strokeWidth={lineWidth} points={arr.map(arr => arr.join(",")).join(" ")} fill={"none"}/>
  }

  const dashedPolyLine = (arr, color) => {
    return <polyline stroke={color || clr} strokeWidth={lineWidth} points={arr.map(arr => arr.join(",")).join(" ")} fill={"none"} strokeDasharray={`${2}`}/>
  }

  const polygon = (arr) => {
    return <polygon stroke={"none"} points={arr.map(arr => arr.join(",")).join(" ")} fill={clr}></polygon>
  }

  const largeHeadSouth = (x, y) => {
    const y1 = y-largeHeadLength
    return polygon([[x-largeHeadBase/2, y1],[x+largeHeadBase/2, y1],[x, y]])
  }
  const largeHeadEast = (x, y) => {
    const x1 = x-largeHeadLength
    return polygon([[x1, y-largeHeadBase/2],[x1, y+largeHeadBase/2],[x, y]])
  }

  const headSouth = (x, y) => {
    const y1 = y-headLength
    return polygon([[x-headBase/2, y1],[x+headBase/2, y1],[x, y]])
  }

  const miniHeadNorth = (x, y) => {
    const y1 = y+miniHeadLength
    return polygon([[x-miniHeadBase/2, y1],[x+miniHeadBase/2, y1],[x, y]])
  }

  const miniHeadSouth = (x, y) => {
    const y1 = y-miniHeadLength
    return polygon([[x-miniHeadBase/2, y1],[x+miniHeadBase/2, y1],[x, y]])
  }

  const miniHeadEast = (x, y) => {
    const x1 = x-miniHeadLength
    return polygon([[x1, y-miniHeadBase/2],[x1, y+miniHeadBase/2],[x, y]])
  }

  const miniHeadWest = (x, y) => {
    const x1 = x+miniHeadLength
    return polygon([[x1, y-miniHeadBase/2],[x1, y+miniHeadBase/2],[x, y]])
  }

  const headEast = (x, y) => {
    return polygon([[x-headLength, y-headBase/2],[x-headLength, y+headBase/2],[x, y]])
  }

  const dynamic = () => {
    console.log("dynamic")
    let sourceSq = overlaySq || matrix[source.y][source.x].e.sq
    if (part !== undefined) {
      sourceSq = sourceSq.parts[part]
    }
    const yOver = yOverlap(sourceSq, sq)
    const xOver = xOverlap(sourceSq, sq)
    if (equalish(sourceSq.x2, sq.x1)) { // Source to the left
      if (sourceSq.y2 === sq.y2) {
        if (direction === HZ)
          return null; // Source and word is bottom aligned
        return right_down(sourceSq)
      }
      if (sourceSq.y2 === sq.y1) { // Source above
        if (direction === HZ)
          return diag_right_east()
        if (direction === VRT)
          return diag_right_down()
      }
      if (sourceSq.y1 === sq.y2) {
        if (direction === HZ)
          return diag_up_right_east()
      }
      if (sourceSq.y1>=sq.y1 && sourceSq.y1 <= sq.y2) {
        if (direction === HZ) {
          return null
        } else if (direction === VRT) {
          return right_down(sourceSq)
        }
      }
    }
    if (equalish(sourceSq.x1, sq.x2)) { // Source to the right
      if (sourceSq.y2 === sq.y2) {
        if (direction === HZ)
          return null; // Source and word is bottom aligned
        return left_down(sourceSq)
      }
      if (sourceSq.y2 === sq.y1) { // Source above
        if (direction === VRT)
          return diag_left_south()
      }
      if (sourceSq.y1>=sq.y1 && sourceSq.y1 <= sq.y2) {
        if (direction === HZ) {
          return null
        } else if (direction === VRT) {
          return left_down(sourceSq)
        }
      }
    }
    if (sourceSq.x1 === sq.x1) { // Source and word left aligned
      if (sourceSq.y2 === sq.y1) { // Source and word y-adjacent
        return direction === HZ ? down_right() : null
      }
      if (equalish(sourceSq.y1, sq.y2)) {
        return up_right()
      }
    }
    if (!yOver && xOver) {
      if (direction === VRT) {
        // The cells are right aligned, but the source is wider. Like a w=2 source above a w=1 cell
        if (equalish(sourceSq.x2, sq.x2) && equalish(sourceSq.y2, sq.y1)) {
          // No arrow needed in this case
          return
        } else if (sourceSq.y2 < sq.y1) {
          return dynamic_down(xOver, sourceSq)
        }
      } else {
        if (sourceSq.y2 < sq.y1) {
          return dynamic_down_right(sourceSq)
        }
      }
    }
    if (yOver && !xOver) {
      if (sourceSq.x2 === sq.x1) {
        if (direction === VRT) {
          return right_down(yOverlap(sq, sourceSq))
        }
        const overlap = yOverlap(sq, sourceSq)
        return mini_east(overlap)
        //return headEast(sq.x1+headLength, overlap.y1 + (overlap.y2 - overlap.y1)/2)
      } else if (sourceSq.x1 === sq.x2) {
        if (direction === VRT) {
          return left_down(yOverlap(sq, sourceSq))
        } else {
          return mini_west()
        }
      }
    }

    if (!yOver && !xOver) {
      if (sourceSq.x2 <= sq.x1) {
        if (sourceSq.y2 <= sq.x2) {
          const x1 = sourceSq.x2
          const x2 = sq.x1 + cSz*L1/2
          const y1 = sourceSq.y1+cSz*OFFSET
          const y2 = sq.y1+cSz*(OFFSET+L2)

          if (direction === VRT) {
            return <g>
              {dashedPolyLine([[x1, y1],[x2,y1],[x2,y2]])}
              {headSouth(x2, y2+(cSz*HEAD_LENGTH))}
            </g>
          }
          if (direction === HZ) {
            const x3 = sq.x1 + cSz*L1
            return <g>
              {dashedPolyLine([[x1, y1],[x2,y1],[x2,y2],[x3,y2]])}
              {headEast(x3+(cSz*HEAD_LENGTH), y2)}
            </g>
          }
        }
      }
    }

    let x1 = sourceSq.x2
    let x2 = sq.x1 + cSz*L1
    if (sq.x2 <= sourceSq.x1) {
      x1 = sourceSq.x1
      x2 = sq.x2 - cSz*L1
    }

    const y1 = sourceSq.y1+cSz*OFFSET
    const y2 = sq.y1+cSz*(OFFSET+L2)
    return <g>
      {dashedPolyLine([[x1, y1],[x2,y1],[x2,y2]])}
      {headSouth(x2, y2+(cSz*HEAD_LENGTH))}
    </g>
  }

  const right_down = (sourceSq) => {
    const x1 = sq.x1
    const x2 = sq.x1 + cSz*L1
    let y1 = (sourceSq || sq).y1+cSz*OFFSET
    let y2 = (sourceSq || sq).y1+cSz*(OFFSET+L2)

    if (!sourceSq && (sourceType === LOWER)) {
      let sourceSq = overlaySq || (matrix[source.y][source.x].e.sq.parts ? matrix[source.y][source.x].e.sq.parts[1] : matrix[source.y][source.x].e.sq)
      const yOver = yOverlap(sourceSq, sq)
      y1 = yOver.y1+cSz*OFFSET
      y2 = yOver.y1+cSz*(OFFSET+L2)
    }

    return <g>
      {polyLine([[x1, y1],[x2,y1],[x2,y2]])}
      {headSouth(x2, y2+(cSz*HEAD_LENGTH))}
    </g>
  }

  const left_down = (sourceSq) => {
    const x1 = sq.x2
    const x2 = sq.x2 - cSz*L1
    let y1 = (sourceSq || sq).y1+cSz*OFFSET
    let y2 = (sourceSq || sq).y1+cSz*(OFFSET+L2)

    if (!sourceSq && (sourceType === LOWER)) {
      let sourceSq = overlaySq || (matrix[source.y][source.x].e.sq.parts ? matrix[source.y][source.x].e.sq.parts[1] : matrix[source.y][source.x].e.sq)
      const yOver = yOverlap(sourceSq, sq)
      y1 = yOver.y1+cSz*OFFSET
      y2 = yOver.y1+cSz*(OFFSET+L2)
    }

    return <g>
      {polyLine([[x1, y1],[x2,y1],[x2,y2]])}
      {headSouth(x2, y2+(cSz*HEAD_LENGTH))}
    </g>
  }

  const diag_up_right_east = () => {
    const x0 = sq.x1-cSz*OFFSET
    const y0 = sq.y2+cSz*OFFSET
    const x1 = sq.x1+cSz*OFFSET
    const x2 = sq.x1+cSz*(OFFSET+L2)
    const y1 = sq.y2-cSz*OFFSET
    const y2 = sq.y2 - cSz*L1

    return <g>
      {polyLine([[x0, y0],[x1, y1],[x1,y2],[x2+1,y2]])}
      {headEast(x2+(cSz*HEAD_LENGTH), y2)}
    </g>
  }

  const diag_up_east = () => {
    const x0 = sq.x1-cSz*OFFSET
    const y0 = sq.y2+cSz*OFFSET
    const x1 = sq.x1+cSz*OFFSET
    const x2 = sq.x1+cSz*(OFFSET+L2)
    const y1 = sq.y2-cSz*OFFSET
    const y2 = sq.y2 - cSz*L1

    return <g>
      {polyLine([[x0, y0],[x1, y1],[x1,y2],[x2+1,y2]])}
      {headEast(x2+(cSz*HEAD_LENGTH), y2)}
    </g>
  }
  const diag_right_east = () => {
    const x0 = sq.x1-cSz*OFFSET
    const y0 = sq.y1-cSz*OFFSET
    const x1 = sq.x1+cSz*OFFSET
    const x2 = sq.x1+cSz*(OFFSET+L2)
    const y1 = sq.y1+cSz*OFFSET
    const y2 = sq.y1 + cSz*L1

    return <g>
      {polyLine([[x0, y0],[x1, y1],[x1,y2],[x2+1,y2]])}
      {headEast(x2+(cSz*HEAD_LENGTH), y2)}
    </g>
  }

  const diag_right_down = () => {
    const x0 = sq.x1-cSz*OFFSET
    const y0 = sq.y1-cSz*OFFSET
    const x1 = sq.x1+cSz*OFFSET
    const x2 = sq.x1+cSz*(OFFSET+L2)
    const y1 = sq.y1+cSz*OFFSET
    const y2 = sq.y1 + cSz*L1

    return <g>
      {polyLine([[x0, y0],[x1, y1],[x2,y1],[x2,y2+1]])}
      {headSouth(x2, y2+(cSz*HEAD_LENGTH))}
    </g>
  }

  const diag_left_south = () => {
    const x0 = sq.x1 + cSz + cSz*OFFSET
    const y0 = sq.y1-cSz*OFFSET
    const x1 = sq.x1+ cSz - cSz*OFFSET
    const x2 = sq.x1+ + cSz - cSz*(OFFSET+L2)
    const y1 = sq.y1+cSz*OFFSET
    const y2 = sq.y1 + cSz*L1

    return <g>
      {polyLine([[x0, y0],[x1, y1],[x2,y1],[x2,y2 + 1]])}
      {headSouth(x2, y2+(cSz*HEAD_LENGTH))}
    </g>
  }

  const down_right_points = () => {
    return {
      x1: sq.x1+cSz*OFFSET,
      x2: sq.x1+cSz*(OFFSET+L2),
      y1: sq.y1,
      y2: sq.y1 + cSz*L1
      }
  }

  const down_right = () => {
    const {x1,x2,y1,y2} = down_right_points()

    return <g>
      {polyLine([[x1, y1],[x1,y2],[x2+1,y2]])}
      {headEast(x2+(cSz*HEAD_LENGTH), y2)}
    </g>
  }

  const dynamic_down_right = (sourceSq) => {
    const {x1,x2,y2} = down_right_points()
    const y1 = sourceSq.y2

    return <g>
      {dashedPolyLine([[x1, y1],[x1,y2],[x2+1,y2]])}
      {headEast(x2+(cSz*HEAD_LENGTH), y2)}
    </g>
  }

  const dynamic_down = (xOver, sourceSq) => {
    const x = xOver.x1 + (xOver.x2-xOver.x1)/2
    return <g>
      {dashedPolyLine([[x, sourceSq.y2],[x,sq.y1]])}
      {headSouth(x, sq.y1+(cSz*HEAD_LENGTH))}
    </g>
  }

  const up_right = () => {
    const x1 = sq.x1+cSz*OFFSET
    const x2 = sq.x1+cSz*(OFFSET+L2)
    const y1 = sq.y2
    const y2 = sq.y2 - cSz*L1

    return <g>
      {polyLine([[x1, y1],[x1,y2],[x2+1,y2]])}
      {headEast(x2+(cSz*HEAD_LENGTH), y2)}
    </g>
  }



  const t_right_down = () => {
    const x1 = sq.x1 + cSz*I_OFFSET
    const x2 = sq.x1 + cSz*(I_OFFSET + L1)
    const y1 = sq.y1+cSz*OFFSET
    const y2 = sq.y1+cSz*(OFFSET+L2)

    return <g>
      {polyLine([[x1, y1],[x2,y1],[x2,y2]])}
      {headSouth(x2, y2+(cSz*HEAD_LENGTH))}
    </g>
  }

  const t_down_right = () => {
    const x1 = sq.x1+cSz*OFFSET
    const x2 = sq.x1+cSz*(OFFSET+L2)
    const y1 = sq.y1 + cSz*I_OFFSET
    const y2 = sq.y1 + cSz*(I_OFFSET + L1)

    return <g>
      {polyLine([[x1, y1],[x1,y2],[x2,y2]])}
      {headEast(x2+(cSz*HEAD_LENGTH), y2)}
    </g>
  }

  const mini_north = () => {
    return <g>
      {miniHeadNorth(sq.x1+cSz/2, sq.y2 - cSz*MINI_HEAD_LENGTH)}
    </g>
  }

  const mini_south = () => {
    return <g>
      {miniHeadSouth(sq.x1+cSz/2, sq.y1 + cSz*MINI_HEAD_LENGTH)}
    </g>
  }

  const annot_down = () => {
    return <g>
      {largeHeadSouth(sq.x1+cSz/2, sq.y1 + cSz*LARGE_HEAD_LENGTH)}
    </g>
  }

  const annot_right = () => {
    return <g>
      {largeHeadEast(sq.x1 + cSz*LARGE_HEAD_LENGTH, sq.y1+cSz/2)}
    </g>
  }

  const mini_west = () => {
    const partPart = sourceType === UPPER ? 0 : 1
    let sourceSq = overlaySq || (matrix[source.y][source.x].e.sq.parts ? matrix[source.y][source.x].e.sq.parts[partPart] : matrix[source.y][source.x].e.sq)
    const yOver = yOverlap(sourceSq, sq)
    return <g>
      {miniHeadWest(sq.x2 - cSz*MINI_HEAD_LENGTH, yOver.y1+(yOver.y2 - yOver.y1)/2)}
    </g>
  }

  const mini_east = (overlap) => {
    if (!source) {
      return <g>
        {miniHeadEast(sq.x1 + cSz*MINI_HEAD_LENGTH, sq.y1+cSz/2)}
      </g>
    }
    let yOver
    if (!overlap) {
      const partPart = sourceType === UPPER ? 0 : 1
      let sourceSq = overlaySq || (matrix[source.y][source.x].e.sq.parts ? matrix[source.y][source.x].e.sq.parts[partPart] : matrix[source.y][source.x].e.sq)
      yOver = yOverlap(sourceSq, sq)
    } else {
      yOver = overlap
    }

    return <g>
      {miniHeadEast(sq.x1 + cSz*MINI_HEAD_LENGTH, yOver.y1+(yOver.y2 - yOver.y1)/2)}
    </g>
  }

  if (!type)
    return
  const arrowType = getType()
  let arrowElem = null
  if (arrowType === LINK.dynamic) {
    arrowElem = dynamic()
  } else if (arrowType.startsWith(LINK.right_down))
    arrowElem = right_down()
  else if (arrowType.startsWith(LINK.left_down))
    arrowElem = left_down()
  else if (arrowType.startsWith(LINK.down_right))
    arrowElem = down_right()
  else if (arrowType.startsWith(LINK.up_right))
    arrowElem = up_right()
  else if (arrowType.startsWith(LINK.t_right_down))
    arrowElem = t_right_down()
  else if (arrowType.startsWith(LINK.t_down_right))
    arrowElem = t_down_right()
  else if (arrowType.startsWith(LINK.mini_south))
    arrowElem = mini_south()
  else if (arrowType.startsWith(LINK.mini_north))
    arrowElem = mini_north()
  else if (arrowType.startsWith(LINK.mini_east))
    arrowElem = mini_east()
  else if (arrowType.startsWith(LINK.annot_down))
    arrowElem = annot_down()
  else if (arrowType.startsWith(LINK.annot_right))
    arrowElem = annot_right()
  else if (arrowType.startsWith(LINK.diag_right_east))
    arrowElem = diag_right_east()
  else if (arrowType.startsWith(LINK.diag_right_down))
    arrowElem = diag_right_down()
  else if (arrowType.startsWith(LINK.diag_left_south))
    arrowElem = diag_left_south()
  else if (arrowType.startsWith(LINK.diag_up_east))
    arrowElem = diag_up_east()
  else if (arrowType.startsWith(LINK.no_arrow))
    arrowElem = null
  else
    console.error(type)

  if (!frame) {
    return arrowElem
  } else {
    return <>
      {arrowElem}
      <rect x={sq.x1} y={sq.y1} width={sq.x2-sq.x1} height={sq.y2-sq.y1} fill={"none"} stroke="black"></rect>
    </>
  }
}

export const MLink = React.memo(Link)
