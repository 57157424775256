import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Auth} from "aws-amplify";
import { useSelector } from 'react-redux';
import {Navigate, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Alert} from "@mui/material";

function Copyright(props) {
  return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.burnessonikvadrat.se">
          Burnesson i kvadrat
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const theme = createTheme();

export default function RecoverPswd() {

  const [showCode, setShowCode] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const user = useSelector((state) => state.user)

  const navigate = useNavigate();

  useEffect(() => {
    console.log("signin useEffect")
    // Update the document title using the browser API
    //Auth.currentUserInfo().then(console.log)
    //Auth.currentCredentials().then(console.log)
    //Auth.currentSession().then(console.log)
    const checkLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        console.log(user)
        //dispatch(userLoggedIn(user.username))
        navigate("/dashboard")
      } catch(err) {
        console.error(err)
      }
    }
    checkLoggedIn()
  }, [navigate]);

  const handleSubmit = (event) => {
    setErrorMessage()
    event.preventDefault()
    const data = new FormData(event.currentTarget);

    if (showCode) {
      console.log("Collect confirmation code and new password, then")
      const userName = data.get('username')
      const code = data.get('code')
      const password = data.get('password')
      console.log(`${userName} ${code} ${password}`)
      Auth.forgotPasswordSubmit(userName, code, password)
          .then((data) => {
            console.log(data)
            Auth.signIn(userName, password).then(user => {
              console.log(user)
              Auth.currentCredentials().then(credentials => {
                console.log(credentials)
                //dispatch(userLoggedIn(user))
                navigate("/dashboard")
              })
            }).catch(error=>{
              console.error(error)
              setErrorMessage(error.message)
            });
          })
          .catch(error =>{
            console.error(error)
            setErrorMessage(error.message)
          });
    } else {
      console.log("Send confirmation code to user's email")
      console.log(`${data.get('username')}`)
      Auth.forgotPassword(data.get('username'))
          .then((data) => console.log(data))
          .then(()=>{setShowCode(true)})
          .catch((err) => {
            setErrorMessage(err.message)
            console.log(err)
          });
    }
  };

  console.log("Signin render")

  if (user?.username) {
    return <Navigate replace to="/dashboard" />;
  }

  return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Recover password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="off"
                  autoFocus
                  type="text"
              />
              {showCode && <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  name="code"
                  autoComplete="number"
                  autoFocus
                  type="number"
              />
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
              />
              </>

              }

              {/*              <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
              />*/}
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
              >
                {showCode ? "Submit" : "Send"}
              </Button>
              {errorMessage && <Alert severity="error">{JSON.stringify(errorMessage, null, 2)}</Alert>}
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
  );
}
