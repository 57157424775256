import {Button, FormControl, InputLabel, MenuItem, Modal, Select} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import {SINGLE_ENTRIES, THREE_IN_TOP} from "../routes/streams/streamTemplates";

export default function CreateStreamModal({open, handleClose, onPicked, title}) {
  const [textValue, setTextValue] = useState("")
  const [streamType, setStreamType] = useState(SINGLE_ENTRIES)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "scroll"
  };

  const handleChange = (event) => {
    setStreamType(event.target.value)
  };

  return <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="text-input-modal-title"
      aria-describedby="text-input-modal-description"
  >
    <Box sx={style}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
            value={streamType}
            onChange={handleChange}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Type"
        >
          <MenuItem value={SINGLE_ENTRIES}>{SINGLE_ENTRIES}</MenuItem>
          <MenuItem value={THREE_IN_TOP}>{THREE_IN_TOP}</MenuItem>
        </Select>
      </FormControl>
      <TextField
          id="text-input-modal-text-input-id"
          label="Title"
          variant="standard"
          value={textValue}
          onChange={(event)=>{
            setTextValue(event.target.value)
          }}
      />
      <Button onClick={()=>{
        onPicked(textValue, streamType)
        handleClose()
      }}>Ok</Button>
    </Box>
  </Modal>
}
