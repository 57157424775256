import {FormControl} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function TextEntryNumberInput({idPart, label, getSize, updateXElem, drawerWidth, elem}) {
    const id = `${idPart}_demo-simple-select-autowidth`
    const lbl = `${id}-label`
    return <FormControl key={`${idPart}_imgFrmCntrl`} sx={{ m: 1, width: (drawerWidth-40) }}>
        <TextField
            sx={{fontSize: 8}}
            labelId={lbl}
            id={id}
            value={getSize(elem)}
            onChange={(e) => {
                console.log(e.target.value)
                updateXElem(newElem => {
                    newElem[idPart] = e.target.value
                })
            }}
            label={label || idPart}
            type={"number"}
            InputProps={{ inputProps: { min: 0, max: 40, step: 0.1 } }}
        />
    </FormControl>
}
