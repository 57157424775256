import React, { memo } from "react";
import Button from '@mui/material/Button';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';

export const NewButton = memo(({ disabled = [], onClick = ()=>{} }) => {
    return (
        <Button
            startIcon={<InsertDriveFileOutlined />}
            onClick={onClick}
            disabled={disabled}
        >
            New
        </Button>
    );
});
