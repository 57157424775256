import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {Auth} from "aws-amplify";
import {userLoggedIn} from "../../features/users/userSlice";
import {deleteKeys, getUserRights, putKeys, queryKeys} from "../../backend";
import {InputBase, ListItem, ListItemButton} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CommitTextArea from "./CommitTextArea";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

let lastScroll =0

export default function KeysPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const searchParams = useSearchParams()[0]
    const [uId, setUid] = useState()
    const [keyWords, setKeyWords] = useState([])
    const [keys, setKeys] = useState([])
    const [response, setResponse] = useState()
    const [keyFilter, setKeyFilter] = useState()
    const [missingWord, setMissingWord] = useState()
    const [currentWord, setCurrentWord] = useState()
    const [offset, setOffset] = useState(0)
    const textRef = useRef()
    const keyRef = useRef()

    useEffect(() => {
        const checkLoggedIn = async () => {
            const user = await Auth.currentAuthenticatedUser()
            dispatch(userLoggedIn(user))
            const credentials = await Auth.currentCredentials()
            if (credentials.identityId) {
                const {identityId} = credentials
                const assume = searchParams.get("uId")
                const userRights = await getUserRights(identityId)
                if (userRights) {
                    console.log(userRights)
                    if (!!assume && identityId !== assume && userRights.xRole === "admin") {
                        setUid(assume)
                        return `Assuming user ${assume}`
                    }
                }
                setUid(credentials.identityId)
                return `Logged in as user ${credentials.identityId}`
            }
        }
        checkLoggedIn()
            .then(console.log)
            .catch(err=>{
                console.error(err)
                navigate("/")
            })
    }, [dispatch, navigate, searchParams]);

    const searchKeyWords = async () => {
        setMissingWord()
        setCurrentWord()
        setKeys([])
        const begins_with = textRef.current.value.toUpperCase()
        const response = await queryKeys(uId, begins_with)
        console.log(response);
        const words = response.Items?.map(item => item.xWord)
        setResponse(response)
        setKeyWords(words)
        if (begins_with.length && words[0] !== begins_with) {
            setMissingWord(begins_with)
        }
        if (words.length > 0) {
            setKeys(response?.Items[0].keys)
            setCurrentWord(words[0])
        }
    }

    const updateKeyFilter = async () => {
        setKeyFilter(keyRef.current.value.toUpperCase())
    }

    const onAddWord = (wrd) => {
        console.log(`onAddWord ${wrd}`)
        setMissingWord()
        setCurrentWord(wrd)
        const newKeys = ["???", ...keys]
        setKeys(newKeys)
        if (missingWord === wrd) {
            setKeyWords([wrd, ...keyWords])
            const newResponse = {...response}
            newResponse.Items?.push({
                xWord: wrd,
                keys: newKeys
            })
        }
    }

    const slicedKeywords = keyWords.length > 100 ? keyWords.slice(offset, 100+offset) : keyWords
    return (
        <Container sx={{backgroundColor: "orange", minHeight: "110vh", paddingTop: "10px"}}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Item>
                            <Paper
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                            >
                                <InputBase
                                    inputRef={textRef}
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search Words"
                                    inputProps={{ 'aria-label': 'search keys' }}
                                    onKeyDown={(e)=>{
                                        if (e.key === 'Enter') {
                                            searchKeyWords()
                                        }
                                    }}
                                />
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={searchKeyWords}>
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </Item>
                    </Grid>
                    <Grid item xs={7}>
                        <Item>
                            <Paper
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                            >
                                <InputBase
                                    inputRef={keyRef}
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Filter keys"
                                    inputProps={{ 'aria-label': 'search keys' }}
                                    onKeyDown={(e)=>{
                                        if (e.key === 'Enter') {
                                            updateKeyFilter()
                                        }
                                    }}
                                />
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={updateKeyFilter}>
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </Item>
                    </Grid>
                    <Grid item xs={5}>
                        {(missingWord || keyWords.length > 0) && <Item><List style={{maxHeight: "80vh", overflow: "auto"}} onScroll={(event)=>{
                            if (keyWords.length > 100) {
                                const UP = event.target.scrollTop < lastScroll
                                console.log(`${UP} ${offset} ${event.target.scrollTop} ${Math.abs(event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop)}`)
                                if (!UP && Math.abs(event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop) < 1) {
                                    console.log("user is at the end of the list so load more items")
                                    setOffset(offset+10)
                                } else if (UP && event.target.scrollTop < 100) {
                                    setOffset(Math.max(0, offset-10))
                                }
                                lastScroll = event.target.scrollTop
                            }
                        }}>
                            {missingWord && <ListItem key={missingWord}>
                                <ListItemButton onClick={()=>{onAddWord(missingWord)}}>
                                    <ListItemText>{missingWord}</ListItemText>
                                    <ListItemIcon style={{color: "lightcyan"}}>
                                        <AddCircleOutlineIcon />
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>}
                            {slicedKeywords.map(wrd => (<ListItem
                                key={`${wrd}`}
                                style={{
                                    backgroundColor: wrd === currentWord ? "lightgrey" : "white"
                                }}
                            >
                                <ListItemButton onClick={()=>{
                                    console.log(`Clicked keyWord ${wrd}`)
                                    setKeys(response?.Items.filter(item => item.xWord === wrd)?.[0].keys)
                                    setCurrentWord(wrd)
                                }}>
                                    <ListItemText>
                                        {wrd}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>))}
                        </List></Item>}
                    </Grid>
                    <Grid item xs={7}>
                        {currentWord && <>
                            <Item>
                                <IconButton onClick={()=>{onAddWord(currentWord)}}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                                {currentWord}
                            </Item>
                            <Item>
                                <List  style={{maxHeight: "80vh", overflow: "auto"}}>
                                    {keys
                                        .filter(key => !keyFilter || key.indexOf(keyFilter) !== -1)
                                        .map(key => {
                                            return <ListItem key={key}>
                                                <CommitTextArea
                                                    theKey={key}
                                                    onDelete={async ()=>{
                                                        console.log(`delete ${key}`)
                                                        const newKeys = [...keys]
                                                        if (newKeys.length === 1) {
                                                            // This is the last key. Remove the whole word
                                                            setResponse({...response, Items: response.Items.filter(newItem => newItem.xWord !== currentWord)})
                                                            setKeys([])
                                                            setCurrentWord()
                                                            setKeyWords(keyWords.filter(w => w !== currentWord))
                                                            const deleteResult = await deleteKeys(uId, currentWord)
                                                            console.log(deleteResult)
                                                        } else {
                                                            const keyIndex = keys.indexOf(key)
                                                            const newKeys = [...keys]
                                                            newKeys.splice(keyIndex, 1)
                                                            setKeys(newKeys)
                                                            const putResult = await putKeys(uId, currentWord, newKeys)
                                                            console.log(putResult)
                                                        }
                                                    }}
                                                    onSave={async (newValue)=>{
                                                        console.log(`save ${key}`)
                                                        const index = keys.indexOf(key)
                                                        const newKeys = [...keys]
                                                        newKeys[index] = newValue.toUpperCase()
                                                        setKeys(newKeys)
                                                        const newResponse = {...response}
                                                        newResponse.Items = newResponse.Items.map(item => {
                                                            const newItem= {...item}
                                                            if (newItem.xWord === currentWord) {
                                                                newItem.keys= newKeys
                                                            }
                                                            return newItem
                                                        })
                                                        setResponse(newResponse)
                                                        //setKeyWords([missingWord, ...keyWords])
                                                        const putResult = await putKeys(uId, currentWord, newKeys)
                                                        console.log(putResult)
                                                    }}
                                                ></CommitTextArea>
                                            </ListItem>
                                        })}
                                </List>
                            </Item>
                        </>}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
