import {SvgIcon, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {TEXT} from "../../cellTypes";

export default function SplitTextBtn({onClick, focusedElement}) {
    const lineY = 12

    if (focusedElement?.type !== TEXT)
        return null
    if (focusedElement.parts)
        return null

    return <Tooltip title={"split"}>
        <IconButton key={`overlay_list_btn_split`} onClick={onClick}>
            <SvgIcon width={30} height={30}>
                <rect x={1} y={1} width={22} height={22} fill={"none"} stroke="black"></rect>
                <line x1={1} x2={22} y1={lineY} y2={lineY} strokeWidth={0.5} stroke="black"/>
            </SvgIcon>
        </IconButton>
    </Tooltip>
}
