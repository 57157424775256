import * as React from "react";
import {HZ, LEFT, LOWER, RIGHT, UPPER, VRT} from "../cellTypes";

export default function Cursor(props) {
  const { selection, wordmarker, matrix, focusRect, direction, yPatternOffset} = props
  const { clickedObj, endObj } = selection || {}
  const { e, part: p} = clickedObj
  const sq = e.sq

  const renderMarkerPotentiallSplit = () => {
    if (!wordmarker || (endObj && endObj !== clickedObj))
      return

    if (yPatternOffset) {
      if (direction === VRT) {
        for (let i = 1; i < wordmarker.length; i++) {
          if (wordmarker[i].sq.y1 < wordmarker[i-1].sq.y1) {
            return [renderMarker(wordmarker.slice(0, i)), renderMarker(wordmarker.slice(i))]
          }
        }
      }
      if (direction === HZ) {
        for (let i = 1; i < wordmarker.length; i++) {
          if (wordmarker[i].sq.x1 < wordmarker[i-1].sq.x1) {
            return [renderMarker(wordmarker.slice(0, i)), renderMarker(wordmarker.slice(i))]
          }
        }
      }

    }

    return renderMarker(wordmarker)


  }

  const renderMarker = (wordmarker) => {
    const upperLeft = {x: wordmarker[0].sq.x1, y: wordmarker[0].sq.y1}
    const upperRights = wordmarker.map(wm => ({x: wm.sq.x2, y: wm.sq.y1}))
    const lowerRight = {x: wordmarker[wordmarker.length-1].sq.x2, y: wordmarker[wordmarker.length-1].sq.y2}
    const lowerLefts = [...wordmarker].reverse().map(wm => ({x: wm.sq.x1, y: wm.sq.y2}))

    const path = [upperLeft, ...upperRights, lowerRight, ...lowerLefts]
        .reduce((acc, curr) => {
          acc.push(`${acc.length === 0 ? "M" : "L"}${curr.x} ${curr.y}`)
          return acc
        }, []);
    path.push(` Z`)
    return <path d={path.join(" ")} fill="none" stroke="lightblue" fillOpacity="0.1" strokeWidth={3}/>
  }

  const renderFocusArea = () => {
    return focusRect && <rect x={focusRect.x1} y={focusRect.y1} width={focusRect.x2 - focusRect.x1} height={focusRect.y2-focusRect.y1} fill="none" stroke="pink" fillOpacity="0.1" strokeWidth={3}/>
  }

  const renderSelectedCell = () => {
    if (!clickedObj)
      return null

    const animation = <animate
        attributeName="rx"
        values="0;5;0"
        dur="10s"
        repeatCount="indefinite" />

    const getOverlayPosition = o => {
      if (o.overlayPosition === UPPER)
        return "ot"
      if (o.overlayPosition === LOWER)
        return "otl"
      if (o.overlayPosition === LEFT)
        return "otLeft"
      if (o.overlayPosition === RIGHT)
        return "otRight"
      return "e"
    }
    const overlay =  matrix[e.rect.y][e.rect.x][getOverlayPosition(e)]
    const selectedSquare =overlay.sq

    let selected = <rect x={selectedSquare.x1} y={selectedSquare.y1} width={selectedSquare.x2-selectedSquare.x1} height={selectedSquare.y2-selectedSquare.y1} fill={"none"} stroke="pink" strokeWidth={3} fillOpacity="0.1">
      {animation}
    </rect>

    if (p != null &&sq.parts?.[p] && !endObj) {
        const part = sq.parts?.[p]
        selected = <rect x={part.x1} y={part.y1} width={part.x2-part.x1} height={part.y2-part.y1} fill={"none"} stroke={"pink"} fillOpacity="0.1" strokeWidth={3}>
          {animation}
        </rect>
    }

    return <>
      {renderMarkerPotentiallSplit()}
      {renderFocusArea()}
      {selected}
    </>
  }

  return renderSelectedCell()

}
