import * as React from "react";
import Texts from "./svgtexts";
import Letters from "./svgletters";
import Rects from "./svgrects";
import Dividers from "./svgdividers";
import Plates from "./svgplates";
import Images from "./svgimages";
import Arrows from "./svgarrows";
import Links from "./svglinks";
import Interactions from "./interactions"
import Frames from "./svgframes";
import {MParagraphstyle} from "./paragraphstyle";
import BGImages from "./svgbgimages";
import OverlayArrows from "./svgoverlayarrows";
import OverlayTextLinks from "./svgoverlaytextlinks";
import {paragraphStyle} from "./defaultParagraphStyle";
import Byline from "./svgbyline";
import {getDynamicFrameLines} from "../xutils";
import Wordfontloader from "./wordfontloader";
import SvgDoc from "./svgdoc";
import SvgChaos from "./SvgChaos";

export default function Crossword (props) {
  const { x,
    iDs = {},
    cursor,
    clickListener,
    mouseEnterListener,
    mouseDownListener,
    mouseUpListener,
    doubleClickListener,
    print,
    matrix,
    stream,
    dragMode,
    scale,
    hide,
    doScale = 1,
    chaosState,
    mouseOutsideListener,
    completedwords,
    onSvgClick,
    renderPattern,
  } = props

  const {xId, uId} = iDs

  if (hide)
    return

  const pStyle = x.paragraphStyle || paragraphStyle

  const cSz = pStyle?.squareSize || 28.34645669
  x.elements.map(e=>e.rect);
  const imageElements = x.elements.filter(e => e.type === "IMAGE")
  const bgImageElements = x.bgImages?.filter(e=>e.overlay !== true)
  const overlayImageElements = x.bgImages?.filter(e=>e.overlay === true)
  const overlayArrowElements = x.bgImages?.filter(e=>e.overlay && e.annot)

  const fontSizes = pStyle?.text?.fontSizes?.map(f => {
    return {
      nbr: parseInt(f.nbr),
      sz: f.sz,
      leading: f.leading,
    }
  })

  let fc = (renderPattern && x?.yPatternOffset) ? 0 : (x.style?.frame?.thickness || 1)

  const bylineSpace = (x.style?.byline || x.style?.imageByline) ? 8*1.5 : 0
  const width = (cSz*x.w + fc)*doScale
  const height = (cSz*x.h + bylineSpace + fc)*doScale

  let polyLines
  let lowerLeft
  let lowerRight
  if (x.style?.frame?.type === "irregular") {
    polyLines = getDynamicFrameLines(matrix, cSz)
    polyLines.forEach(line => {
      line.forEach(point => {
        if (!lowerLeft) {
          lowerLeft = lowerRight = point
          return
        }
        if (point.y > lowerLeft.y || (point.y === lowerLeft.y && point.x < lowerLeft.x)) {
          lowerLeft = point
        }
        if (point.y > lowerRight.y || (point.y === lowerRight.y && point.x > lowerRight.y)) {
          lowerRight = point
        }
      })
    })
  }

  const renderTheSvg = (noViewBox) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={x.doc?.content.pt.width || width}
            height={x.doc?.content.pt.height || height}
            viewBox={noViewBox ? undefined : (x.doc ? `${-x.doc.puzzle.pt.left} ${-x.doc.puzzle.pt.top} ${x.doc.content.pt.width} ${x.doc.content.pt.height}` : `${-fc/2} ${-fc/2} ${cSz*x.w+fc} ${cSz*x.h+fc+bylineSpace}`)} // Viewbox is needed to not clip the frame
            onClick={(e)=>{
              onSvgClick?.()
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{touchAction: "none"}}
            onPointerLeave={mouseOutsideListener}
            id="theX"
        >
          {x.doc && <SvgDoc doc={x.doc} chaosInfo={x.chaosInfo} chaosState={chaosState} completedwords={completedwords}/>}
          {!print && <Wordfontloader x={x} xId={xId} uId={uId} stream={stream}/>}
          <BGImages cSz={cSz} elements={bgImageElements} iDs={iDs} print={print} stream={stream} layer="bgImageLayer"/>
          <Images cSz={cSz} elements={imageElements} iDs={iDs} print={print} stream={stream}/>
          <Plates cSz={cSz} elements={x.elements.filter(e => e.type !== "IMAGE" && e.type !== "GHOST")} matrix={matrix} overlayTexts={x.overlayTexts}/>
          <OverlayArrows cSz={cSz} elements={overlayArrowElements} overlayTexts={x.overlayTexts}/>
          <BGImages cSz={cSz} elements={overlayImageElements} iDs={iDs} print={print} stream={stream} layer="overlayImageLayer" fontSizes={fontSizes} dragMode={dragMode} scale={scale}/>
          {!print && cursor}
          <Dividers elements={x.elements.filter(e => e.parts)} cSz={cSz} />
          <Letters paragraphStyle={pStyle.text} cSz={cSz} elements={x.elements.filter(e => e.type === "WORD" || e.type === "SPECIAL")} wordFont={x.wordFont}/>
          <Texts paragraphStyle={pStyle.text} cSz={cSz} elements={x.elements.filter(e => e.type === "TEXT")} matrix={matrix} overlayTexts={x.overlayTexts}/>
          <Arrows cSz={cSz} elements={x.elements.filter(e => e.type === "ARROW")} overlayTexts={x.overlayTexts} arrowColor={x.arrowColor} arrCmyk={x.arrCmyk}/>
          <OverlayTextLinks cSz={cSz} overlayTexts={x.overlayTexts?.filter(ot => ot.links)} matrix={matrix}/>
          <Links cSz={cSz} links={x.links} matrix={matrix}/>
          <Rects cSz={cSz} elements={x.elements.filter(e=>e.type !== "GHOST" && !e.noLine)} overlayTexts={x.overlayTexts} matrix={matrix}/>
          <Frames cSz={cSz} polylines={polyLines} frame={x.style?.frame} imageElements={imageElements} xw={x.w} xh={x.h}/>
          {x.chaosInfo && <SvgChaos chaosInfo={x.chaosInfo} cSz={cSz} chaosState={chaosState}/>}
          {!print && !dragMode && <Interactions
              cSz={cSz}
              elements={x.elements}
              clickListener={clickListener}
              doubleClickListener={doubleClickListener}
              mouseEnterListener={mouseEnterListener}
              mouseDownListener={mouseDownListener}
              mouseUpListener={mouseUpListener}
              overlayTexts={x.overlayTexts}
              matrix={matrix}
          />}
          {!print && <MParagraphstyle iDs={iDs} paragraphStyle={pStyle} stream={stream}></MParagraphstyle>}
          {(!x.doc || x.bylineInPdf) && <Byline lowerLeft={lowerLeft} lowerRight={lowerRight} paragraphStyle={pStyle.text} cSz={cSz} byline={x.style?.byline} imageByline={x.style?.imageByline} xw={x.w} xh={x.h}></Byline>}
        </svg>

    )
  }

  if (x.yPatternOffset && renderPattern) {
    const svgWidth = x.doc?.content.pt.width || width
    const svgHeight = x.doc?.content.pt.height || height
    const repetitions = x.h/x.yPatternOffset
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={svgWidth*repetitions}
        height={svgHeight}
        viewBox={x.doc ? `${-x.doc.puzzle.pt.left} ${-x.doc.puzzle.pt.top} ${x.doc.content.pt.width} ${x.doc.content.pt.height}` : `${0} ${- (x.h-x.yPatternOffset)*cSz} ${svgWidth*repetitions} ${svgHeight}`} // Viewbox is needed to not clip the frame
        onClick={(e)=>{
          onSvgClick?.()
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{touchAction: "none"}}
        onPointerLeave={mouseOutsideListener}
    >

      {renderTheSvg(true)}

      {[0,1].flatMap(yInd => {
        return Array.from(Array(x.h/x.yPatternOffset).keys()).map(xInd => {
          const xPos = `${svgWidth*xInd}`
          const yPos = `${-((x.yPatternOffset)*xInd*cSz + yInd*svgHeight)}`
          return <use href="#theX" x={xPos} y={yPos} width={svgWidth} height={svgHeight}/>
        })
      })}
    </svg>
  }

  return renderTheSvg()
}
