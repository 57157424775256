import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {Refresh, RemoveCircle} from "@mui/icons-material";
import {openX} from "../../backend";
import {paragraphStyle} from "../../crossword/defaultParagraphStyle";
import {createMatrix} from "../../xutils";
import {createThumb} from "../../exportUtils";
import {Paper, Tooltip} from "@mui/material";
import React, {useState} from "react";
import Crossword from "../../crossword/svgcrossword";

export default function SingleEntry({entry, onUpdated, uId, index}) {

    const [imgError, setImgError] = useState(false)

    return <Paper key={entry.xId} sx={{margin: "10px", backgroundColor: `${imgError ? "red" : "white"}`}}>
        <Grid margin={2}>
            <h4>{entry.name}</h4>
            <Typography sx={{fontSize: "8px"}}>xId: {entry.xId}</Typography>
            {!imgError && <img
                width={"200"}
                src={`https://d31agymsqnwyv.cloudfront.net/thumbs/${entry.xId}_thumb.jpeg`}
                onError={()=>{setImgError(true)}}
                alt={entry.xId}
            />}
            <br/>
            <Tooltip title="Remove from stream">
                <IconButton onClick={()=>{
                    onUpdated(index)
                }
                }><RemoveCircle/></IconButton>
            </Tooltip>
            <Tooltip title="Refresh thumbnail"><IconButton onClick={async ()=>{
                try {
                    const x = await openX(entry.xId, uId)
                    console.log(x)
                    if (!x.paragraphStyle) {
                        console.log("Opened x with no paragraphStyle. Adding  it")
                        x.paragraphStyle = paragraphStyle
                    }
                    const matrix = createMatrix(x)
                    const printX = <Crossword
                        x={x}
                        iDs={{xId: x.xId, uId: uId}}
                        print
                        matrix={matrix}
                    ></Crossword>
                    await createThumb({uId: uId, x: x, printCrossword: printX});
                    setImgError(false)
                } catch (err) {
                    console.error(err)
                }
            }}><Refresh/>></IconButton></Tooltip>
        </Grid>
    </Paper>
}
