import * as React from "react";
import {MText, MOverlayText} from "./svgtext";

export default function Texts (props) {
  const {cSz, elements, paragraphStyle, overlayTexts, matrix} = props
  const fontSizes = paragraphStyle.fontSizes.map(f => {
    return {
      nbr: parseInt(f.nbr),
      sz: f.sz,
      leading: f.leading,
    }
  })
  return <g className="textLayer" fontFamily={paragraphStyle.font}>
    {
      elements.map((e) => {
        const overlayText = matrix[e.rect.y][e.rect.x].ot

        const textKey = e.parts?.map(p => p.text).join("_") || e.text
        const key = `x${e.rect.x}_y${e.rect.y}_w${e.rect.w||1}_h${e.rect.h||1}_${textKey}`
        return <MText
            key={key}
            id={key}
            cSz={cSz}
            e={e}
            fontSizes={ fontSizes }
            linesAbove={(overlayText && overlayText.overlayPosition === "upper" && overlayText.text.split("<br>").length) || 0}
            linesUnder={(overlayText && overlayText.overlayPosition === "lower" && overlayText.text.split("<br>").length) || 0}
            sq={e.sq}
        />
      })
    }
    {
      overlayTexts?.map(ot => {
        const textKey = ot.parts?.map(p => p.text).join("_") || ot.text
        const key = `ot_text_${ot.overlayPosition}_x${ot.rect.x}_y${ot.rect.y}_w${ot.rect.w||1}_h${ot.rect.h||1}_${textKey}`
        return <MOverlayText
            key={key}
            cSz={cSz}
            e={ot}
            fontSizes={ fontSizes }
            fontFamily={paragraphStyle.font}
            sq={ot.sq}
        />
      })
    }
  </g>
}
