import * as React from "react";
import convert from "color-convert"
import {east_right, east_straight, north_right, south_left, south_straight, west_left} from "../arrowTypes";
import {sqEqualish} from "./square";

const HEAD_BASE=0.4
const HEAD_LENGTH=0.3
const LINE_WIDTH=2

function Arrow({arrowType, sq, cSz, frame, arrowColor, arrCmyk}) {
  const w = sq.x2 - sq.x1
  const h = sq.y2 - sq.y1

  const headBase = HEAD_BASE*cSz
  const headLength = HEAD_LENGTH*cSz

  let clr = "black"

  if (arrCmyk) {
    const hex = convert.cmyk.hex(arrCmyk[0], arrCmyk[1], arrCmyk[2], arrCmyk[3])
    clr = `#${hex}`
  } else if (arrowColor) {
    clr = arrowColor
  }

  const polyLine = (arr, conf={}) => {
    const {strokeWidth = LINE_WIDTH, fill = "none"} = conf
    return <polyline stroke={clr} strokeWidth={strokeWidth} points={arr.map(arr => arr.join(",")).join(" ")} fill={fill}/>
  }
  const polygon = (arr) => {
    return <polygon stroke={"none"} points={arr.map(arr => arr.join(",")).join(" ")} fill={clr}></polygon>
  }

  const headSouth = (x, y, hL = headLength) => {
    const y1 = y-hL
    return polygon([[x-headBase/2, y1],[x+headBase/2, y1],[x, y]])
  }

  const headEast = (x, y) => {
    return polygon([[x-headLength, y-headBase/2],[x-headLength, y+headBase/2],[x, y]])
  }

  const eastRight = () => {
    const height = sq.y2 - sq.y1
    const hSqRatio = height/cSz
    let hL = headLength
    let conf = undefined
    let y1 = sq.y2-cSz/2
    if (hSqRatio < 0.7) {
      hL = h/3
      y1 = sq.y1+h/2
      if (hSqRatio < 0.3) {
        y1 = sq.y1+h/3
        conf = {strokeWidth: 1}
      }
    }

    const x1 = sq.x1
    const x2 = sq.x2-cSz/2
    let y2 = sq.y2-hL


    return <g>
      {polyLine([[x1, y1],[x2,y1],[x2,y2+1]], conf)}
      {headSouth(x2, sq.y2, hL)}
    </g>
  }

  const westLeft = () => {
    const hSqRatio = h/cSz
    let hL = headLength
    let y1 = sq.y2-cSz/2
    let conf = undefined
    if (hSqRatio < 0.7) {
      hL = h/3
      y1 = sq.y1+h/2
      if (hSqRatio < 0.3) {
        y1 = sq.y1+h/3
        conf = {strokeWidth: 1}
      }
    }

    const x1 = sq.x2
    const x2 = sq.x1+cSz/2
    let y2 = sq.y2-hL

    return <g>
      {polyLine([[x1, y1],[x2,y1],[x2,y2+1]], conf)}
      {headSouth(x2, sq.y2, hL)}
    </g>
  }

  const southLeft = () => {
    const x1 = sq.x1+cSz/2
    const x2 = sq.x2-headLength
    const y1 = sq.y1
    // If the height is not an even number of squares...
    // This should actually be done by checking the intersection of the target rect and the arrow end rect.
    // But that's a larger thing.
    const squaresCoverPercent = h/cSz
    const roundSquaresCover = Math.round(squaresCoverPercent)
    const coverDiff = Math.abs(h-roundSquaresCover*cSz)

    const y2 = sq.y2-(coverDiff > 1 ? (cSz-coverDiff) : cSz)/2

    return <g>
      {polyLine([[x1, y1],[x1,y2],[x2+1,y2]])}
      {headEast(sq.x2, y2)}
    </g>
  }

  const northRight  = () => {
    const x1 = sq.x1+(sq.x2-sq.x1)/2
    const x2 = sq.x2-headLength
    const y1 = sq.y2

    // If the height is not an even number of squares...
    // This should actually be done by checking the intersection of the target rect and the arrow end rect.
    // But that's a larger thing.
    const squaresCoverPercent = h/cSz
    const roundSquaresCover = Math.round(squaresCoverPercent)
    const coverDiff = Math.abs(h-roundSquaresCover*cSz)
    const y2 = sq.y1+(coverDiff > 1 ? (cSz-coverDiff) : cSz)/2

    return <g>
      {polyLine([[x1, y1],[x1,y2],[x2+1,y2]])}
      {headEast(sq.x2, y2)}
    </g>
  }

  const eastStraight  = () => {
    const y = sq.y1+h/2

    return <g>
      {polyLine([[sq.x1, y],[sq.x2-headLength+0.01,y]])}
      {headEast(sq.x2, y)}
    </g>
  }

  const southStraight  = () => {
    const x = sq.x1+w/2
    return <g>
      {polyLine([[x, sq.y1],[x,sq.y2-headLength+0.01]])}
      {headSouth(x, sq.y2)}
    </g>
  }

  let arrowElem = null
  if (arrowType.startsWith(east_right))
    arrowElem = eastRight()
  else if (arrowType.startsWith(west_left))
    arrowElem = westLeft()
  else if (arrowType.startsWith(south_left))
    arrowElem = southLeft()
  else if (arrowType.startsWith(north_right))
    arrowElem = northRight()
  else if (arrowType.startsWith(east_straight))
    arrowElem = eastStraight()
  else if (arrowType.startsWith(south_straight))
    arrowElem = southStraight()
  else
    console.error(`arrowType: ${arrowType} ${JSON.stringify(sq)}`)

  if (!frame) {
    return arrowElem
  } else {
    return <>
      {arrowElem}
      <rect x={sq.x1} y={sq.y1} width={sq.x2-sq.x1} height={sq.y2-sq.y1} fill={"none"} stroke="black"></rect>
    </>
  }
}

export const MArrow = React.memo(Arrow, (pp, np) => {
  return pp.id === np.id && pp.cSz === np.cSz && sqEqualish(pp.sq, np.sq)
})
