import React, {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getUserRights, openStream, saveStream} from "../../backend";
import {Auth} from "aws-amplify";
import {userLoggedIn} from "../../features/users/userSlice";
import {fetchXlist} from "../../features/xlist/xlistSlice";
import {fetchLists} from "../../features/wordlists/wordlists";
import IconButton from "@mui/material/IconButton";
import {AddCircle, Save} from "@mui/icons-material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import ThreeInTopEditor from "./ThreeInTopEditor";
import {SINGLE_ENTRIES, THREE_IN_TOP} from "./streamTemplates";
import SingleEntriesEditor from "./SingleEntriesEditor";

export default function StreamEditor() {

    const searchParams = useSearchParams()[0];
    const xListSlice=useSelector((state) => state.persistedReducer.xlist)
    const xlist = xListSlice?.entities
    const xOrders = xListSlice?.xOrders
    const xListMap = {}
    xlist?.forEach(xEntry => xListMap[xEntry.xId] = xEntry)
    xOrders.forEach(xEntry => xListMap[xEntry.xId] = {name: xEntry.xName})

    const {streamId} = useParams()
    const [uId, setUid] = useState()
    const [data, setData] = useState()
    const [isLoggedIn, setIsLoggedIn] = React.useState(false)

    useTheme();

    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        console.log("streameditor useEffect")
        const checkLoggedIn = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser()
                console.log(user)
                dispatch(userLoggedIn(user))
                const credentials = await Auth.currentCredentials()
                setUid(credentials.identityId)
                return true
            } catch(err) {
                console.error(err)
                navigate("/signin")
                return false
            }
        }
        const loggedIn = checkLoggedIn()
        setIsLoggedIn(loggedIn)
    }, [dispatch, navigate]);

    useEffect(()=>{
        if (isLoggedIn && uId) {
            dispatch(fetchXlist(uId))
            dispatch(fetchLists(uId))
        }
    },[dispatch, isLoggedIn, uId])

    useEffect(()=>{
        if (uId && uId !== searchParams.get("uId")) {
            getUserRights(uId).then((userRights)=> {
                console.log(userRights)
                const assume = searchParams.get("uId")
                if (userRights.xRole === "admin" && assume) {
                    setUid(assume)
                }
            })
        }
    }, [searchParams, uId])

    useEffect(() => {
        openStream(uId, streamId).then(setData).catch(console.error)
    }, [streamId, uId])

    console.log(data)

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container sx={{backgroundColor: "orange", minHeight: "110vh"}}>
            <Box>
                <Typography variant="h4" color="text.primary" component="div" sx={{padding: 3}}>
                    Dagens korsord
                </Typography>
                <IconButton onClick={()=>{
                    const dataCopy = {...data}
                    const newDate = new Date(new Date(data.schedule?.[0]?.start || new Date().getTime()).getTime() + 1000*60*60*24)
                    dataCopy.schedule.push({created: new Date().getTime(), start: newDate.toLocaleDateString("se"), threeTop:[{}, {}, {}]})
                    console.log(dataCopy)
                    setData(dataCopy)
                }
                }><AddCircle/></IconButton>
                <IconButton onClick={()=>{
                    saveStream(uId,streamId, data)
                }
                }><Save/></IconButton>
                <IconButton onClick={()=>{
                    function get(stream, dateString) {
                        if (stream.schedule) {
                            const preceding = stream.schedule.filter(entry => new Date(entry.start) <= new Date(dateString))
                            console.log(preceding)
                            return preceding[0]
                        } else {
                            console.log(stream)
                        }

                    }
                    const date = new Date().toISOString().split("T")[0]
                    console.log(get(data, date))
                }
                }>Testing</IconButton>

            </Box>
            {((!data?.type) || (data.type === THREE_IN_TOP)) && <ThreeInTopEditor xListMap={xListMap} data={data} uId={uId} xlist={xlist} onUpdated={setData}/>}
            {data?.type === SINGLE_ENTRIES && <SingleEntriesEditor  xListMap={xListMap} data={data} uId={uId} xlist={xlist} onUpdated={setData}/>}
        </Container>
    </LocalizationProvider>
}
