import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchOpenUrl, fetchUrl} from "../features/urlcache/urlSlice";
import Draggable from "react-draggable";

export default function BGImages ({cSz, elements, iDs, print, stream, layer, fontSizes, scale}) {
  if (!elements?.length)
    return
  return <g className={layer || "bgImageLayer"}>
    {
      elements?.map((e) => {
        return print ? <BGPrintImage
            key={`x${e.r.x}_y${e.r.y}_w${e.r.w||1}_h${e.r.h||1}`}
            r={e.r}
            cSz={cSz}
            e={e}
        /> : <MBGImage
            key={`x${e.r.x}_y${e.r.y}_w${e.r.w||1}_h${e.r.h||1}`}
            r={e.r}
            cSz={cSz}
            e={e}
            iDs={iDs}
            stream={stream}
            print={print}
            fontSizes={fontSizes}
            dragMode
            scale={scale}
        />
      })
    }
  </g>
}


const BGImage = (props) => {
  const dispatch = useDispatch()
  const {e, r, cSz, iDs, stream, fontSizes, dragMode, scale} = props
  const {xId, uId} = iDs
  const signedUrl = useSelector((state) => state.persistedReducer.urls.cache?.[e.image]?.url)

  useEffect(() => {
    const getImage = async () => {
      if (xId && e.image) {
        if (stream) {
          dispatch(fetchOpenUrl({streamName: stream, puzzleId: xId, imageName: e.image}))
        } else if (uId) {
          dispatch(fetchUrl({xId, uId, image: e.image}))
        } else {
          console.error(`Cant get image yet since: ${stream}, : ${uId}`)
        }
      } else {
        console.error(`Cant get image yet since: ${xId}, : ${e.image}`)
      }
    }
    getImage()
  }, [dispatch, e.image, uId, xId, stream])
  const {floatPnt} = e
  const x = floatPnt?.x || r.x * cSz
  const y = floatPnt?.y || r.y * cSz
  const w = floatPnt?.w || (r.w || 1) * cSz
  const h = floatPnt?.h || (r.h || 1) * cSz
  let imageTag;
  if (e.image) {
    if (e.clip) {
      imageTag = <svg x={x} y={y} width={w} height={h} viewBox={`${e.clip.x} ${e.clip.y} ${e.clip.w} ${e.clip.h}`} version="1.1">
        <image
            href={signedUrl}
        />
      </svg>
    } else {
      imageTag = <image
          x={r.x * cSz}
          y={r.y * cSz}
          width={(r.w || 1) * cSz}
          height={(r.h || 1) * cSz}
          href={signedUrl}
          preserveAspectRatio="xMinYMin slice"
      />
    }
  }


  return <>
    {imageTag}
    {e.text &&
      <ConditionalWrapper
          condition={dragMode}
          wrapper={children => (
              <Draggable
                  onDrag={(a, b)=>{
                    console.log(a)
                    console.log(b)
                  }}
                  scale={scale}
              >
                {children}
              </Draggable>
          )}
      >
        <foreignObject x={x} y={y} width={w} height={h}>
          <div xmlns="http://www.w3.org/1999/xhtml" style={{
            fontSize: fontSizes?.[fontSizes.length-2]?.sz||10,
            whiteSpace: e.whiteSpace,
            fontFamily: "tektonpro-boldobl",
            textAlign: "center center",
            lineHeight: `${fontSizes?.[fontSizes.length-2]?.leading}px`
          }}>
            {e.text}
          </div>
        </foreignObject>
      </ConditionalWrapper>
    }
  </>

}

const BGPrintImage = (props) => {
  const {e, r, cSz} = props

  if (e.clip) {

    return (<svg x={r.x * cSz}
                 y={r.y * cSz}
                 width={(r.w || 1) * cSz}
                 height={(r.h || 1) * cSz}
                 viewBox={`${e.clip.x} ${e.clip.y} ${e.clip.w} ${e.clip.h}`}
                 version="1.1"
    >
      <image
          href={e.image}
      />
    </svg>)
  } else {
    return (<image
        x={r.x * cSz}
        y={r.y * cSz}
        width={(r.w || 1) * cSz}
        height={(r.h || 1) * cSz}
        href={e.image}
        preserveAspectRatio="xMinYMin slice"
    />)
  }
}

const MBGImage = React.memo(BGImage, (pp, np) => {
  return pp.e.clip === np.e.clip && pp.e.image === np.e.image && pp.scale === np.scale
})

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;
