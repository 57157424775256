import Draggable from "react-draggable";
import {Button, Divider, Paper, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {deleteJob, getJob, getJobEvent, listJobs} from "../backend";
import Crossword from "../crossword/svgcrossword";
import {createMatrix} from "../xutils";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {Clear, PauseCircle, SkipNext, SkipPrevious, StopCircle} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import WindowHeader from "./WindowHeader";
import {useSelector} from "react-redux";

//const PIXEL_L2R = "L2R"
const PIXEL_LOWEST_CNT = "LOWEST_CNT"
//const LETTER_SORT_MOST_COMMON_LETTER_FIRST = "LETTER_SORT_MOST_COMMON_LETTER_FIRST"
//const LETTER_SORT_ALPHA = "LETTER_SORT_ALPHA"
//const LETTER_SORT_ALPHA_REVERSED = "LETTER_SORT_ALPHA_REVERSED"
const LETTER_SORT_RANDOM = "LETTER_SORT_RANDOM"

function MusicButton(props) {
  return <Button {...props} variant="contained" style={{padding: "2px", margin: "2px"}}>{props.children}</Button>
}

function ControlButton(props) {
  return <Button {...props} variant="contained" style={{margin: "2px"}}>{props.children}</Button>
}

function Job({xId, uId, jobName, onShow, onDelete}) {
  const [jobEvent, setJobEvent] = useState()
  const [jobStream, setJobStream] = useState()
  useEffect(()=>{
    getJob(xId, uId, jobName, true).then(setJobStream)
    getJobEvent(xId, uId, jobName).then(data => {
      //console.log(data)
      setJobEvent(data)
    })
  }, [xId, uId, jobName])
  return <Stack direction="row" alignItems="center" gap={1}>
    <Button variant="contained" onClick={()=>{
      //console.log(`Setting ${JSON.stringify(jobEvent)}`)
      onShow(jobEvent.x, jobStream)
    }
    }><Typography>{new Date(parseInt(jobName)).toLocaleString()}</Typography></Button>
    <IconButton onClick={
      ()=>{
        deleteJob(xId, uId, jobName).then(console.log).catch(console.error)
        onDelete?.()
      }
    }><Clear/></IconButton>
  </Stack>
}

export default function GeneratorWindow({xId, uId, tryGenerateX, stopGenerateX, area, onApply, onClose}) {

  const [jobs, setJobs] = useState()
  const [x, setX] = useState()
  const [play, setPlay] = useState()
  const [minimized, setMinimized] = useState(false)
  const [playIndex, setPlayIndex] = useState(-1)
  const [job, setJob] = useState()
  const [letterSort] = useState(LETTER_SORT_RANDOM)
  const [pickAlgo] = useState(PIXEL_LOWEST_CNT)
  const [currentJobName, setCurrentJobName] = useState()

  const [intervalTimer, setIntervalTimer] = useState()

  const volatileX = (JSON.parse(JSON.stringify(x || {})))

  const {wordMap=true} = useSelector(state => state.persistedReducer.editor)

  const stateRef = useRef();
  stateRef.intervalTimer = intervalTimer
  stateRef.wordMap = wordMap

  //console.log(playIndex)

  useEffect(()=>{
    listJobs(xId, uId).then(data => {
      //console.log(data)
      setJobs(data)
    })
  }, [uId, xId])

  useEffect(()=>{
    //console.log("Running the play useEffect")
    if (play) {
      if (playIndex < job.results.length-1) {
        setPlayIndex(playIndex+1)
      } else {
        setPlay(false)
      }
    } else {
      //console.log("Play is turned of")
    }
  }, [job, play, playIndex])

  const renderCrossword = () => {
    if (!x)
      return

    const mouseEnterListener = ()=>{}
    const mouseDownListener = ()=>{}
    const mouseUpListener = ()=>{}

    //console.log(volatileX)

    const matrix = createMatrix(volatileX)

    if (playIndex >= 0) {
      for (let pI = 0; pI <= playIndex; pI++) {
        try {
          const entry = job.results[pI]
          matrix[entry[1]][entry[0]].e.val = entry[2]
          //if previous entry was a "back down" failure. Unset that one
          /*const prevEntry = job.results[pI-1]
          if (prevEntry && prevEntry.message === "No more letters to try. Backing down.") {
            console.log("backing down")
            matrix[prevEntry.y][prevEntry.x].e.val = ""
          }*/
        } catch(err) {
          console.error(err)
        }
      }
    }
    //console.log(job.results[playIndex])
    const theCrossword = <Crossword
        x={volatileX}
        iDs={{xId: x.xId, uId: uId}}
        //clickListener={clickListener}
        mouseEnterListener={mouseEnterListener}
        mouseDownListener={mouseDownListener}
        mouseUpListener={mouseUpListener}
        matrix={matrix}
        scale={1}
        hide={false}
        doScale={0.5}
    ></Crossword>

    let steps = 1
    if (job.results.length > 10000) {
      steps = 100
    } else if (job.results.length > 1000) {
      steps = 10
    }

    return <>
      <Divider/>
      {x && <MusicButton disabled={play !== false} onClick={()=>{
        setPlay(true)
      }
      }><PlayCircleIcon/></MusicButton>}
      {x && <MusicButton disabled={play !== true} variant="contained" onClick={()=>{
        setPlay(false)
      }
      }><PauseCircle/></MusicButton>}
      {x && <MusicButton
          disabled={play !== false}
          variant="contained"
          onClick={()=>{
            setPlayIndex(playIndex+1)
          }}
          onDoubleClick={()=>{
            setPlayIndex(Math.min(playIndex+100, job.results.length-1))
          }}
      ><SkipNext/></MusicButton>}
      {x && <MusicButton disabled={play !== false} variant="contained" onClick={()=>{
        if (playIndex < job.results.length-1) {
          setPlayIndex(playIndex-1)
        }
      }
      }><SkipPrevious/></MusicButton>}
      {x && <MusicButton variant="contained" onClick={()=>{
        setPlay(false)
        setPlayIndex(-1)
      }
      }><StopCircle/></MusicButton>}
      {job?.results && <Slider
          aria-label="Steps"
          value={playIndex}
          getAriaValueText={()=> x?.name}
          valueLabelDisplay="auto"
          step={steps}
          marks
          min={-1}
          max={job.results.length-1}
          onChange={(e, value)=>{
            setPlayIndex(value)
          }}
      />}
      <Divider/>
      <Typography>{new Date(parseInt(currentJobName)).toLocaleString()} <div style={{fontSize: "6px"}}>({currentJobName})</div></Typography>
      {theCrossword}
    </>
  }

  const reversedJobs = jobs && [...jobs].reverse()

  const onShowStream = (jobId, eventX, jobStream) => {
    //console.log("onShowStream")
    //console.log(jobId)
    //console.log(eventX)
    //console.log(jobStream)
    setCurrentJobName(jobId)
    setJob(jobStream)
    setX(eventX)
    setPlay(false)
    setPlayIndex(jobStream.results.length-1)
    //console.log("onShowStream done")
  }

  const tryAutoStart = async () => {
    let newJob
    let newJobStream
    let newJobEvent
    let started = false
    setIntervalTimer(setInterval(async () => {
      if (!newJob) {
        //console.log("Fetching newJob")
        const jobCheck = await listJobs(xId, uId)
        //console.log(jobCheck)
        if (jobs?.length !== jobCheck.length) {
          newJob = jobCheck[jobCheck.length - 1]
        }
      }
      if (!newJobEvent && newJob) {
        //console.log("Fetching jobEvent")
        newJobEvent = await getJobEvent(xId, uId, newJob)
        //console.log(newJobEvent)
      }
      if (!newJobStream && newJob) {
        //console.log("Fetching jobStream")
        newJobStream = await getJob(xId, uId, newJob, true)
        //console.log(newJobStream)
      }

      if (newJobEvent && newJobStream && newJob) {
        if (!started) {
          onShowStream(newJob, newJobEvent.x, newJobStream)
          started = true
        } else {
          setPlay(true)
          const updatedStream = await getJob(xId, uId, newJob, true)
          setJob(updatedStream)
        }
      }

    }, 1000))
    setTimeout(()=>{
      //console.log("Clearing intervalTimer")
      //console.log(stateRef.intervalTimer)
      clearInterval(stateRef.intervalTimer)
      setIntervalTimer()
    }, 15000)
  }

  return <Draggable bounds="parent" handle="strong">
    <Paper
        elevation={3}
        style={{
          position: "absolute",
          left: "50%",
          top: "0%",
          zIndex: 9999999}}>
      <WindowHeader title="Weave" onClose={onClose} onMinimize={()=>setMinimized(true)} onMaximize={()=>setMinimized(false)}/>
      <Divider/>
      {!minimized && <div>
        {!intervalTimer && <ControlButton onClick={ async ()=>{
          tryGenerateX({pickAlgo: pickAlgo, letterSort: letterSort, nolog: true, area: area, wordMap: stateRef.wordMap}).then(()=>{
            //console.log("Generate done")
          })
          tryAutoStart()
        }}>Generate</ControlButton>}
        {intervalTimer && <ControlButton onClick={async ()=>{
          stopGenerateX(currentJobName).then(()=>{
            //console.log("Stopped generating")
            clearInterval(stateRef.intervalTimer)
            setIntervalTimer()
            setPlay(false)
          })
        }
        }>Stop Generate</ControlButton>}
        {!intervalTimer && <ControlButton onClick={()=>{
          onApply(volatileX)
        }}>Apply</ControlButton>}
        {renderCrossword()}
        <Divider/>
        <div style={{maxHeight: "20vh", overflow: "scroll"}}>
          {reversedJobs?.map(jobName => (
              <Job xId={xId} uId={uId} jobName={jobName} key={`job_${jobName}`} setX={setX}
                   onShow={(eventX, jobStream)=>{
                     onShowStream(jobName, eventX, jobStream)
                   }}
                   onDelete={()=>{
                     //console.log(`Deleting ${jobName}`)
                     //console.log(jobs)
                     const newJobs = jobs.filter(j=>j !== jobName)
                     //console.log(newJobs)
                     setJobs(newJobs)
                   }}
              >
              </Job>
          ))}
        </div>
      </div>}
    </Paper>
  </Draggable>
}
