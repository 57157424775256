import * as React from "react";
import TextEntryNumberInput from "./TextEntryNumberInput";
import TextEntrySelect from "./TextEntrySelect";
Array.from(new Array(100), (x, i) => `${i + 4}`);
export default function TextEntry({elem, fonts, drawerWidth, updateXElem, getFontSize, getLeadingSize}) {

    return <>
        <TextEntrySelect
            idPart={"font"}
            vals={fonts}
            updateXElem={updateXElem}
            drawerWidth={drawerWidth}
            elem={elem}
        />
        <TextEntryNumberInput
            idPart={"fontSize"}
            label={"font size"}
            getSize={getFontSize}
            updateXElem={updateXElem}
            drawerWidth={drawerWidth}
            elem={elem}/>
        <TextEntryNumberInput
            idPart={"leading"}
            label={"leading"}
            getSize={getLeadingSize}
            updateXElem={updateXElem}
            drawerWidth={drawerWidth}
            elem={elem}/>
    </>
}
