import React, {useCallback, useRef} from 'react'
import {useDropzone} from 'react-dropzone'
import {getPutFont} from "../backend";

const imageTypes = [
    ".jpg",
    ".jpeg",
    ".png"
]

const fontTypes = [
    ".otf",
    "ttf"
]

export default function MyDropzone({uId, xId, children, cb, sx}) {

    const stateRef = useRef();
    stateRef.xId = xId
    stateRef.uId = uId
    const onDrop = useCallback(async (acceptedFiles, fileRejections, event) => {
        console.log(event)

        for (const file of acceptedFiles) {

            console.log(file.name)
            console.log(stateRef.uId)

            if (fontTypes.filter(fontType => file.name.endsWith(fontType)).length > 0) {
                const response = await getPutFont(stateRef.uId, file.name, "font/otf")
                console.log(response)
                // PUT request: upload file to S3
                const result = await fetch(response, {
                    method: "PUT",
                    body: file,
                });
                console.log(result)
                cb?.(file, result)
            } else if (imageTypes.filter(imageType => file.name.endsWith(imageType)).length > 0) {
                if (event.target.dropInfo) {
                    cb?.(file, event.target.dropInfo)
                } else {
                    console.error("No dropInfo")
                }
            } else {
                console.error(`File ${file.name} not supported`)
            }
        }
    }, [cb])
    const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true})
    return (
        <div {...getRootProps()} style={{...sx}}>
            <input {...getInputProps()} readOnly={true} />
            {children}
        </div>
    )
}
