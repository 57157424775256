import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import PickOrderBox from "../../ui/PickOrderBox";
import React from "react";
import SingleEntry from "./SingleEntry";


export default function SingleEntriesEditor({uId, data, onUpdated}) {

    const style = {
        height: "80vh",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: "scroll"
    };

    return <Grid container spacing={2} >
        <Grid container item xs={8} direction="column" >
            <Divider/>
            {data?.singleEntries.map((entry, index)=>{
                return <SingleEntry
                    entry={entry}
                    index={index}
                    onUpdated={()=>{
                        const dataCopy = {...data}
                        dataCopy.singleEntries.splice(index, 1)
                        onUpdated(dataCopy)
                    }}
                    uId={uId}
                    key={entry.xId}/>
            })}

        </Grid>
        <Grid container item xs={4} direction="column" >
            <PickOrderBox boxStyle={style} onPicked={(picked)=>{
                console.log(picked)
                console.log(data)
                const newData = {...data}
                const currentIndex = newData.singleEntries.findIndex(entry => entry.xId === picked.id)
                if (currentIndex !== -1) {
                    newData.singleEntries.splice(currentIndex, 1)
                } else {
                    newData.singleEntries.push({xId: picked.id, name: picked.name, uId: uId})
                }
                onUpdated(newData)
            }}/>
        </Grid>
    </Grid>
}
