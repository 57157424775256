import Box from "@mui/material/Box";
import * as React from "react";
import {useDropzone} from "react-dropzone";
import {useCallback, useEffect, useRef} from "react";
import {Alert, ListItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {getFontList, getPutFont} from "../backend";
import List from "@mui/material/List";

export default function FontAdder({uId}) {

    const [fonts, setFonts] = React.useState([])
    const [error, setError] = React.useState()
    const [message, setMessage] = React.useState()

    const cleanSetFonts = (fts) => {
        setFonts(fts?.map(f=>f.name))
    }

    useEffect(()=>{
        getFontList(uId)
            .then(cleanSetFonts)
    },[uId])

    const onDrop = useCallback(async (acceptedFiles, fileRejections, event) => {
        const fontTypes = [
            ".otf",
            ".ttf"
        ]

        for (const file of acceptedFiles) {
            console.log(file.name)
            console.log(stateRef.uId)

            const fileNameParts = file.name.split(".")

            if (fileNameParts.length !== 2) {
                setError(`Bad file!! ${fileNameParts}`)
                setTimeout(setError, 5000)
                console.error(`File ${file.name} not supported`)
            } else if (fontTypes.filter(fontType => file.name.toLowerCase().endsWith(fontType)).length > 0) {
                const washed = `${fileNameParts[0]}.${fileNameParts[1].toLowerCase()}`
                const response = await getPutFont(stateRef.uId, washed, `font/${fileNameParts[fileNameParts.length-1]}`)
                console.log(response)
                // PUT request: upload file to S3
                const result = await fetch(response, {
                    method: "PUT",
                    body: file,
                });
                console.log(result)
                setMessage(`Added font ${washed}`)
                setTimeout(setMessage, 2000)
            } else {
                setError(`File ${file.name} not supported. Please upload any of ${fontTypes.join(" ")}`)
                setTimeout(setError, 5000)
                console.error(`File ${file.name} not supported`)
            }
        }
        getFontList()
            .then(cleanSetFonts)
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true})
    const stateRef = useRef();
    stateRef.uId = uId

    console.log(fonts)
    return <>
        <div {...getRootProps()} >
            <input {...getInputProps()} readOnly={true} />
            <Box sx={{ minWidth: 120, minHeight: 500, backgroundColor: "darkGray" }}>
                <Typography sx={{textAlign: "center", padding: "70px 0"}} variant="h3">Drop your font file here!</Typography>
            </Box>
        </div>
        <List>
            {fonts.map(fontName => <ListItem key={fontName} disablePadding>{fontName}</ListItem>)}
        </List>
        {message && <Alert style={{position: "fixed", top: "50%", width: "100%"}} onClick={()=>{setMessage(null)}} key={message} severity={"success"}>{message}</Alert>}
        {error && <Alert style={{position: "absolute", top: "50%", width: "100%"}} onClick={()=>{setError(null)}} key={error} severity="error">{error}</Alert>}
        </>
}
