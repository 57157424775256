import IconButton from "@mui/material/IconButton";
import {Auth} from "aws-amplify";
import Avatar from "@mui/material/Avatar";
import {CircularProgress, Tooltip} from "@mui/material";
import * as React from "react";
import {createThumb} from "../exportUtils";
import {openX} from "../backend";
import Crossword from "../crossword/svgcrossword";
import {createMatrix} from "../xutils";

export default function Fab5Button({xId, name, searchParams}) {

    const [loadingError, setLoadingError] = React.useState(false)
    const [generating, setGenerating] = React.useState(false)

    return <Tooltip key={`${xId}`} title={`${generating ? "Generating thumbnail" : name}`}>
        <IconButton onClick={()=>{
            Auth.currentCredentials().then(credentials => {
                setLoadingError(false)
                if (loadingError) {
                    setGenerating(true)
                    const uId = searchParams.get("uId") || credentials.identityId
                    openX(xId, uId).then(x => {
                        const matrix = createMatrix(x)
                        const printX = <Crossword
                            x={x}
                            iDs={{xId, uId}}
                            print
                            matrix={matrix}
                        ></Crossword>
                        return createThumb({uId, x, printCrossword: printX})
                    }).then(()=>{
                        setGenerating(false)
                        let win = window.open(`/editor?xId=${xId}&uId=${searchParams.get("uId") || credentials.identityId}${searchParams.get("uId") ? "&noAutoSave=true" : ""}`, '_self')
                        win.focus()
                    })
                } else {
                    let win = window.open(`/editor?xId=${xId}&uId=${searchParams.get("uId") || credentials.identityId}${searchParams.get("uId") ? "&noAutoSave=true" : ""}`, '_self')
                    win.focus()
                }
            })
        }}>
            {generating ? <CircularProgress/> : <Avatar
                src={`https://d31agymsqnwyv.cloudfront.net/thumbs/${xId}_thumb.jpeg`}
                style={{
                    margin: "10px",
                    width: "100px",
                    height: "100px",
                }}
                onError={()=>{
                    setLoadingError(true)
                    console.log(`Couldn't load ${xId} ${name}`)

                }}
            />}
        </IconButton>
    </Tooltip>
}