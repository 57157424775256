import Crossword from "../../crossword/svgcrossword";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {createMatrix} from "../../xutils";
import {Plupper} from "../../utils/Plupper";
import TextField from "@mui/material/TextField";
import RefreshIcon from '@mui/icons-material/Refresh';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import IconButton from "@mui/material/IconButton";
import {debounce} from "../../debounce";
import {generateX, stopGenerateX} from "../../backend";
import {CircularProgress} from "@mui/material";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {useSelector} from "react-redux";

const websocketUrl = 'wss://edeq3osypi.execute-api.eu-north-1.amazonaws.com/dev'
const PIXEL_LOWEST_CNT = "LOWEST_CNT"
const LETTER_SORT_RANDOM = "LETTER_SORT_RANDOM"

export default function LayoutModalTemplate({x, uId, scale, edited, setEdited, words}) {

    const [theMatrix, setTheMatrix] = useState(null)
    const [density, setDensity] = useState(null)
    const [generated, setGenerated] = useState(null)
    const [websocket, setWebsocket] = useState(null)
    const [working, setWorking] = useState(false)
    const [theJobName, setTheJobname] = useState(null)

    const {density: origDensity, wordMap=true} = useSelector(state => state.persistedReducer.editor)
    const stateRef = useRef();
    stateRef.wordMap = wordMap

    useEffect(()=>setTheMatrix(createMatrix(x)),[x])

    const onGenerateClick = async ()=>{
        if (!websocket) {
            const jobName = new Date().getTime()
            const p = new Plupper(edited || x)
            const asyncCreateWebSocket = async () => {
                return new Promise((resolve, reject) => {
                    setWorking(true)
                    setTheJobname(jobName)
                    const ws = new WebSocket(`${websocketUrl}?uId=${uId}&xId=${x.xId}&jobName=${jobName}`);
                    setWebsocket(ws)
                    ws.onclose = (event) => {
                        setWebsocket(null)
                        setWorking(false)
                    }
                    ws.onerror = (event)=>{
                        console.error(event)
                        reject(event)
                        setWorking(false)
                    }
                    ws.onmessage = (event)=>{
                        const {data} = event
                        const parsedData = JSON.parse(data)
                        const {x: xPos,y: yPos,v: value, state, message} = parsedData
                        if (!isNaN(xPos) && !isNaN(yPos)) {
                            p.e(xPos,yPos).val = value
                            setGenerated(p.getX())
                        } else if (state === "SUCCESS" && message === "All cells are filled") {
                            setEdited(p.getX())
                        } else if (state === "FAILED") {
                            setGenerated(null)
                        }
                     }
                    ws.onopen = () => resolve(ws)
                })
            }

            const ws = await asyncCreateWebSocket()

            const config = {pickAlgo: PIXEL_LOWEST_CNT, letterSort: LETTER_SORT_RANDOM, nolog: true}
            const done = await generateX(x.xId, uId, p.getX(), {...config, jobName, socket: true, storeS3: false, wordMap: stateRef.wordMap})
            if (ws.readyState === 1) {
                ws.close()
            }
            return done
        } else {
            console.error("There is allready a websocket connected")
        }
    }

    const onSvgClick=()=>{
        const p = new Plupper(x)
        p.plupBoard({densityLimit: density || origDensity, avoidLongCrosses: true, words})
        const newX = p.getX()
        setEdited(newX)
        setGenerated(null)
    }

    const handleDensityChanged = (newDensity) => {
        const p = new Plupper(x)
        p.plupBoard({densityLimit: newDensity, avoidLongCrosses: true, words})
        setEdited(p.getX())
        setGenerated(null)
    }
    const densityChangedDebounced = useRef(debounce(handleDensityChanged, 500))

    return theMatrix && <>
        <Crossword
            x={generated || edited || x}
            print
            matrix={theMatrix}
            doScale={scale || 0.1}
            onSvgClick={onSvgClick}
        ></Crossword>
        {working && <>
            <CircularProgress /><IconButton onClick={()=>{
                stopGenerateX(x.xId, uId, theJobName)
            }
        }><StopCircleIcon></StopCircleIcon></IconButton>
        </>}
        {!working && <>
            <IconButton onClick={onGenerateClick}><SmartToyIcon/></IconButton>
            <IconButton onClick={onSvgClick}><RefreshIcon/></IconButton>
            <TextField
                id="outlined-number"
                label="Density"
                type="number"
                value={density || origDensity}
                InputProps={{
                    inputProps: {
                        max: 0.6, min: 0.01, step: 0.01
                    }
                }}
                onChange={(e)=>{
                    e.preventDefault()
                    e.stopPropagation()
                    const newDensity = parseFloat(e.target.value)
                    densityChangedDebounced.current(newDensity)
                    setDensity(newDensity)
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>}
    </>
}
