export const mini_east = "mini_east"
export const mini_south = "mini_south"
export const mini_north = "mini_north"
export const right_down = "right_down"
export const left_down = "left_down"
export const down_right = "down_right"
export const up_right = "up_right"
export const t_right_down = "t_right_down"
export const annot_down = "annot_down"
export const annot_right = "annot_right"
export const diag_right_east = "diag_right_east"
export const diag_right_down = "diag_right_down"
export const diag_left_south = "diag_left_south"
export const diag_up_east = "diag_up_east"
export const t_down_right = "t_down_right"
export const no_arrow = "no_arrow"
export const dynamic = "dynamic"
export const miniArrows = [
    right_down,
    left_down,
    down_right,
    up_right,
    t_right_down,
    t_down_right,
    mini_south,
    mini_east,
    annot_down,
    annot_right,
    diag_right_east,
    diag_right_down,
    diag_left_south]
