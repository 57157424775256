import {MArrow} from "./svgarrow";
import {isPartiallyInside, ru} from "../xutils";

export default function Arrows ({cSz, elements, overlayTexts, arrowColor, arrCmyk}) {

  return <g className="arrowLayer">
    {
      elements.map(e => {
        const overlayText = overlayTexts?.find(ot => ru.equals(ot.rect, e.rect) || isPartiallyInside(ot.rect, e.rect))
        const id = `arrow_x${e.rect.x}_y${e.rect.y}_w${e.rect.w||1}_h${e.rect.h||1}`
        return <MArrow
            key={id}
            id={id}
            cSz={cSz}
            sq={e.sq}
            arrowType={e.arrow}
            linesAbove={(overlayText && overlayText.overlayPosition === "upper" && overlayText.text.split("<br>").length) || 0}
            linesUnder={(overlayText && overlayText.overlayPosition === "lower" && overlayText.text.split("<br>").length) || 0}
            arrowColor={arrowColor}
            arrCmyk={arrCmyk}
        />
      })
    }
  </g>
}
