import {isFullyInside, isPartiallyInside, ru} from "../xutils";
import {MArrow} from "./svgarrow";
import square from "./square";

export default function OverlayArrows({cSz, elements, overlayTexts}) {
  if (!elements?.length)
    return
  return <g className="arrowLayer">
    {
      elements.map(e => {
        const overlayText = overlayTexts?.find(ot => ru.equals(ot.rect, e.r) || isFullyInside(ot.rect, e.r) || isPartiallyInside(ot.rect, e.r))
        console.log(overlayText)
        const id = `arrow_x${e.r.x}_y${e.r.y}_w${e.r.w||1}_h${e.r.h||1}`
        const calcE = {...e, rect: e.r}
        return <MArrow
            key={id}
            id={id}
            cSz={cSz}
            sq={e.sq || square(calcE, cSz)}
            arrowType={e.annot}
            linesAbove={(overlayText && overlayText.overlayPosition === "upper" && overlayText.text.split("<br>").length) || 0}
            linesUnder={(overlayText && overlayText.overlayPosition === "lower" && overlayText.text.split("<br>").length) || 0}
        />
      })
    }
  </g>
}
