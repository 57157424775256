import {Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {fetchUrl} from "../../features/urlcache/urlSlice";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import {debounce} from "../../debounce"

const alignments = ["xMinYMin", "xMidYMid", "xMaxYMax"]

function ClipSelect({clip, naturalWidth, naturalHeight, onChange}) {
    const [newClip, setNewClip] = useState(clip)

    const dbx = useRef(debounce(onChange, 500))

    const handleChange = (c) => {
        setNewClip(c)
        dbx.current(c)
    }
    const renderTextField = (label) => {
        return <TextField
            id={`"outlined-number-${label}`}
            label={label}
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            value={newClip?.[label] || clip?.[label]}
            onChange={(e)=>{
                const c = {...(newClip || clip)}
                c[label]=e.target.value
                handleChange(c)
            }}
        />
    }
    return <div>
        <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Clip" checked={clip !== undefined} onChange={(e)=>{
                if (e.target.checked) {
                    onChange(newClip || {x: 0, y: 0, w: naturalWidth, h: naturalHeight})
                } else {
                    onChange()
                }
            }}/>
        </FormGroup>
        {clip && <>
            {renderTextField("x")}
            {renderTextField("y")}
            {renderTextField("w")}
            {renderTextField("h")}
            <div>{naturalWidth}</div>
            <div>{naturalHeight}</div>
        </>
        }
    </div>
}

export default function ImageEntry({elem, drawerWidth, updateXElem, x, xId, uId}) {
    const dispatch = useDispatch()
    const signedUrl = useSelector((state) => state.persistedReducer.urls.cache?.[elem.image]?.url)
    const imageRef = useRef()

    useEffect(() => {
        if (xId && elem.image && !signedUrl && uId) {
            dispatch(fetchUrl({xId, uId, image: elem.image}))
        }
    }, [dispatch, elem.image, uId, xId, signedUrl])

    console.log(imageRef.current?.naturalWidth)
    console.log(imageRef.current?.naturalHeight)

    const onImageSelect = (event)=>{
        updateXElem(newElem => {
            newElem.image = event.target.value === "None" ? undefined : event.target.value
        })
    }

    const onAlignmentSelect = (event)=>{
        updateXElem(newElem => {
            newElem.alignment = event.target.value === "None" ? undefined : event.target.value
        })
    }

    const renderImageSelect = (idPart, vals, handler) => {
        const id = `${idPart}_demo-simple-select-autowidth`
        const lbl = `${id}-label`
        return <FormControl key={`${idPart}_imgFrmCntrl`} sx={{ m: 1, width: (drawerWidth-40) }}>
            <InputLabel id={lbl}>{idPart}</InputLabel>
            <Select
                sx={{fontSize: 8}}
                labelId={lbl}
                id={id}
                value={elem[idPart] || "None"}
                onChange={handler}
                autoWidth
                label={idPart}
            >
                <MenuItem key="None" value="None">
                    <em>None</em>
                </MenuItem>
                {vals?.map(val => (<MenuItem key={val} value={val}>{val}</MenuItem>))}
            </Select>
        </FormControl>
    }

    return <>
        {signedUrl && <img src={signedUrl} width={30} alt={"Whot is this?"} ref={imageRef}></img>}
        <Divider/>
        {renderImageSelect("image", x.images, onImageSelect)}
        <Divider/>
        {renderImageSelect("alignment", alignments, onAlignmentSelect)}
        <Divider/>
        <ClipSelect
            naturalWidth={imageRef.current?.naturalWidth}
            naturalHeight={imageRef.current?.naturalHeight}
            clip={elem.clip}
            onChange={(newClip)=>{
                console.log("onChange")
                console.log(newClip)
                updateXElem(newElem => {
                    if (!newClip)
                        delete newElem.clip
                    else
                        newElem.clip = newClip
                })}}

        />
    </>
}
