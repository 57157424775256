import React, {useEffect, useReducer, useRef, useState} from "react";
import Crossword from "../../crossword/svgcrossword";
import {allStep, createMatrix, getWordMarker, wordStep, compareElementRects, hasLink} from "../../xutils";
import {HZ, VRT, WORD, KRYPTO, CHAOS} from "../../cellTypes"
import { useTheme } from '@mui/material/styles';
import {useNavigate, useParams} from "react-router-dom";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import {layout} from '../../keyboardlayouts/seCrossword'
import Cursor from "../../crossword/svgcursor";
import Box from "@mui/material/Box";
import {useDispatch} from "react-redux";
import {clearCache} from "../../features/urlcache/urlSlice";
import {Auth} from "aws-amplify";
import {userLoggedIn} from "../../features/users/userSlice";
import {openX} from "../../backend";
import {t_down_right, t_right_down} from "../../linkTypes";
import ChaosCursor from "../../crossword/ChaosCursor";
import chaosReducer, {
    CHAOS_ACTION_END,
    CHAOS_ACTION_INIT,
    CHAOS_ACTION_RESET,
    CHAOS_ACTION_START,
    chaosStartState
} from "./chaosReducer";
import {debounce} from "../../debounce";
import {paragraphStyle} from "../../crossword/defaultParagraphStyle";

export default function CorrSolver() {

    const navigate = useNavigate()
    const [showKeyboard, setShowKeyboard] = useState(false)
    const {xId} = useParams()
    const [uId, setUid] = useState()
    const [direction, setDirection] = useState(HZ)

    const [selections, setSelections] = useState([])
    const [mouseMoved, setMouseMoved] = useState(false)
    const [touchStarted, setTouchStarted] = useState(false)
    const [initedPuzzle, setInitedPuzzle] = useState()

    const dispatch = useDispatch()
    const [chaosState, chaosDispatch] = useReducer(chaosReducer, chaosStartState)

    useTheme();

    useEffect(() => {

        document.oncontextmenu = e => {
            console.log("oncontextmenu")
            e.preventDefault()
            e.stopPropagation()
        }
        const checkLoggedIn = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser()
                dispatch(userLoggedIn(user))
                const credentials = await Auth.currentCredentials()
                setUid(credentials.identityId)
                return true
            } catch (err) {
                console.error(err)
                navigate("/signin")
                return false
            }
        }
        if (!initedPuzzle) {
            const loggedIn = checkLoggedIn()
            if (loggedIn) {
                openX(xId).then(data => {
                    console.log(data)
                    //setX(data)
                    if (data.type !== CHAOS) {
                        data.elements.filter(e=>e.type === WORD && !e.locked).forEach(e=>delete e.val)
                    }
                    xUpdated(data, false)
                    chaosDispatch({type: CHAOS_ACTION_INIT, cSz: data?.paragraphStyle.squareSize, puzzle: data})
                }).catch(console.error)
            }
        }
    }, [dispatch, initedPuzzle, navigate, xId]);


    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('touchstart', handleTouchStarted)

        // cleanup this component
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchstart', handleTouchStarted);
        };
    });

    const keyboardRef = useRef(null)

    const stateRef = useRef();
    stateRef.showKeyboard = showKeyboard
    stateRef.selections = selections
    stateRef.x = initedPuzzle
    stateRef.matrix = createMatrix(initedPuzzle)
    stateRef.direction = direction
    stateRef.mouseMoved = mouseMoved
    stateRef.chaosState = chaosState

    const reset = ()=>{
        chaosDispatch({type: CHAOS_ACTION_RESET})
    }

    const resetDebounced = useRef(debounce(reset, 1000))

    const onChange = (input) => {
        console.log("Input changed", input);
    }

    const onKeyRelease = (button, e) => {
        console.log("Button pressed", button);
        if (button === '⌨️') {
            setTimeout(()=> {
                setShowKeyboard(false)
            }, 200)

        } else {
            let code = button
            if (button === "{space}") {
                code = "Space"
            } else if (button === "⟸") {
                code = "Backspace"
            } else if (button === "↵") {
                code = "Enter"
            }
            handleKeyDown({code: code, key: button})
        }
        e.stopPropagation()
    }

    const resetCursor = () => {
        setSelections([])
    }

    const getFocusedElement = () => {
        const el = stateRef.selections[0].clickedObj.e
        if (el.overlayPosition && stateRef.matrix[el.rect.y][el.rect.x].ot && stateRef.matrix[el.rect.y][el.rect.x].ot.overlayPosition === el.overlayPosition) {
            return stateRef.matrix[el.rect.y][el.rect.x].ot
        }
        return stateRef.matrix[el.rect.y][el.rect.x].e
    }

    const startObj = () => {
        return stateRef.selections?.[stateRef.selections.length - 1]?.clickedObj
    }

    const switchDirection = () => {
        console.log("switchDirection")
        setDirection((stateRef.direction + 1) % 2)
    }

    const xUpdated = (x, triggerSave=true) => {
        console.log(`xUpdated ${x.xId} ${triggerSave}`)
        setInitedPuzzle(x)

        const answer = x.elements
            .filter(e=>e.type === "WORD")
            .sort(compareElementRects)
            .map(e => e.val || ".")
        console.log(answer)
    }

    const handleMouseMove = (e) => {
        if (!touchStarted) {
            setMouseMoved(true)
        }
    }
    const handleTouchStarted = (e) => {
        setTouchStarted(true)
    }
    const handleKeyDown = (e) => {
        if (e.shiftKey || e.ctrlKey || e.metaKey) {
            //setEndObj()
            //setDragging(false)
        }
        if (stateRef.selections.length === 0)
            return false
        const element = getFocusedElement()
        if (element) {
            console.log(e.key)
            let next = null
            const chr = e.key.toUpperCase()
            const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ"

            if (e.code === "ArrowRight") {
                next = allStep(stateRef.matrix, startObj(), HZ)
            } else if (e.code === "ArrowLeft") {
                next = allStep(stateRef.matrix, startObj(), HZ, false)
            } else if (e.code === "ArrowUp") {
                next = allStep(stateRef.matrix, startObj(), VRT, false)
            } else if (e.code === "ArrowDown") {
                next = allStep(stateRef.matrix, startObj(), VRT)
            }
            else {
                console.log(e.code)
                if (e.code === "Enter") {
                    if (element.type === WORD) {
                        switchDirection()
                    }
                } else if (e.code === "Space") {
                    if (element.type === WORD) {
                        if (!element.locked) {
                            element.val = ""
                            if (stateRef.x.type === KRYPTO && !isNaN(element.number)) {
                                stateRef.x.elements.filter(elem => elem.number === element.number && !elem.locked).forEach(elem => elem.val = "")
                            }
                            xUpdated(JSON.parse(JSON.stringify(stateRef.x)))
                        }
                        next = wordStep(stateRef.matrix, startObj(), direction)
                        if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                            setDirection(VRT)
                        } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                            setDirection(HZ)
                        }
                    }
                } else if (e.code === "Backspace") {
                    if (element.type === WORD) {
                        if (!element.locked) {
                            element.val = ""
                            if (stateRef.x.type === KRYPTO && !isNaN(element.number)) {
                                stateRef.x.elements.filter(elem => elem.number === element.number && !elem.locked).forEach(elem => elem.val = "")
                            }
                            xUpdated({...stateRef.x})
                        }
                        next = wordStep(stateRef.matrix, startObj(), direction, false)
                        if (next?.e) {
                            if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                                setDirection(HZ)
                            } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                                setDirection(VRT)
                            }
                        }
                    }
                } else {
                    if (ALPHABET.indexOf(chr) !== -1) {
                        if (element?.type === WORD) {
                            if (!element.locked) {
                                element.val = chr
                                if (stateRef.x.type === KRYPTO && !isNaN(element.number)) {
                                    stateRef.x.elements.filter(elem => elem.number === element.number && !elem.locked).forEach(elem => elem.val = chr)
                                }
                                xUpdated({...stateRef.x}) // This works because the key of the element changes. Memo won't work though
                            }
                            next = wordStep(stateRef.matrix, startObj(), direction)
                            if (next?.e) {
                                if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                                    setDirection(VRT)
                                } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                                    setDirection(HZ)
                                }
                            }
                        }
                    } else {
                        console.error(`${chr} is not in alphabet`)
                    }
                }
            }
            if (next) {
                if (e.shiftKey) {
                    const newSel = [...stateRef.selections]
                    newSel[newSel.length - 1].endObj = next
                    setSelections(newSel)
                } else {
                    const newSel = [{clickedObj: next}]
                    setSelections(newSel)
                }
            }
        } else {
            console.error("Couldn't find any match")
        }
    }

    const wordMarker = stateRef.selections.length === 1 && getWordMarker(stateRef.matrix, startObj(), direction, null, null, stateRef.x)

    const pStyle = stateRef.x?.paragraphStyle || paragraphStyle
    const cSz = pStyle?.squareSize || 28.34645669
    const cursor = chaosState.points ? <ChaosCursor points={chaosState.points}/> : <>
        {selections.map(selection => {
            const {clickedObj} = selection
            return <Cursor key={clickedObj.key} cSz={cSz} selection={selection}
                           wordmarker={wordMarker} matrix={stateRef.matrix}></Cursor>
        })}
    </>

    const filterKeys = (event) => {
        return event.ctrlKey || event.metaKey
    }

    const getElem = () => {
        const info = startObj()
        if (info?.e?.parts) {
            return info.e.parts[info.part || 0]
        }
        return info?.e
    }

    const chaosMouseDownListener = (event, nativeEvent) => {
        chaosDispatch({type: CHAOS_ACTION_START, rect: event.e.rect})
    }

    const mouseDownListener = (event, nativeEvent) => {
        if (stateRef.x.type === CHAOS) {
            chaosMouseDownListener(event, nativeEvent)
        } else {
            if (filterKeys(nativeEvent))
                return
            const focusedElement = getElem()
            const newSel = nativeEvent.shiftKey ? [...stateRef.selections] : []
            event.key = new Date().getTime()
            newSel.push({clickedObj: event})
            setSelections(newSel)
            const {e} = event
            if (e === focusedElement) {
                switchDirection()
            }
            /*if (!showKeyboard && focusedElement.type === WORD) {
                setShowKeyboard(true)
            }*/
        }
    }

    const chaosMouseEnterListener = (event, nativeEvent) => {
        if (nativeEvent.nativeEvent.buttons) {
            if (stateRef.chaosState.start) {
                chaosDispatch({type: CHAOS_ACTION_END, rect: event.e.rect})
            }
        } else {
            chaosDispatch({type: CHAOS_ACTION_RESET})
        }
    }

    const chaosMouseUpListener = () => {
        resetDebounced.current()
    }

    const mouseUpListener = (event, nativeEvent) => {
        if (stateRef.x.type === CHAOS) {
            chaosMouseUpListener(event, nativeEvent);
        } else {
            if (filterKeys(nativeEvent))
                return
            const newSel = nativeEvent.shiftKey ? [...stateRef.selections] : []
            if (newSel.length > 0) {
                newSel[newSel.length - 1].endObj = event
                setSelections(newSel)
            }
        }
    }

    const doubleClickListener = () => {
        console.log("double clicked")
    }

    console.log("Render corrsolver")

    return <>
        <Box className="fullheightdiv"
             style={{height: "100vh", backgroundColor: "lightblue"}}
             onClick={resetCursor}
        >
            <TransformWrapper
                    wheel={{step: 0.05}}
                    initialScale={1}
                    maxScale={100}
                    minScale={0.1}
                    centerOnInit={false}
                    limitToBounds={false}
                    doubleClick={{disabled: true}}
                    panning={{disabled: stateRef.x?.type === CHAOS && stateRef.chaosState.start}}
                    //wheel={{activationKeys: ["Alt", "Shift", "Control"]}}
                >
                    <TransformComponent
                        wrapperStyle={{
                            width: "100%",
                            height: "100%"}}
                    >
                        {initedPuzzle && <Crossword
                            x={initedPuzzle}
                            iDs={{xId: initedPuzzle.xId, uId: uId}}
                            cursor={cursor}
                            matrix={stateRef.matrix}
                            chaosState={stateRef.chaosState}
                            clickListener={() => {
                                console.log(new Date().toISOString() + " clickListener " + stateRef.showKeyboard + " " + stateRef.mouseMoved)
                                if (!stateRef.mouseMoved) {
                                    console.log("settingShowKeyboard true")
                                    setShowKeyboard(true)
                                }
                            }}
                            mouseDownListener={mouseDownListener}
                            mouseUpListener={mouseUpListener}
                            mouseEnterListener={(initedPuzzle.type === CHAOS && chaosMouseEnterListener) || undefined}
                            mouseOutsideListener={(initedPuzzle.type === CHAOS && chaosMouseUpListener) || undefined}
                            doubleClickListener={doubleClickListener}
                            touchAction={stateRef.x?.type === CHAOS ? "none" : undefined}
                        />}
                    </TransformComponent>
                </TransformWrapper>
            {<>
                <div
                    style={{position: "fixed", top: 0, right: 0, backgroundColor: showKeyboard ? "lightgray" : "lightgreen", fontSize: "54px", borderRadius: "64px", height: "64px", width: "64px", textAlign: "center", verticalAlign: "middle"}}
                    onClick={(e)=>{
                        console.log("Clicked keyboard button")
                        setShowKeyboard(!stateRef.showKeyboard)
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    onDoubleClick={()=> {
                        dispatch(clearCache())
                    }}
                >⌨️</div>
                {showKeyboard && <div
                    onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    className="keyboardContainer" style={{position: "fixed", bottom: 0, width: "100%"}} >
                    <Keyboard
                        onChange={onChange}
                        onKeyReleased={onKeyRelease}
                        keyboardRef={(r) => (keyboardRef.current = r)}
                        layout={layout}
                    />
                </div>}
            </>}

        </Box>
        {/*{puzzle2 && <div>{JSON.stringify(puzzle2, "<\br>", 2)}</div>}
        {puzzle3 && <div>{JSON.stringify(puzzle3, "<\br>", 2)}</div>}*/}
    </>
}
