import {createMatrix} from "../../xutils";

export const chaosStartState = {
    cSz: null,
    start: null,
    end: null,
    points: null,
    found: null
}

export const CHAOS_ACTION_INIT = "init"
export const CHAOS_ACTION_START = "start"
export const CHAOS_ACTION_END = "end"
export const CHAOS_ACTION_RESET = "reset"
export const CHAOS_ACTION_SET_FOUND = "set_found"

const cell2Point = (cell, cSz) => `${cell.x*cSz + cSz/2},${cell.y*cSz + cSz/2}`

export default function chaosReducer(state, action) {
    console.log(state)
    switch (action.type) {
        case CHAOS_ACTION_SET_FOUND:
            return {...state, found: action.found}
        case CHAOS_ACTION_INIT:
            const matrix = action.puzzle && createMatrix(action.puzzle)
            return {...state, cSz: action.cSz, puzzle: action.puzzle, matrix: matrix}
        case CHAOS_ACTION_START:
            let startCells = [{...action.rect}]
            let startLetters = state.matrix && startCells.map(cell => state.matrix[cell.y][cell.x].e.val).join("")
            let startPoints = startCells.map(cell => {return cell2Point(cell, state.cSz)})
            return {
                ...state,
                start: action.rect,
                end: null,
                cells: startCells,
                points: startPoints,
                letters: startLetters
            }
        case CHAOS_ACTION_END:
            const end = action.rect
            const {x: startX,y: startY} = state.start
            const {x: endX, y: endY} = end
            const deltaX = Math.abs(endX-startX)+0.01
            const deltaY = Math.abs(endY-startY)+0.01
            const ratio = deltaX/deltaY

            const cells = []

            const north = () => {
                for (let y = startY; y >= endY; y--) {
                    cells.push({x: startX, y: y})
                }
            }
            const south = () => {
                for (let y = startY; y <= endY; y++) {
                    cells.push({x: startX, y: y})
                }
            }
            const southEast = () => {
                for (let length = 0; startY + length <= endY && startX + length <= endX; length++) {
                    cells.push({x: startX + length, y: startY + length})
                }
            }
            const northEast = () => {
                for (let length = 0; startY - length >= endY && startX + length <= endX; length++) {
                    cells.push({x: startX + length, y: startY - length})
                }
            }
            const east = () => {
                for (let length = 0; startX + length <= endX; length++) {
                    cells.push({x: startX + length, y: startY})
                }
            }
            const southWest = () => {
                for (let length = 0; startY + length <= endY && startX - length >= endX; length++) {
                    cells.push({x: startX - length, y: startY + length})
                }
            }
            const west = () => {
                for (let length = 0; startX - length >= endX; length++) {
                    cells.push({x: startX - length, y: startY})
                }
            }
            const northWest = () => {
                for (let length = 0; startY - length >= endY && startX - length >= endX; length++) {
                    cells.push({x: startX - length, y: startY - length})
                }
            }


            if (endX === startX) {
                if (endY > startY) {
                    south()
                } else if (endY < startY) {
                    north()
                } else {
                    cells.push({...action.rect})
                }
            } else if (endX > startX) {
                if (endY > startY) {
                    if (ratio <= 0.5) {
                        south()
                    } else if (ratio <= 1.5) {
                        southEast()
                    } else {
                        east()
                    }
                } else if (endY < startY) {
                    if (ratio <= 0.5) {
                        north()
                    } else if (ratio <= 1.5) {
                        northEast()
                    } else {
                        east()
                    }
                } else {
                    east()
                }
            } else {
                if (endY > startY) {
                    if (ratio <= 0.5) {
                        south()
                    } else if (ratio <= 1.5) {
                        southWest()
                    } else {
                        west()
                    }
                } else if (endY < startY) {
                    if (ratio <= 0.5) {
                        north()
                    } else if (ratio <= 1.5) {
                        northWest()
                    } else {
                        west()
                    }
                } else {
                    west()
                }
            }

            const letters = state.matrix && cells.map(cell => state.matrix[cell.y][cell.x].e.val).join("")

            const points = cells.map(cell => {return cell2Point(cell, state.cSz)})

            const newFound = state.found ? [...state.found] : []
            const allWords = state.puzzle?.chaosInfo?.allWords
            if (allWords && allWords.indexOf(letters) !== -1 && newFound.indexOf(letters) === -1) {
                newFound.push(letters)
            }

            return {
                ...state,
                end: action.rect,
                cells: cells,
                points: points,
                letters: letters,
                found: newFound
            }
        case CHAOS_ACTION_RESET:
            return {
                ...state,
                start: null,
                end: null,
                points: null,
                cells: null,
                letters: null,
            }
        default:
            return {...state}
    }
}
