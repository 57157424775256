import * as React from 'react';
import {useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import LayoutModalTemplate from "./LayoutModalTemplate";
import {BOTTOM_LEFT, DIRECTION_HZ, DIRECTION_VRT, Plupper, TOP_LEFT, TOP_RIGHT} from "../../utils/Plupper";
import List from "@mui/material/List";
import {CircularProgress, ListItemButton, Stack} from "@mui/material";
import {createEntries} from "../../utils/algo";

const possibleSizes = [[4,5],[4,6],[4,7],[4,8],[5,6],[5,7],[5,8],[7,6],[7,7],[7,8],[8,7],[9,7],[9,7],[10,7],[7,10],[10,8],[8,10]]
export default function LayoutModal({open, onCancel, onAccept, x, uId, words}) {

    const [entries, setEntries] = useState([])
    const [directions, setDirections] = useState([])
    const [previews, setPreviews] = useState([])
    const [editedPreviews, setEditedPreviews] = useState([])
    const [selected, setSelected] = useState(-1)
    const [working, setWorking] = useState(false)
    const [sizes, setSizes] = useState([{sz: [4,5]}, {sz: [5, 6]}, {sz: [7, 6]}])

    const stateRef = useRef();
    stateRef.editedPreviews = editedPreviews

    const reset = ()=>{
        setEntries([])
        setDirections([])
        setPreviews([])
        setSelected(-1)
        setEditedPreviews([])
        setWorking(false)
    }

    const handleAccept = (someX) => {
        reset()
        onAccept(someX)
    };

    const handleCancel = () => {
        reset()
        onCancel()
    }

    const handlePreview = async () => {
        setWorking(true)
        setTimeout(()=>{
            setWorking(false)
            const suggestions = []
            let anchorCombinations = []
            if (entries.length === 2) {
                anchorCombinations = [
                    [{anchor: TOP_LEFT}, {anchor: BOTTOM_LEFT}],
                    [{anchor: TOP_RIGHT}, {anchor: BOTTOM_LEFT}]
                ]
            } else if (entries.length === 1) {
                anchorCombinations = [[{anchor: TOP_LEFT}, {anchor: TOP_RIGHT}, {anchor: BOTTOM_LEFT}]]
            }
            const dirs = [{direction: DIRECTION_HZ}, {direction: DIRECTION_VRT}]
            const configs = entries.length > 0 ? createEntries(entries, dirs, sizes, anchorCombinations) : []

            if (configs.length === 0) {
                suggestions.push({x, entries})
            } else {
                configs.forEach(entries => {
                    const p = new Plupper(x)
                    p.print()
                    try {
                        entries.forEach(entry => {
                            p.addImageWithSentences(entry)
                        })
                        suggestions.push({x: p.getX(), entries})
                    } catch(err) {
                        console.error(err)
                        console.error(`Couldn't handle combination ${directions}`)
                    }
                })
            }
            setPreviews(suggestions)
            setEditedPreviews(new Array(suggestions.length))
        }, 1000)
    }

    const onAddSentence = (i) => {
        const newEntries = [...entries]
        newEntries[i].sentences.push("")
        setEntries(newEntries)
    }

    const createKey = (entries)=>{
        try {
            return entries?.[0] ? `${entries.map(entry => `anchor_${entry.anchor.replace(" ", "-")}_sz_(${entry.sz.join("-")})_sentenceInfos_${entry.sentenceInfos.map(si => `${si.sentence.replace(" ", "-")}-${si.direction}`).join("_")}`).join("_")}` : "no-entries"
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleCancel}
            >
                <DialogTitle>Auto Layout</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add an entry for each Image. For each image add an entry for each sentence.
                        The Auto Layout will place the images and the sentences for you.
                    </DialogContentText>
                    <Typography>Chosen sizes</Typography>
                    <List component={Stack} direction="row">
                        {sizes.map(({sz}, ind) => (<div key={sz.join("-")}>
                            <ListItemButton onClick={()=>{
                                const newSizes = [...sizes]
                                newSizes.splice(ind,1)
                                setSizes(newSizes)
                            }}>{sz[0]}/{sz[1]}</ListItemButton>
                        </div>))}
                    </List>
                    <Typography>Possible sizes</Typography>
                    <div style={{width: "100%", overflowX: "scroll"}}>
                        <List component={Stack} direction="row">
                            {possibleSizes
                                .filter(tuple=>sizes.findIndex(({sz})=>tuple[0]===sz[0]&&tuple[1]===sz[1]) === -1)
                                .map(tuple=>(<div key={tuple.join("-")}>
                                    <ListItemButton onClick={()=>{
                                        const newSizes = [...sizes, {sz: tuple}]
                                        setSizes(newSizes)
                                    }}>
                                        {tuple[0]}/{tuple[1]}
                                    </ListItemButton>
                                </div>))
                            }
                        </List>
                    </div>

                    <IconButton onClick={()=>{
                        setEntries([...entries, {sentences: [""]}])
                    }}><AddCircleOutlineIcon/></IconButton>
                    {entries.map((entry, i) => (<div key={`entry_${i}`}>
                        <Divider/>
                        <Typography>Image {i+1}</Typography>

                        {entry.sentences?.map((sentence, j) => (<div key={`sentence_${j}`}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id={`entry_${i}_sentence_${j}`}
                                name={`entry_${i}_sentence_${j}`}
                                label={`Sentence ${j+1}`}
                                type="text"
                                fullWidth
                                variant="standard"
                                value={sentence}
                                onKeyDown={(e)=>{
                                    if (e.shiftKey && e.code === "Enter") {
                                        onAddSentence(i,j)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }
                                }}
                                onChange={(e)=>{
                                    const newEntries = [...entries]
                                    newEntries[i].sentences[j] = e.target.value.toUpperCase()
                                    setEntries(newEntries)
                                }}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                            />
                            <IconButton onClick={()=>{
                                onAddSentence(i,j)
                            }}><AddBoxIcon/></IconButton>
                        </div>))}
                    </div>))}
                    <h1>Previews <IconButton onClick={handlePreview}><RefreshIcon/></IconButton></h1>
                    {working && <CircularProgress />}
                    {!working && <List>
                        {previews.map((preview, previewIndex) => {
                            const {x, entries} = preview
                            const key = createKey(entries)

                            return <ListItemButton
                                selected={selected === previewIndex}
                                key={key}
                                onClick={()=>setSelected(previewIndex)}>
                                <LayoutModalTemplate
                                    uId={uId}
                                    x={x}
                                    edited={editedPreviews[previewIndex]}
                                    setEdited={(selX)=>{
                                        const newEditedArray = [...stateRef.editedPreviews]
                                        newEditedArray[previewIndex] = selX
                                        setEditedPreviews(newEditedArray)
                                        setSelected(previewIndex)
                                    }}
                                    scale={0.3}
                                    words={words}
                                ></LayoutModalTemplate>
                            </ListItemButton>
                        })}
                    </List>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={event=> {
                        event.preventDefault();
                        handleAccept(editedPreviews[selected] || previews[selected].x);
                    }}>Ok</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
