import SvgContent from "./SvgContent";
import * as React from "react";
import {ClassListContext, DocContext} from "./ClassListContext";
import {useContext} from "react";

export default function SvgDoc({doc, chaosInfo, chaosState, completedwords}) {
    const {content} = doc
    const selectedWord = chaosInfo?.wrdsLists?.map(dirList => dirList.words.filter(word=>word.strike).map(word=>word.word))?.flat()?.[0]
    const currentLetters = chaosState?.letters

    const docContext = useContext(DocContext)
    const {cb} = docContext

    return <svg className="docLayer" x={-doc.puzzle.pt.left} y={-doc.puzzle.pt.top} onClick={()=>{cb?.()}}>
        <ClassListContext.Provider value={doc.classList}>
            <SvgContent content={content} selectedWord={selectedWord} currentLetters={currentLetters} found={chaosState?.found || completedwords}/>
        </ClassListContext.Provider>
    </svg>
}
