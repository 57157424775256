import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import {useEffect, useReducer, useRef, useState} from "react";
import {Auth} from "aws-amplify";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {userLoggedIn, userLoggedOut} from "../../features/users/userSlice";
import {setProperty} from "../../features/editor/editorSlice"
import Crossword from "../../crossword/svgcrossword";
import {Alert, Button, Checkbox, FormControlLabel, FormGroup, Paper, SvgIcon, Tooltip} from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ConstructionIcon from '@mui/icons-material/Construction';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import PreviewIcon from '@mui/icons-material/Preview';
import BoltIcon from '@mui/icons-material/Bolt';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import Draggable from "react-draggable"
import {FullScreen, useFullScreenHandle} from "react-full-screen"
import {
  getFontList,
  getPutImg,
  openX,
  saveX,
  generateX,
  getKeys,
  listXVersions,
  deleteXVersion, stopGenerateX, getUserRights,
} from "../../backend";
import Cursor from "../../crossword/svgcursor";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VideocamIcon from '@mui/icons-material/Videocam';
import SquareIcon from '@mui/icons-material/Square';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from "@mui/icons-material/Menu";
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Link from '@mui/icons-material/Link';
import LinkOff from '@mui/icons-material/LinkOff';
import PaletteIcon from '@mui/icons-material/Palette';

import {
  getWordMarker,
  createMatrix,
  wordStep,
  allStep,
  setArea,
  splitArea,
  divideArea,
  setAreaClr,
  clearLetters,
  ru,
  elementEquals,
  clearClues,
  unbreakKey,
  addOverlayToAreas,
  getSelectionRect,
  removeOverlay,
  hasLink,
  calculateElementRect, printXForAlgo, getAllWords
} from "../../xutils";
import {debounce} from "../../debounce"

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import {MArrow} from "../../crossword/svgarrow";
import {MLink} from "../../crossword/svglink";
import MyDropzone from "../../ui/MyDropzone";
import {MWordListWindow} from "../../ui/WordListWindow";
import {clearCache, fetchUrl} from "../../features/urlcache/urlSlice";
import TextField from "@mui/material/TextField";
import GeneratorWindow from "../../ui/GeneratorWindow";
import KeyEditWindow from "../../ui/KeyEditWindow";
import {
  annot_down, annot_right, diag_left_south, diag_right_down,
  diag_right_east,
  down_right, left_down, mini_east,
  mini_south,
  miniArrows, right_down,
  t_down_right,
  t_right_down, up_right
} from "../../linkTypes";
import {AutoAwesome, BrowserNotSupported, Clear, ClearAll, KeyOff} from "@mui/icons-material";
import {
  HZ,
  LOWER,
  TEXT,
  UPPER,
  VRT,
  WORD,
  IMAGE,
  ARROW,
  GHOST,
  LEFT,
  RIGHT,
  ot2Prop,
  otExcludes,
  CHAOS
} from "../../cellTypes";
import {
  autoLink,
  autoLinkTextRange,
  autoLinkWordRangeToOverlayText,
  autoLinkWordRangeToTextRange,
  link
} from "../../linkutils";
import {arrowTypes} from "../../arrowTypes";
import ContextEntries from "../../ui/context/ContextEntries";
import OverlayTextBtn from "../../ui/buttons/overlayTextBtn";
import square, {sqEqualish, xOverlap, yOverlap} from "../../crossword/square";
import PuzzlePropertyEntries from "../../ui/context/PuzzlePropertyEntries";
import SplitTextBtn from "../../ui/buttons/splitTextBtn";
import {createRoot} from "react-dom/client";
import {flushSync} from "react-dom";
import {fetchUserLists, fetchUserWords} from "../../features/wordlists/userWordlists";
import {fetchLists, fetchWords} from "../../features/wordlists/wordlists";
import {paragraphStyle} from "../../crossword/defaultParagraphStyle";
import {editorReducer, forEachPos, startState} from "./editorReducer";
import {createImage, createThumb, exportPdf} from "../../exportUtils";
import LayoutModal from "./LayoutModal";
import FastWeaveModal from "./FastWeaveModal";
import {Plupper} from "../../utils/Plupper";
import AnalyticsModal from "./AnalyticsModal";
import {triggerUsed} from "../../features/fab5Slice";
const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ"
const NUMBERS = "01234566789"
const OK_CHARS= "-_.,;()"
const ALLOWED_KEY_CHARS = `${ALPHABET}${OK_CHARS}`
const deepCopy = data => {
  if (!data)
    return data
  return JSON.parse(JSON.stringify(data))
}
const theme = createTheme({
  palette: {
    primary: {
      light: '#fff263',
      main: '#fbc02d',
      dark: '#c49000',
      contrastText: '#000000',
    },
    secondary: {
      light: '#99d066',
      main: '#689f38',
      dark: '#387002',
      contrastText: '#FFF',
    },
  },
})

const arrowSq = square({rect: {x: 0, y: 0, w: 1, h: 1}}, 24)

export default function Editor() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const searchParams = useSearchParams()[0];
  const [clickLayout, setClickLayout] = useState(false)
  const [baseList, setBaseList] = useState()
  const [words, setWords] = useState([])
  const [notSaved, setNotSaved] = useState(false)
  const [uId, setUid] = useState()
  const showExport = false
  const [fonts, setFonts] = useState([])
  const [showWords, setShowWords] = useState(false)
  const [showLocalHistory, setShowLocalHistory] = useState(false)
  const [showGeneratorWindow, setShowGeneratorWindow] = useState(false)
  const [showKeyEditWindow, setShowKeyEditWindow] = useState(false)
  const [wrdCntCache, setWrdCntCache] = useState({})
  const [prepareSwitch, setPrepareSwitch] = useState()
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [editorModalOpen, setEditorModalOpen] = useState(false)

  const pushHistory = (x, triggerSave) => {
    //console.log(`pushHistory ${triggerSave}`)
    const prevX = stateRef.xHistory?.[xHistoryIndex]?.x
    const savedX = JSON.stringify(x)
    if (prevX !== savedX) {
      setXHistory([{
        x: JSON.stringify(x), time: new Date().toLocaleString()},
        ...stateRef.xHistory])
      setXHistoryIndex(0)

      if (triggerSave) {
        setNotSaved(true)
        trySaveXDelayed()
      }
    } else {
      console.log("No update")
    }
  }
  const pushHistoryDebounced = useRef(debounce(pushHistory, 500))
  const transformComponentRef = useRef(null);

  /**
   * The elements in selections is READ_ONLY. To change the puzzle.
   * Only do lookup in selection and update elements in the puzzle.
   * */
  const [myState, myDispatch] = useReducer(editorReducer, startState)
  const setSelections = (s) => {
    myDispatch({type: 'setSelections', selections: s})
  }
  const [dragging, setDragging] = useState(false)
  function setDirection(direction) {
    myDispatch({type: 'setDirection', direction})
  }
  const direction = myState.direction
  const [xHistory, setXHistory] = useState([])
  const [xVersions, setXVersions] = useState([])
  const [xHistoryIndex, setXHistoryIndex] = useState(-1)
  const [hideCrossword, setHideCrossword] = useState(false)
  const [dragMode, setDragMode] = useState(false)
  const [showDrawer, setShowDrawer] = useState(true)

  const [saveTimer, setSaveTimer] = useState()

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel, func) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      func?.()
    }
  };

  const setX = x => {
    myDispatch({type: "setX", x: x})
  }
  const xUpdated = (x, triggerSave=true) => {
    dispatch(triggerUsed({xId: x.xId, name: x.name}))
    pushHistoryDebounced.current(x, triggerSave && stateRef.autoSave)
    myDispatch({type: "xUpdated", x})
  }

  const handle = useFullScreenHandle()

  const user = useSelector((state) => state.user)
  const lists = useSelector((state) => state.persistedReducer.userLists)?.lists;

  const {wheelStep, initialScale, density, avoidLongCrosses, wordMap=true, autoSave=true, renderPattern=false} = useSelector(state => state.persistedReducer.editor)
  const muiBoxRootRef = useRef()

  const stateRef = useRef();
  stateRef.dragging = dragging;
  stateRef.selections = myState.selections
  stateRef.direction = direction
  stateRef.x = myState.x
  const x = myState.x
  stateRef.matrix = myState.matrix
  stateRef.xHistory = xHistory
  stateRef.xHistoryIndex = xHistoryIndex
  stateRef.prepareSwitch = prepareSwitch
  stateRef.saveTimer = saveTimer
  stateRef.focusedElement = myState.focusedElement
  stateRef.clickLayout = clickLayout
  stateRef.wordMap = wordMap
  stateRef.autoSave = autoSave
  stateRef.renderPattern = renderPattern


  const getSelections = ()=>deepCopy(stateRef.selections)
  const getX = ()=>deepCopy(stateRef.x)

  const drawerWidth = 240;

  const switchDirection = () => setDirection((stateRef.direction + 1) % 2)

  /*  const clickListener = (event, nativeEvent) => {
      console.log(event)
      if(filterKeys(nativeEvent))
        return
    }*/

  const tryAutoLinkAndShowKeyEdit = (element) => {
    const part = stateRef.selections[0]?.clickedObj?.part
    const links = stateRef.x.links.filter(link => (ru.equals(element.rect, link.source)
        && (
            (link.sourceType === undefined && (link.part === part))
            || (link.sourceType === part === undefined)
            || (link.sourceType === UPPER && part === 0)
            || (link.sourceType === LOWER && part === 1)
        )))
    if (links.length === 0) {
      const newX = autoLinkTextRange(stateRef.x, element, stateRef.selections[0]?.clickedObj?.part)
      xUpdated(newX)
      setSelections(getSelections())
    }
    setShowKeyEditWindow(true)
  }

  const zoomToSelection = (s) => {
    if (transformComponentRef.current && s[0]?.clickedObj?.e?.sq) {
      const {x1, y1} = s[0].clickedObj.e.sq
      const endSq = s[0]?.endObj?.e?.sq || s[0].clickedObj.e.sq
      const {x2, y2} = endSq
      const targetScale = stateRef.transformState?.scale || 2
      const calculatedX = window.innerWidth/2 - (x1+(x2-x1)/2)*targetScale
      const calculatedY =  window.innerHeight/2 - (y1+(y2-y1)/2)*targetScale
      const animationTime = 500
      transformComponentRef.current.setTransform(calculatedX, calculatedY, targetScale, animationTime)
    }
  }

  const selectionIsOutsideWindow = (s) => {
    if (stateRef.transformState && s[0]?.clickedObj?.e?.sq) {
      const {x1, y1} = s[0].clickedObj.e.sq
      const {positionX, positionY, scale} = stateRef.transformState
      const endSq = s[0]?.endObj?.e?.sq || s[0].clickedObj.e.sq
      const {x2, y2} = endSq
      const calculatedX = positionX + (x1+(x2-x1)/2)*scale
      const calculatedY = positionY + (y1+(y2-y1)/2)*scale
      return calculatedX > window.innerWidth-200
          || calculatedY > window.innerHeight-200
          || calculatedX < 0+200
          || calculatedY < 0+200
    }
    return false
  }

  const doubleClickListener = (event, nativeEvent) => {
    console.log(`doubleClickListener`)
    /*setDragging(false)
    const element = stateRef.focusedElement
    if (element.type === "TEXT") {
      //check if there is a link
      tryAutoLinkAndShowKeyEdit(element)
    } else if (element.type === WORD) {
      setDirection((stateRef.direction+1)%2)
    }*/
    const element = stateRef.focusedElement
    if (element?.type === "TEXT") {
      //check if there is a link
      tryAutoLinkAndShowKeyEdit(element)
    } else if (element?.overlayPosition) {
      setShowKeyEditWindow(true)
    }
  }

  const cleanSetFonts = (fts) => {
    setFonts(fts?.filter(f=>f.name.toUpperCase().indexOf(".OTF") !== -1 || f.name.toUpperCase().indexOf(".TTF") !== -1))
  }

  const updateXElem = (fnc) => {
    const oldElem = stateRef.focusedElement
    const newX = getX()
    let newElem
    if (oldElem.overlayPosition) {
      newElem = newX.overlayTexts.find(e => sqEqualish(oldElem.sq, e.sq))
    }
    else {
      newElem = newX.elements.find(e => ru.equals(oldElem.rect, e.rect))
    }
    if (newElem) {
      const newObj = newElem.parts ? newElem.parts[stateRef.selections[0]?.clickedObj.part || 0] : newElem
      fnc(newObj)
      setX(newX)
      const newSelections = getSelections()
      newSelections[0].clickedObj.e = newElem
      setSelections(newSelections)
      pushHistoryDebounced.current(newX, true)
    } else {
      console.error("Couldn't find newElem")
    }
  }

  const trySaveX = async () => {
    //console.log("calling trySaveX" +  (new Date().toLocaleTimeString()))
    const cleanedX = deepCopy(stateRef.x)
    cleanedX.elements?.forEach(e => delete e.sq)
    cleanedX.overlayTexts?.forEach(e => delete e.sq)
    cleanedX.elements?.forEach(e => {
      if (e.type === "WORD" && !e.val) {
        e.val = ""
      }
    })
    const saved = await saveX(stateRef.x.xId, searchParams.get("uId") || uId, cleanedX)
    if (saved) {
      setNotSaved(false)
    }
  }
  const trySaveXDelayed = () => {
    //console.log("calling trySaveXDelayed" +  (new Date().toLocaleTimeString()))
    if (!stateRef.saveTimer && !searchParams.get("noAutoSave")) {
      setSaveTimer(setTimeout(()=>{
        trySaveX()
        setSaveTimer()
      }, 10000))
    }
  }

  const undoX = () => {
    const historyIndex = xHistoryIndex+1
    if (xHistory[historyIndex]) {
      setXHistoryIndex(historyIndex)
      setX(JSON.parse(xHistory[historyIndex].x))
      setSelections(stateRef.selections)
    }
  }

  const redoX = () => {
    const historyIndex = xHistoryIndex-1
    if (xHistory[historyIndex]) {
      setXHistoryIndex(historyIndex)
      setX(JSON.parse(xHistory[historyIndex].x))
      setSelections(stateRef.selections)
    }
  }

  const tryGenerateX = (config) => {
    return generateX(stateRef.x.xId, searchParams.get("uId") || uId, stateRef.x, config)
  }

  const tryStopGenerateX = (jobName) => {
    return stopGenerateX(stateRef.x.xId, searchParams.get("uId") || uId, jobName)
  }

  const mouseEnterListener = (event, nativeEvent) => {
    if (filterKeys(nativeEvent))
      return
    if (stateRef.dragging) {
      if (stateRef.selections.length > 0) {
        const newSel = getSelections()
        newSel[newSel.length - 1].endObj = event
        setSelections(newSel)
      }
    }
  }

  // Print selections
  //console.log(`render ${stateRef.selections?.length}`)

  const mouseDownListener = (event, nativeEvent) => {
    muiBoxRootRef.current?.focus()
    if (nativeEvent.ctrlKey && nativeEvent.shiftKey) {
      const sel = getSelections()
      if (sel.length === 1 && sel[0].clickedObj.e.type === WORD) {
        if (event.e.type === TEXT) {
          const newX = autoLinkWordRangeToTextRange(stateRef.x, sel[0].clickedObj.e, event.e, event.part, stateRef.direction)
          xUpdated(newX)
        } else if (event.e.overlayPosition) {
          const newX = autoLinkWordRangeToOverlayText(stateRef.x, sel[0].clickedObj.e, event.e, stateRef.direction)
          xUpdated(newX)
        }
      }
    }
    if (filterKeys(nativeEvent))
      return
    const info = startObj()
    const newSel = nativeEvent.shiftKey ? getSelections() : []
    event.key = new Date().getTime()
    newSel.push({clickedObj: event})
    setSelections(newSel)
    setDragging(true)
    const {e} = event
    if (e.type === WORD && ru.equals(e.rect, info?.e?.rect)) {
      setPrepareSwitch(e.rect)
    } else {
      setPrepareSwitch()
    }
  }

  const mouseUpListener = (event, nativeEvent) => {
    if (filterKeys(nativeEvent))
      return
    const newSel = nativeEvent.shiftKey ? getSelections() : []
    if (newSel.length > 0) {
      const down = newSel[newSel.length - 1].clickedObj
      if (!(elementEquals(down.e, event.e) && down.part === event.part)) {
        newSel[newSel.length - 1].endObj = event
        setSelections(newSel)
      }
    }
    const info = startObj()
    const {e} = event
    if (ru.equals(e.rect, info?.e?.rect) && ru.equals(e.rect, stateRef.prepareSwitch)) {
      switchDirection()
    }
    setDragging(false)
    if (stateRef.clickLayout) {
      console.log("clickLayout")
      if (e.type === WORD) {
        setArea(stateRef.x, stateRef.matrix, e.rect, TEXT, {text: ""})
        xUpdated({...stateRef.x})
        setSelections([])
      } else if (e.type === TEXT) {
        setArea(stateRef.x, stateRef.matrix, e.rect, WORD, {val: ""})
        xUpdated({...stateRef.x})
        setSelections([])
      }
    }
  }

  const handleDropped = async (file, dropInfo) => {
    if (!stateRef.matrix?.[dropInfo.y]?.[dropInfo.x])
      return
    const el = stateRef.matrix[dropInfo.y][dropInfo.x].e
    const fileName = file.name
    const parts = fileName.split(".")
    if (parts.length !== 2) {
      throw new Error(`Bad filename ${fileName}`)
    }
    const newPart0 = parts[0].replace(/[\W]+/g,"")
    const newFileName = [newPart0, ".", parts[1]].join("")
    if (el.type === IMAGE) {
      const response = await getPutImg(stateRef.x.xId, searchParams.get("uId") || stateRef.uId, newFileName)
      await fetch(response, {method: "PUT", body: file})
      const newEl = {...el, image: newFileName}
      const index = stateRef.x.elements.indexOf(el)
      stateRef.x.elements[index] = newEl
      stateRef.x.images = [...new Set([...(stateRef.x.images || []), newFileName].sort())]
      xUpdated({...stateRef.x})
    } else {
      console.log("Didn't drop image in image area")
    }
  }

  const resetCursor = () => {
    setSelections([])
    setDragging(false)
  }

  const linkAll = () => {
    // First auto link all
    let newX = autoLink(stateRef.x)

    // Link text ranges that was not handled
    // but auto link.
    const plupper = new Plupper(newX)
    const {textRanges: utr} = plupper.getUnlinkedTextRanges()
    utr.forEach(tr => {
      plupper.handleUnLinkedTextRange(tr)
    })
    newX = plupper.getX()

    // Run another round of auto link since
    // Plupper merges text cells without
    // linking them.
    newX = autoLink(newX)
    xUpdated(newX)
  }

  const unlinkAll = () => {
    stateRef.x.links = []
    xUpdated({...stateRef.x})
  }

  const tryClear = () => {
    const newX = getX()
    const newMatrix = createMatrix(newX)
    getSelections().forEach(selection => {
      const selectionRect = getSelectionRect(selection)
      clearLetters(newX, newMatrix, selectionRect)
    })
    xUpdated(newX)
  }

  const tryClearClues = () => {
    const newX = getX()
    const newMatrix = createMatrix(newX)
    getSelections().forEach(selection => {
      const selectionRect = getSelectionRect(selection)
      const part = selection.endObj ? undefined : selection.clickedObj.part
      clearClues(newX, newMatrix, selectionRect, part)
    })
    xUpdated(newX)
  }

  const skipWord = () => {
    const theWordMarker = stateRef.selections.length === 1 && getWordMarker(stateRef.matrix, startObj(), direction, null, null, stateRef.x)
    if (selectedWord.indexOf(".") === -1 && theWordMarker) {
      const newX = getX()
      const newMatrix = createMatrix(newX)
      forEachPos(({row, col}) => {
        newMatrix[row][col].e.val = ""
      }, theWordMarker)
      newX.excludeWordList = newX.excludeWordList || []
      newX.excludeWordList.push(selectedWord)
      newX.excludeWordList = [...new Set(newX.excludeWordList)];
      xUpdated(newX)
    }
  }

  const tryAutoFill = () => {
    const newX = getX()
    const newMatrix = createMatrix(newX)
    const promises = []
    newX.links.forEach(link=>{
      const wRect = link.wordRange
      const wordRange = newMatrix[wRect.y][wRect.x].e
      const wordMarker2 = getWordMarker(newMatrix, {e: wordRange}, link.direction, true, null, newX)
      const textRange = newMatrix[link.source.y][link.source.x].e
      let textRangeToSet = null
      try {
        if (textRange.parts) {
          const part = link.part !== undefined ? link.part : (link.sourceType === UPPER ? 0 : 1)
          if (!textRange.parts[part].text) {
            textRangeToSet = {textRange, part}
          }
        } else if (!textRange.text){
          textRangeToSet = {textRange}
        }
      } catch(err) {
        console.error(err)
      }
      if (wordMarker2 && textRangeToSet) {
        let theWord = ""
        forEachPos(({row, col}) => {
          theWord+=stateRef.matrix[row][col].e.val || "."
        }, wordMarker2)
        promises.push(getKeys(x.xId, searchParams.get("uId") || uId, theWord)
            .then(keys => {
              return {word: theWord, keys: keys, link: link, textRange: textRangeToSet.textRange, part: textRangeToSet.part}
            }))
      }
    })
    Promise.all(promises).then((keyPairs)=>{
      const newClues = {}
      keyPairs.forEach(keyPair=>{
        const {textRange, part} = keyPair
        const chosenKey = keyPair.keys.sort((a,b)=>a.length-b.length)[0] || ""
        const key = unbreakKey(chosenKey)
        newClues[keyPair.word]=keyPair.keys
        if (isNaN(part)) {
          textRange.text = key
        } else {
          textRange.parts[part].text = key
        }
      })

      keyPairs.forEach(keyPair => {
        const {textRange, part, word} = keyPair
        if (word === "EKONOMI") {
          console.log("Checking out EKONOMI")
        }
        if (isNaN(part)) {

        } else {
          const otherPart = (part+1)%2
          const nbrOtherRows = textRange.parts[otherPart].text.split("<br>").length
          const possibleKeys = keyPair.keys.filter(key => {
            const keyRows = key.split("\n")
            const longestLength = keyRows.reduce((prevLongest, row) => {return Math.max(row.length, prevLongest)}, 0)
            return nbrOtherRows + keyRows.length <= 4 && longestLength <= 8
          })
          if (possibleKeys.length > 0) {
            const chosenKey = possibleKeys[Math.floor(Math.random()*possibleKeys.length)]
            textRange.parts[part].text = unbreakKey(chosenKey)
          }
        }
      })

      xUpdated(newX)
    })
  }

  const setAreasTo = (type, params) => {
    getSelections().map(getSelectionRect).forEach(rect => setArea(stateRef.x, stateRef.matrix, rect, type, params))
    xUpdated({...stateRef.x})
    stateRef.matrix = createMatrix(stateRef.x)
    updateSelections()
  }

  const halfAreas = () => {
    const newSelections = getSelections()
    const newX = getX()
    const newMatrix = createMatrix(newX)
    newSelections.forEach(selection => {
      const rect = getSelectionRect(selection);
      const divided = divideArea(newX, newMatrix, rect)
      if (divided) {
        selection.clickedObj.e = divided
        selection.clickedObj.part = 0
      }
    })
    xUpdated(newX)
    if (newSelections.length === 1) {
      delete(newSelections[0].endObj)
    }
    setSelections(newSelections)
  }

  const splitAreas = _ => {
    getSelections().map(getSelectionRect).forEach(rect => splitArea(stateRef.x, stateRef.matrix, rect))
    xUpdated({...stateRef.x})
  }

  function getSelectedTextOverlay(e, newMatrix) {
    if (e.overlayPosition === UPPER) {
      return newMatrix[e.rect.y][e.rect.x].ot
    } else if (e.overlayPosition === LOWER) {
      return newMatrix[e.rect.y][e.rect.x].otl
    } else if (e.overlayPosition === LEFT) {
      return newMatrix[e.rect.y][e.rect.x].otLeft
    } else if (e.overlayPosition === RIGHT) {
      return newMatrix[e.rect.y][e.rect.x].otRight
    }
  }

  const addTextChar = (e, chr) => {
    const newX = getX()
    const newMatrix = createMatrix(newX)
    let newElement = getSelectedTextOverlay(e, newMatrix) || newMatrix[e.rect.y][e.rect.x].e
    if (newElement.parts) {
      const newElementPart = newElement.parts[stateRef.selections[0].clickedObj.part || 0]
      newElementPart.text += chr
    } else {
      newElement.text += chr
    }
    const pStyle = x.paragraphStyle || paragraphStyle
    const cSz = pStyle?.squareSize || 28.34645669
    newElement.sq = calculateElementRect(newElement, newMatrix, cSz)

    xUpdated(newX)
    setSelections([{clickedObj: {...startObj(), e: newElement}}])
  }

  const removeTextChar = (e) => {

    const newX = getX()
    const newMatrix = createMatrix(newX)
    let newElement = getSelectedTextOverlay(e, newMatrix) || newMatrix[e.rect.y][e.rect.x].e
    if (newElement.parts) {
      const newElementPart = newElement.parts[stateRef.selections[0].clickedObj.part || 0]
      newElementPart.text = newElementPart.text.slice(0, newElementPart?.text?.endsWith("<br>") ? -4 : -1)
    } else {
      newElement.text = newElement.text.slice(0, newElement?.text?.endsWith("<br>") ? -4 : -1)
    }

    const pStyle = x.paragraphStyle || paragraphStyle
    const cSz = pStyle?.squareSize || 28.34645669
    newElement.sq = calculateElementRect(newElement, newMatrix, cSz)

    xUpdated(newX)
    setSelections([{clickedObj: {...startObj(), e: newElement}}])
  }

  const updateSelections = () => {
    const newSel = getSelections()
    if (newSel.length === 1) {
      newSel.forEach(selection => {
        const {clickedObj, endObj} = selection
        if (clickedObj) {
          const e1 = clickedObj.e
          const r1 = e1.rect
          clickedObj.e = stateRef.matrix[r1.y][r1.x].e
          const e2 = endObj?.e
          if (e2) {
            const r2 = e2.rect
            endObj.e = stateRef.matrix[r2.y][r2.x].e
          }
        }
      })
      setSelections(newSel)
    }
  }

  const isText = e => e.type === TEXT || e.overlayPosition

  const shiftMetaCmds = {
    "Z": (e)=>{
      redoX()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const shiftCmds = {
    A: ()=> {
      setSelections([{
        clickedObj: {e: stateRef.matrix[0][0].e},
        endObj: {e: stateRef.matrix[stateRef.x.h-1][stateRef.x.w-1].e}
      }])
    },
    D: halfAreas,
    G: ()=>setAreasTo(GHOST),
    I: ()=>setAreasTo(IMAGE),
    S: splitAreas,
    T: ()=>setAreasTo(TEXT),
    W: ()=>setAreasTo(WORD),
    Enter: ()=>{
      tryAutoLinkAndShowKeyEdit(stateRef.focusedElement)
    },
    Backspace: ()=>{
      const element = stateRef.focusedElement
      element.number = element.number?.slice(0, -1);
      xUpdated({...stateRef.x})
    },
  }

  const metaCmds = {
    "Z": (e)=>{
      undoX()
      e.preventDefault()
      e.stopPropagation()
    },
    "S": (e)=>{
      trySaveX()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const getLeadingSize = (element) => {
    return element.leading || getFontSize(element)
  }

  const getFontSize = (element) => {
    const fontSizes = x?.paragraphStyle?.text?.fontSizes?.map(f => {
      return {
        nbr: parseInt(f.nbr),
        sz: f.sz,
        leading: f.leading,
      }
    })
    const fontSizeInfo = fontSizes[fontSizes.length-1]
    const fontSize = Math.round(parseFloat(element.fontSize || fontSizeInfo.sz)*10)/10
    return fontSize
  }

  const shiftCtrlCmds = {
    "A": () => {
      if (isText(stateRef.focusedElement)) {
        updateXElem(elm => elm.leading = getLeadingSize(elm) - 0.1)
      }
    },
    "S": () => {
      if (isText(stateRef.focusedElement)) {
        updateXElem(elm => elm.leading = getLeadingSize(elm) + 0.1)
      }
    },
    "D": () => {
      if (isText(stateRef.focusedElement)) {
        updateXElem(elm => elm.fontSize = elm.leading = getFontSize(elm) - 0.1)
      }
    },
    "F": (event) => {
      if (isText(stateRef.focusedElement)) {
        updateXElem(elm => elm.fontSize = elm.leading = getFontSize(elm) + 0.1)
      }
    },
    "G": () => {
      if (isText(stateRef.focusedElement)) {
        updateXElem(elm => {
          elm.fontSize = getFontSize(elm) - 0.1
        })
      }
    },
    "H": () => {
      if (isText(stateRef.focusedElement)) {
        updateXElem(elm => elm.fontSize = getFontSize(elm) + 0.1)
      }
    }
  }
  const ctrlCmds = {}

  const handleKeyDown = (e) => {
    if (editorModalOpen) {
      return
    }
    muiBoxRootRef.current?.focus()
    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      //setEndObj()
      //setDragging(false)
    }
    if (stateRef.selections.length === 0)
      return false
    const element = stateRef.focusedElement
    if (element) {
      let next = null
      const chr = e.key.toUpperCase()

      if (e.code === "ArrowRight") {
        next = allStep(stateRef.matrix, (e.shiftKey && endObj()) || startObj(), HZ)
      } else if (e.code === "ArrowLeft") {
        next = allStep(stateRef.matrix, (e.shiftKey && endObj()) || startObj(), HZ, false)
      } else if (e.code === "ArrowUp") {
        next = allStep(stateRef.matrix, (e.shiftKey && endObj()) || startObj(), VRT, false)
      } else if (e.code === "ArrowDown") {
        next = allStep(stateRef.matrix, (e.shiftKey && endObj()) || startObj(), VRT)
      }
      else if (e.shiftKey) {
        if (e.metaKey) {
          shiftMetaCmds[chr]?.(e)
        } if (e.ctrlKey) {
          shiftCtrlCmds[chr]?.(e)
        } else {
          shiftCmds[chr]?.()
          shiftCmds[e.key]?.()
        }
      }
      else if (e.metaKey) {
        metaCmds[chr]?.(e)
      }
      else if (e.ctrlKey) {
        ctrlCmds[chr]?.(e)
      }
      else {
        if (e.code === "Enter") {
          if (element.type === WORD) {
            switchDirection()
          } else if (isText(element)) {
            addTextChar(element, "<br>")
          }
        } else if (e.code === "Space") {
          if (element.type === WORD) {
            element.val = ""
            xUpdated(JSON.parse(JSON.stringify(x)))
            next = wordStep(stateRef.matrix, startObj(), direction)
            if (next?.e) {
              if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                setDirection(VRT)
              } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                setDirection(HZ)
              }
            }
          } else if (isText(element)) {
            addTextChar(element, " ")
          }
        } else if (e.code === "Backspace") {
          if (element.type === WORD) {
            element.val = ""
            xUpdated({...x})
            next = wordStep(stateRef.matrix, startObj(), direction, false)
            if (next?.e) {
              if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                setDirection(HZ)
              } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                setDirection(VRT)
              }
            }
          } else if (isText(element)) {
            removeTextChar(element)
          }
        } else if (e.code === "Escape") {
          if (showKeyEditWindow) {
            setShowKeyEditWindow(false)
            muiBoxRootRef.current?.focus()
          }
        } else {
          if (ALLOWED_KEY_CHARS.indexOf(chr) !== -1) {
            if (element?.type === WORD) {
              const newX = getX()
              const newMatrix = createMatrix(newX)
              newMatrix[element.rect.y][element.rect.x].e.val = chr
              xUpdated(newX)
              next = wordStep(stateRef.matrix, startObj(), direction)
              if (next?.e) {
                if (hasLink(stateRef.matrix, next.e.rect, t_right_down)) {
                  setDirection(VRT)
                } else if (hasLink(stateRef.matrix, next.e.rect, t_down_right)) {
                  setDirection(HZ)
                }
              }
            } else if (isText(element)) {
              addTextChar(element, chr)
            }
          } else if (NUMBERS.indexOf(chr) !== -1) {
            if (element?.type === WORD) {
              element.number = (element.number || "" ) + chr
              xUpdated({...stateRef.x})
            }
          } else {
            console.error(`${chr} is not in alphabet`)
          }
        }
      }
      if (next?.e) {
        if (e.shiftKey) {
          const newSel = getSelections()
          newSel[newSel.length - 1].endObj = next
          setSelections(newSel)
          if (selectionIsOutsideWindow(newSel)) {
            zoomToSelection(newSel)
          }
        } else {
          const newSel = [{clickedObj: next}]
          setSelections(newSel)
          if (selectionIsOutsideWindow(newSel)) {
            zoomToSelection(newSel)
          }
        }
      }
    } else {
      console.error("Couldn't find any match")
    }
  }

  const filterKeys = (event) => {
    return event.ctrlKey || event.metaKey
  }

  const renderPos = () => {
    const focusedElement = stateRef?.selections[0]?.clickedObj?.e
    return <span className={"focus-info"}>{focusedElement?.rect.x},{focusedElement?.rect.y}</span>
  }

  const startObj = () => {
    return getSelections()?.[stateRef.selections.length - 1]?.clickedObj
  }

  const endObj = () => {
    return getSelections()?.[stateRef.selections.length - 1]?.endObj
  }

  const signOut = () => {
    Auth.signOut().then(() => {
      dispatch(userLoggedOut())
      navigate("/signin")
    })
  }

  const renderExportWindow = () => {
    return <Draggable bounds="parent">
      <div style={{position: "absolute", zIndex: 9999999, backgroundColor: "pink", visibility: showExport ? "visible" : "hidden"}}>
        <Button variant="outlined" onClick={handle.enter}>FS</Button>
        <Button variant="outlined" onClick={() => {
          const printX = <Crossword
              x={x}
              iDs={{xId: x.xId, uId:  searchParams.get("uId") || uId}}
              print
              matrix={stateRef.matrix}
          ></Crossword>

          const div = document.createElement('div')
          const root = createRoot(div)
          flushSync(()=>{
            root.render(printX)
          })
          console.log(div.innerHTML);
          // Remove any characters outside the Latin1 range
          let decoded = unescape(encodeURIComponent(div.innerHTML));
          let base64svg = window.btoa(decoded)
          let imgSource = `data:image/svg+xml;base64,${base64svg}`;
          console.log(imgSource)
        }}>PRINT</Button>
        {renderPos()}
      </div>
    </Draggable>
  }

  const renderLinksButtons = () => {
    return miniArrows.map(arrowType => {
      return <IconButton key={arrowType} onClick={(event)=>{
        event.preventDefault()
        event.stopPropagation()
        // Try to find an adjacent overlay that can be connected. If not, try link to textRange.
        let ot
        const ge = stateRef?.selections[0]?.clickedObj?.e
        console.log(arrowType)
        if (arrowType === annot_down || arrowType === mini_south) {
          ot = stateRef.x.overlayTexts?.find(o => o.sq.y2 === ge.sq.y1 && xOverlap(o.sq, ge.sq))
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, HZ)
            xUpdated(newX)
            return
          } else {
            link(stateRef.x, tAt(ge, 0, -1) || anyAt(ge, 0, -1), wAt(ge, 0, 0), VRT, arrowType, sourceAt(ge, 0, -1, LOWER))
          }
        } else if (arrowType === right_down) {
          ot = stateRef.x.overlayTexts.find(o => o.sq.x2 === ge.sq.x1 && yOverlap(o.sq, ge.sq))
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, VRT)
            xUpdated(newX)
            return
          } else {
            link(stateRef.x, tAt(ge, -1, 0), wAt(ge, 0, 0), VRT, arrowType, sourceAt(ge, -1, 0))
          }
        } else if (arrowType === up_right) {
          ot = stateRef.x.overlayTexts.find(o => {
            console.log(parseInt(o.sq.y1)-parseInt(ge.sq.y2))
            console.log(xOverlap(o.sq, ge.sq))
            return parseInt(o.sq.y1) === parseInt(ge.sq.y2) && xOverlap(o.sq, ge.sq)
          })
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, HZ)
            xUpdated(newX)
            return
          } else {
            link(stateRef.x, tAt(ge, 0, 1), wAt(ge, 0, 0), HZ, arrowType, sourceAt(ge, 0, 1, UPPER))
          }
        } else if (arrowType === mini_east || arrowType === annot_right) {
          ot = stateRef.x.overlayTexts?.find(o => o.sq.x2 === ge.sq.x1 && yOverlap(o.sq, ge.sq))
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, VRT)
            xUpdated(newX)
            return
          } else {
            if (stateRef.focusedElement.type === WORD) {
              link(stateRef.x, tAt(ge, -1, 0) || anyAt(ge, -1, 0), wAt(ge, 0, 0), HZ, arrowType, sourceAt(ge, -1, 0))
            }
            else if (stateRef.focusedElement.type === TEXT) {
              const part = stateRef?.selections[0]?.clickedObj?.part
              link(stateRef.x, tAt(ge, 0, 0), wAt(ge, 1, 0), HZ, arrowType, sourceAt(ge, 0, 0, part))
            }

          }
        } else if (arrowType === left_down) {
          ot = stateRef.x.overlayTexts.find(o => o.sq.x1 === ge.sq.x2 && yOverlap(o.sq, ge.sq))
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, VRT)
            xUpdated(newX)
            return
          } else {
            link(stateRef.x, tAt(ge, 1, 0), wAt(ge, 0, 0), VRT, arrowType, sourceAt(ge, 1, 0))
          }
        } else if (arrowType === t_right_down) {
          link(stateRef.x, wAt(ge, -1, 0), wAt(ge, 0, 0), VRT, arrowType, sourceAt(ge, 1, 0))
        } else if (arrowType === t_down_right) {
          link(stateRef.x, wAt(ge, 0, -1), wAt(ge, 0, 0), HZ, arrowType,)
        } else if (arrowType === diag_right_east) {
          ot = stateRef.x.overlayTexts.find(o => {
            if (o.sq.y2 === ge.sq.y1) {
              const overlap = xOverlap(o.sq, ge.sq)
              return o.sq.x2 === ge.sq.x1 || overlap.x1 < ge.sq.x1
            }
            return null
          })
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, HZ)
            xUpdated(newX)
            return
          } else {
            link(stateRef.x, tAt(ge, -1, -1), wAt(ge, 0, 0), HZ, arrowType, sourceAt(ge, -1, -1, LOWER))
          }
        } else if (arrowType === diag_right_down) {
          ot = stateRef.x.overlayTexts.find(o => {
            if (o.sq.y2 === ge.sq.y1) {
              const overlap = xOverlap(o.sq, ge.sq)
              return o.sq.x2 === ge.sq.x1 || overlap.x1 < ge.sq.x1
            }
            return null
          })
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, VRT)
            xUpdated(newX)
            return
          } else {
            link(stateRef.x, tAt(ge, -1, -1), wAt(ge, 0, 0), VRT, arrowType, sourceAt(ge, -1, -1, LOWER))
          }
        } else if (arrowType === diag_left_south) {
          ot = stateRef.x.overlayTexts.find(o => {
            if (o.sq.y2 === ge.sq.y1) {
              const overlap = xOverlap(o.sq, ge.sq)
              return o.sq.x1 === ge.sq.x2 || (overlap && overlap.x1 < ge.sq.x1)
            }
            return null
          })
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, VRT)
            xUpdated(newX)
            return
          } else {
            link(stateRef.x, tAt(ge, 1, -1), wAt(ge, 0, 0), VRT, arrowType, sourceAt(ge, 1, -1, LOWER))
          }
        } else if (arrowType === down_right) {
          ot = stateRef.x.overlayTexts.find(o => o.sq.y2 === ge.sq.y1 && xOverlap(o.sq, ge.sq))
          if (ot) {
            const newX = autoLinkWordRangeToOverlayText(stateRef.x, ge, ot, HZ)
            xUpdated(newX)
            return
          } else {
            link(stateRef.x, tAt(ge, 0, -1), wAt(ge, 0, 0), HZ, arrowType, sourceAt(ge, 0, -1, LOWER))
          }
        } else {
          console.error(`Unknown arrow ${arrowType}`)
        }
        xUpdated({...stateRef.x})
      }}>
        <SvgIcon>
          <MLink e={{type: arrowType, wordRange: {x: 0, y: 0, w: 1, h: 1}, clr: "black"}} cSz={24} frame matrix={stateRef.matrix} wordRangeSq={square({rect:{x:0, y:0}}, 24)}/>
        </SvgIcon>
      </IconButton>
    })
  }

  useEffect(()=>{
    if (uId) {
      getFontList(searchParams.get("uId") || uId)
          .then(cleanSetFonts)
          .catch(console.error)
    }
  }, [searchParams, uId])

  const selectedWord = myState.selectedWord


  useEffect(()=>{
    if (selectedWord && !wrdCntCache[selectedWord]) {
      let matches = []
      const reg = new RegExp(selectedWord)
      for (let q = 0; q < words.length; q++) {
        if (words[q].length === selectedWord.length && words[q].match(reg)) {
          matches.push(words[q])
        }
      }
      wrdCntCache[selectedWord] = matches
      setWrdCntCache({...wrdCntCache})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWord, wrdCntCache])

  useEffect(()=>{
    fonts.forEach(font => dispatch(fetchUrl({uId, font: font})))
  }, [dispatch, fonts, uId])

  const renderLocalHistory = () => {
    return <Draggable handle="strong" bounds="parent">
      <Paper elevation={3} style={{
        position: "absolute",
        left: drawerWidth,
        top: 30,
        zIndex: 9999999,
        visibility: showLocalHistory ? "visible" : "hidden",
        width: 200
      }}>
        <div style={{position: "absolute", right: 0}}><IconButton sx={{fontSize: 8, marginLeft: "auto"}} onClick={()=>setShowLocalHistory(false)}><CloseIcon sx={{fontSize: 12}}/></IconButton></div>
        <Box
            m={1}
            //margin
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
        >
          <strong style={{flexGrow: 1}} className="cursor"><Typography variant="h6" style={{textAlign: "center"}}>Lokal historia </Typography></strong>
        </Box>
        <List style={{maxHeight:"50vh", overflow: "auto"}}>
          {xHistory.map((entry, index) => (
              <ListItem disablePadding key={`history_item_${index}`}>
                <ListItemButton sx={{fontSize: 12}} dense onClick={()=>{
                  const newIndex = index
                  setXHistoryIndex(newIndex)
                  setX(JSON.parse(xHistory[newIndex].x))
                }}>
                  {xHistoryIndex === index && <span>*</span>}
                  {entry.time}
                </ListItemButton>
              </ListItem>))}
        </List>
      </Paper>
    </Draggable>
  }

  const wordMarker = myState.wordMarker

  const apply = (word) => {
    if (wordMarker) {
      forEachPos(({row, col}, index) => {
        stateRef.matrix[row][col].e.val = word.charAt(index)
      }, wordMarker)
      console.log(`Applied ${word}`)
      xUpdated({...stateRef.x})
    }
  }

  const userHasList = useSelector(state => baseList && state.persistedReducer.userLists?.lists?.filter(info => info.name === baseList)?.length > 0)

  const baseListUrl = useSelector(state=>{
    if (baseList) {
      const userBaseListUrl = state.persistedReducer.userLists?.wordLists?.[baseList]?.url
      const globalBaseListUrl = state.persistedReducer.lists?.wordLists?.[baseList]?.url
      return (x?.baseList && userBaseListUrl) || globalBaseListUrl;
    }
  })

  useEffect(() => {

    document.oncontextmenu = e => {
      console.log("oncontextmenu")
      e.preventDefault()
      e.stopPropagation()
    }
    const checkLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        dispatch(userLoggedIn(user))
        const credentials = await Auth.currentCredentials()
        setUid(credentials.identityId)
        return true
      } catch (err) {
        console.error(err)
        navigate("/signin")
        return false
      }
    }
    if (!x) {
      const loggedIn = checkLoggedIn()
      setIsLoggedIn(loggedIn)
    }
  }, [searchParams, x, notSaved, dispatch, navigate]);

  useEffect(()=>{
    if (uId && isLoggedIn) {
      dispatch(fetchUserLists(searchParams.get("uId") || uId))
      dispatch(fetchLists(searchParams.get("uId") || uId))
      openX(searchParams.get("xId"), searchParams.get("uId") || uId).then(data => {
        if (!data.paragraphStyle) {
          data.paragraphStyle = paragraphStyle
        }
        //setX(data)
        xUpdated(data, false)
      }).catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uId, isLoggedIn])

  useEffect(() => {
    //console.log("running effect to set baselist")
    if (x) {
      //console.log("x exists")
      if (x.baseList) {
        //console.log("x has baselist")
        if (baseList !== x.baseList) {
          //console.log("baselist is wrong")
          setBaseList(x.baseList)
          if (userHasList) {
            dispatch(fetchUserWords({userId: uId, lists: [x.baseList]}))
          }

          dispatch(fetchWords({userId: uId, lists: [x.baseList]}))
        }
      } else {
        //console.log("x has not baselist")
        if (!baseList || baseList !== "level3") {
          console.log(`baselist is not set or set to wrong ${baseList}`)
          setBaseList("level3")
          dispatch(fetchWords({userId: uId, lists: ["level3"]}))
        }
      }
    }
  }, [x, words, dispatch, uId, baseList, userHasList])

  useEffect(()=>{
    if (baseListUrl) {
      fetch(baseListUrl)
          .then(response => response.arrayBuffer())
          .then(buffer => {
            let decoder = new TextDecoder("utf-8");
            return decoder.decode(buffer).split("\n");
          })
          .then(textArray => {
            setWrdCntCache({})
            setWords(textArray)
          })
          .catch(err => {
            console.error(err)
            setWrdCntCache({})
            setWords(["FAILURE"])
          })
    }
  }, [baseListUrl])

  useEffect(()=>{
    if (uId && user) {
      getUserRights(uId).then((userRights)=> {
        const assume = searchParams.get("uId")
        if (assume !== uId) {
          setUid(assume)
        }
        if (userRights.xRole === "admin") {
          setIsAdmin(true)
        }
      })
    }
  }, [searchParams, uId, user])

  const cursor = <>
    {myState.selections.map((selection, index) => {
      const {clickedObj} = selection
      return <Cursor
          key={clickedObj.key}
          cSz={x?.paragraphStyle?.squareSize}
          selection={selection}
          wordmarker={wordMarker}
          matrix={stateRef.matrix}
          direction={stateRef.direction}
          yPatternOffset={stateRef.x?.yPatternOffset}
          focusRect={myState.focusRects?.[index]}
      ></Cursor>
    })}
  </>
  const theCrossword = x && <Crossword
      x={x}
      iDs={{xId: x.xId, uId: searchParams.get("uId") || uId}}
      cursor={cursor}
      //clickListener={clickListener}
      doubleClickListener={doubleClickListener}
      mouseEnterListener={mouseEnterListener}
      mouseDownListener={mouseDownListener}
      mouseUpListener={mouseUpListener}
      matrix={stateRef.matrix}
      dragMode={dragMode}
      scale={stateRef.transformState?.scale || 2}
      hide={hideCrossword}
      renderPattern={stateRef.renderPattern}
  ></Crossword>

  // Setup for the context aware arrows ui
  const m = stateRef.matrix
  const tAt = (e, x, y) => {
    try {
      return e && (m[e.rect.y+y]?.[e.rect.x+x]?.e?.type === TEXT) && {x: e.rect.x+x, y: e.rect.y+y}
    } catch (err) {
      console.error(err)
    }
  }

  const anyAt = (e, x, y) => {
    try {
      return e && (m[e.rect.y+y]?.[e.rect.x+x]?.e?.type) && {x: e.rect.x+x, y: e.rect.y+y}
    } catch(err) {
      console.error(err)
    }
  }
  const wAt = (e, x, y) => {
    try {
      return e && (m[e.rect.y+y]?.[e.rect.x+x]?.e?.type === WORD) && {x: e.rect.x+x, y: e.rect.y+y}
    } catch(err) {
      console.error(err)
    }
  }

  const sourceAt = (e, x, y, def) => {
    const target = m[e.rect.y+y]?.[e.rect.x+x]?.e
    if (target?.type  === TEXT) {
      if (target?.parts) {
        return def || UPPER
      }
    }
  }
  const togglePullMode = () => {
    if (dragMode) {
      setHideCrossword(true)
      setTimeout(setHideCrossword, 1)
    }
    setDragMode(!dragMode)
  }

  const setAreasToColor = (clr) => {
    const newX = getX()
    const newMatrix = createMatrix(newX)
    getSelections().forEach(selection => {
      const selectionRect = getSelectionRect(selection)
      const part = selection.endObj ? undefined : selection.clickedObj.part
      const overlayPosition = selection.endObj ? undefined : selection.clickedObj.e.overlayPosition
      if (overlayPosition) {
        const e = selection.clickedObj.e
        const r = e.rect
        let newO
        if (overlayPosition === UPPER) {
          newO = newMatrix[r.y][r.x].ot
        } else if (overlayPosition === LOWER) {
          newO = newMatrix[r.y][r.x].otl
        } else if (overlayPosition === LEFT) {
          newO = newMatrix[r.y][r.x].otLeft
        } else if (overlayPosition === RIGHT) {
          newO = newMatrix[r.y][r.x].otRight
        }
        newO.clr = clr
      } else {
        setAreaClr(newX, newMatrix, selectionRect, clr === "#FFFFFF" ? undefined : clr, part)
      }
    })
    xUpdated(newX)
  }

  const applyNewKey = newKey => {
    updateXElem(elm => elm.text = newKey)
  }

  const renderPositionInfo = ()=>{
    const r1 = myState.selections?.[0]?.clickedObj?.e?.rect
    if (!r1)
      return
    let w,h
    if (myState.focusAreas?.[myState.focusAreas.length - 1]) {
      w = myState.focusAreas?.[myState.focusAreas.length - 1].w
      h = myState.focusAreas?.[myState.focusAreas.length - 1].h
    } else {
      if (r1?.w > 1 || r1?.h > 1) {
        w = r1.w
        h = r1.h
      }
    }

    return <>
      <Typography sx={{minWidth: "100px"}}>{selectedWord || myState.currentWord || ""}</Typography>
      <Typography sx={{minWidth: "30px"}} onClick={()=>{setShowWords(!showWords)}}>{wrdCntCache[selectedWord]?.length || 0}</Typography>
      <Typography sx={{minWidth: "80px"}}>y:{r1.y},  x:{r1.x} </Typography>
      <Typography sx={{minWidth: "80px"}}>w:{w || 1} h:{h || 1}</Typography>
    </>
  }

  const renderGeneratorWindow = () => {
    if (!showGeneratorWindow)
        return null

    let area
    const sls = getSelections()
    if (sls?.length === 1 && sls[0].endObj) {
      area = getSelectionRect(sls[0])
    }

    return <GeneratorWindow
        uId={searchParams.get("uId") || uId}
        xId={x?.xId}
        tryGenerateX={tryGenerateX}
        stopGenerateX={tryStopGenerateX}
        onApply={theX => {xUpdated(theX)}}
        onClose={()=>setShowGeneratorWindow(false)}
        area={area}
    />
  }

  const renderErrors = () => {
    return myState.errors && <Box>
      {Object.keys(myState.errors).map(k => myState.errors[k]).map(error => <Alert onClick={()=>console.log(error)} key={error.message} severity="error">{error.message}</Alert>)}
    </Box>
  }

  const getPrintX = () => {
    return <Crossword
        x={stateRef.x}
        iDs={{xId: stateRef.x.xId, uId: uId}}
        print
        matrix={stateRef.matrix}
        renderPattern={stateRef.renderPattern}
    ></Crossword>
  }

  return (
      <ThemeProvider theme={theme}>
        <FullScreen
            handle={handle}
        >
          <Box sx={{flexGrow: 1}}>
            <CssBaseline/>
            <AppBar
                //position="static"
                position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
            >
              <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{mr: 2}}
                    onClick={() => {
                      setShowDrawer(!showDrawer)
                    }}
                >
                  <MenuIcon/>
                </IconButton>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}} onClick={() => {
                  var win = window.open(`/dashboard`, "_self")
                  win.focus()
                }}>
                  Kruxet {x?.name && ` / ${x.name}`}
                </Typography>
                {renderErrors()}
                {renderPositionInfo()}
                {user.username && <Button color="inherit" onClick={signOut}> {user.username} - Log out</Button>}
              </Toolbar>
            </AppBar>
          </Box>
          {showDrawer && <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
              }}
          >
            <Toolbar/>
            <Box sx={{overflow: 'auto'}}>
              <Accordion expanded={expanded === 'structurePanel'} onChange={handleChange('structurePanel')}>
                <AccordionSummary expandIcon={<ConstructionIcon/>} aria-controls="structurePanel-content" id="structurePanel-header">
                  <Typography>Basic Tools</Typography>
                </AccordionSummary>
                {x && expanded === 'structurePanel' && <AccordionDetails>
                  <Tooltip title="Letter Shift+w">
                    <span>
                      <IconButton disabled={!stateRef.focusedElement} onClick={_=>{setAreasTo(WORD)}}><SquareOutlinedIcon/></IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Clue Shift+t">
                    <span>
                      <IconButton disabled={!stateRef.focusedElement} onClick={_=>{setAreasTo(TEXT)}}><KeyOutlinedIcon/></IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Image Shift+i">
                    <span>
                      <IconButton disabled={!stateRef.focusedElement} onClick={_=>{setAreasTo(IMAGE)}}><InsertPhotoOutlinedIcon/></IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Hole Shift+g">
                    <span>
                      <IconButton disabled={!stateRef.focusedElement} onClick={_=>{setAreasTo(GHOST)}}><BrowserNotSupported/></IconButton>
                    </span>
                  </Tooltip>
                  <Divider></Divider>
                  <SplitTextBtn focusedElement={stateRef.focusedElement} onClick={() => {
                    halfAreas()
                    muiBoxRootRef.current?.focus()
                  }}/>
                  {[{overlayPosition: RIGHT, text: "", fixedHzSize: 40},
                    {overlayPosition: LEFT, text: "", fixedHzSize: 40},
                    {overlayPosition: UPPER, text: ""},
                    {overlayPosition: LOWER, text: ""},
                  ].map((e) => {
                    const elem = stateRef.focusedElement
                    const otProp = ot2Prop(e.overlayPosition)
                    if (!elem)
                      return <></>
                    return <>
                      {[false, true].map(strike => {
                        const existing = stateRef.matrix[elem.rect.y][elem.rect.x][otProp]
                        const anyNonAllowedExisting = otExcludes(e.overlayPosition).map(overlayPosition => stateRef.matrix[elem.rect.y][elem.rect.x][ot2Prop(overlayPosition)]).filter(n=>n).length > 0
                        // If there allready exists something that excludes this one.
                        if ((!elem.overlayPosition && !existing && !anyNonAllowedExisting && !strike) || (elem.overlayPosition === e.overlayPosition && strike) ) {
                          return <OverlayTextBtn strike={strike} fontFamily={(x?.paragraphStyle).text.font} overlayPosition={e.overlayPosition} text={e.text} onClick={() => {
                            const newX = getX()
                            if (strike) {
                              removeOverlay(elem, newX)
                              setSelections([{clickedObj: {
                                  e: stateRef.matrix[elem.rect.y][elem.rect.x].e
                                }}])
                            } else {
                              addOverlayToAreas(newX, stateRef.matrix, getSelections(), e)
                              setSelections([{clickedObj: {
                                  e: newX.overlayTexts[newX.overlayTexts.length-1]
                                }}])
                            }
                            xUpdated(newX)
                            muiBoxRootRef.current?.focus()
                          }}/>
                        }
                        return null
                      })}
                    </>
                  })}
                  <Divider></Divider>
                  {arrowTypes.map(arrowType => {
                    return <IconButton disabled={!myState.focusedElement} key={`arrow_list_btn_${arrowType}`} onClick={()=>{setAreasTo(ARROW, {arrow: arrowType})}}>
                      <SvgIcon>
                        <MArrow arrowType={arrowType} cSz={24} sq={arrowSq} frame/>
                      </SvgIcon>
                    </IconButton>
                  })}
                  <Divider/>
                  {renderLinksButtons()}
                </AccordionDetails>}
              </Accordion>
              <Accordion expanded={expanded === 'magicPanel'} onChange={handleChange('magicPanel')}>
                <AccordionSummary expandIcon={<AutoFixHighIcon/>} aria-controls="magicPanel-content" id="magicPanel-header">
                  <Typography>Advanced Tools</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Tooltip title="Connect"><IconButton onClick={linkAll}><Link/></IconButton></Tooltip>
                  <Tooltip title="Auto Clue"><IconButton onClick={tryAutoFill}><AutoAwesome/></IconButton></Tooltip>
                  <Divider/>
                  <Tooltip title="Disconnect"><IconButton onClick={unlinkAll}><LinkOff/></IconButton></Tooltip>
                  <Tooltip title="Clear clues"><span><IconButton disabled={!stateRef.focusedElement} onClick={tryClearClues}><KeyOff/></IconButton></span></Tooltip>
                  <Tooltip title="Clear letters"><span><IconButton disabled={!stateRef.focusedElement} onClick={tryClear}><ClearAll/></IconButton></span></Tooltip>
                  <Tooltip title="Skip word"><IconButton onClick={skipWord}><CleanHandsIcon/></IconButton></Tooltip>
                  <Divider/>
                  <Tooltip title={"Fast layout"}><IconButton onClick={()=>{
                    const p = new Plupper(x)
                    p.plupBoard({densityLimit: density, avoidLongCrosses: true, words})
                    xUpdated(p.getX())
                  }}><DashboardCustomizeIcon/></IconButton></Tooltip>
                  <FastWeaveModal x={stateRef.x} uId={searchParams.get("uId") || uId} handleAccept={xUpdated} area={stateRef.selections[0] ? getSelectionRect(stateRef.selections[0]) : null}/>
                  {!showGeneratorWindow && <Tooltip title="Weave record"><IconButton onClick={_=>{setShowGeneratorWindow(!showGeneratorWindow)}}><VideocamIcon/></IconButton></Tooltip>}
                  <Tooltip title={"Auto Layout"}><IconButton onClick={()=>{
                    setEditorModalOpen(true)
                  }}><SquareIcon/></IconButton></Tooltip>
                  <LayoutModal
                      uId={searchParams.get("uId") || uId}
                      x={stateRef.x}
                      open={editorModalOpen}
                      onCancel={() => {
                        setEditorModalOpen(false)
                      }}
                      onAccept={(acceptedX) => {
                        setEditorModalOpen(false)
                        xUpdated(acceptedX, false)
                      }}
                      words={words}
                  />
                  <Divider/>
                  <Tooltip title="Click Layout"><IconButton color={clickLayout ? 'success':'default'} onClick={()=>{
                    setClickLayout(!clickLayout)
                  }}><BoltIcon/></IconButton></Tooltip>
                  <AnalyticsModal x={stateRef.x} uId={searchParams.get("uId") || uId} setSelections={
                    s=>{
                      setSelections(s)
                      zoomToSelection(s)
                    }
                  } onCancel={()=>{setTimeout(()=>{muiBoxRootRef.current?.focus()}, 10)}}/>
                  {x?.overlayImages && <Tooltip title="Pull overlays"><IconButton color={dragMode ? "warning" : "disabled"} onClick={togglePullMode}><DragIndicatorIcon/></IconButton></Tooltip>}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'colorPanel'} onChange={handleChange('colorPanel')}>
                <AccordionSummary
                    expandIcon={<PaletteIcon />}
                    aria-controls="paneColor2bh-content"
                    id="panelColor2bh-header"
                >
                  <Typography>Colors</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {x?.themeColors?.map(themeColor => {
                    return <IconButton key={`theme_clr_item_${themeColor}`} onClick={()=>{setAreasToColor(themeColor)}}>
                      <SvgIcon sx={{
                        backgroundColor: themeColor,
                        borderRadius: "50%",
                        border: "1px solid black"
                      }}>
                      </SvgIcon>
                    </IconButton>
                  })}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<SettingsIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                  <Typography>Puzzle Properties</Typography>
                </AccordionSummary>
                {x && expanded === 'panel1' && <AccordionDetails>
                  <PuzzlePropertyEntries lists={lists} fonts={fonts} x={x} xUpdated={xUpdated} wordFont={x.wordFont}></PuzzlePropertyEntries>
                </AccordionDetails>}
              </Accordion>
              <Accordion expanded={expanded === 'contextPanel'} onChange={handleChange('contextPanel')}>
                <AccordionSummary expandIcon={<ConstructionIcon/>} aria-controls="contextPanel-content" id="contextPanel-header">
                  <Typography>Context</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {expanded === 'contextPanel' && <ContextEntries
                      uId={searchParams.get("uId") || uId}
                      x={stateRef.x}
                      updateXElem={updateXElem}
                      elem={stateRef.focusedElement}
                      part={stateRef.selections[0]?.clickedObj?.part}
                      drawerWidth={drawerWidth}
                      fonts={fonts}
                      selections={stateRef.selections}
                      getFontSize={getFontSize}
                      getLeadingSize={getLeadingSize}
                  />}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'history'} onChange={handleChange('history', async ()=>{
                const versionData = await listXVersions(x.xId, searchParams.get("uId") || uId)
                setXVersions(versionData.Versions)
              })}>
                <AccordionSummary
                    expandIcon={<HistoryIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                  <Typography>History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{maxHeight:"50vh", overflow: "auto"}}>
                    <Divider>Local history</Divider>
                    <ListItem>
                      <IconButton onClick={undoX}><SkipPreviousIcon/></IconButton>
                      <IconButton onClick={redoX}><SkipNextIcon/></IconButton>
                    </ListItem>
                    {xHistory.map((entry, index) => (
                        <ListItem disablePadding key={`ahistory_item_${index}`}>
                          <ListItemButton sx={{fontSize: 12}} dense onClick={()=>{
                            const newIndex = index
                            setXHistoryIndex(newIndex)
                            setX(JSON.parse(xHistory[newIndex].x))
                          }}>
                            {xHistoryIndex === index && <span>*</span>}
                            {entry.time}
                          </ListItemButton>
                        </ListItem>))}
                    <Divider>Saved versions</Divider>
                    {xVersions?.map((xVersion, xVersionIndex) => (
                        <ListItem disablePadding key={`version_item_${xVersionIndex}`}>
                          <ListItemButton sx={{fontSize: 12}} dense onClick={()=>{
                            openX(searchParams.get("xId"), searchParams.get("uId") || uId, xVersion.VersionId).then(data => {
                              if (!data.paragraphStyle) {
                                console.log("Opened x with no paragraphStyle. Adding  it")
                                data.paragraphStyle = paragraphStyle
                              }
                              //setX(data)
                              xUpdated(data, false)
                            }).catch(console.error)
                          }}>
                            {new Date(xVersion.LastModified).toLocaleString()}
                          </ListItemButton>
                          <IconButton size="small" onClick={
                            ()=>{
                              deleteXVersion(stateRef.x.xId, searchParams.get("uId") || uId, xVersion.VersionId)
                              setXVersions(xVersions.filter((v, index) => index !==xVersionIndex))
                            }
                          }><Clear/></IconButton>
                        </ListItem>))}
                  </List>
                </AccordionDetails>
              </Accordion>
              <Divider/>
              <Accordion expanded={expanded === 'settingsPanel'} onChange={handleChange('settingsPanel')}>
                <AccordionSummary
                    expandIcon={<SettingsIcon />}
                    aria-controls="settingsPanel-content"
                    id="settingsPanel-header"
                >
                  <Typography>Settings</Typography>
                </AccordionSummary>
                {expanded === 'settingsPanel' && <AccordionDetails>
                  <Box sx={{padding: 3}}>
                    <TextField
                        sx={{paddingBottom: "10px"}}
                        required
                        id="outlined-required"
                        label="Wheel step"
                        defaultValue={wheelStep}
                        onChange={(e)=>{
                          dispatch(setProperty({property: "wheelStep", value: e.target.value}))
                        }}
                        type="number"
                        inputProps={{
                          step: 0.01,
                          max: 1,
                          min: 0.01
                        }}
                    />
                    <Divider sx={{margin: "10px"}}/>
                    <TextField
                        required
                        id="outlined-required"
                        label="Density"
                        defaultValue={density}
                        onChange={(e)=>{
                          dispatch(setProperty({property: "density", value: e.target.value}))
                        }}
                        type="number"
                        inputProps={{
                          step: 0.01,
                          max: 1,
                          min: 0.01
                        }}
                    />
                    <Divider sx={{margin: "10px"}}/>
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox
                            onChange={(e)=>{
                              dispatch(setProperty({
                                property: "avoidLongCrosses",
                                value: e.target.checked}))
                            }}
                            checked={avoidLongCrosses} />} label="No long cross" />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox
                            onChange={(e)=>{
                              dispatch(setProperty({
                                property: "wordMap",
                                value: e.target.checked}))
                            }}
                            checked={wordMap} />} label="No duplicates" />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox
                            onChange={(e)=>{
                              dispatch(setProperty({
                                property: "autoSave",
                                value: e.target.checked}))
                            }}
                            checked={autoSave} />} label="Auto save" />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox
                            onChange={(e)=>{
                              dispatch(setProperty({
                                property: "renderPattern",
                                value: e.target.checked}))
                            }}
                            checked={renderPattern} />} label="Visa mönster" />
                    </FormGroup>

                    <Divider sx={{margin: "10px"}}/>
                    <Button variant="contained" onClick={()=>{dispatch(clearCache())}}>Clear cache</Button>
                  </Box>
                </AccordionDetails>}
              </Accordion>
              <Accordion expanded={expanded === 'pdfPanel'} onChange={handleChange('pdfPanel')}>
                <AccordionSummary
                    expandIcon={<PictureAsPdfIcon />}
                    aria-controls="pdfPanel-content"
                    id="pdfPanel-header"
                >
                  <Typography>Export</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Tooltip title="Export puzzle">
                    <IconButton
                        onClick={async ()=> {
                          const skipLayers = stateRef.x.type === CHAOS ? ["chaosSolutionLayer"] : ["letterLayer"]
                          const r = await exportPdf({
                            uId: uId,
                            x: stateRef.x,
                            matrix: stateRef.matrix,
                            skipLayers: skipLayers,
                            fileName: `${stateRef.x.xId}_puzzle`,
                            printCrossword: getPrintX(),
                            renderPattern: stateRef.renderPattern
                          })
                          window.open(r)
                        }}>
                      <FileDownloadIcon color="warning"/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Export answer">
                    <IconButton onClick={async ()=>{
                      const r = await exportPdf({uId: uId, x: stateRef.x, matrix: stateRef.matrix, fileName: `${stateRef.x.xId}_solution`, printCrossword: getPrintX()})
                      window.open(r)
                    }}>
                      <FileDownloadIcon color="success"/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="thumb" onClick={async ()=>{
                    const printX = <Crossword
                        x={x}
                        iDs={{xId: x.xId, uId: uId}}
                        print
                        matrix={stateRef.matrix}
                    ></Crossword>
                    const r = await createThumb({uId: uId, x: stateRef.x, printCrossword: printX});
                    window.open(r.signedUrl)
                  }}><IconButton><FileDownloadIcon color="info"/></IconButton></Tooltip>
                  <Tooltip title="image" onClick={async ()=>{
                    const r = await createImage({uId: uId, x: stateRef.x, printCrossword: getPrintX(), renderPattern: stateRef.renderPattern});
                    window.open(r.signedUrl)
                  }}><IconButton><FileDownloadIcon color="danger"/></IconButton></Tooltip>
                  {isAdmin && <Tooltip title="stringify" onClick={async ()=>{
                    console.log(JSON.stringify(getX()))
                  }}><IconButton><FileDownloadIcon color="info"/></IconButton></Tooltip>}
                  {isAdmin && <Button variant="outlined" onClick={()=>{
                    printXForAlgo(stateRef.x, stateRef.matrix)
                    const allWords = getAllWords(stateRef.matrix, stateRef.x).sort()
                    console.log(allWords)
                    console.log(allWords.filter((item, index) => allWords.indexOf(item) !== index))
                  }
                  }>Algo</Button>}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'fontPanel'} onChange={handleChange('fontPanel')}>
                <AccordionSummary
                    expandIcon={<FontDownloadIcon />}
                    aria-controls="fontPanel-content"
                    id="fontPanel-header"
                >
                  <Typography>Fonts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MyDropzone sx={{flexGrow: 1, backgroundColor: "gray", minHeight: "200px"}} cb={dropResult => {
                    console.log(dropResult)
                    const {name} = dropResult
                    if (fonts.indexOf(name) === -1) {
                      getFontList()
                          .then(cleanSetFonts)
                    }
                  }
                  }>
                    {!fonts?.length && <span style={{display: "table", margin: "0 auto", paddingTop: "40px"}}>Drop fonts here</span>}
                    {fonts?.map(fontInfo => (<div style={{fontFamily: fontInfo.name.split(".")[0]}} key={fontInfo.name}
                                                  onClick={()=>{
                                                    const newX = {...stateRef.x}
                                                    newX.paragraphStyle.text.font = fontInfo.name
                                                    setX(newX)
                                                  }}
                    >{fontInfo.name}</div>))}
                  </MyDropzone>
                </AccordionDetails>
              </Accordion>
              <Divider/>
              <List>
                <Tooltip title={notSaved ? "Autosaves to the cloud after one minute. Press to save now" : "Saved"}><IconButton onClick={_=>{trySaveX()}}><CloudUploadIcon color={notSaved ? "warning" : "disabled"}/></IconButton></Tooltip>
                <Tooltip title={"Preview"}><IconButton onClick={()=>{
                  window.open(`/corrsolve/${x.xId}`)
                }}><PreviewIcon/></IconButton></Tooltip>
              </List>
            </Box>
          </Drawer>}
          <MyDropzone uId={searchParams.get("uId") || uId} xId={x?.xId} cb={handleDropped}>
            <Box
                ref={muiBoxRootRef}
                style={{backgroundColor: "lightblue"}}
                onClick={resetCursor}
                onKeyDown={handleKeyDown}
                tabIndex={-1}>
              <Container component="main" sx={{height: "100vh"}} maxWidth={false} onClick={(e)=>{
                e.preventDefault()
                e.stopPropagation()}
              }>
                {renderExportWindow()}
                {renderGeneratorWindow()}
                {showKeyEditWindow && <KeyEditWindow
                    uId={searchParams.get("uId") || uId} xId={x?.xId}
                    currentWord={selectedWord || myState.currentWord}
                    currentKey={myState.currentKey}
                    onApply={applyNewKey}
                    colors={x.themeColors}
                    onClose={()=>{
                      setShowKeyEditWindow(false)
                      muiBoxRootRef.current?.focus()
                    }}
                ></KeyEditWindow>}
                {renderLocalHistory()}
                <MWordListWindow callback={apply} matrix={stateRef.matrix} wrdCntCache={wrdCntCache} left={drawerWidth} showWords={showWords} currentWord={selectedWord}></MWordListWindow>
                {x && <TransformWrapper
                    wheel={{step: wheelStep}}
                    initialScale={initialScale}
                    maxScale={100}
                    minScale={0.1}
                    centerOnInit={true}
                    panning={{activationKeys: ["Control"]}}
                    limitToBounds={false}
                    onTransformed={(theRef)=>{
                      stateRef.transformState = {...theRef.state}
                    }}
                    disabled={dragMode}
                    doubleClick={{disabled: true}}
                    ref={transformComponentRef}
                    //wheel={{activationKeys: ["Alt", "Shift", "Control"]}}
                >
                  <TransformComponent
                      wrapperStyle={{
                        width: "100%",
                        height: "100%"}}
                  >
                    {theCrossword}
                    {/*{<div className={"someOverlay"} contentEditable style={{position: "absolute", width: x.paragraphStyle.squareSize, height: x.paragraphStyle.squareSize, backgroundColor: "pink", fontSize: 7}}/>}*/}
                  </TransformComponent>
                </TransformWrapper>}
              </Container>
            </Box>
          </MyDropzone>
        </FullScreen>
      </ThemeProvider>
  )
}
