export const THREE_IN_TOP = "threeInTop"
export const SINGLE_ENTRIES = "singleEntries"
export function createThreeInTopStreamTemplate(uId) {
  return {
    type: THREE_IN_TOP,
    "origins": [
      "localhost"
    ],
    "admins": [
      `${uId}`
    ],
    "schedule": []
  }
}

export function createSingleEntriesStreamTemplate(uId) {
  return {
    type: SINGLE_ENTRIES,
    origins: ["localhost"],
    admins: [`${uId}`],
    singleEntries:[],
  }
}
