import {deletePdf, generatePdf, pdf2Image} from "./backend";
import {paragraphStyle} from "./crossword/defaultParagraphStyle";
import {createRoot} from "react-dom/client";
import {flushSync} from "react-dom";
import {findColors, findFonts, findImages} from "./xsearch";
import {CHAOS} from "./cellTypes";

export const createThumb = async (params) => {
    console.log(params)
    const {uId, x} = params
    const fileName = `${x?.xId}_thumb`
    const skipLayers = x.type === CHAOS ? ["chaosSolutionLayer"] : ["letterLayer"]
    const exportPdfR = await exportPdf({...params, skipLayers: skipLayers, fileName: fileName})
    console.log(exportPdfR)

    const r = await pdf2Image(x.xId, uId, fileName)
    console.log(r)

    const deleteResult = await deletePdf(x?.xId, uId, fileName)
    console.log(deleteResult)
    return r
}

export const createImage = async (params) => {
    console.log(params)
    const {uId, x} = params
    const fileName = `${x?.xId}_image`
    const skipLayers = x.type === CHAOS ? ["chaosSolutionLayer"] : ["letterLayer"]
    const exportPdfR = await exportPdf({...params, skipLayers: skipLayers, fileName: fileName})
    console.log(exportPdfR)

    const r = await pdf2Image(x.xId, uId, fileName, Math.trunc(x.w*x.cellSz*10))
    console.log(r)

    const deleteResult = await deletePdf(x?.xId, uId, fileName)
    console.log(deleteResult)
    return r
}

export const exportPdf = async ({uId, x, skipLayers = [], fileName, printCrossword, size, renderPattern}) => {
    //window.open(`./printer?xId=${searchParams.get("xId")}&uId=${searchParams.get("uId")}`)
    console.log("Generating PDf")

    const pStyle = x.paragraphStyle || paragraphStyle
    const cSz = pStyle?.squareSize || 28.34645669
    const bylineSpace = (x.style?.byline || x.style?.imageByline) ? 8*1.5 : 0
    let fc = (x.style?.frame?.thickness || 1)
    let w = (x.doc?.content?.pt?.width) || (x.w * cSz + fc)
    let h = (x.doc?.content?.pt?.height) || (x.h * cSz + fc + bylineSpace)

    if (renderPattern && x.yPatternOffset) {
        const repetitions = x.h/x.yPatternOffset
        const width = (cSz*x.w)*repetitions
        const height = (cSz*x.h)
        w=width
        h=height
    }

    const xPos = 0
    const yPos = 0

    const div = document.createElement('div')
    const root = createRoot(div)
    flushSync(()=>{
        root.render(printCrossword)
    })
    console.log(div.innerHTML);

    const svgData = div.innerHTML
    const fonts = findFonts(x)
    const images = findImages(x)
    const colors = findColors(x)
    return await generatePdf({
        xId: x.xId,
        userId: uId,
        svgData: svgData,
        skipLayers: skipLayers,
        fileName: fileName,
        fonts: fonts,
        images: images,
        colorMap: colors,
        pdfOptions:{size: size || [w, h]},
        x: xPos,
        y: yPos
    })
}
