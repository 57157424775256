import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";

export default function TextEntrySelect({idPart, label, vals, elem, updateXElem, drawerWidth}) {
    const id = `${idPart}_demo-simple-select-autowidth`
    const lbl = `${id}-label`
    const onSelect = (event, prop) => {
        updateXElem(newElem => {
            newElem[prop] = event.target.value === "None" ? undefined : event.target.value
        })
    }
    return <FormControl key={`${idPart}_imgFrmCntrl`} sx={{ m: 1, width: (drawerWidth-40) }}>
        <InputLabel id={lbl}>{idPart}</InputLabel>
        <Select
            labelId={lbl}
            id={id}
            value={elem[idPart] || "None"}
            onChange={(e)=>{onSelect(e, idPart)}}
            autoWidth
            label={label || idPart}
        >
            <MenuItem key="None" value="None">
                <em>None</em>
            </MenuItem>
            {vals?.map(val => (<MenuItem key={val} value={val}>{val}</MenuItem>))}
        </Select>
    </FormControl>
}
