import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchOpenUrl, fetchUrl} from "../features/urlcache/urlSlice";

export default function Images (props) {
  const {cSz, elements, iDs, print, stream} = props
  return <g className="imageLayer">
    {
      elements.map((e) => {
        return print ? <PrintImage
            key={`x${e.rect.x}_y${e.rect.y}_w${e.rect.w||1}_h${e.rect.h||1}`}
            r={e.rect}
            cSz={cSz}
            e={e}
            alignment={e.alignment}
            clip={e.clip}
        /> : <MImage
            key={`x${e.rect.x}_y${e.rect.y}_w${e.rect.w||1}_h${e.rect.h||1}`}
            r={e.rect}
            cSz={cSz}
            e={e}
            iDs={iDs}
            stream={stream}
            print={print}
            alignment={e.alignment}
            clip={e.clip}
        />
      })
    }
  </g>
}


const Image = (props) => {
  const dispatch = useDispatch()
  const {e, r, cSz, iDs, stream, alignment} = props
  const {xId, uId} = iDs
  const signedUrl = useSelector((state) => state.persistedReducer.urls.cache?.[e.image]?.url)

  useEffect(() => {
    const getImage = async () => {
      if (xId && e.image) {
        if (!signedUrl) {
          if (stream) {
            dispatch(fetchOpenUrl({streamName: stream, puzzleId: xId, imageName: e.image}))
          } else if (uId) {
            dispatch(fetchUrl({xId, userId: uId, image: e.image}))
          } else {
            console.error(`Cant get image yet since: ${stream}, : ${uId}`)
          }
        }
      } else {
        console.error(`Cant get image yet since: ${xId}, : ${e.image}`)
      }
    }
    getImage()
  }, [dispatch, e.image, uId, xId, stream, signedUrl])

  if (e.clip) {
    return (<svg
        x={r.x * cSz} y={r.y * cSz} width={(r.w || 1) * cSz} height={(r.h || 1) * cSz}
        viewBox={`${e.clip.x} ${e.clip.y} ${e.clip.w} ${e.clip.h}`}
        version="1.1"
    >
      <image
          href={signedUrl}
      />
    </svg>)
  } else {
    return (<image
        x={r.x * cSz} y={r.y * cSz} width={(r.w || 1) * cSz} height={(r.h || 1) * cSz}
        href={signedUrl}
        preserveAspectRatio={`${alignment || "xMinYMin"} slice`}
    />)
  }

}

const PrintImage = (props) => {
  const {e, r, cSz, alignment} = props

  if (e.clip) {
    return (<svg x={r.x * cSz}
                 y={r.y * cSz}
                 width={(r.w || 1) * cSz}
                 height={(r.h || 1) * cSz}
                 viewBox={`${e.clip.x} ${e.clip.y} ${e.clip.w} ${e.clip.h}`}
                 version="1.1"
    >
      <image
          href={e.image}
      />
    </svg>)
  }

  return (<image
      x={r.x * cSz}
      y={r.y * cSz}
      width={(r.w || 1) * cSz}
      height={(r.h || 1) * cSz}
      href={e.image}
      preserveAspectRatio={`${alignment || "xMinYMin"} slice`}
  />)
}

const MImage = React.memo(Image, (pp, np) => {
  return pp.e.clip === np.e.clip && pp.e.image === np.e.image && pp.e.alignment === np.e.alignment && pp.iDs?.uId === np.iDs?.uId
})
