import TextField from "@mui/material/TextField";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import * as React from "react";
import ColorProperty from "./ColorProperty";

export default function PuzzlePropertyEntries({x, xUpdated, lists, fonts, wordFont}) {
    const currentFont = x?.paragraphStyle?.text?.font
    return <Box>
        <TextField
            sx={{paddingBottom: "10px"}}
            required
            id="outlined-required"
            label="Name"
            defaultValue={x.name}
            onChange={(e) => {
                xUpdated({...x, name: e.target.value})
            }}
        />
        <FormControl fullWidth sx={{paddingBottom: "10px"}}>
            <InputLabel id="demo-baselist-select-label">Base list</InputLabel>
            <Select
                labelId="demo-baselist-select-label"
                id="baselist-select"
                value={x.baseList || "None"}
                label="Base list"
                onChange={(event) => {
                    const newValue = event.target.value === "None" ? undefined : event.target.value
                    xUpdated({...x, baseList: newValue})
                }}
            >
                <MenuItem value={"None"}>None</MenuItem>)
                {lists.map(list => (<MenuItem key={list.name} value={list.name}>{list.name}</MenuItem>))}
            </Select>
        </FormControl>
        <TextField
            sx={{paddingBottom: "10px"}}
            required
            id="outlined-required"
            label="byline"
            defaultValue={x.style?.byline || ""}
            onChange={(e) => {
                xUpdated({...x, style: {...x.style, byline: e.target.value}})
            }}
        />
        <TextField
            sx={{paddingBottom: "10px"}}
            required
            id="outlined-required"
            label="image byline"
            defaultValue={x.style?.imageByline || ""}
            onChange={(e) => {
                xUpdated({...x, style: {...x.style, imageByline: e.target.value}})
            }}
        />

      <ColorProperty
          theme={x.themeColors}
          onNewColor={(newThemeColors)=>{
            console.log(`onUpdateTheme ${JSON.stringify(newThemeColors)}`)
            xUpdated({...x, themeColors: [...x.themeColors, newThemeColors.hex]})
          }}
          onDeleteColor={clr => {
            console.log(`onDeleteColor ${clr}`)

            const newThemeColors = x.themeColors.filter(c => clr !== c)
            console.log(`${JSON.stringify(x.themeColors)}, ${JSON.stringify(newThemeColors)}`)
            xUpdated({...x, themeColors: newThemeColors})
          }}
      ></ColorProperty>

        <FormControl fullWidth sx={{paddingBottom: "10px"}} key={"formctrl-font-select"}>
            <InputLabel id="font-select-label">Main font</InputLabel>
            <Select
                labelId="font-select-label"
                id="font-select"
                value={currentFont || "None"}
                label="Solution font"
                onChange={(event) => {
                    const newX = {...x}
                    newX.paragraphStyle.text.font = event.target.value
                    xUpdated(newX)
                }}
            >
                <MenuItem value={"None"}>None</MenuItem>
                {
                    fonts.map(font => {
                        const fontName = font.name.split(".")[0]
                        return <MenuItem value={fontName}>{fontName}</MenuItem>
                    })
                }
            </Select>
        </FormControl>

        <FormControl fullWidth sx={{paddingBottom: "10px"}} key={"formctrl-wordfont-select"}>
            <InputLabel id="font-wordselect-label">Solution font</InputLabel>
            <Select
                labelId="font-wordselect-label"
                id="wordfont-select"
                value={wordFont || "None"}
                label="Word font"
                onChange={(event) => {
                    const newX = {...x}
                    newX.wordFont = event.target.value
                    xUpdated(newX)
                }}
            >
                <MenuItem value={"None"}>None</MenuItem>
                {
                    fonts.map(font => {
                        const fontName = font.name.split(".")[0]
                        return <MenuItem value={fontName}>{fontName}</MenuItem>
                    })
                }
            </Select>
        </FormControl>

        <FormControl fullWidth sx={{paddingBottom: "10px"}} key={"formctrl-framestyle-select"}>
            <InputLabel id="framestyle-select-label">Frame type</InputLabel>
            <Select
                labelId="framestyle-select-label"
                id="framestyle-select"
                value={x?.style?.frame?.type || "none"}
                label="Frame type"
                onChange={(event) => {
                    console.log(event.target.value)
                    const newX = JSON.parse(JSON.stringify(x))
                    let frame = newX?.style?.frame || {color: "black", thickness: 1}
                    frame.type =  event.target.value
                    if (!newX?.style) {
                        newX.style = {}
                    }
                    newX.style.frame = frame
                    console.log(newX)
                    xUpdated(newX)
                }}
            >
                <MenuItem value={"none"}>None</MenuItem>
                <MenuItem value={"standard"}>Standard</MenuItem>
                <MenuItem value={"irregular"}>Irregular</MenuItem>
            </Select>
        </FormControl>
        <FormControl fullWidth sx={{paddingBottom: "10px"}} key={"formctrl-framestyle-select"}>
        <InputLabel id="yPatternOffset-select-label">Y-pattern offset</InputLabel>
        <Select
            labelId="framestyle-select-label"
            id="framestyle-select"
            value={x?.yPatternOffset || "none"}
            label="Y-pattern offset"
            onChange={(event) => {
              console.log(event.target.value)
              const newX = JSON.parse(JSON.stringify(x))
              if (event.target.value === "none") {
                newX.yPatternOffset = undefined
              } else {
                newX.yPatternOffset = parseInt(event.target.value)
              }
              xUpdated(newX)
            }}
        >
            <MenuItem value={"none"}>None</MenuItem>
            {Array.from(Array(x.h).keys()).filter(item => x.h%item === 0).map((item) => (<MenuItem value={`${item}`}>{item}</MenuItem>))}
        </Select>
      </FormControl>
        {x?.style?.frame?.thickness && <FormControl fullWidth sx={{paddingBottom: "10px"}} key={"formctrl-framewidth-select"}>
            <InputLabel id="framewidth-select-label">Frame width</InputLabel>
            <Select
                labelId="framewidth-select-label"
                id="framewidth-select"
                value={x.style.frame.width}
                label="Frame width"
                onChange={(event) => {
                    const newX = JSON.parse(JSON.stringify(x))
                    newX.style.frame.thickness = event.target.value
                    xUpdated(newX)
                }}
            >
                <MenuItem value={"none"}>0</MenuItem>
                {[1,2,3,4,5].map(v => (<MenuItem value={v}>{v}</MenuItem>))}
            </Select>
        </FormControl>}

        <List sx={{padding: 0, fontSize: 8}}>{
            x.images?.map(i => {
                return <ListItem sx={{width: "100%", overflow: "scroll", padding: 0}}>{i}</ListItem>
            })
        }</List>

    </Box>
}
