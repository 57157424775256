import convert from "color-convert";
import * as React from "react";

export default function SvgChaos({chaosInfo, chaosState, cSz}) {
    const {strokeClr, wrdsLists} = chaosInfo
    const strokeHex = strokeClr && `#${convert.cmyk.hex(...strokeClr)}`
    let offset = cSz/2
    const lines = wrdsLists.map(dirList => {
        const {type} = dirList
        return dirList.words.map(word=>{
            const points = word.cells.map(cell => `${cell.column*cSz + offset},${cell.row*cSz + offset}`)
            return <polyline key={`${type}_${points[0]}`} points={points.join(" ")} stroke={strokeHex || "black"} fill="none" strokeWidth={2} markerStart="url(#triangle)" markerEnd="url(#triangle)"/>
        })
    })
    const solvedLines = wrdsLists.map(dirList => {
        const {type} = dirList
        return dirList.words.map(word=>{
            if (chaosState?.found && chaosState.found.indexOf(word.word) !== -1) {
                const points = word.cells.map(cell => `${cell.column*cSz + offset},${cell.row*cSz + offset}`)
                return <polyline key={`${type}_${points[0]}`} points={points.join(" ")} stroke={strokeHex || "black"} fill="none" strokeWidth={2} markerStart="url(#triangle)" markerEnd="url(#triangle)"/>
            }
            return <></>
        })
    })

    const line = wrdsLists.map(dirList => {
        const {type} = dirList
        return dirList.words.filter(word=>word.strike).map(word=>{
            const points = word.cells.map(cell => `${cell.column*cSz + offset},${cell.row*cSz + offset}`)
            return <polyline key={`${type}_${points[0]}`} points={points.join(" ")} stroke={strokeHex || "black"} fill="none" strokeWidth={2} markerStart="url(#triangle)" markerEnd="url(#triangle)"/>
        })
    })
    return <>
        {/*<defs>
            <marker
                id="triangle"
                viewBox="0 0 5 5"
                refX="0.5"
                refY="2.5"
                markerUnits="strokeWidth"
                markerWidth="5"
                markerHeight="5"
                orient="auto">
                <path d="M 0 0 L 5 2.5 L 0 5 z" fill={strokeHex} />
            </marker>
        </defs>*/}
        {!chaosState && <g className={"chaosSolutionLayer"}>
            {lines}
        </g>}
        {chaosState && <g className={"chaosStateLayer"}>
            {solvedLines}
        </g>}
        <g className={"chaosLayer"}>
            {line}
        </g>
    </>
}
