import List from "@mui/material/List";
import {IMAGE, TEXT} from "../../cellTypes";
import {ru} from "../../xutils";
import ListItem from "@mui/material/ListItem";
import * as React from "react";
import ImageEntry from "./ImageEntry";
import TextEntry from "./TextEntry";
import {useState} from "react";
import {Info} from "@mui/icons-material";
import TextOverlayEntry from "./TextOverlayEntry";

export default function ContextEntries({elem, drawerWidth, fonts, updateXElem, x, uId, selections, part, getFontSize, getLeadingSize}) {
    const [showInfo, setShowInfo] = useState(false)
    const [showSelections, setShowSelections] = useState(false)

    const renderContext = () => {
        if (!elem)
            return

        return <List sx={{fontSize: 8, padding: 0}}>
            <Info color="info" onClick={() => {setShowInfo(!showInfo)}}/>
            <Info onClick={() => {setShowSelections(!showSelections)}}/>
            {elem.type === IMAGE && <ImageEntry key={`imageEntry-${ru.str(elem.rect)}`} elem={elem} drawerWidth={drawerWidth} updateXElem={updateXElem} x={x} xId={x.xId} uId={uId}/>}
            {elem.type === TEXT && <TextEntry key={`textEntry-${ru.str(elem.rect)}`} fonts={[...new Set(fonts.filter(f=>f.name.indexOf(".otf") !== -1 || f.name.indexOf(".ttf") !== -1).map(f=>f.name.split(".")[0]))]} elem={elem.parts? elem.parts[part] : elem} drawerWidth={drawerWidth} updateXElem={updateXElem} x={x} xId={x.xId} uId={uId} getFontSize={getFontSize} getLeadingSize={getLeadingSize}/>}
            {elem.overlayPosition && <TextOverlayEntry key={`otEntry-${elem.overlayPosition}-${ru.str(elem.rect)}`} fonts={[...new Set(fonts.filter(f=>f.name.indexOf(".otf") !== -1 || f.name.indexOf(".ttf") !== -1).map(f=>f.name.split(".")[0]))]} elem={elem} drawerWidth={drawerWidth} updateXElem={updateXElem} x={x} xId={x.xId} uId={uId} getFontSize={getFontSize} getLeadingSize={getLeadingSize}></TextOverlayEntry>}
            {showInfo && Object.keys(elem).filter(k=>!(elem.type === IMAGE && k === 'image')).map(k=>{
                return <ListItem key={k} sx={{padding: 0}}>
                    {k}: {JSON.stringify(elem[k])}
                </ListItem>
            })}
            {showSelections && selections.map((selection, i) => {
                return <ListItem key={`selection_item_${i}`}>
                    {selection.clickedObj && <ListItem>clickedObj: {JSON.stringify(selection.clickedObj)}</ListItem>}
                </ListItem>
            })}
        </List>
    }
    return renderContext()
}
