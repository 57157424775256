import IconButton from "@mui/material/IconButton";
import PanToolIcon from "@mui/icons-material/PanTool";
import {Divider, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {Maximize, Minimize} from "@mui/icons-material";
import {useState} from "react";

export default function WindowHeader({title, onClose, onMinimize, onMaximize}){
    const [minimized, setMinimized] = useState()
    const theme = useTheme()
    return <>
        <strong style={{flexGrow: 1}} className="cursor">
            <ul style={
                {
                    cursor: "grab",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 33%)",
                    justifyItems: "center",
                    listStyle: "none",
                    padding: 0,
                    margin: 0,
                    backgroundColor: theme.palette.secondary.light,
                    minWidth: "400px"
                }}>
                <li style={{marginRight: "auto"}}>
                    <IconButton size="small" disabled>
                        <PanToolIcon style={{transform: "scale(0.75)"}}></PanToolIcon>
                    </IconButton>
                </li>
                <li style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Typography style={{userSelect: "none"}}>
                        {title}
                    </Typography>
                </li>
                <li style={{marginLeft: "auto"}}>
                    {minimized && <IconButton size="small" onClick={()=>{
                        onMaximize()
                        setMinimized(false)
                    }}>
                        <Maximize></Maximize>
                    </IconButton>}

                    {!minimized && <IconButton size="small" onClick={()=>{
                        setMinimized(true)
                        onMinimize()
                    }}>
                        <Minimize></Minimize>
                    </IconButton>}

                    <IconButton size="small" onClick={onClose}>
                        <CloseIcon></CloseIcon>
                    </IconButton>
                </li>
            </ul>
        </strong>
        <Divider/>
    </>
}
