import _ from "lodash"; // cool kids know _ is low-dash

export const docStartState = {
    selection: null,
    attempts: null,
}

export const ON_DOC_SELECTION = "selection"
export const ON_KEY_PRESS = "key_press"
export const RESTORE_SAVED = "restore_saves"
export const SUDOKU_KEY_DOWN = "sudoku_key_down"

const EMPTY = "."

export default function docReducer(state, action) {

    const handleNumberPress = (number) => {
        const {attempts={}, selection} = state
        if (!selection?.puzzle) {
            return
        }
        const newAttempts = {...attempts}
        if (number === "Clear") {
            delete newAttempts[selection.puzzle]
        } else if (number === "Empty") {
            const attempt = attempts?.[selection.puzzle] || Array(80).fill(EMPTY)
            attempt[selection.col*9 + selection.row] = EMPTY
            newAttempts[selection.puzzle] = attempt
        } else {
            const attempt = attempts?.[selection.puzzle] || Array(80).fill(EMPTY)
            attempt[selection.col*9 + selection.row] = number
            newAttempts[selection.puzzle] = attempt
        }
        return newAttempts
    }

    switch (action.type) {
        case SUDOKU_KEY_DOWN:
            const {key} = action
            if (!isNaN(key)) {
                const number = parseInt(key)
                if (number >= 1 && number <= 9) {
                    const newAttempts = handleNumberPress(key) || state.attempts
                    return {...state, attempts: newAttempts}
                }
            } else {
                let newRow = state.selection.row
                let newCol = state.selection.col
                switch (key) {
                    case "Backspace":
                        const newAttempts = handleNumberPress(EMPTY) || state.attempts
                        return {...state, attempts: newAttempts}
                    case "ArrowUp":
                        while (newRow > 0) {
                            newRow --
                            if (state.selection.puzzle.charAt(state.selection.col*9 + newRow) === EMPTY) {
                                const newState = _.cloneDeep(state)
                                newState.selection.row = newRow
                                return newState
                            }
                        }
                        return {...state}
                    case "ArrowDown":
                        while (newRow < 8) {
                            newRow++
                            if (state.selection.puzzle.charAt(newCol*9 + newRow) === EMPTY) {
                                const newState = _.cloneDeep(state)
                                newState.selection.row = newRow
                                return newState
                            }
                        }
                        return {...state}
                    case "ArrowLeft":
                        while (newCol > 0) {
                            newCol--
                            if (state.selection.puzzle.charAt(newCol*9 + newRow) === EMPTY) {
                                const newState = _.cloneDeep(state)
                                newState.selection.col = newCol
                                return newState
                            }
                        }
                        return {...state}
                    case "ArrowRight":
                        while (newCol < 8) {
                            newCol++
                            if (state.selection.puzzle.charAt(newCol*9 + newRow) === EMPTY) {
                                const newState = _.cloneDeep(state)
                                newState.selection.col = newCol
                                return newState
                            }
                        }
                        return {...state}
                    default:
                        console.log(key)
                }
            }
            return {...state}
        case ON_DOC_SELECTION:
            return {...state, selection: {...action.selection}}
        case ON_KEY_PRESS:
            const newAttempts = handleNumberPress(action.nbr) || state.attempts
            return {...state, attempts: newAttempts}
        case RESTORE_SAVED:
            return {...state, attempts: _.cloneDeep(action.attempts)}
        default:
            console.log(`Unmappend type ${action.type}`)
            return state
    }
}
