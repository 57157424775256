import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea} from "@mui/material";

export default function MediaControlCard(props) {
    const {image, title, text, onClick} = props
    useTheme();

    return (
        <Card className="mediacontrolcard">
            <CardActionArea onClick={onClick}>
                {(title || text) && <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography className="mediacontrolcard-title" component="div" variant="h5">
                                {title}
                            </Typography>
                            <Typography className="mediacontrolcard-text" variant="subtitle1" component="div">
                                {text}
                            </Typography>
                        </CardContent>
                    </Box>
                    <div></div>
                </>}
                <Box
                    sx={{ height: 200, overflow: "hidden", flex: 1 }}
                >
                    <div className="mediacontrolcard-image">
                        {image}
                    </div>
                </Box>
            </CardActionArea>
        </Card>
    );
}
