import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {CircularProgress, ListItem, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import {useEffect, useRef, useState} from "react";
import Crossword from "../../crossword/svgcrossword";
import {generateX, stopGenerateX} from "../../backend";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import List from "@mui/material/List";
import {Plupper} from "../../utils/Plupper";
import {createMatrix} from "../../xutils";
import {debounce} from "../../debounce";
import {useSelector} from "react-redux";

const websocketUrl = 'wss://edeq3osypi.execute-api.eu-north-1.amazonaws.com/dev'
const PIXEL_LOWEST_CNT = "LOWEST_CNT"
const LETTER_SORT_RANDOM = "LETTER_SORT_RANDOM"

export default function FastWeaveModal({x, uId, handleAccept, area}) {

    const [open, setOpen] = useState(false)
    const [theMatrix, setTheMatrix] = useState(null)
    const [generated, setGenerated] = useState(null)
    const [websocket, setWebsocket] = useState(null)
    const [working, setWorking] = useState(false)
    const [theJobName, setTheJobname] = useState(null)

    const {wordMap=true} = useSelector(state => state.persistedReducer.editor)

    const stateRef = useRef();
    stateRef.area = area
    stateRef.x = x
    stateRef.wordMap = wordMap

    const onGenerateClick = async (someX)=>{
        if (!websocket) {
            const jobName = new Date().getTime()
            const p = new Plupper(someX)
            const asyncCreateWebSocket = async () => {
                return new Promise((resolve, reject) => {
                    setWorking(true)
                    setTheJobname(jobName)
                    const ws = new WebSocket(`${websocketUrl}?uId=${uId}&xId=${someX.xId}&jobName=${jobName}`);
                    setWebsocket(ws)
                    ws.onclose = (event) => {
                        setWebsocket(null)
                        setWorking(false)
                    }
                    ws.onerror = (event)=>{
                        reject(event)
                        setWorking(false)
                    }
                    ws.onmessage = (event)=>{
                        const {data} = event
                        const parsedData = JSON.parse(data)
                        const {x: xPos,y: yPos,v: value, state, message} = parsedData
                        if (!isNaN(xPos) && !isNaN(yPos)) {
                            p.e(xPos,yPos).val = value
                            setGenerated(p.getX())
                        } else if (state === "SUCCESS" && message === "All cells are filled") {
                            setGenerated(p.getX())
                        } else if (state === "FAILED") {
                            setGenerated(null)
                        }
                    }
                    ws.onopen = (event) => resolve(ws)
                })
            }

            const ws = await asyncCreateWebSocket()

            const config = {pickAlgo: PIXEL_LOWEST_CNT, letterSort: LETTER_SORT_RANDOM, nolog: true}
            const done = await generateX(someX.xId, uId, p.getX(), {...config, jobName, socket: true, storeS3: false, area: stateRef.area, wordMap: stateRef.wordMap})
            if (ws.readyState === 1) {
                ws.close()
            }
            return done
        } else {
            console.error("There is allready a websocket connected")
        }
    }
    const generateDebounced = useRef(debounce(onGenerateClick, 500))

    useEffect(() => {
        if (!open) {
            setGenerated(null)
        } else if (x) {
            if (!theMatrix) {
                const xtg = JSON.parse(JSON.stringify(x))
                setTheMatrix(createMatrix(xtg))
            } else {
                generateDebounced.current(x)
            }
        }
    }, [open, x, theMatrix])

    const handleCancel = () => {
        setOpen(false)
        stopGenerateX(x.xId, uId, theJobName)
    }

    return (<React.Fragment>
        {!open && <Tooltip title="Fast weave">
            <IconButton onClick={()=>{setOpen(true)}}>
                <SmartToyIcon/>
            </IconButton></Tooltip>}
        {open && <Dialog
            open={open}
            onClose={handleCancel}
        >
            <DialogTitle>Fast weave</DialogTitle>
            <DialogContent>
                <List>
                    <ListItem>
                        {theMatrix && <Crossword
                            x={generated || x}
                            print
                            matrix={theMatrix}
                            doScale={0.5}
                            onSvgClick={()=>{}}
                        ></Crossword>}
                    </ListItem>
                    <ListItem>
                        {working && <>
                            <CircularProgress />
                            <IconButton
                                onClick={()=>{
                                    stopGenerateX(x.xId, uId, theJobName)
                                }}>
                                <StopCircleIcon/>
                            </IconButton>
                        </>}
                        {!working && <>
                            <IconButton onClick={()=>{
                                generateDebounced.current(stateRef.x)
                            }}><RefreshIcon/></IconButton>
                        </>}
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={event=> {
                    event.preventDefault();
                    setOpen(false)
                    stopGenerateX(x.xId, uId, theJobName)
                    handleAccept?.(generated);
                }}>Ok</Button>
            </DialogActions>
        </Dialog>}
    </React.Fragment>)
}
