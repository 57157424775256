import * as React from "react";
import {ru} from "../xutils";
import {sqEqualish} from "./square";

export default function Rects (props) {
  const {elements, strokeWidth, stroke, overlayTexts} = props
  return <g className="rectLayer">
    {
      elements.map(element => {
        return <MRect
            key={`e_rect_${ru.str(element.rect)}`}
            stroke={stroke}
            strokeWidth={strokeWidth}
            sq={element.sq}
        />
      })
    }
    {
      overlayTexts?.map(ot=>{
        return <MRect
            key={`ot_${ot.overlayPosition}_rect_${ru.str(ot.rect)}`}
            stroke={stroke}
            strokeWidth={strokeWidth}
            sq={ot.sq}
        />
      })
    }
  </g>
}

export const Rect = ({stroke, strokeWidth, sq}) => {
  return (<rect x={sq.x1} y={sq.y1} width={sq.x2-sq.x1} height={sq.y2-sq.y1} fill="none" stroke={stroke || "black"} strokeWidth={strokeWidth || 0.5}></rect>)
}


export const MRect = React.memo(Rect, (pp, np) => {
  return sqEqualish(pp.sq, np.sq)
      && pp.strokeWidth === np.strokeWidth
      && pp.stroke === np.stroke
})
