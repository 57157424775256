import * as React from "react";
import {elementEquals, eu} from "../xutils";
import {calculateElementRect, calculateOverlayRect} from "../xutils";
import {useLayoutEffect, useRef} from "react";

const noPointerEventSupport = !("PointerEvent" in window)

export default function Interactions (props) {
  const {
    cSz,
    elements,
    clickListener,
    doubleClickListener,
    mouseEnterListener,
    mouseDownListener,
    mouseUpListener,
    overlayTexts,
    matrix
  } = props

  return <g className="interactionLayer">
    {elements.map((e) => (<MInteraction
      cSz={cSz}
      e={e}
      key={`interaction_${eu.str(e)}`}
      clickListener={clickListener}
      doubleClickListener={doubleClickListener}
      mouseEnterListener={mouseEnterListener}
      mouseUpListener={mouseUpListener}
      mouseDownListener={mouseDownListener}
      overlayTexts={overlayTexts}
      matrix={matrix}
      rectFunc={calculateElementRect}
  />))}
    {overlayTexts?.map((e) => (<MInteraction
        cSz={cSz}
        e={e}
        key={`ot_${e.overlayPosition}_interaction_${eu.str(e)}`}
        clickListener={clickListener}
        doubleClickListener={doubleClickListener}
        mouseEnterListener={mouseEnterListener}
        mouseUpListener={mouseUpListener}
        mouseDownListener={mouseDownListener}
        overlayTexts={overlayTexts}
        matrix={matrix}
        rectFunc={calculateOverlayRect}
    />))}
  </g>
}

const Interaction = (props) => {
  const {e, cSz, clickListener, doubleClickListener, mouseEnterListener, mouseDownListener, mouseUpListener,matrix, rectFunc} = props
  const {rect: r} = e
  const sq = rectFunc(e, matrix, cSz)
  const ref = useRef(null);
  //console.log("Render Interaction")

  useLayoutEffect(() => {
    ref.current.style.setProperty("pointer-events", "auto", "important");
  }, []);

  const doubleClickHandler = (event, part) => {
    doubleClickListener?.({e, part}, event)
  }

  const clickHandler = (event, part) => {
    clickListener?.({e, part}, event)
  }

  const mouseUpHandler = (event, part) => {
    mouseUpListener?.({e, part}, event)
  }

  const mouseDownHandler = (event, part) => {
      event.target.releasePointerCapture?.(event.pointerId)
      mouseDownListener?.({e, part}, event)
  }

  const mouseEnterHandler = (event, part) => {
    mouseEnterListener?.({e, part}, event)
  }

  if (sq.parts) {
    return sq.parts.map((part, i)=> {
      return <rect
          onDoubleClick={(event)=>{
            doubleClickHandler(event, i)
          }}
          onClick={(event, i) => {
            clickHandler(event, i)
          }}
          onPointerDown={(event)=> {
            mouseDownHandler(event, i)
          }}
          onPointerEnter={(event)=>{
            mouseEnterHandler(event, i)
          }}
          onPointerUp={(event)=> {
            mouseUpHandler(event, i)
          }}
          onMouseDown={noPointerEventSupport ? (event)=> {
            mouseDownHandler(event, i)
          } : undefined}
          onMouseEnter={noPointerEventSupport ? (event)=>{
            mouseEnterHandler(event, i)
          } : undefined}
          onMouseUp={noPointerEventSupport ? (event)=> {
            mouseUpHandler(event, i)
          } : undefined}
          key={`part_y${part.y1}`}
          x={part.x1} y={part.y1}
          width={part.x2 - part.x1}
          height={part.y2 - part.y1}
          fill="transparent"
          stroke="transparent"
          ref={ref}
      />
    })
  }
  return (<rect
    onDoubleClick={(event)=>{
      doubleClickHandler(event)}}
    onClick={(event) => {clickHandler(event)}}
    onMouseDown={noPointerEventSupport ? (event) => {mouseDownHandler(event)} : undefined}
    onMouseEnter={noPointerEventSupport ?(event)=>{mouseEnterHandler(event)} : undefined}
    onMouseUp={noPointerEventSupport ?(event)=>{mouseUpHandler(event)} : undefined}
    onPointerDown={(event) => {mouseDownHandler(event)}}
    onPointerEnter={(event)=>{mouseEnterHandler(event)}}
    onPointerUp={(event)=>{mouseUpHandler(event)}}
    onDrop={(e) => {
      e.target.dropInfo = {x: r.x, y: r.y}
    }}
    x={sq.x1}
    y={sq.y1}
    width={sq.x2 - sq.x1}
    height={sq.y2 - sq.y1}
    fill="transparent"
    stroke="transparent"
    ref={ref}
  />)
}

const MInteraction = React.memo(Interaction, (pp, np) => {
  return elementEquals(pp.e, np.e)
})
