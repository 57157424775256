import * as React from "react";

export default function Dividers (p) {
  const { elements, cSz } = p
  return <g className="lineLayer">{elements.map(element => {
    const id = `element_${JSON.stringify(element)}`
    return <MElement
        key={id}
        id={id}
        e={element}
        cSz={cSz}/>
  })}</g>
}

const Element = (p) => {
  const {e} = p
  const sq = e.sq
  return sq.parts.map((p, i) => {
    if (i === 0)
      return null
    return <line
        key={`line_y${p.y2}`}
        x1={p.x1}
        x2={p.x2}
        y1={p.y1}
        y2={p.y1}
        strokeWidth={0.5}
        stroke="black"
    />
  })
}

const MElement = React.memo(Element, (pp, np) => {
  return pp.id === np.id && pp.cSz === np.cSz
})
