import {SvgIcon, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {LOWER, UPPER} from "../../cellTypes";
import VrtOverlayTextBtn from "./vrtOverlayTextBtn";

export default function OverlayTextBtn({overlayPosition, text, fontFamily, onClick, strike}) {
    const lineY = overlayPosition === LOWER ? 16 : 8
    const textY = overlayPosition === UPPER ? 0 : lineY - 1

    if (overlayPosition !== LOWER && overlayPosition !== UPPER) {
        return <VrtOverlayTextBtn overlayPosition={overlayPosition} text={text} fontFamily={fontFamily} onClick={onClick} strike={strike}/>
    }

    return <Tooltip title={overlayPosition === "upper" ? "overlay top" : "overlay bottom"}>
        <IconButton key={`overlay_list_btn_${overlayPosition}`} onClick={onClick}>
            <SvgIcon width={30} height={30}>
                <text x={12} y={textY} textAnchor="middle" fontSize={7} fontFamily={fontFamily}>
                    <tspan dy={7}>{text}</tspan>
                </text>
                <rect x={1} y={1} width={22} height={22} fill={"none"} stroke="black"></rect>
                <line x1={1} x2={22} y1={lineY} y2={lineY} strokeWidth={0.5} stroke="black"/>
                {strike && <line x1={1} x2={22} y1={1} y2={22} strokeWidth={2.5} stroke="red"/>}
            </SvgIcon>
        </IconButton>
    </Tooltip>
}
