import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import InputSlider from "./InputSlider";
import HeightIcon from "@mui/icons-material/Height";
import {newX} from "../xutils";
import {useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function NewCrosswordModal(props) {
  const {open, setOpen, accept} = props

  const [newXTitle, setNewXTitle] = useState(`Kryss_${(new Date()).toLocaleString()}`)
  const [newXWidth, setNewXWidth] = useState(12)
  const [newXHeight, setNewXHeight] = useState(12)

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = () => {
    setOpen(false);
    const theNewX = newX(newXWidth, newXHeight, newXTitle, 30)
    accept(theNewX)
  }

  return (
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Create a new puzzle</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Provide name and size of the puzzle and press OK.
            </DialogContentText>
            <TextField
                sx={{ m: 5 }}
                id="new-crossword-title-input"
                label="Title"
                defaultValue={newXTitle}
                helperText="Name the puzzle to recognize it"
                onChange={(e)=>{setNewXTitle(e.target.value)}}
            />
            <InputSlider onChange={setNewXWidth} min={2} max={53} def={newXWidth} label="Width" icon={<HeightIcon className="rotate-90"></HeightIcon>}/>
            <InputSlider onChange={setNewXHeight} min={2} max={51} def={newXHeight} label="Height" icon={<HeightIcon/>}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCreate}>Ok</Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}
