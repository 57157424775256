import square, {equalish, sqEqualish} from "./crossword/square";
import {TEXT, WORD, HZ, VRT, UPPER, LOWER, EMPTY_KEY, ONE_CELL_TYPES, IMAGE, GHOST, RIGHT, LEFT} from "./cellTypes";
import {paragraphStyle} from "./crossword/defaultParagraphStyle";
import {t_down_right, t_right_down} from "./linkTypes";


export const eu = {
    str: e1 => {
        return e1 ? `${e1?.type || e1?.overlayPosition}_${ru.str(e1?.rect)}` : null
    }
}

export const ru = {
    equals: function (r1, r2) {
        return r1?.x === r2?.x && r1?.y === r2?.y
    },

    equalswh: function (r1, r2) {
        return this.equals(r1, r2) && (r1?.w || 1) === (r2?.w || 1) && (r1?.h || 1) === (r2?.h || 1)
    },

    str: r1 => `${r1?.x}_${r1?.y}_${r1?.w || "-"}_${r1?.h || "-"}`
}

const pEquals = (e1, e2) => {
    return e1.text === e2.text
        && e1.font === e2.font
        && e1.fontSize === e2.fontSize
        && e1.leading === e2.leading
}
export const elementEquals = (e1, e2) => {
    if (!e1 || !e2)
        return true
    if (e1.type !== e2.type)
        return false
    if (e1.type === TEXT) {
        if (e1.parts) {
            if (!e2.parts) {
                return false
            }
            for (let i = 0; i < e1.parts.length; i++) {
                if (!pEquals(e1.parts[i], e2.parts[i]) || !(e1.sq.parts[i] && e2.sq.parts[i] && sqEqualish(e1.sq.parts[i], e2.sq.parts[i]))) {
                    return false
                }
            }
        } else {
            if (e2.parts) {
                return false
            }
        }
    } else if (e1.type === IMAGE) {
        if (e1.image !== e2.image) {
            return false
        }
    }

    return pEquals(e1, e2) && e1.sq && e2.sq && sqEqualish(e1.sq, e2.sq)
}

export const overlayEquals = (e1, e2) => {
    if (!e1 || !e2)
        return true
    return ru.equalswh(e1.rect, e2.rect) && e1.overlayPosition === e2.overlayPosition;
}

export const createMatrix = (x) => {
    if (!x)
        return
    const matrix = Array(x.h)
    for (let y = 0; y < matrix.length; y++) {
        matrix[y] = Array(x.w)
    }
    for (let row = 0; row < x.h; row++) {
        for (let col = 0; col < x.w; col++) {
            matrix[row][col] = {}
        }
    }

    x.elements.forEach(element => {
        const {rect: r} = element

        if (r.w && r.h) {
            for (let k = 0; k < (r.h || 1); k++) {
                for (let l = 0; l < (r.w || 1); l++) {
                    matrix[r.y + k][r.x + l].e = element
                }
            }
        } else {
            //console.log(element)
            matrix[r.y][r.x].e = element
        }
    })

    x.overlayTexts?.forEach(ot => {
        const {rect: r} = ot
        for (let k = 0; k < (r.h || 1); k++) {
            for (let l = 0; l < (r.w || 1); l++) {
                if (ot.overlayPosition === UPPER) {
                    matrix[r.y + k][r.x + l].ot = ot
                }
                if (ot.overlayPosition === LOWER) {
                    matrix[r.y + k][r.x + l].otl = ot
                }
                if (ot.overlayPosition === RIGHT) {
                    matrix[r.y + k][r.x + l].otRight = ot
                }
                if (ot.overlayPosition === LEFT) {
                    matrix[r.y + k][r.x + l].otLeft = ot
                }
            }
        }
    })

    x.links.forEach(link => {
        if (link.wordRange) {
            matrix[link.wordRange.y][link.wordRange.x].l = matrix[link.wordRange.y][link.wordRange.x].l || []
            matrix[link.wordRange.y][link.wordRange.x].l.push(link)
        }
    })

    // calculate sq for all elements and all overlayTexts and elements
    const pStyle = x.paragraphStyle || paragraphStyle
    const cSz = pStyle?.squareSize || 28.34645669
    x.overlayTexts?.forEach(ot => {
        ot.sq = calculateOverlayRect(ot, matrix, cSz)
    })
    x.elements.forEach(elem => {
        try {
            elem.sq = calculateElementRect(elem, matrix, cSz)
        } catch (err) {
            console.error(err)
            throw err
        }
    })

    return matrix
}

export const allStep = (matrix, focusedElement, direction, forward = true) => {
    const {e, part} = focusedElement
    const {x, y} = e.rect
    let newE = null
    if (direction === HZ) {
        if (forward) {
            if (e.overlayPosition === LEFT) { // Move from left overlay into element
                newE = matrix[y][x].e
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            } else if (!e.overlayPosition && matrix[y][x].otRight) { // move from element to right overlay
                newE = matrix[y][x].otRight
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            } else if (x < matrix[y].length - (e.rect.w || 1) && matrix[y][x + (e.rect.w || 1)].e.type) {
                newE = (matrix[y][x + (e.rect.w || 1)].otLeft || matrix[y][x + (e.rect.w || 1)].e)
            }
        } else {
            if (e.overlayPosition === RIGHT) { // From right overlay into element
                newE = matrix[y][x].e
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            } else if (!e.overlayPosition && matrix[y][x].otLeft) { // From element to left overlay
                newE = matrix[y][x].otLeft
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            } else if (x > 0 && matrix[y][x - 1].e.type) {
                newE = matrix[y][x - 1].otRight || matrix[y][x - 1].e
            }
        }
        if (newE) {
            return {
                e: newE,
                part: (newE.parts && part !== 'undefined') ? (part < newE.parts.length ? part : 0) : undefined,
                key: new Date().getTime()
            }
        }
    } else if (direction === VRT) {
        if (forward) {
            if (e.parts && part !== 'undefined' && part < e.parts.length - 1) { // traverse split element
                return {e, part: part + 1, key: new Date().getTime()}
            } else if (e.overlayPosition === UPPER) { // Move from upper overlay into element
                newE = matrix[y][x].e
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            } else if (!e.overlayPosition && matrix[y][x].otl) { // move from element to lower overlay
                newE = matrix[y][x].otl
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            } else if (y < matrix.length - (e.rect.h || 1) && matrix[y + (e.rect.h || 1)][x].e.type) { // move to next cell, upper overlay or element
                const entry = matrix[y + (e.rect.h || 1)][x]
                newE = entry.ot || entry.e
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            }
        } else {
            if (e.parts && part !== 'undefined' && part > 0) { // traverse split element
                return {e, part: part - 1, key: new Date().getTime()}
            } else if (e.overlayPosition === LOWER) { // From lower overlay into element
                newE = matrix[y][x].e
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            } else if (!e.overlayPosition && matrix[y][x].ot) { // From element to upper overlay
                newE = matrix[y][x].ot
                return {e: newE, part: newE.parts && 0, key: new Date().getTime()}
            } else if (y > 0 && matrix[y - 1][x].e.type) { // to next cell, lower overlay or element
                const entry = matrix[y - 1][x]
                newE = entry.otl || entry.e
                return {e: newE, part: newE.parts && newE.parts.length - 1, key: new Date().getTime()}
            }
        }
    }
}

export const wordStep = (matrix, focusedElement, direction, forward = true) => {
    let newE = null
    const {e: startElement} = focusedElement
    if (startElement?.type === WORD) {
        const {x, y} = startElement.rect
        if (direction === HZ) {
            if (!forward && x > 0 && matrix[y][x - 1].e.type === WORD) {
                newE = matrix[y][x - 1].e
            }
            if (forward && x < matrix[y].length - 1 && matrix[y][x + 1].e.type === WORD) {
                newE = matrix[y][x + 1].e
            }
        } else if (direction === VRT) {
            if (!forward && y > 0 && matrix[y - 1][x].e.type === WORD) {
                newE = matrix[y - 1][x].e
            }
            if (forward && y < matrix.length - 1 && matrix[y + 1][x].e.type === WORD) {
                newE = matrix[y + 1][x].e
            }
        }
    }
    return newE ? {e: newE, key: new Date().getTime()} : null
}

export const compareElementRects = (e1, e2) => {
    return compareRects(e1.rect, e2.rect)
}
export const compareRects = (r1, r2) => {
    if (r1.y === r2.y) {
        return r1.x - r2.x
    }
    return r1.y - r2.y
}

const isOneCellType = type => ONE_CELL_TYPES.indexOf(type) !== -1

export const isInside = (r1, r2) => {
    const xInside = r1.x >= r2.x && r1.x < r2.x + (r2.w || 1)
    const yInside = r1.y >= r2.y && r1.y < r2.y + (r2.h || 1)
    return xInside && yInside
}

export const isPartiallyInside = (r1, r2) => {
    const upperLeft = isInside({x: r1.x, y: r1.y}, r2)
    const upperRight = isInside({x: r1.x + (r1.w || 1) - 1, y: r1.y}, r2)
    const lowerLeft = isInside({x: r1.x, y: r1.y + (r1.h || 1) - 1}, r2)
    const lowerRight = isInside({x: r1.x + (r1.w || 1) - 1, y: r1.y + (r1.h || 1) - 1}, r2)

    //console.log(`${JSON.stringify(r1)} ${JSON.stringify(r2)}`)
    //console.log(`${upperLeft} ${upperRight} ${lowerLeft} ${lowerRight} `)
    // All inside. So not partially...
    if (upperLeft && upperRight && lowerLeft && lowerRight) {
        return false
    }
    //if (upperLeft || upperRight || lowerLeft || lowerRight)
    //  console.log("PARTIAL")
    return upperLeft || upperRight || lowerLeft || lowerRight
}

export const isFullyInside = (r1, r2) => {
    const xInside = r1.x >= r2.x && r1.x + (r1.w || 1) <= r2.x + (r2.w || 1)
    const yInside = r1.y >= r2.y && r1.y + (r1.h || 1) <= r2.y + (r2.h || 1)
    //console.log(`isFullyInside ${JSON.stringify(r1)} ${JSON.stringify(r2)} => ${xInside} ${yInside}`)
    return xInside && yInside
}

export const clearLinks = (xObj, rect) => {
    xObj.links = xObj.links.filter(link => {
        const {source, wordRange} = link
        return !(isInside(source, rect) || isInside(wordRange, rect));
    })
}

export const divideArea = (xObj, matrix, rect) => {
    console.log("divideArea")
    const {x, y} = rect
    const elem = matrix[y][x].e
    console.log(`splitArea ${ru.str(rect)} ${JSON.stringify(elem)}`)
    if (!ru.equals(elem.rect, rect)) {
        console.error(`Trying to divide rect not matching elem ${JSON.stringify(rect)}  ${JSON.stringify(elem.rect)}`)
        return
    }
    if (elem.type === TEXT) {
        if (elem.parts) {
            console.error("Trying to divide element allready divided")
            return
        }
        clearLinks(xObj, rect)
        elem.parts = [{text: elem.text}, {text: EMPTY_KEY}]
        delete (elem.text)
        return elem
    }
}

export const splitArea = (xObj, matrix, rect) => {
    const {x, y, w = 1, h = 1} = rect
    const elem = matrix[y][x].e
    console.log(`splitArea ${ru.str(rect)} ${JSON.stringify(elem)}`)
    if (!ru.equals(elem.rect, rect)) {
        console.error(`Trying to split rect not matching elem ${JSON.stringify(rect)}  ${JSON.stringify(elem.rect)}`)
        return
    }
    if (elem.type === TEXT) {
        clearLinks(xObj, rect)
        xObj.elements = xObj.elements.filter(e => !isFullyInside(e.rect, rect))
        xObj.overlayTexts = xObj.overlayTexts?.filter(e => !isFullyInside(e.rect, rect))
        for (let rx = x; rx < x + w; rx++) {
            for (let ry = y; ry < y + h; ry++) {
                xObj.elements.push({
                    type: TEXT,
                    rect: {x: rx, y: ry},
                    text: EMPTY_KEY
                })
            }
        }
        xObj.elements = xObj.elements.sort(compareElementRects)
    }
}

export const clearLetters = (xObj, matrix, rect = {x: 0, y: 0, w: xObj.w, h: xObj.h}) => {
    const {x, y} = rect
    const w = rect.w || 1
    const h = rect.h || 1
    for (let rx = x; rx < x + w; rx++) {
        for (let ry = y; ry < y + h; ry++) {
            const elem = matrix[ry][rx].e
            if (elem.type === WORD && (!elem.clr)) {
                elem.val = ""
            }
        }
    }
}

export const clearClues = (xObj, matrix, rect, part) => {
    const {x, y} = rect
    const w = rect.w || 1
    const h = rect.h || 1
    for (let rx = x; rx < x + w; rx++) {
        for (let ry = y; ry < y + h; ry++) {
            if (matrix[ry][rx].e.type !== TEXT) {
                continue
            }
            if (isFullyInside(matrix[ry][rx].e.rect, rect)) {
                //console.log(`pushing ${type} to ${rx}, ${ry}`)

                if (matrix[ry][rx].e.parts) {
                    if (part !== undefined) {
                        matrix[ry][rx].e.parts[part].text = ""
                    } else {
                        matrix[ry][rx].e.parts.forEach(ePart => ePart.text = "")
                    }
                } else {
                    matrix[ry][rx].e.text = ""
                }
            }
        }
    }
}

export const setAreaClr = (xObj, matrix, rect, clr, part) => {
    const {x, y} = rect
    const w = rect.w || 1
    const h = rect.h || 1
    for (let rx = x; rx < x + w; rx++) {
        for (let ry = y; ry < y + h; ry++) {
            if (isFullyInside(matrix[ry][rx].e.rect, rect)) {
                //console.log(`pushing ${type} to ${rx}, ${ry}`)

                if (matrix[ry][rx].e.parts) {
                    if (part !== undefined) {
                        matrix[ry][rx].e.parts[part].clr = clr
                    } else {
                        matrix[ry][rx].e.parts.forEach(ePart => ePart.clr = clr)
                    }
                } else {
                    matrix[ry][rx].e.clr = clr
                }
            }
        }
    }
}
export const setArea = (xObj, matrix, rect, type, params) => {
    const {x, y} = rect
    const w = rect.w || 1
    const h = rect.h || 1

    // Count elements that are fully inside the rect
    const elementCnt = xObj.elements.filter(e => isFullyInside(e.rect, rect)).length

    if (elementCnt === 0) {
        console.log("No element was fully contained within rect. Skipping setArea")
        return
    }

    clearLinks(xObj, rect)

    if (isOneCellType(type)) {
        // Remove all elements not inside
        xObj.elements = xObj.elements.filter(elem => !isFullyInside(elem.rect, rect))
        xObj.overlayTexts = xObj.overlayTexts?.filter(ot => !isFullyInside(ot.rect, rect))
        for (let rx = x; rx < x + w; rx++) {
            for (let ry = y; ry < y + h; ry++) {
                if (isFullyInside(matrix[ry][rx].e.rect, rect)) {
                    //console.log(`pushing ${type} to ${rx}, ${ry}`)
                    xObj.elements.push({
                        type: type,
                        rect: {x: rx, y: ry}
                    })
                }
            }
        }
    } else {
        // Count elements that are partially inside the rect
        const partialCnt = xObj.elements.filter(e => isPartiallyInside(e.rect, rect)).length
            + xObj.overlayTexts?.filter(e => isPartiallyInside(e.rect, rect)).length

        if (partialCnt > 0) {
            console.log("Some element is only partially inside the rect. Skipping setArea")
            return
        }
        xObj.elements = xObj.elements.filter(e => !isFullyInside(e.rect, rect))
        xObj.overlayTexts = xObj.overlayTexts?.filter(e => !isFullyInside(e.rect, rect))
        const toPush = {
            type: type,
            rect: rect,
            text: (type === TEXT) ? EMPTY_KEY : undefined,
            ...params
        }

        xObj.elements.push(toPush)
    }
    xObj.elements = xObj.elements.sort(compareElementRects)
    return xObj
}
export const getSelectionRect = sel => {
    const {clickedObj, endObj} = sel
    if (!endObj || endObj === clickedObj)
        return clickedObj.e.rect

    const {e} = clickedObj
    const {e: e2} = endObj

    const minX = Math.min(e.rect.x, e2.rect.x)
    const minY = Math.min(e.rect.y, e2.rect.y)

    const maxX = Math.max(e.rect.x + (e.rect.w || 1), e2.rect.x + (e2.rect.w || 1))
    const maxY = Math.max(e.rect.y + (e.rect.h || 1), e2.rect.y + (e2.rect.h || 1))

    return {x: minX, y: minY, w: maxX - minX, h: maxY - minY}
}
export const addOverlayToAreas = (newX, matrix, selections, properties) => {
    const {overlayPosition} = properties
    selections.map(getSelectionRect).forEach(rect => {
        if (matrix[rect.y][rect.x].ot && overlayPosition === UPPER) {
            console.warn(`Skip adding upper overlay to ${ru.str(rect)}`)
        } else if (matrix[rect.y][rect.x].otl && overlayPosition === LOWER) {
            console.warn(`Skip adding lower overlay to ${ru.str(rect)}`)
        } else {
            if (!newX.overlayTexts)
                newX.overlayTexts = []
            const newRect = {...rect}
            if (overlayPosition === UPPER) {
                newRect.h = 1
            } else if (overlayPosition === LOWER) {
                newRect.h = 1
                newRect.y = rect.y + (rect.h || 1) - 1
            } else if (overlayPosition === LEFT) {
                newRect.w = 1
            } else if (overlayPosition === RIGHT) {
                newRect.w = 1
                newRect.x = rect.x + (rect.w || 1) - 1
            }
            newX.overlayTexts.push({
                ...properties,
                rect: newRect,
                links: [] // Todo: Can this be removed,
            })
        }
    })
}

export function removeOverlay(overlay, xObj) {
    const index = xObj.overlayTexts.findIndex(ot => {
        const equals = overlayEquals(overlay, ot)
        return equals
    })
    if (index !== -1) {
        xObj.overlayTexts.splice(index, 1)
    }
}

function getNewDirection(matrix, x, y, defaultDir) {
    if ((y === 0 || matrix?.[y - 1][x].e.type !== WORD) && matrix?.[y][x]?.l?.filter(lnk => lnk.type === t_right_down).length > 0) {
        return HZ
    } else if ((x === 0 || matrix?.[y][x - 1].e.type !== WORD) && matrix?.[y][x]?.l?.filter(lnk => lnk.type === t_down_right).length > 0) {
        return VRT
    }
    return defaultDir
}

export const getAllWordMarkers = (matrix, xObj) => {
    const getInDir = (dir) => {
        return matrix?.flat()
            .map(pos => {
                const prev = matrix[pos.e.rect.y - (dir === VRT ? 1 : 0)]?.[pos.e.rect.x - (dir === HZ ? 1 : 0)]
                if (!prev || prev.e.type !== WORD) {
                    return getWordMarker(matrix, pos, dir, true, null, xObj)
                }
                return null
            })
            .filter(n => n)
    }

    return [HZ, VRT].flatMap(getInDir).sort()
}

export const getAllWords = (matrix, xObj) => {
    const getInDir = (dir) => {
        return matrix?.flat()
            .map(pos => {
                const prev = matrix[pos.e.rect.y - (dir === VRT ? 1 : 0)]?.[pos.e.rect.x - (dir === HZ ? 1 : 0)]
                if (!prev || prev.e.type !== WORD) {
                    return getWordMarker(matrix, pos, dir, true, null, xObj)?.map(e => (e.val || ".")).join("")
                }
                return null
            })
            .filter(n => n)
            .filter(n => n.indexOf(".") === -1)
            .filter(w => w.length > 1)
    }

    return [HZ, VRT].flatMap(getInDir).sort()
}

export const getWordMarker = (matrix, focusedElement, startDirection, startAtFocus, endElement, xObj) => {
    const {e} = (focusedElement || {})
    if (e?.type === "WORD") {
        const {x: focusX, y: focusY} = e.rect
        const wordArray = [{...e}]
        let startX = focusX
        let startY = focusY
        let direction = getNewDirection(matrix, startX, startY, startDirection)
        if (!startAtFocus) {
            do {
                let currentEntry = matrix[startY][startX]
                let previousEntry
                if (direction === HZ) {
                    if (startX > 0) {
                        previousEntry = matrix[startY][startX - 1]
                        if (previousEntry?.e.type === WORD && !currentEntry.otLeft && !previousEntry.otRight) {
                            startX--
                        } else {
                            break
                        }
                    } else if (xObj?.yPatternOffset) {
                        let newY = startY - xObj?.yPatternOffset
                        if (newY < 0) {
                            newY = xObj.h+newY
                        }
                        previousEntry = matrix[newY][xObj.w-1]
                        if (previousEntry?.e.type === WORD && !currentEntry.otLeft && !previousEntry.otRight) {
                            startX = xObj.w-1
                            startY = newY
                        } else {
                            break
                        }
                    } else {
                        break
                    }
                }
                if (direction === VRT) {
                    if (startY > 0) {
                        previousEntry = matrix[startY - 1][startX]
                        if (previousEntry.e.type === WORD && !currentEntry.ot && !previousEntry.otl) {
                            startY--
                        } else {
                            break
                        }
                    } else if (xObj?.yPatternOffset) {
                        previousEntry = matrix[xObj.h-1][startX]
                        if (previousEntry.e.type === WORD && !currentEntry.ot && !previousEntry.otl) {
                            startY=xObj.h-1
                        } else {
                            break
                        }
                    } else {
                        break
                    }
                }
                wordArray.unshift(previousEntry.e)
                direction = getNewDirection(matrix, startX, startY, direction)
                if ((direction === HZ && wordArray.length >= (xObj?.w || 100)) || (direction === VRT && wordArray.length >= (xObj?.h || 100))) {
                    break
                }
            } while (true)

        }

        let endX = focusX
        let endY = focusY
        direction = startDirection
        if (matrix?.[endY][endX]?.l?.filter(lnk => lnk.type === t_right_down).length > 0) {
            direction = VRT
        } else if (matrix?.[endY][endX]?.l?.filter(lnk => lnk.type === t_down_right).length > 0) {
            direction = HZ
        }

        do {
            let currentEntry = matrix[endY][endX]
            if (direction === HZ) {
                let nextEntry
                if (endX < matrix[0].length - 1) {
                    nextEntry = matrix[endY][endX + 1]
                    if (nextEntry.e.type === WORD && !nextEntry.otLeft && !currentEntry.otRight && (!endElement || (currentEntry.e.rect.x < endElement.rect.x))) {
                        endX++
                    } else {
                        break
                    }
                } else if (xObj?.yPatternOffset) {
                    let newY = (endY + xObj?.yPatternOffset)%xObj.h
                    nextEntry = matrix[newY][0]
                    if (nextEntry.e.type === WORD && !nextEntry.otLeft && !currentEntry.otRight && (!endElement || (currentEntry.e.rect.x < endElement.rect.x))) {
                        endY = newY
                        endX = 0
                    } else {
                        break
                    }
                } else {
                    break
                }
            }
            if (direction === VRT) {
                let nextEntry
                if (endY < matrix.length - 1) {
                    nextEntry = matrix[endY + 1][endX]
                    if (nextEntry.e.type === WORD && !nextEntry.ot && !currentEntry.otl && (!endElement || (currentEntry.e.rect.y < endElement.rect.y))) {
                            endY++
                    } else {
                        break
                    }
                } else if (xObj?.yPatternOffset) {
                    nextEntry = matrix[0][endX]
                    if (nextEntry.e.type === WORD && !nextEntry.ot && !currentEntry.otl && (!endElement || (currentEntry.e.rect.y < endElement.rect.y))) {
                        endY=0
                    } else {
                        break
                    }
                } else {
                    break
                }
            }
            wordArray.push(matrix[endY][endX].e)
            if (matrix?.[endY][endX]?.l?.filter(lnk => lnk.type === t_right_down).length > 0) {
                direction = VRT
            } else if (matrix?.[endY][endX]?.l?.filter(lnk => lnk.type === t_down_right).length > 0) {
                direction = HZ
            }
            if ((direction === HZ && wordArray.length >= (xObj?.w || 100)) || (direction === VRT && wordArray.length >= (xObj?.h || 100))) {
                break
            }
        } while(true)

        return wordArray
    }
}

export const newX = (w, h, name, cellSz, level = 3) => {
    const theX = {
        xId: new Date().getTime(),
        w: w,
        h: h,
        name: name,
        cellSz: cellSz,
        level: level,
        elements: [],
        links: [],
        themeColors: ["#FFF799", "#FBDBDB", "#E3E3E2", "#FFFFFF"],
        excludeWordList: [],
        overlayTexts: [],
        keyFont: "tektonpro-boldobl",
        wordFont: "tektonpro-bold",
        paragraphStyle: {
            name: "default",
            squareSize: 28.34645669,
            text: {
                font: "tektonpro-boldobl",
                fontSizes: [
                    {
                        nbr: 3,
                        sz: 7,
                        leading: 7.5
                    },
                    {
                        nbr: 4,
                        sz: 6.4,
                        leading: 6.2
                    }
                ]
            }
        }
    }

    for (let r = 0; r < h; r++) {
        for (let c = 0; c < w; c++) {
            theX.elements.push({type: "WORD", rect: {x: c, y: r}, val: ""})
        }
    }

    return theX
}

const doCnt = e => e?.text?.split("<br>").length || 0

const cntPartLines = element => {
    let cnt = 0;
    for (let i = 0; i < element.parts.length; i++)
        cnt += doCnt(element.parts[i])
    return cnt
}

const cntLines = (element) => {
    return element.parts ? cntPartLines(element) : doCnt(element)
}

export const calculateOverlayRect = (ot, matrix, cSz) => {
    const {rect: r} = ot
    const otLines = doCnt(ot)
    let maxLines = 0
    try {
        for (let k = 0; k < (r.h || 1); k++) {
            for (let l = 0; l < (r.w || 1); l++) {
                const e = matrix[r.y + k][r.x + l].e
                if (e.type === TEXT) {
                    const eLines = cntLines(e)
                    maxLines = Math.max(maxLines, eLines)
                } else {
                    maxLines = 4 - otLines
                }
            }
        }
    } catch (err) {
        console.error(err)
    }
    let sq = square(ot, cSz)

    if (ot.overlayPosition === UPPER) {
        sq.y2 = sq.y1 + otLines * cSz / 4
    } else if (ot.overlayPosition === LOWER) {
        sq.y1 = sq.y2 - otLines * cSz / 4
    } else if (ot.overlayPosition === RIGHT) {
        sq.x1 = sq.x2 - (ot.fixedHzSize || 40) * 0.01 * cSz
    } else if (ot.overlayPosition === LEFT) {
        sq.x2 = sq.x1 + (ot.fixedHzSize || 40) * 0.01 * cSz
    } else {
        console.error(`Bad overlayPosition ${ot.overlayPosition}`)
        //throw new Error(`Bad overlayPosition ${ot.overlayPosition}`)
    }
    return sq
}

export const calculateElementRect = (e, matrix, cSz) => {
    const {rect: r} = e
    let sq = square(e, cSz)
    let ot = null
    let otl = null
    let otRight = null
    let otLeft = null
    if (matrix) {
        for (let k = 0; k < (r.h || 1); k++) {
            for (let l = 0; l < (r.w || 1); l++) {
                if (matrix[r.y + k][r.x + l].ot) {
                    ot = matrix[r.y + k][r.x + l].ot
                }
                if (matrix[r.y + k][r.x + l].otl) {
                    otl = matrix[r.y + k][r.x + l].otl
                }
                if (matrix[r.y + k][r.x + l].otRight) {
                    otRight = matrix[r.y + k][r.x + l].otRight
                }
                if (matrix[r.y + k][r.x + l].otLeft) {
                    otLeft = matrix[r.y + k][r.x + l].otLeft
                }
            }
        }
    }
    const otRect = ot?.sq
    const otlRect = otl?.sq
    const otRightRect = otRight?.sq
    const otLeftRect = otLeft?.sq
    if (otRect || otlRect || otRightRect || otLeftRect) {
        const otLines = ot?.text.split("<br>").length || 0
        const otlLines = otl?.text.split("<br>").length || 0
        const otRightLines = otRight?.text.split("<br>").length || 0
        const otLeftLines = otLeft?.text.split("<br>").length || 0
        const otH = (otRect && (otRect.y2 - otRect.y1)) || 0
        const otlH = (otlRect && (otlRect.y2 - otlRect.y1)) || 0
        const otRightW = (otRightRect && (otRightRect.x2 - otRightRect.x1)) || 0
        const otLeftW = ((otLeftRect && (otLeftRect.x2 - otLeftRect.x1))) || 0
        const extra = {
            ySz: otH + otlH,
            yDelta: otH,
            xSz: otLeftW + otRightW,
            xDelta: otLeftW,
            otLines: otLines,
            otlLines: otlLines,
            otRightLines: otRightLines,
            otLeftLines: otLeftLines
        }
        return square(e, cSz, extra)
    }
    return sq
}

export const stripBr = (str) => {
    if (!str)
        return str
    return str.replaceAll("-<br>", "").replaceAll("<br>", " ")
}

export const breakKey = str => {
    if (!str)
        return str
    return str.replaceAll("<br>", "\n")
}

export const unbreakKey = str => {
    if (!str)
        return str
    return str.replace(/\n+$/, "").trim().replaceAll("\n", "<br>")
}

/**
 * There are two type of lines generated.
 * The ones defining an outer border and the
 * ones defining an inner border (hole).
 * */
export const getDynamicFrameLines = (matrix, cSz) => {
    const polyLines = []
    for (let y = 0; y < matrix.length; y++) {
        for (let x = 0; x < matrix[y].length; x++) {
            const e = matrix[y]?.[x]?.e
            const type = e?.type
            const newLines = []
            if (type && type !== GHOST) {
                const lType = matrix[y]?.[x - 1]?.e?.type
                const uType = matrix[y - 1]?.[x]?.e?.type
                const rType = matrix[y]?.[x + 1]?.e?.type
                const lowerType = matrix[y + 1]?.[x]?.e?.type
                const sq = square({rect: {x: x, y: y, w: 1, h: 1}}, cSz)
                if (!lType || lType === GHOST) {
                    newLines.push([{x: sq.x1, y: sq.y2}, {x: sq.x1, y: sq.y1}])
                }
                if (!uType || uType === GHOST) {
                    newLines.push([{x: sq.x1, y: sq.y1}, {x: sq.x2, y: sq.y1}])
                }
                if (!rType || rType === GHOST) {
                    newLines.push([{x: sq.x2, y: sq.y1}, {x: sq.x2, y: sq.y2}])
                }
                if (!lowerType || lowerType === GHOST) {
                    newLines.push([{x: sq.x2, y: sq.y2}, {x: sq.x1, y: sq.y2}])
                }
            }
            newLines.forEach(line => {
                const oldBeforeLine = polyLines.find(obl => {
                    const endPoint = obl[obl.length - 1]
                    return equalish(endPoint.x, line[0].x) && equalish(endPoint.y, line[0].y)
                })
                const oldAfterLine = polyLines.find(obl => {
                    const startPoint = obl[0]
                    return equalish(startPoint.x, line[1].x) && equalish(startPoint.y, line[1].y)
                })
                if (oldBeforeLine) {
                    oldBeforeLine.push(line[1])
                } else if (oldAfterLine) {
                    oldAfterLine.unshift(line[0])
                }
                if (!(oldBeforeLine || oldAfterLine)) {
                    polyLines.push(line)
                }
            })
        }
    }
    const newPolyLines = []
    let cntr = 0
    while (polyLines.length > 0) {
        if (cntr++ > 10) {
            throw new Error("Problem with dynamic frame!!!!")
        }
        const line = polyLines.pop()
        const oldBeforeLine = newPolyLines.find(obl => {
            const endPoint = obl[obl.length - 1]
            return equalish(endPoint.x, line[0].x) && equalish(endPoint.y, line[0].y)
        })
        if (oldBeforeLine) {
            oldBeforeLine.pop()
            oldBeforeLine.push(...line)
        } else {
            newPolyLines.push(line)
        }
    }

    return newPolyLines
}

export const hasLink = (matrix, pos, linkType) => {
    return (matrix?.[pos.y][pos.x]?.l?.filter(lnk => lnk.type === linkType) || []).length > 0
}

export const printXForAlgo = (xObj, matrix) => {
    const rows = []
    for (let y = 0; y < xObj.h; y++) {
        let str = ""
        for (let x = 0; x < xObj.w; x++) {
            const elem = matrix[y][x].e
            if (elem.type === WORD) {
                str += elem.val || " "
            } else {
                str += "#"
            }
        }
        rows.push(str)
    }
    console.log(JSON.stringify({board: rows}, null, 2))
}
