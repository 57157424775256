import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    fifo: []
}

const fab5Slice = createSlice({
    name: "fab5",
    initialState,
    reducers: {
        clear: (state, action) => {
            return initialState
        },
        triggerUsed: {
            reducer(state, action) {
                const fifo = [...state.fifo]
                const index = fifo.findIndex(x => x.xId === action.payload.xId)
                fifo.unshift({...action.payload})
                if (index !== -1) {
                    fifo.splice(index+1, 1)
                }
                return {...state, fifo: fifo.slice(0,5)}
            }
        }
    }
})

export const fab5Reducer = fab5Slice.reducer
export const triggerUsed = fab5Slice.actions.triggerUsed