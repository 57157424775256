import {useState} from "react";
import {TextareaAutosize} from "@mui/material";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {paragraphStyle} from "../../crossword/defaultParagraphStyle";

const cSz = paragraphStyle.squareSize
const font = paragraphStyle.text.font
const fontSizes = paragraphStyle.text.fontSizes
const fontSize = fontSizes[1].sz
const leading = fontSizes[1].leading

export default function CommitTextArea ({onSave, theKey, onDelete}) {
    const [newValue, setNewValue] = useState()

    const splitKey = (newValue || theKey)?.split("\n")
    if (!splitKey)
        return null
    const longestLength = Math.max(...(splitKey.map(el => el.length)));
    const width = longestLength > 7 ? (longestLength > 15 ?  cSz*3 : cSz*2) : cSz

    const theValue = "" + (!isNaN(newValue?.length) ? newValue : theKey)
    console.log(`${theKey} theValue = ${theValue}`)

    return <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={200}
            height={100}
            viewBox={`-1 -1 ${100} ${50}`}
        >
            <rect fill="none" width={width} height={(cSz/4)*splitKey.length} stroke="black" strokeWidth={0.5}/>
            <rect fill="none" width={width} height={cSz} stroke="black" strokeWidth={0.5}/>
            <g textAnchor="middle" fontSize={fontSize} fontFamily={font}>
                {splitKey.map((t, i) => (<text y={(i+1)*leading} x={width/2} key={`${i}_${t}`}>{t}</text>))}
            </g>)
        </svg>
        <TextareaAutosize
            style={{minWidth: `${longestLength*10}px`}}
            value={theValue}
            onKeyDown={(e)=>{
                if (e.shiftKey && e.code === "Enter" && newValue && newValue !== theKey) {
                    onSave(newValue)
                }
            }}
            onChange={(e)=>{
                console.log(`TextareaAutosize ${theKey} onChange ${e.target.value.toUpperCase()}`)
                setNewValue(e.target.value.toUpperCase())
            }}
        ></TextareaAutosize>
        {newValue && newValue !== theKey && <IconButton onClick={()=>{
            onSave(newValue)
        }}>
            <SaveIcon/>
        </IconButton>}
        <IconButton onClick={onDelete}>
            <DeleteIcon/>
        </IconButton>
    </>
}
