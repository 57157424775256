import * as React from "react";
import {elementEquals} from "../xutils";
import {sqEqualish} from "./square";

export default function Letters (props) {
  const {cSz, elements, paragraphStyle, wordFont} = props
  const fontSize = cSz*4/5
  return <>
    <g className="letterLayer" key="letterLayer" fontFamily={wordFont || paragraphStyle.font} textAnchor="middle" fontSize={fontSize}>
      {
        elements.map(e => (<MLetter
            key={`letter_x${e.rect.x}_y${e.rect.y}_w${e.rect.w||1}_h${e.rect.h||1}_${e.val||""}`}
            val={e.val}
            r={e.rect}
            sq={e.sq}
            fontSize={fontSize}
            locked={e.locked}
        />))
      }
    </g>
    <g className="numberLayer" key="numberLayer">
      {
        elements.map((e, i) => {
          if (!e.number)
            return null
          return <MNumber
              key={`letter_nbr_x${e.rect.x}_y${e.rect.y}_w${e.rect.w||1}_h${e.rect.h||1}_${e.number||""}`}
              cSz={cSz}
              val={e.number}
              r={e.rect}
              fontFamily={paragraphStyle.font}
          />
        })
      }
    </g>
  </>
}

const Letter = ({val, sq, fontSize, locked}) => {
  const h = sq.y2-sq.y1
  const w = sq.x2-sq.x1
  const fSz = Math.abs(fontSize - h*4/5) > 0.1 ? h*4/5 : undefined
  const y = sq.y1 + (h - (fSz || fontSize)*1.2) / 2 // Todo: 1.2 is good for tekton. Need to check font info to get good for other fonts. Or set manually...
  return (
      <text
          x={sq.x1 + w/2}
          y={y + (fSz || fontSize)}
          //dominantBaseline="middle"
          fontSize={fSz}
          fill={locked && "pink"}
      >
        {val}
      </text>)
}

const Number = (p) => {
  const {cSz, val, r, fontFamily} = p
  const w = r.w || 1
  const fontSize = 8
  const y = r.y*cSz
  return (
      <text
          x={r.x*cSz + w*cSz - 3}
          y={y}
          //dominantBaseline="middle"
          textAnchor="end"
          fontSize={fontSize}
          fontFamily={fontFamily}
          letterSpacing="-0.5pt"
      >
        <tspan dy={fontSize}>{val}</tspan>
      </text>)
}

const MLetter = React.memo(Letter, (pp, np) => {
  return sqEqualish(pp.sq, np.sq) && elementEquals(pp.e, np.e)
})

const MNumber = React.memo(Number, (pp, np) => {
  return pp.cSz === np.cSz && elementEquals(pp.e, np.e)
})
